#cultura {

    .banner-normal {
        min-height: 95dvh;
        position: relative;
        padding-top: 180px;
        padding-bottom: 200px;

        @media (width < 991px) {
            height: auto;
            min-height: 420px;
            overflow-x: hidden;
            //igual o tamanho do header a margin-top
            margin-top: 83px;
            padding-top: 134px;
            padding-bottom: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 18.24%, rgba(0, 132, 181, 0.00) 81.3%);
            mix-blend-mode: multiply;
            @media (width < 991px) {
                background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 97.21%, rgba(0, 132, 181, 0.00) 51.34%);
                mix-blend-mode: multiply;
                width: 103%;
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, #000 -19.62%, rgba(0, 0, 0, 0.00) 61.41%);
            z-index: 2;

            @media (width < 991px) {
                background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 7.21%, rgba(0, 132, 181, 0.00) 51.34%);
                mix-blend-mode: multiply;
            }
        }

        .banner-img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            object-fit: cover;
            height: 100%;
        }

        .content-text {
            position: relative;
            z-index: 9;
            display: flex;
            flex-direction: column;
            max-width: 583px;

            h1 {
                color: #FFF;
                font-family: 'Revx Neue', sans-serif;
                font-size: 3.646vw;
                font-style: normal;
                font-weight: 700;
                line-height: 3.385vw;
                /* 92.857% */
                margin-bottom: 33px;

                @media (max-width: 1100px) {
                    margin-bottom: 20px;
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 35px;
                    /* 100% */
                }
            }

            p {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                /* 156.25% */
                margin-bottom: 44px;
                max-width: 408px;

                @media (max-width: 1100px) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    /* 128.571% */
                }
            }
        }
    }

    // componente cardProfessionals
    #cardProfessionals {
        max-width: Min(1270px, 100%);
        position: relative;
        z-index: 3;

        .card-professionals-container {
            gap: 0 8px;
            margin-block: -198px 157px;
            isolation: isolate;

            @media(max-width: 1600px) {
                margin-block: -102px 157px;
            }

            @media (max-width: 1100px) {
                overflow-y: scroll;
            }

            .card-professional {
                padding: 34px 47px;
                color: #F5F5F5;
                height: 311px;
                mix-blend-mode: multiply;
                display: flex;
                flex-direction: column;
                justify-content: end;

                @media (max-width: 1100px) {
                    aspect-ratio: 1.1;
                }

                .conteudo {
                    position: unset;
                    z-index: 2;
                }

                &::after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    z-index: -1;
                    background: linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%);
                    mix-blend-mode: multiply;
                    transition: all .3s ease-in;
                }

                &:hover {
                    transition: all .3s ease-in;
                    margin-top: -15px;

                    ::after {
                        content: '';
                        position: absolute;
                        inset: 0;
                        z-index: -1;
                        background: linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%);
                        transition: all .3s ease-in;
                    }
                }

                &-img {
                    inset: 0;
                    object-fit: cover;
                    height: 100%;
                    z-index: -2;
                }

                &-title {
                    font-family: 'Revx Neue', sans-serif;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px;
                    /* 100% */
                    margin-bottom: 11px;
                }

                &-text {
                    font-family: inherit;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    /* 156.25% */
                }

                &-title,
                &-text {
                    z-index: 2;
                }
            }
        }
    }

    .know-company {
        padding-top: 0px;
        padding-bottom: 10.417vw;
        position: relative;

        @media (max-width: 1399px) {
            padding-bottom: 5vw;
        }
        @media (max-width: 1100px) {
            padding-bottom: 80px;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 45.573vw;
            height: 31.25vw;
            background: var(--tbl-secondary-secondary-500, #0084B5);
            z-index: -1;

            @media (max-width: 1100px) {
                height: 240px;
                width: 78.573vw;
            }
        }

        .wrapper-know {
            position: relative;

            .bg-destaque {
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(-97px, -20px);

                @media (max-width: 1400px) {
                    width: 120%;
                    transform: translate(-8%, -1%);
                }

                @media (max-width: 1100px) {
                    width: 110%;
                    transform: translate(-5%, 3%);
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .know-img {
                border-radius: 10px;
                position: relative;
                z-index: 9;
                margin-top: 61px;
                max-height: 410px;

                @media (max-width: 1400px) {
                    width: 100%;
                }

                @media (max-width: 1100px) {
                    margin-top: 30px;
                }
            }
        }

        .content-text {
            @media (max-width: 1100px) {
                padding-top: 40px;
            }

            h1 {
                color: #383838;
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 400;
                line-height: 50px;
                /* 111.111% */
                position: relative;
                margin-bottom: 32px;

                &::after {
                    content: '';
                    position: absolute;
                    top: -35px;
                    left: 0;
                    width: 63px;
                    height: 4px;
                    background: #00AEEF;
                }

                span {
                    color: #383838;
                    /* H2 - Title bold */
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 50px;
                }

                @media (max-width: 1100px) {
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28px;
                    /* 100% */
                    margin-bottom: 20px;

                    span {
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 28px;
                    }
                }
            }

            p {
                color: var(--tbl-default-black, #383838);
                /* H6 - Text Body */
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                /* 156.25% */
                margin-bottom: 56px;

                span {
                    color: var(--tbl-primary-primary-500, #00AEEF);
                    font-weight: 700;
                    line-height: 25px;
                }

                @media (max-width: 1100px) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;

                    /* 128.571% */
                    span {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                    }
                }
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 185px;
                background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                color: var(--tbl-default-white, #FFF);
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                z-index: 1;
                text-decoration: none;
                overflow: hidden;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                    z-index: -1;
                    transition: 0.4s;
                    transition: transform 0.3s ease;
                    transform-origin: 0 100%;
                    will-change: transform;
                    transform: scaleY(0);
                }

                &:hover {
                    &::after {
                        transform: scaleY(1);
                    }
                }
            }
        }
    }

    .oportunidade {
        position: relative;
        padding-bottom: 6.771vw;
        padding-top: 11.458vw;

        @media (max-width: 1100px) {
            padding-top: 45px;
        }

        .wrap-img {
            position: absolute;
            right: 0;
            aspect-ratio: 1.1315;
            max-width: 43.906vw;

            @media (max-width: 1399px) {
                top: 17vw;
            }

            @media (max-width: 1100px) {
                position: relative;
                display: flex;
                justify-content: flex-end;
                max-width: inherit;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                @media (max-width: 1100px) {
                    width: 94%;
                }
            }

            .listras {
                position: absolute;
                width: 256px;
                height: 39px;
                bottom: -15px;
                right: 0;
                object-fit: contain;
                object-position: right;
            }
        }

        .text-absolute {
            top: 80px;
            right: 0;
            width: 100%;
            overflow: hidden;
            height: 250px;
            position: absolute;
            display: flex;
            justify-content: flex-end;
            @media (max-width: 1100px) {
                height: auto;
            }

            h3 {
                font-family: 'Revx Neue';
                font-size: 14vw;
                font-style: normal;
                font-weight: 700;
                line-height: 100px;
                /* 40% */
                color: transparent;
                -webkit-text-stroke: 2px #00AEEF;
                /* width and color of the stroke */
                text-stroke: 2px #00AEEF;
                /* Standard syntax, if supported */
                background-clip: text;
                position: relative;
                right: 0;
                text-align: end;
                bottom: -80px;
                filter: blur(0);

                @media (max-width: 1100px) {
                    font-size: 80px;
                    line-height: 80px;
                }
            }
        }

        .content-text {
            padding-top: 240px;
            margin-bottom: 80px;
            @media (max-width: 1399px) {
                min-height: 550px;
            }
            h2 {
                color: #383838;
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 400;
                line-height: 50px;
                /* 111.111% */
                margin-bottom: 26px;
                position: relative;

                &::after {
                    position: absolute;
                    top: -35px;
                    left: 0;
                    width: 63px;
                    height: 4px;
                    background: #00AEEF;
                    content: '';
                }

                span {
                    color: #383838;
                    /* H2 - Title bold */
                    font-family: 'Revx Neue';
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 50px;
                }
            }

            p {
                color: var(--tbl-default-black, #383838);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                /* 156.25% */
                margin-bottom: 0;

                span {
                    color: var(--tbl-primary-primary-500, #00AEEF);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 25px;
                }
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 185px;
                background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                color: var(--tbl-default-white, #FFF);
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                z-index: 1;
                text-decoration: none;
                overflow: hidden;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                    z-index: -1;
                    transition: 0.4s;
                    transition: transform 0.3s ease;
                    transform-origin: 0 100%;
                    will-change: transform;
                    transform: scaleY(0);
                }

                &:hover {
                    &::after {
                        transform: scaleY(1);
                    }
                }
            }
        }
    }

    .treinamento {
        @media (max-width: 1100px) {
            padding-bottom: 0;

            .content-text {
                margin-bottom: 0;
            }
        }

        .text-absolute {
            @media (max-width: 1100px) {
                top: 37%;
                display: flex;
                justify-content: flex-end;
            }

            h3 {
                right: 0;
                text-align: start;
                width: fit-content;
                left: inherit;
                @media (max-width: 1600px) {
                    right: 0;
                    text-align: end;
                    font-size: 14vw;
                }

                @media (max-width: 1100px) {
                    text-align: end;
                    width: 70%;
                    right: 15px;
                    font-size: 70px;
                    line-height: 66px;
                }
            }
        }

    }

    .campanhas {
        @media (max-width: 1399px) {
            padding-top: 0;
        }
        @media (max-width: 1100px) {
            padding-bottom: 0;
        }

        .content-text {
            @media (max-width: 1399px) {
                padding-top: 220px;
            }
            @media (max-width: 1100px) {
                padding-top: 230px;
                margin-bottom: 0;
            }
        }

        .text-absolute {
            @media (max-width: 1399px) {
                top: -79px;
            }
            @media (max-width: 1100px) {
                top: 37%;
                display: flex;
                justify-content: flex-start;

                h3 {
                    width: 70%;
                    text-align: start;
                    right: -15px;
                }
            }

        }

        .wrap-img {
            left: 0;
            @media (max-width: 1399px) {
                top: 7vw;
            }
            @media (max-width: 1100px) {
                justify-content: flex-start;
                max-width: inherit;
            }

            .listras {
                transform: rotate(180deg);
                bottom: 96px;
                right: inherit;
                left: 0;
            }
        }
    }

    .liderança {
        @media (max-width: 1399px) {
            padding-top: 100px;
            padding-bottom: 0;
            .wrap-img{
                top: 4vw;
            }
            .text-absolute{
                top: -140px;
            }
            .content-text{
                padding-top: 80px;
            }
        }

        @media (max-width: 1100px) {
            padding-bottom: 60px;

            .text-absolute {
                top: 37%;
                display: flex;
                justify-content: flex-end;

                h3 {
                    right: 15px;
                }
            }

            .content-text {
                margin-bottom: 0;
                padding-top: 164px;
            }
        }

        .text-absolute h3 {
            right: 31px;
            text-align: center;
        }
    }

}
