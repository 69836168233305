#esg {
    overflow: hidden;

    .section-margin {
        margin-bottom: 200px;

        @media (max-width: 1100px) {
            margin-bottom: 100px;
        }
    }

    .s5 {
        width: 100px;
        height: 100px;
    }

    .download-pdf {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        min-width: 185px;
        margin-top: 56px;
        background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
        color: var(--tbl-default-white, #FFF);
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        z-index: 1;
        text-decoration: none;
        overflow: hidden;
        position: relative;
        padding-inline: 20px;
        width: fit-content;
        margin-bottom: 30px;

        button {
            color: #fff;
            display: flex;
            gap: 15px;
            align-items: center;

            span {
                filter: invert(1);
            }
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            z-index: -1;
            transition: 0.4s;
            transition: transform 0.3s ease;
            transform-origin: 0 100%;
            will-change: transform;
            transform: scaleY(0);
        }

        &:hover {
            &::after {
                transform: scaleY(1);
            }
        }
    }

    .download-e-selo {
        padding: 180px 0;

        @media(max-width: 991px){
            padding: 80px 0;
        }

        .title {
            color: #383838;
            font-family: "Revx Neue";
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 63px;
                height: 4px;
                background-color: #00AEEF;
                top: -38px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }

        .content-download {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 100px;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                min-width: 185px;
                margin-top: 56px;
                background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                color: var(--tbl-default-white, #FFF);
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                z-index: 1;
                text-decoration: none;
                overflow: hidden;
                position: relative;
                padding-inline: 20px;

                button {
                    color: #fff;
                    display: flex;
                    gap: 15px;
                    align-items: center;

                    span {
                        filter: invert(1);
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                    z-index: -1;
                    transition: 0.4s;
                    transition: transform 0.3s ease;
                    transform-origin: 0 100%;
                    will-change: transform;
                    transform: scaleY(0);
                }

                &:hover {
                    &::after {
                        transform: scaleY(1);
                    }
                }
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 50px;
            margin-top: 100px;

            div {
                display: flex;
                gap: 20px;

                img {
                    width: 50px;
                    height: 50px;
                    filter: invert(48%) sepia(69%) saturate(1777%) hue-rotate(162deg) brightness(98%) contrast(101%);
                }
            }
        }

        .text-container {
            max-width: 50%;
            display: flex;
            align-items: center;

            @media(max-width: 991px) {
                max-width: 100%;
            }

            p {
                color: var(--tbl-default-black, #383838);

                font-family: inherit;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                /* 156.25% */

                strong {
                    color: var(--tbl-primary-primary-500, #00AEEF);
                }

                @media(max-width: 991px){
                    text-align: center;
                }
            }
        }

        .s5 {
            float: right;
            margin-right: 50px;

            @media(max-width: 991px){
                float: inherit;
                margin: 20px auto;
                display: flex;
            }
        }


        .title-section {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            margin-bottom: 30px;
            position: relative;
            text-align: center;
            margin-bottom: 80px;

            @media(max-width: 991px){
                margin-bottom: 30px;
            }

            &::after {
                content: '';
                position: absolute;
                width: 63px;
                height: 4px;
                background-color: #00AEEF;
                top: -38px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }

            strong {
                color: var(--tbl-default-black, #383838);
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }

            @media (max-width: 1100px) {
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
                /* 100% */
                padding-inline: 17.5px;

                strong {
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
        }
    }

    .cards {
        width: 100%;
        padding-top: 150px;

        @media (max-width: 1100px) {
            padding-top: 95px;
            padding-bottom: 0px;
        }

        .title-section {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            margin-bottom: 30px;
            position: relative;
            text-align: center;

            &::after {
                content: '';
                position: absolute;
                width: 63px;
                height: 4px;
                background-color: #00AEEF;
                top: -38px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }

            strong {
                color: var(--tbl-default-black, #383838);
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }

            @media (max-width: 1100px) {
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
                /* 100% */
                padding-inline: 17.5px;

                strong {
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
        }

        p {
            color: var(--tbl-default-black, #383838);
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 630px;
            margin-bottom: 26px;
            margin: 0 auto;
            text-align: center;

            strong {
                color: var(--tbl-primary-primary-500, #00AEEF);
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }

            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                /* 128.571% */
                padding-inline: 17.5px;

                strong {
                    line-height: 18px;
                    /* 128.571% */
                    font-size: 14px;
                }
            }
        }

        .row {
            gap: 59px;
            row-gap: 0;

            @media (max-width: 1100px) {
                gap: 10px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                overflow-x: scroll;
                padding-bottom: 1rem;
                padding-inline: 20px;
            }
        }

        .mini-box {
            border-radius: 20px 0px;
            background: #FFF;
            box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
            padding: 24px 28px;
            max-width: 350px;
            height: auto;
            margin-top: 56px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .icone {
                height: 28px;
            }

            .numero {
                color: var(--tbl-secondary-secondary-500, #0084B5);
                font-family: 'Revx Neue';
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                margin-top: 29px;

                @media(max-width: 1400px){
                    font-size: 25px;
                }
            }

            .desc {
                color: var(--tbl-default-black, #383838);
                font-family: 'Revx Neue';
                font-size: 18px;
                font-style: normal;
                // font-weight: 700;
                line-height: 16px;
            }

            &:hover {
                background-color: #0084B5;
                transition: 0.3s ease-in-out;

                img {
                    filter: grayscale(100%) brightness(2);
                    transition: 0.3s ease-in-out;
                }

                .numero,
                .desc {
                    color: #fff;
                    transition: 0.3s ease-in-out;
                }
            }
        }
    }

    .slider-section {
        position: relative;
        margin-top: 70px;

        .slider.center {
            .cl {
                height: 544px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                @media (max-width: 991px) {
                    height: 415px;
                }

                .content {
                    width: 100%;
                    height: 100%;
                    position: relative;

                    .texto {
                        position: absolute;
                        bottom: 0;
                        left: 43px;
                        max-width: 339px;
                        z-index: 2;
                        height: 100%;
                        padding-bottom: 50px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding-top: 43px;

                        @media (max-width: 991px) {
                            left: 15px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            gap: 50px;

                            @media (max-width: 991px) {
                                gap: 15px;
                            }
                        }

                        .text-top {
                            margin: 0;
                            color: #D9D9D9;
                            font-family: 'Revx Neue';
                            font-size: 25px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 40px;
                            /* 160% */
                            display: none;

                            @media (max-width: 991px) {
                                padding-inline: 17.5px;
                            }
                        }

                        h3 {
                            color: #FFF;
                            font-family: 'Revx Neue';
                            font-size: 130px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 130px;
                            /* 100% */
                            text-decoration: none;
                            position: absolute;
                            bottom: 50px;
                            left: 90px;
                        }

                        p {
                            color: #F5F5F5;
                            font-family: 'Roboto';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 25px;
                            text-align: left;
                            display: none;

                            @media(max-width: 991px) {
                                font-size: 14px;
                                line-height: 18px;
                                padding-inline: 17.5px;
                            }
                        }
                    }

                    .layer {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: var(--tbl-gradients-effects-gradient-linear, linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%));
                        mix-blend-mode: multiply;
                    }
                }
            }

            .slick-active {
                padding: 30px 0;
            }

            .slick-current {
                .texto {
                    h3 {
                        color: #FFF;
                        font-family: 'Revx Neue';
                        font-size: 90px !important;
                        font-style: normal;
                        font-weight: 700 !important;
                        line-height: 90px !important;
                        /* 100% */
                        text-decoration: underline #00AEEF !important;
                        position: relative !important;
                        bottom: 0 !important;
                        left: 0 !important;
                        text-decoration-thickness: 3px !important;

                        @media (max-width: 991px) {
                            font-size: 58.205px !important;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 58.205px !important;
                            /* 100% */
                            padding-inline: 17.5px;
                        }
                    }

                    p {
                        display: block !important;
                    }

                    .text-top {
                        display: block !important;
                    }
                }

                .cl .content .layer {
                    background: var(--TBL-Gradients-Effects-gradienteDiagonal, linear-gradient(260deg, #000 -58.46%, rgba(0, 0, 0, 0.00) 124.89%));
                }
            }

            .slick-center {
                transform: scale(1.1);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                z-index: 99;
                position: relative;
                max-width: 540px;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 4px;
                    background-color: #0084B5;
                    bottom: 34px;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                }
            }

            .slick-slide:not(.slick-active) {
                margin: 30px 0;
            }

            .child {
                width: 100%;
            }

            .slide:not(.slick-active) {
                cursor: pointer;
            }

            .layer-blue {
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0.00) 100%);
            }
        }

        .slider.center-first {
            .cl {
                height: 544px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                @media (max-width: 991px) {
                    height: 415px;
                }

                .content {
                    width: 100%;
                    height: 100%;
                    position: relative;

                    .texto {
                        position: absolute;
                        bottom: 0;
                        left: 43px;
                        max-width: 339px;
                        z-index: 2;
                        height: 100%;
                        padding-bottom: 50px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding-top: 43px;

                        @media (max-width: 991px) {
                            left: 15px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            gap: 50px;

                            @media (max-width: 991px) {
                                gap: 15px;
                            }
                        }

                        .text-top {
                            margin: 0;
                            color: #D9D9D9;
                            font-family: 'Revx Neue';
                            font-size: 25px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 40px;
                            /* 160% */
                            display: none;

                            @media (max-width: 991px) {
                                padding-inline: 17.5px;
                            }
                        }

                        h3 {
                            color: #FFF;
                            font-family: 'Revx Neue';
                            font-size: 130px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 130px;
                            /* 100% */
                            text-decoration: none;
                            position: absolute;
                            bottom: 50px;
                            left: 90px;
                        }

                        p {
                            color: #F5F5F5;
                            font-family: 'Roboto';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 25px;
                            text-align: left;
                            display: none;

                            @media(max-width: 991px) {
                                font-size: 14px;
                                line-height: 18px;
                                padding-inline: 17.5px;
                            }
                        }
                    }

                    .layer {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: var(--tbl-gradients-effects-gradient-linear, linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%));
                        mix-blend-mode: multiply;
                    }
                }
            }

            .slick-active {
                padding: 30px 0;
            }

            .slick-current {
                .texto {
                    h3 {
                        color: #FFF;
                        font-family: 'Revx Neue';
                        font-size: 90px !important;
                        font-style: normal;
                        font-weight: 700 !important;
                        line-height: 90px !important;
                        /* 100% */
                        text-decoration: underline #00AEEF !important;
                        position: relative !important;
                        bottom: 0 !important;
                        left: 0 !important;
                        text-decoration-thickness: 3px !important;

                        @media (max-width: 991px) {
                            font-size: 58.205px !important;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 58.205px !important;
                            /* 100% */
                            padding-inline: 17.5px;
                        }
                    }

                    p {
                        display: block !important;
                    }

                    .text-top {
                        display: block !important;
                    }
                }

                .cl .content .layer {
                    background: var(--TBL-Gradients-Effects-gradienteDiagonal, linear-gradient(260deg, #000 -58.46%, rgba(0, 0, 0, 0.00) 124.89%));
                }
            }

            .slick-center {
                transform: scale(1.1);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                z-index: 99;
                position: relative;
                max-width: 540px;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 4px;
                    background-color: #0084B5;
                    bottom: 34px;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                }
            }

            .slick-slide:not(.slick-active) {
                margin: 30px 0;
            }

            .child {
                width: 100%;
            }

            .slide:not(.slick-active) {
                cursor: pointer;
            }

            .layer-blue {
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0.00) 100%);
            }
        }


        .center-prev,
        .center-next,
        .slider-1-next,
        .slider-1-prev,
        .slider-2-next,
        .slider-2-prev,
        .slider-3-next,
        .slider-3-prev {
            position: absolute;
            top: calc(50% - 64px);
            cursor: pointer;
            z-index: 2;

            @media(max-width: 991px) {
                display: none;
            }

        }

        .center-prev,
        .slider-1-prev,
        .slider-2-prev,
        .slider-3-prev {
            left: 88px;
        }

        .center-next,
        .slider-1-next,
        .slider-2-next,
        .slider-3-next {
            right: 88px
        }

        .first-prev,
        .first-next {
            position: absolute;
            top: calc(50% - 64px);
            cursor: pointer;
            z-index: 2;

            @media(max-width: 991px) {
                display: none;
            }

        }

        .first-prev {
            left: 88px;
        }

        .first-next {
            right: 88px
        }

        .layer-blue {
            position: absolute;
            width: 476px;
            height: calc(100% - 64px);
            top: 30px;
            background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0.00) 100%);
            z-index: 1;

            @media(max-width: 991px) {
                display: none;
            }
        }

        .layer-blue-second {
            position: absolute;
            width: 476px;
            height: calc(100% - 64px);
            top: 30px;
            right: 0;
            background: linear-gradient(90deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%);
            z-index: 1;

            @media(max-width: 991px) {
                display: none;
            }

        }
    }

    .ambiental {
        padding: 0 0 0px 77px;
        display: flex;
        justify-content: end;
        flex-wrap: wrap;

        &-container {
            gap: 133px;

            &--text {
                max-width: 621px;
                padding-top: 200px;

                @media(max-width: 991px) {
                    margin-top: 0px;
                    padding-top: 0;
                }

                .divider {
                    height: 4px;
                    width: 63px;
                    background: #00AEEF;
                    margin-bottom: 35px;
                }

                h2 {
                    color: #383838;

                    font-family: 'Revx Neue', sans-serif;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 50px;
                    /* 111.111% */

                    max-width: 268px;
                    margin-bottom: 26px;

                    strong {
                        font-family: inherit;
                        font-weight: 700;
                    }
                }

                .text-container {
                    p {
                        color: var(--tbl-default-black, #383838);

                        font-family: inherit;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25px;
                        /* 156.25% */

                        strong {
                            color: var(--tbl-primary-primary-500, #00AEEF);
                        }
                    }

                    p+p {
                        margin-top: 26px;
                    }

                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 50px;
                        width: 185px;
                        margin-top: 56px;
                        background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                        color: var(--tbl-default-white, #FFF);
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        z-index: 1;
                        text-decoration: none;
                        overflow: hidden;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                            z-index: -1;
                            transition: 0.4s;
                            transition: transform 0.3s ease;
                            transform-origin: 0 100%;
                            will-change: transform;
                            transform: scaleY(0);
                        }

                        &:hover {
                            &::after {
                                transform: scaleY(1);
                            }
                        }
                    }
                }
            }

            &--img {
                height: fit-content;
                z-index: -1;

                .listras {
                    right: 0;
                    bottom: -16px;
                }
            }
        }

        .mask-text {
            top: -135px;
            left: 112px;
            width: min-content;
            overflow: hidden;
            height: 250px;

            h3 {
                font-family: 'Revx Neue', sans-serif;
                font-size: 14vw;
                font-style: normal;
                font-weight: 700;
                line-height: 100px;
                /* 40% */

                color: transparent;
                -webkit-text-stroke: 2px #00AEEF;
                /* width and color of the stroke */
                text-stroke: 2px #00AEEF;
                /* Standard syntax, if supported */
                background-clip: text;
                position: relative;
                bottom: -80px;
                left: 0;
                filter: blur(0);
            }
        }

    }

    .ambiental-video {
        margin-bottom: 166px;

        .bg-blue {
            left: 0;
            bottom: 0;
            background: var(--tbl-secondary-secondary-500, #0084B5);
            width: Min(948px, 50vw);
            height: 546px;
            z-index: -1;
        }

        .video-wrapper {
            display: grid;
            place-items: center;
            background-color: #F5F5F5;
            border-radius: 22px;
            padding: 84px;

            translate: 0 -108px;

            .video {
                border-radius: 8px;
                object-fit: cover;
            }

            .play-icon {
                top: 50%;
                left: 50%;
                translate: -50% -50%;
                z-index: 2;
            }
        }
    }

    .social {
        padding: 0 77px 0px 0;
        display: flex;
        flex-wrap: wrap;

        &-container {
            gap: 111px;

            &--text {
                max-width: 621px;
                padding-top: 200px;

                @media(max-width: 991px) {
                    margin-top: 0px;
                    padding-top: 0;
                }

                .divider {
                    height: 4px;
                    width: 63px;
                    background: #00AEEF;
                    margin-bottom: 35px;
                }

                h2 {
                    color: #383838;

                    font-family: 'Revx Neue', sans-serif;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 50px;
                    /* 111.111% */

                    max-width: 268px;
                    margin-bottom: 26px;

                    strong {
                        font-family: inherit;
                        font-weight: 700;
                    }
                }

                .text-container {
                    p {
                        color: var(--tbl-default-black, #383838);

                        font-family: inherit;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25px;
                        /* 156.25% */

                        strong {
                            color: var(--tbl-primary-primary-500, #00AEEF);
                        }
                    }

                    p+p {
                        margin-top: 26px;
                    }

                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 50px;
                        width: 185px;
                        margin-top: 56px;
                        background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                        color: var(--tbl-default-white, #FFF);
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        z-index: 1;
                        text-decoration: none;
                        overflow: hidden;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                            z-index: -1;
                            transition: 0.4s;
                            transition: transform 0.3s ease;
                            transform-origin: 0 100%;
                            will-change: transform;
                            transform: scaleY(0);
                        }

                        &:hover {
                            &::after {
                                transform: scaleY(1);
                            }
                        }
                    }
                }
            }

            &--img {
                height: fit-content;
                z-index: -1;

                .listras {
                    left: 0;
                    bottom: -20px;
                    transform: rotate(180deg);
                }
            }
        }

        .mask-text {
            top: -121px;
            right: 0;
            width: min-content;
            overflow: hidden;
            height: 250px;

            h3 {
                font-family: 'Revx Neue', sans-serif;
                font-size: 14vw;
                font-style: normal;
                font-weight: 700;
                line-height: 100px;
                /* 40% */

                color: transparent;
                -webkit-text-stroke: 2px #00AEEF;
                /* width and color of the stroke */
                text-stroke: 2px #00AEEF;
                /* Standard syntax, if supported */
                background-clip: text;
                position: relative;
                bottom: -80px;
                left: 0;
                filter: blur(0);
            }
        }
    }

    .compliance {
        padding: 0 0 0px 77px;
        display: flex;
        justify-content: end;
        flex-wrap: wrap;

        @media (max-width: 991px) {
            padding-bottom: 0;
        }

        &-container {
            gap: 138px;

            &--text {
                max-width: 621px;
                padding-top: 200px;

                @media(max-width: 991px) {
                    margin-top: 0px;
                    padding-top: 0;
                }

                .divider {
                    height: 4px;
                    width: 63px;
                    background: #00AEEF;
                    margin-bottom: 35px;
                }

                h2 {
                    color: #383838;

                    font-family: 'Revx Neue', sans-serif;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 50px;
                    /* 111.111% */

                    max-width: 268px;
                    margin-bottom: 26px;

                    strong {
                        font-family: inherit;
                        font-weight: 700;
                    }
                }

                .text-container {
                    p {
                        color: var(--tbl-default-black, #383838);

                        font-family: inherit;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25px;
                        /* 156.25% */

                        strong {
                            color: var(--tbl-primary-primary-500, #00AEEF);
                        }
                    }

                    p+p {
                        margin-top: 26px;
                    }

                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 50px;
                        width: 185px;
                        margin-top: 56px;
                        background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                        color: var(--tbl-default-white, #FFF);
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        z-index: 1;
                        text-decoration: none;
                        overflow: hidden;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                            z-index: -1;
                            transition: 0.4s;
                            transition: transform 0.3s ease;
                            transform-origin: 0 100%;
                            will-change: transform;
                            transform: scaleY(0);
                        }

                        &:hover {
                            &::after {
                                transform: scaleY(1);
                            }
                        }
                    }
                }
            }

            &--img {
                height: fit-content;
                z-index: -1;

                .listras {
                    right: 0;
                    bottom: -16px;
                }
            }
        }

        .mask-text {
            top: -137px;
            left: 80px;
            width: min-content;
            overflow: hidden;
            height: 250px;

            h3 {
                font-family: 'Revx Neue', sans-serif;
                font-size: 14vw;
                font-style: normal;
                font-weight: 700;
                line-height: 100px;
                /* 40% */

                color: transparent;
                -webkit-text-stroke: 2px #00AEEF;
                /* width and color of the stroke */
                text-stroke: 2px #00AEEF;
                /* Standard syntax, if supported */
                background-clip: text;
                position: relative;
                bottom: -80px;
                left: 0;
                filter: blur(0);
            }
        }
    }

    .cta-sobre {
        padding: 132.5px 321px 132.5px 77px;

        &>img {
            inset: 0;
            object-fit: cover;
            height: 100%;
            width: 100%;
            z-index: -2;
        }

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            background: var(--tbl-gradients-effects-gradiente-diagonal, linear-gradient(260deg, #000 -13.46%, rgba(0, 0, 0, 0.00) 78.89%));
            z-index: -1;
        }

        &-container {
            justify-content: end;

            &--text {
                max-width: 408px;
                color: #FFF;

                h2 {
                    font-family: 'Revx Neue', sans-serif;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 50px;
                    /* 111.111% */

                    max-width: 321px;

                    span {
                        font-family: inherit;
                        font-weight: 400;
                    }
                }

                .text-container {
                    p {
                        font-family: inherit;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25px;
                        margin-block: 30px 56px;
                    }

                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 50px;
                        width: 185px;
                        margin-top: 56px;
                        background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                        color: var(--tbl-default-white, #FFF);
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        z-index: 1;
                        text-decoration: none;
                        overflow: hidden;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                            z-index: -1;
                            transition: 0.4s;
                            transition: transform 0.3s ease;
                            transform-origin: 0 100%;
                            will-change: transform;
                            transform: scaleY(0);
                        }

                        &:hover {
                            &::after {
                                transform: scaleY(1);
                            }
                        }
                    }
                }
            }
        }

        .lines-detail {
            left: 0;
            bottom: 90px;
            top: auto;
            max-width: 765px;
            max-height: 230px;
        }
    }

    @media (width < 991px) {
        .ambiental {
            padding: 0 0 0 0;

            &-container {
                flex-direction: column-reverse;
                gap: 0;

                &--text {
                    max-width: 100%;
                    padding-inline: 25px;

                    h2 {
                        font-size: 28px;
                        line-height: 28px;
                        margin-bottom: 32px;
                        max-width: 167px;
                    }

                    .text-container {
                        p {
                            font-size: 14px;
                            line-height: 18px;
                        }

                        p+p {
                            margin-top: 20px;
                        }

                        a {
                            min-width: Min(100%, 600px);
                            margin-inline: auto;

                            font-size: 14px;
                            line-height: normal;
                        }
                    }
                }

                &--img {
                    padding-right: 25px;
                }
            }

            .mask-text {
                position: static !important;
                height: fit-content;
                text-align: center;
                width: 100%;
                margin-block: 10px 40px;

                h3 {
                    font-size: 14vw;
                    max-width: 100%;
                    position: static;
                }

                @media (width < 400px) {
                    h3 {
                        font-size: 64px;
                    }
                }

                @media (width < 310px) {
                    h3 {
                        font-size: 55px;
                    }
                }
            }
        }

        .ambiental-video {
            margin-bottom: 44px;
            padding-inline: 25px;

            .bg-blue {
                height: 240px;
                width: Min(306px, 90vw);
            }

            .video-wrapper {
                padding: 20px;
                border-radius: 8px;
                max-width: Min(500px, 100%);

                translate: 0 -90px;

                .video {
                    height: 213px;
                }
            }

            .play-icon {
                max-width: 40px;
                aspect-ratio: 1;
            }
        }

        .social {
            padding: 0 0 60px 25px;
            padding-bottom: 0;

            &-container {
                flex-direction: column-reverse !important;
                gap: 0;

                &--text {
                    max-width: 100%;
                    padding-right: 25px;

                    h2 {
                        font-size: 28px;
                        line-height: 28px;
                        margin-bottom: 32px;
                        max-width: 167px;
                    }

                    .text-container {
                        p {
                            font-size: 14px;
                            line-height: 18px;
                        }

                        p+p {
                            margin-top: 20px;
                        }

                        a {
                            min-width: Min(100%, 600px);
                            margin-inline: auto;

                            font-size: 14px;
                            line-height: normal;
                        }
                    }
                }
            }

            .mask-text {
                position: static !important;
                height: fit-content;
                text-align: end;
                width: 100%;
                padding-inline-end: 15px;
                margin-block: 12px 0px;

                h3 {
                    font-size: 14vw;
                    max-width: 100%;
                    position: static;
                }
            }
        }

        .compliance {
            padding: 0 0 60px 0;

            &-container {
                flex-direction: column-reverse;
                gap: 0;

                &--text {
                    max-width: 100%;
                    padding-inline: 25px;

                    h2 {
                        font-size: 28px;
                        line-height: 28px;
                        margin-bottom: 32px;
                        max-width: 167px;
                    }

                    .text-container {
                        p {
                            font-size: 14px;
                            line-height: 18px;
                        }

                        p+p {
                            margin-top: 20px;
                        }

                        a {
                            min-width: Min(100%, 600px);
                            margin-inline: auto;

                            font-size: 14px;
                            line-height: normal;
                        }
                    }
                }

                &--img {
                    padding-right: 25px;
                }
            }

            .mask-text {
                position: static !important;
                height: fit-content;
                text-align: center;
                width: 100%;
                margin-block: 12px 0px;

                h3 {
                    font-size: 14vw;
                    max-width: 100%;
                    position: static;
                }

                @media (width < 435px) {
                    h3 {
                        font-size: 14vw;
                    }
                }

                @media (width < 350px) {
                    h3 {
                        font-size: 55px;
                    }
                }
            }
        }

        .listras {
            display: none;
        }
    }
}

// componente cardProfessionals
#cardProfessionals {
    max-width: Min(1270px, 100%);
    position: relative;
    z-index: 3;

    .card-professionals-container {
        gap: 0 8px;
        margin-block: -198px 157px;
        isolation: isolate;

        @media(max-width: 1600px) {
            margin-block: -102px 157px;
        }

        @media (max-width: 1100px) {
            overflow-y: scroll;
        }

        a {
            text-decoration: none;
        }

        .card-professional {
            padding: 34px 47px;
            color: #F5F5F5;
            height: 311px;
            max-width: 418px;
            mix-blend-mode: multiply;

            display: flex;
            flex-direction: column;
            justify-content: end;

            .conteudo {
                position: unset;
                z-index: 2;
            }

            &::after,
            &::before {
                content: '';
                position: absolute;
                inset: 0;
                z-index: 0;
                background: linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%);
                mix-blend-mode: multiply;
                transition: all .3s ease-in;
            }

            &:hover {
                transition: all .3s ease-in;
                margin-top: -15px;
                cursor: pointer;

                ::after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    z-index: -1;
                    background: linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%);
                    transition: all .3s ease-in;
                }
            }

            &-img {
                inset: 0;
                object-fit: cover;
                height: 100%;
                z-index: -2;
            }

            &-title {
                font-family: 'Revx Neue', sans-serif;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
                /* 100% */
                margin-bottom: 11px;
            }

            &-text {
                font-family: inherit;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                /* 156.25% */
            }

            &-title,
            &-text {
                z-index: 2;
            }
        }
    }

    @media (width < 991px) {
        max-width: 100%;

        .card-professionals-container {
            margin-block: 32px 44px !important;
            margin-inline: 0 !important;

            .card-professional {
                padding: 28.5px 26.5px;
                height: 252px;
                width: 341px;

                &-text {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
}

html {
    scroll-behavior: smooth;
}
