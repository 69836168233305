@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";


#who-story {
    overflow-y: hidden;

    .ambiental-video {
        margin-bottom: 166px;
        padding-top: 90px;

        @media(max-width: 991px) {
            display: flex;
            flex-direction: column-reverse;
        }

        .content {

            .title-section {
                color: var(--tbl-default-black, #383838);
                font-family: "Revx Neue";
                font-size: 45px;
                font-style: normal;
                font-weight: 400;
                line-height: 50px;
                margin-bottom: 43px;
                position: relative;

                strong {
                    color: var(--TBL-Default-black, #383838);
                    /* H2 - Title bold */
                    font-family: 'Revx Neue';
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 50px;
                }

                @media(max-width: 991px) {
                    font-size: 28px;
                    line-height: 28px;
                    padding-inline: 17.5px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 63px;
                    height: 4px;
                    background-color: #00AEEF;
                    top: -38px;
                    left: 0;
                }

            }

            p {
                color: var(--tbl-default-black, #383838);
                font-family: "Roboto";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                margin-bottom: 26px;

                strong {
                    color: var(--TBL-Primary-primary-500, #00AEEF);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 25px;
                }

                @media(max-width: 991px) {
                    font-size: 14px;
                    line-height: 18px;
                    padding-inline: 17.5px;

                    strong {
                        color: var(--TBL-Primary-primary-500, #00AEEF);
                        /* H6 - TextBody */
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                    }
                }
            }
        }

        .bg-blue {
            left: 0;
            top: 310px;
            background: var(--tbl-secondary-secondary-500, #0084B5);
            width: Min(948px, 50vw);
            height: 815px;
            z-index: -1;

            @media(max-width: 991px) {
                width: 80%;
                height: 240px;
                top: inherit;
                bottom: -50px;
            }
        }

        .video-wrapper {
            display: grid;
            place-items: center;
            background-color: #F5F5F5;
            border-radius: 22px;
            padding: 84px;
            margin-bottom: 75px;

            @media(max-width: 991px) {
                padding: 20px;
                max-width: 339px;
            }

            .video {
                border-radius: 8px;
            }

            .play-icon {
                top: 50%;
                left: 50%;
                translate: -50% -50%;
                z-index: 2;
            }
        }
    }

    .completa {
        margin-top: 370px;
        padding-top: 91px;
        background-color: #fff;
        position: relative;

        @media (max-width: 991px) {
            margin-top: 200px;
            padding-top: 50px;
        }

        .text-absolute {
            top: -190px;
            right: 0;
            width: 100%;
            overflow: hidden;
            height: 250px;
            position: absolute;

            @media (max-width: 991px) {
                right: 15px;
            }

            h3 {
                font-family: 'Revx Neue';
                font-size: 14vw;
                font-style: normal;
                font-weight: 700;
                line-height: 100px;
                /* 40% */
                color: transparent;
                -webkit-text-stroke: 2px #00AEEF;
                /* width and color of the stroke */
                text-stroke: 2px #00AEEF;
                /* Standard syntax, if supported */
                background-clip: text;
                position: relative;
                right: inherit;
                text-align: start;
                bottom: -80px;
                left: -14px;
                filter: blur(0);

                @media(max-width: 991px) {
                    font-size: 14vw;
                    line-height: 98px;
                    left: 0;
                    text-align: end;
                    -webkit-text-stroke: 1.5px #00AEEF;

                }
            }
        }

        .title-section {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            margin-bottom: 43px;
            position: relative;

            @media(max-width: 991px) {
                font-size: 28px;
                line-height: 28px;
            }

            &::after {
                content: '';
                position: absolute;
                width: 63px;
                height: 4px;
                background-color: #00AEEF;
                top: -38px;
                left: 50%;
                transform: translateX(-50%);
            }

            strong {
                color: var(--tbl-default-black, #383838);
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;

                @media(max-width: 991px) {
                    font-size: 28px;
                    line-height: 28px;
                }

            }
        }

        .title-section {
            text-align: center;
            margin-bottom: 31px;


        }

        p {
            color: var(--tbl-neutral-neutral-500, #4F4F4F);
            text-align: center;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 627px;
            margin: 0 auto;

            @media(max-width: 991px) {
                font-size: 14px;
                line-height: 18px;
                padding-inline: 17.5px;
            }
        }

        .lines {
            position: absolute;
            right: 0;
            top: 30px;

            @media(max-width: 991px) {
                display: none;
            }
        }

        .slider-section {
            position: relative;
            margin-top: 70px;

            .slider.center {
                .cl {
                    height: 544px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                    @media (max-width: 991px) {
                        height: 415px;
                    }

                    .content {
                        width: 100%;
                        height: 100%;
                        position: relative;

                        .texto {
                            position: absolute;
                            bottom: 0;
                            left: 43px;
                            max-width: 339px;
                            z-index: 2;
                            height: 100%;
                            padding-bottom: 50px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            padding-top: 43px;

                            @media (max-width: 991px) {
                                left: 15px;
                            }

                            div {
                                display: flex;
                                flex-direction: column;
                                gap: 50px;

                                @media (max-width: 991px) {
                                    gap: 15px;
                                }
                            }

                            .text-top {
                                margin: 0;
                                color: #D9D9D9;
                                font-family: 'Revx Neue';
                                font-size: 25px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 40px;
                                /* 160% */
                                display: none;

                                @media (max-width: 991px) {
                                    padding-inline: 17.5px;
                                }
                            }

                            h3 {
                                color: #FFF;
                                font-family: 'Revx Neue';
                                font-size: 130px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 130px;
                                /* 100% */
                                text-decoration: none;
                                position: absolute;
                                bottom: 50px;
                                left: 90px;
                            }

                            p {
                                color: #F5F5F5;
                                font-family: 'Roboto';
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 25px;
                                text-align: left;
                                display: none;

                                @media(max-width: 991px) {
                                    font-size: 14px;
                                    line-height: 18px;
                                    padding-inline: 17.5px;
                                }
                            }
                        }

                        .layer {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: var(--tbl-gradients-effects-gradient-linear, linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%));
                            mix-blend-mode: multiply;
                        }
                    }
                }

                .slick-active {
                    padding: 30px 0;
                }

                .slick-current {
                    .texto {
                        h3 {
                            color: #FFF;
                            font-family: 'Revx Neue';
                            font-size: 90px !important;
                            font-style: normal;
                            font-weight: 700 !important;
                            line-height: 90px !important;
                            /* 100% */
                            text-decoration: underline #00AEEF !important;
                            position: relative !important;
                            bottom: 0 !important;
                            left: 0 !important;
                            text-decoration-thickness: 3px !important;

                            @media (max-width: 991px) {
                                font-size: 58.205px !important;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 58.205px !important;
                                /* 100% */
                                padding-inline: 17.5px;
                            }
                        }

                        p {
                            display: block !important;
                        }

                        .text-top {
                            display: block !important;
                        }
                    }

                    .cl .content .layer {
                        background: var(--TBL-Gradients-Effects-gradienteDiagonal, linear-gradient(260deg, #000 -58.46%, rgba(0, 0, 0, 0.00) 124.89%));
                    }
                }

                .slick-center {
                    transform: scale(1.1);
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    z-index: 99;
                    position: relative;
                    max-width: 540px;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 4px;
                        background-color: #0084B5;
                        bottom: 34px;
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    }
                }

                .slick-slide:not(.slick-active) {
                    margin: 30px 0;
                }

                .child {
                    width: 100%;
                }

                .slide:not(.slick-active) {
                    cursor: pointer;
                }

                .layer-blue {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0.00) 100%);
                }
            }

            .center-prev,
            .center-next {
                position: absolute;
                top: calc(50% - 64px);
                cursor: pointer;
                z-index: 2;

                @media(max-width: 991px) {
                    display: none;
                }

            }

            .center-prev {
                left: 88px;
            }

            .center-next {
                right: 88px
            }

            .layer-blue {
                position: absolute;
                width: 476px;
                height: calc(100% - 64px);
                top: 30px;
                background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0.00) 100%);
                z-index: 1;

                @media(max-width: 991px) {
                    display: none;
                }
            }

            .layer-blue-second {
                position: absolute;
                width: 476px;
                height: calc(100% - 64px);
                top: 30px;
                right: 0;
                background: linear-gradient(90deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%);
                z-index: 1;

                @media(max-width: 991px) {
                    display: none;
                }

            }
        }
    }

    .oportunidade {
        position: relative;
        padding-bottom: 130px;
        padding-top: 220px;

        @media(max-width: 1600px) {
            padding-bottom: 0px;
        }

        @media(max-width: 991px) {
            padding-bottom: 50px;
            padding-top: 120px;
        }

        .wrap-img {
            position: absolute;
            right: 0;
            aspect-ratio: 1.1315;
            width: 44.896vw;

            @media(max-width: 991px) {
                position: relative;
                width: 100%;
                max-width: inherit;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .listras {
                position: absolute;
                width: 256px;
                height: 39px;
                bottom: inherit;
                top: -15px;
                right: 0;
                object-fit: contain;
                object-position: right;
            }
        }

        .text-absolute {
            top: 80px;
            right: 0;
            width: 100%;
            overflow: visible;
            height: 250px;
            position: absolute;

            @media(max-width: 991px) {
                overflow: visible;
            }

            h3 {
                font-family: 'Revx Neue';
                font-size: 14vw;
                font-style: normal;
                font-weight: 700;
                line-height: 100px;
                /* 40% */
                color: transparent;
                -webkit-text-stroke: 2px #00AEEF;
                /* width and color of the stroke */
                text-stroke: 2px #00AEEF;
                /* Standard syntax, if supported */
                background-clip: text;
                position: relative;
                right: -41px;
                text-align: end;
                bottom: -80px;
                filter: blur(0);

                @media(max-width: 991px) {
                    right: 0px;
                    line-height: 90px;
                    font-size: 14vw;
                    text-align: start;
                    bottom: 0;
                    top: 530px;
                    left: 15px;
                }
            }
        }

        .content-text {
            padding-top: 240px;
            margin-bottom: 80px;

            @media(max-width: 991px) {
                padding-top: 111px;
                margin-bottom: 0px;
            }

            h2 {
                color: #383838;
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 400;
                line-height: 50px;
                /* 111.111% */
                margin-bottom: 26px;
                position: relative;

                @media(max-width: 991px) {
                    font-size: 25px;
                    line-height: 25px;
                }

                &::after {
                    position: absolute;
                    top: -35px;
                    left: 0;
                    width: 63px;
                    height: 4px;
                    background: #00AEEF;
                    content: '';
                }

                span {
                    color: #383838;
                    /* H2 - Title bold */
                    font-family: 'Revx Neue';
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 50px;

                    @media(max-width: 991px) {
                        font-size: 25px;
                        line-height: 25px;
                    }
                }
            }

            p {
                color: var(--tbl-default-black, #383838);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                /* 156.25% */
                margin-bottom: 0;

                @media(max-width: 991px) {
                    font-size: 14px;
                    line-height: 18px;
                }

                span {
                    color: var(--tbl-primary-primary-500, #00AEEF);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 25px;

                    @media(max-width: 991px) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 185px;
                background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                color: var(--tbl-default-white, #FFF);
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                z-index: 1;
                text-decoration: none;
                overflow: hidden;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                    z-index: -1;
                    transition: 0.4s;
                    transition: transform 0.3s ease;
                    transform-origin: 0 100%;
                    will-change: transform;
                    transform: scaleY(0);
                }

                &:hover {
                    &::after {
                        transform: scaleY(1);
                    }
                }
            }
        }
    }

    .treinamento {
        margin-top: 68px;

        @media(max-width: 1600px) {
            padding-top: 100px;
        }

        @media(max-width: 991px) {
            margin-top: 0;
            padding-top: 0;
        }

        .text-absolute {
            top: 135px;

            @media (max-width: 991px) {
                top: 320px;
                height: auto;
            }

            h3 {
                right: 0;
                text-align: start;

                @media(max-width: 1600px) {
                    bottom: 58px;
                }

                @media(max-width: 991px) {
                    top: 0;
                }
            }
        }
    }

    .campanhas {
        .text-absolute {
            @media (max-width: 991px) {
                height: auto;
                top: 450px;
            }

            h3 {
                right: 0px;
                text-align: right;

                @media(max-width: 991px) {
                    right: 0px;
                    line-height: 90px;
                    font-size: 14vw;
                    text-align: start;
                    bottom: 0;
                    top: 0;
                    left: 15px;
                }
            }
        }

        .wrap-img {
            left: 0;

            .listras {
                transform: rotate(180deg);
                bottom: inherit;
                right: inherit;
                left: 0;
            }
        }
    }

    .section4 {
        position: relative;
        padding-top: 156px;
        padding-bottom: 111px;
        background-color: #F5F5F5;

        .title-section {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            margin-bottom: 34px;
            position: relative;

            @media(max-width: 991px) {
                font-size: 28px;
                line-height: 28px;
            }

            &::after {
                content: '';
                position: absolute;
                width: 63px;
                height: 4px;
                background-color: #00AEEF;
                top: -38px;
                left: 0;
            }

            strong {
                color: var(--tbl-default-black, #383838);
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;

                @media(max-width: 991px) {
                    font-size: 28px;
                    line-height: 28px;
                }

            }
        }

        .title-section {
            text-align: center;

            &::after {
                right: 0;
                left: 0;
                margin: 0 auto;
            }
        }

        p {
            color: #4F4F4F;
            text-align: center;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 627px;
            margin: 0 auto;

            @media(max-width: 991px) {
                font-size: 14px;
                line-height: 18px;
            }
        }

        .blocos {
            margin-top: 83px;

            .bloco {
                max-width: 406px;
                height: 271px;
                border-radius: 20px 0px;
                background: #FFF;
                box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
                text-align: center;
                padding: 40px 20px;
                margin-bottom: 30px;

                h3 {
                    color: #0084B5;
                    font-family: 'Revx Neue';
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                    margin-top: 12px;
                    margin-bottom: 20px;

                    @media(max-width: 991px) {
                        font-size: 22px;
                        line-height: 37px;
                    }
                }

                p {
                    color: #383838;
                    text-align: center;
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;

                    @media(max-width: 991px) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }

                &:hover {
                    background-color: #0084B5;
                    transition: 0.3s ease;

                    img {
                        filter: grayscale(100%) brightness(2);
                        transition: 0.3s ease;
                    }

                    p,
                    h3 {
                        color: #fff;
                        transition: 0.3s ease;
                    }
                }
            }
        }


        .texto-section {
            font-family: "Revx Neue";
            font-size: 250px;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #00AEEF;
            text-stroke: 2px #00AEEF;
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: -60px;
            left: -30px;
            right: 0;
            margin: 0 auto;
            text-align: start;
            filter: blur(0);
        }
    }

}
