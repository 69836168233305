#whoStructure {
    overflow: hidden;

    .content {
        @media (max-width: 1100px) {
            padding-inline: 17.5px;
        }

        .title-section {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            margin-bottom: 43px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 63px;
                height: 4px;
                background-color: #00AEEF;
                top: -38px;
                left: 0;
            }

            strong {
                color: var(--tbl-default-black, #383838);
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }

            @media (max-width: 1100px) {
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;

                /* 100% */
                strong {
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
        }

        p {
            color: var(--tbl-default-black, #383838);
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 376px;
            margin-bottom: 26px;

            strong {
                color: var(--tbl-primary-primary-500, #00AEEF);
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }

            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;

                /* 128.571% */
                strong {
                    line-height: 18px;
                    /* 128.571% */
                    font-size: 14px;
                }
            }
        }
    }

    .about {
        position: relative;
        padding-top: 105px;
        padding-bottom: 255px;
        @media (max-width: 1399px) {
            padding-bottom: 50px;
        }
        @media (max-width: 1100px) {
            padding-top: 70px;
            padding-bottom: 0px;
        }

        .row {
            @media (max-width: 1100px) {
                flex-direction: column-reverse;
            }
        }

        .bloco-azul {
            width: 875px;
            height: 30.313vw;
            background-color: #0084B5;
            position: absolute;
            top: 228px;
            left: -42px;

            @media(max-width: 1600px) {
                max-width: 61%;
            }

            @media (max-width: 1100px) {
                width: 80%;
                max-width: 100%;
                height: 240px;
                left: 0;
                position: absolute;
                top: inherit;
                bottom: 30px;
            }
        }

        .col-bloco-cinza {
            @media (max-width: 1100px) {
                padding-inline: 25px;
            }
        }

        .bloco-cinza {
            width: 100%;
            max-width: 868px;
            border-radius: 22px;
            background: #F5F5F5;
            padding: 49px 98px 49px 98px;
            position: absolute;
            left: 151px;
            top: 95px;

            @media(max-width: 1600px) {
                left: 0;
                max-width: 55%;
            }

            @media (max-width: 1100px) {
                width: calc(100% - 35px);
                max-width: 100%;
                top: 0;
                left: 0;
                position: relative;
                padding: 14px 25px;
                margin: 0 auto;
                margin-bottom: 96px;
                height: 253px;
            }

            h3 {
                font-family: 'Revx Neue';
                font-size: 25px;
                font-style: normal;
                font-weight: 600;
                line-height: 27px;
                color: var(--tbl-default-black, #383838);
                text-align: center;
                margin-bottom: 27px;

                @media (max-width: 1100px) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 15px;
                    margin-bottom: 7px;
                }

                strong {
                    color: var(--tbl-primary-primary-500, #00AEEF);
                }
            }

            .video {
                width: 100%;
                max-width: 671px;
                border-radius: 10px;
                margin-bottom: 33px;
                position: relative;

                @media (max-width: 1100px) {
                    height: 100%;
                    margin-bottom: 14px;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }

                img {
                    border-radius: 10px;
                    width: 100%;
                }

                .play {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 39%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    pointer-events: none;
                }
            }

            p {
                color: var(--tbl-default-black, #383838);
                text-align: center;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;

                @media (max-width: 1100px) {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    /* 116.667% */
                }
            }
        }

        .content {
            margin-left: 87px;

            @media (max-width: 1100px) {
                margin-left: 0;
                padding-inline: 17.5px;
            }

            p {
                color: var(--tbl-default-black, #383838);
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                max-width: 376px;
                margin-bottom: 26px;

                strong {
                    color: var(--tbl-primary-primary-500, #00AEEF);
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 25px;
                }
            }
        }
    }

    .content-destaque {
        margin-bottom: 70px;

        h1 {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            /* 111.111% */
            margin-bottom: 32px;
            position: relative;
            text-align: center;

            &::after {
                content: '';
                position: absolute;
                top: -35px;
                width: 63px;
                height: 4px;
                background: #00AEEF;
                left: 50%;
                /* Move to 50% of the container's width */
                transform: translateX(-50%);
                /* Shift back to the left by 50% of its own width */
            }

            span {
                color: var(--tbl-default-black, #383838);
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }

            @media (max-width: 1100px) {
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;

                span {
                    font-size: 28px;
                    line-height: 28px;
                }
            }
        }

        p {
            color: var(--tbl-neutral-neutral-500, #4F4F4F);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;

            /* 156.25% */
            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                padding-inline: 17.5px;
            }
        }
    }

    .cards {
        padding-top: 160px;
        padding-bottom: 200px;

        @media (max-width: 1100px) {
            padding-top: 95px;
            padding-bottom: 0px;
        }

        .title-section {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            margin-bottom: 30px;
            position: relative;
            text-align: center;

            &::after {
                content: '';
                position: absolute;
                width: 63px;
                height: 4px;
                background-color: #00AEEF;
                top: -38px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }

            strong {
                color: var(--tbl-default-black, #383838);
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }

            @media (max-width: 1100px) {
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
                /* 100% */
                padding-inline: 17.5px;

                strong {
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
        }

        p {
            color: var(--tbl-default-black, #383838);
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 630px;
            margin-bottom: 26px;
            margin: 0 auto;
            text-align: center;

            strong {
                color: var(--tbl-primary-primary-500, #00AEEF);
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }

            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                /* 128.571% */
                padding-inline: 17.5px;

                strong {
                    line-height: 18px;
                    /* 128.571% */
                    font-size: 14px;
                }
            }
        }

        .row {
            gap: 59px;

            @media (max-width: 1100px) {
                gap: 10px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                overflow-x: scroll;
                padding-bottom: 1rem;
                padding-inline: 20px;
            }
        }

        .mini-box {
            border-radius: 20px 0px;
            background: #FFF;
            box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
            padding: 24px 28px;
            max-width: 208px;
            height: 200px;
            margin-top: 56px;

            .icone {
                height: 28px;
            }

            .numero {
                color: var(--tbl-secondary-secondary-500, #0084B5);
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
                margin-top: 29px;
            }

            .desc {
                color: var(--tbl-default-black, #383838);
                font-family: 'Revx Neue';
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;
            }

            &:hover {
                background-color: #0084B5;
                transition: 0.3s ease-in-out;

                img {
                    filter: grayscale(100%) brightness(2);
                    transition: 0.3s ease-in-out;
                }

                .numero,
                .desc {
                    color: #fff;
                    transition: 0.3s ease-in-out;
                }
            }
        }
    }

    .caminho {
        position: relative;
        padding-bottom: 300px;

        @media (max-width: 1100px) {
            padding-bottom: 0;
        }

        .img-right {
            position: absolute;
            width: 43.906vw;
            height: 745px;
            left: 0;
            top: 0;

            @media(max-width: 1600px) {
                left: -200px;
            }

            @media (max-width: 1100px) {
                position: relative;
                width: 100%;
                height: 342px;
                display: flex;
                justify-content: flex-end;
                left: 0;
                top: -100px;

            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                @media (max-width: 1100px) {
                    width: 94%;
                }
            }
        }

        .lines {
            position: absolute;
            top: -16px;
            left: 0;
            transform: rotate(180deg);

            @media (max-width: 1100px) {
                display: none;
            }
        }

        .texto-section {
            font-family: "Revx Neue";
            font-size: 215px;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #00AEEF;
            text-stroke: 2px #00AEEF;
            -webkit-background-clip: text;
            background-clip: text;
            position: relative;
            right: -25px;
            text-align: end;
            top: -50px;
            z-index: 99;
            filter: blur(0);

            @media (max-width: 1100px) {
                font-size: 80px;
                line-height: 80px;
                top: 417px;
                width: 70%;
                right: 0;
                word-wrap: break-word;
                left: inherit;
                right: -25%;
            }
        }

        .content {
            padding-top: 116px;

            @media (max-width: 1100px) {
                padding-inline: 25px;
            }
        }

        .title-section {
            max-width: 292px;
        }

        p {
            color: var(--tbl-default-black, #383838);
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 626px;
            margin-bottom: 26px;

            strong {
                color: var(--tbl-primary-primary-500, #00AEEF);
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }

            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;

                strong {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

        .btn-section {
            width: 236px;
            margin-top: 63px;
        }
    }

    .unidades {
        position: relative;
        padding-top: 160px;

        @media (max-width: 1100px) {
            padding-top: 200px;
        }

        .text-absolute {
            top: -140px;
            left: 0;
            width: 100%;
            overflow: hidden;
            height: 250px;
            position: absolute;

            h3 {
                font-family: "Revx Neue";
                font-size: 14vw;
                font-style: normal;
                font-weight: 700;
                line-height: 100px;
                color: transparent;
                -webkit-text-stroke: 2px #00AEEF;
                text-stroke: 2px #00AEEF;
                -webkit-background-clip: text;
                background-clip: text;
                position: relative;
                right: 0px;
                text-align: start;
                bottom: -80px;
                filter: blur(0);
            }

            @media (max-width: 1100px) {
                height: auto;
                display: flex;
                justify-content: flex-end;
                top: 0;

                h3 {
                    font-size: 80px;
                    line-height: 80px;
                    bottom: 0;
                    right: 15px;
                    width: 65%;
                    word-break: break-word;
                    text-align: end;
                }
            }
        }

        .map-unity {
            position: absolute;
            right: 50px;
            bottom: 0;
            z-index: -1;
            width: 35vw;

            @media (max-width: 1600px) {}

            @media (max-width: 1100px) {
                display: none;
            }
        }

        .form-select {
            height: 54px;
            background: var(--tbl-primary-primary-500, #00AEEF);
            color: var(--bertolini-white, #FFF);
            font-family: 'Roboto';
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            /* 17px */
            --bs-form-select-bg-img: url("../img/arrow-down.svg");
            padding-inline: 32px;
            background-image: url("../img/arrow-down.svg");
            background-repeat: no-repeat;
            background-position: right 24px center;
            background-size: 24px;
            margin-bottom: 24px;

            &:focus {
                box-shadow: none;
                border: none;
            }
        }
    }

    .unidades-local {
        padding: 84px 0;
        background: #F5F5F5;
        position: relative;
        z-index: 99;

        @media (max-width: 1100px) {
            padding-bottom: 3rem;
        }

        .box-unidades {
            #no-unit {
                display: flex;
                align-items: center;
                gap: 12px;

                h2 {
                    color: #383838;
                    font-family: 'Revx Neue', sans-serif;
                    font-size: 30px;
                    line-height: 42px;
                    font-weight: 600;

                    @media (max-width: 1100px) {
                        font-size: 22px;
                        line-height: 30px;
                    }
                }
                ion-icon {
                    color: #383838;
                    font-size: 28px;

                    @media (max-width: 1100px) {
                        font-size: 22px;
                    }
                }
            }
        }

        .card-unidade {
            border-bottom: solid 2px #00AEEF;
            padding: 35px 0 20px 0;
            height: 100%;
            border-radius: 20px 0px;
            background: #FFF;
            box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
            padding: 24px 28px;
            max-width: 358px;
            height: 200px;
            margin-top: 56px;

            @media (max-width: 1100px) {
                padding-inline: 55px;
                padding-bottom: 3rem;
                height: auto;
            }

            a{
                text-decoration: none;
            }

            h2 {
                color: var(--tbl-neutral-neutral-500, #4F4F4F);
                /* H5 - Description */
                font-family: 'Revx Neue';
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;
                /* 100% */
                margin-bottom: 32px;
            }

            p {
                display: flex;
                gap: 12px;
                color: var(--tbl-neutral-neutral-500, #4F4F4F);
                /* H6 - Text Body */
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                /* 156.25% */
                margin-bottom: 12px;
            }

        }

        .redes {
            display: flex;
            gap: 20px;
            padding-top: 23px;
            border-top: 2px solid #fff;
            margin-top: 8px;

            a {
                text-decoration: none;
            }
        }
    }
}
