#itucuma {
    overflow: hidden;
    .title-section {
        color: var(--tbl-default-black, #383838);
        font-family: 'Revx Neue', sans-serif;
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        margin-bottom: 34px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 63px;
            height: 4px;
            background-color: #00AEEF;
            top: -38px;
            left: 0;
        }

        strong {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue', sans-serif;
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px;
        }

        @media (width < 991px) {

            &,
            & strong {
                font-size: 28px;
                line-height: 28px;
            }
        }
    }

    .conheca-itucuma {
        position: relative;

        .video {
            padding: 84px;
            position: relative;
            border-radius: 22px;
            background: #F5F5F5;
            z-index: 2;

            .img-video {
                width: 100%;
                height: 456px;
                border-radius: 10px;
                object-fit: cover;
            }

            .player img {
                position: absolute;
                top: calc(50% - 39px);
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }

        .quadrado {
            width: 839px;
            height: 783px;
            background: #849550;
            position: absolute;
            top: 186px;
            left: 0;
            z-index: 1;

            @media(max-width: 1600px){
                max-width: 45%;
            }
        }

        .texto-section {
            font-family: "Revx Neue", sans-serif;
            font-size: 14vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #849550;
            text-stroke: 2px #849550;
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            bottom: -50px;
            left: 0;

            @media(max-width: 1600px){
                font-size: 12vw;
                bottom: -30px;
            }
        }

        .conteudo {
            margin-top: 70px;

            p {
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                margin-bottom: 20px;

                strong {
                    color: #00AEEF;
                    font-family: 'Roboto', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 25px;
                }
            }
        }

        @media (width < 991px) {
            &>.container {
                margin: 0;
                padding-inline: 25px;
                max-width: 100%;

                &>.row {
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: start !important;
                    gap: 20px;
                }
            }

            .video {
                padding: 20px;
                border-radius: 8px;
                max-width: min(100%, 500px);
                margin-inline: auto;

                .img-video {
                    border-radius: inherit;
                    height: 213px;
                }

                .player img {
                    max-width: 40px;
                    aspect-ratio: 1;
                    top: calc(50% - 18px);
                }
            }

            .quadrado {
                width: Min(306px, 80vw);
                height: 240px;

                top: auto;
                right: auto;
                bottom: -70px;
                left: 0;
            }

            .texto-section {
                font-size: 90px;
                line-height: 100%;
                max-width: 100%;
                bottom: -172px;
                left: 43px;
            }

            .conteudo {
                p {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }

    .videos {
        margin-top: 132px;
        position: relative;
        padding-bottom: 184px;
        @media (width < 1400px) {
            padding-bottom: 9.583vw;
        }
        #myTab {
            margin-bottom: 80px;
        }

        .nav-tabs {
            border-bottom: none;
        }

        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            border: none;
            background-color: transparent;
        }

        .nav-tabs .nav-link:focus,
        .nav-tabs .nav-link:hover {
            border: none;
        }

        .nav-tabs .nav-link {
            margin-bottom: 0;
            border: none;
            border-top-left-radius: none;
            border-top-right-radius: none;
        }

        .nav-link {
            padding: 40px;
            border-radius: 20px 0px;
            background: #FFF;
            box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
            max-width: 406px;
            cursor: pointer;

            h4 {
                color: #849550;
                font-family: 'Revx Neue', sans-serif;
                font-size: 35px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px;
                margin-bottom: 32px;
                text-align: center;
            }

            p {
                color: #383838;
                text-align: center;
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
            }

            &.active {
                background: #849550;

                h4,
                p {
                    color: #fff;
                }
            }
        }

        .conteudo {
            .video {
                padding: 84px;
                position: relative;
                border-radius: 22px;
                background: #F5F5F5;
                z-index: 2;

                .img-video {
                    width: 100%;
                    height: 456px;
                    border-radius: 10px;
                    object-fit: cover;
                }

                .player img {
                    position: absolute;
                    top: calc(50% - 39px);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
            }
        }

        .quadrado {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 948px;
            height: 622px;
            background: #849550;
            z-index: 1;
        }

        @media (width < 991px) {
            margin-top: 212px;
            padding-bottom: 0;

            &>.container {
                padding-inline: 25px;

                #myTab {
                    margin-bottom: 44px;

                    .col-lg-4+.col-lg-4 {
                        margin-top: 20px;
                    }

                    .col-lg-4 {
                        .nav-link {
                            margin-inline: auto;
                        }
                    }
                }

                .tab-content {
                    .video+.video {
                        margin-top: 20px;
                    }

                    .video {
                        padding: 20px;
                        border-radius: 8px;

                        .img-video {
                            border-radius: inherit;
                            height: 213px;
                        }

                        .player img {
                            max-width: 40px;
                            aspect-ratio: 1;
                            top: calc(50% - 18px);
                        }
                    }

                    .texto-section {
                        font-size: 90px;
                        line-height: 100%;
                        max-width: 100%;
                        bottom: -172px;
                        left: 43px;
                    }
                }
            }

            .nav-link {
                h4 {
                    font-size: 22px;
                    line-height: 37px;
                    margin-bottom: 32px;
                }

                p {
                    font-size: 14px;
                    line-height: 18px;
                }
            }

            .quadrado {
                width: Min(306px, 80vw);
                height: 240px;

                top: auto;
                left: auto;
                bottom: -70px;
                right: 0;
            }
        }
    }

    .slides {
        margin-top: 195px;
        margin-bottom: 150px;

        .title-section {
            text-align: center;

            &::after {
                left: 0;
                right: 0;
                margin: 0 auto;
                background-color: #849550;
            }
        }

        p {
            color: #4F4F4F;
            text-align: center;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 627px;
            margin: 0 auto;
        }

        .slider-section {
            position: relative;
            margin-top: 82px;

            .slider.center {
                .cl {
                    height: 544px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;

                    .content {
                        width: 100%;
                        height: 100%;
                        position: relative;

                        .texto {
                            position: absolute;
                            bottom: 69px;
                            left: 50px;
                            max-width: 339px;
                            z-index: 2;

                            h4 {
                                color: #F5F5F5;
                                font-family: 'Revx Neue', sans-serif;
                                font-size: 30px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 30px;
                                margin: 20px 0;
                            }

                            p {
                                color: #F5F5F5;
                                font-family: 'Roboto', sans-serif;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 25px;
                                text-align: left;
                            }
                        }

                        .layer {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: var(--tbl-gradients-effects-gradient-linear, linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #849550 100%));
                            mix-blend-mode: multiply;
                        }
                    }
                }

                .slick-active {
                    padding: 30px 0;
                }

                .slick-center {
                    transform: scale(1.1);
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    z-index: 99;
                    position: relative;
                    max-width: 540px;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 4px;
                        background-color: transparent;
                        bottom: 34px;
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    }
                }

                .slick-slide:not(.slick-active) {
                    margin: 30px 0;
                }

                .child {
                    width: 100%;
                }

                .slide:not(.slick-active) {
                    cursor: pointer;
                }

                .layer-blue {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0.00) 100%);
                }
            }

            .center-prev,
            .center-next {
                position: absolute;
                top: calc(50% - 64px);
                cursor: pointer;
                z-index: 2;
            }

            .center-prev {
                left: 88px;
            }

            .center-next {
                right: 88px
            }

            .layer-green {
                position: absolute;
                width: 476px;
                height: calc(100% - 64px);
                top: 30px;
                background: linear-gradient(90deg, #849550 0%, rgba(0, 132, 181, 0.00) 100%);
                z-index: 1;
            }

            .layer-green-second {
                position: absolute;
                width: 476px;
                height: calc(100% - 64px);
                top: 30px;
                right: 0;
                background: linear-gradient(90deg, rgba(0, 132, 181, 0.00) 0%, #849550 100%);
                z-index: 1;
            }
        }

        @media (width < 991px) {
            margin-top: calc(98px + 70px);
            margin-bottom: 60px;

            p {
                font-size: 14px;
                line-height: 18px;
            }

            .slider-section {
                margin-top: 56px;

                .cl {
                    .texto {
                        bottom: 40px !important;
                        left: 33px !important;

                        .icone>img {
                            max-width: 28.5px;
                            aspect-ratio: 1;
                        }

                        h4 {
                            font-size: 29.102px;
                            line-height: 32.336px;
                            margin-block: 11px 12px;
                        }
                    }
                }
            }

            .layer-green,
            .layer-green-second,
            .center-prev,
            .center-next {
                display: none;
            }
        }
    }

    .gente {
        position: relative;
        padding-top: 349px;
        padding-bottom: 114px;
        @media (max-width: 1400px) {
            padding-bottom: 5.938vw;
        }
        .title-section {
            margin-bottom: 23px;
        }

        p {
            color: #383838;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 621px;
            margin-bottom: 26px;

            strong {
                color: #00AEEF;
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
        }

        .video-section {
            position: absolute;
            width: Min(43vw, 843px);
            height: 745px;
            top: 106px;
            right: 0;

            .video {
                position: relative;

                .img-video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .player img {
                    position: absolute;
                    top: calc(50% - 39px);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                .linhas {
                    position: absolute;
                    right: 0;
                    bottom: -26px;
                }
            }
        }

        .texto-section {
            font-family: "Revx Neue", sans-serif;
            font-size: 14vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #849550;
            text-stroke: 2px #849550;
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: 41px;
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: center;
        }

        @media (width < 991px) {
            padding-block: 60px !important;

            &>.container {
                margin: 0;
                max-width: 100%;
                padding: 0;

                &>.row {
                    display: flex;
                    flex-direction: column-reverse;
                    gap: 175px;

                    .col-lg-7 {
                        padding-inline: 25px;
                    }
                }
            }

            p {
                font-size: 14px;
                line-height: 18px;
                max-width: 100%;
            }

            .video-section {
                position: relative;
                padding: 0 0 0 25px;
                top: 0;
                width: Min(100%, 400px);
                height: Min(100%, 350px);
                align-self: end;

                .video {
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .player img {
                        max-width: 60px;
                        max-height: 60px;
                    }

                    .linhas {
                        display: none;
                    }
                }
            }

            .texto-section {
                font-size: 90px;
                line-height: 80%;
                top: 446px;
                left: auto;
                right: 0;
                word-wrap: break-word;
                text-align: end;
            }
        }
    }

    .sustentabilidade {
        padding-top: 157px;
        padding-bottom: 205px;
        background-color: #F5F5F5;
        @media (max-width: 1400px) {
            padding-bottom: 10.677vw;
        }
        .title-section {
            text-align: center;
            margin-bottom: 29px;

            &::after {
                left: 0;
                right: 0;
                margin: 0 auto;
                background: #849550;
            }
        }

        p {
            color: #4F4F4F;
            text-align: center;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 627px;
            margin: 0 auto;
        }

        .container-fluid {
            max-width: 1521px;
        }

        .blocos {
            margin-top: 80px;

            .row {
                gap: 30px;
            }


            .card {
                border: none;
                max-width: 280px;
                height: 250px;
                border-radius: 20px 0px;
                background: #FFF;
                box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
                padding-left: 40px 20px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;

                h4 {
                    color: #5E6A39;
                    font-family: 'Revx Neue', sans-serif;
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                    margin-top: 14px;
                    margin-bottom: 20px;
                    text-align: center;
                }

                p {
                    color: #383838;
                    text-align: center;
                    font-family: 'Roboto', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    max-width: 240px;
                }

                &:hover {
                    background-color: #5E6A39;
                    transition: 0.3s ease-in-out;

                    img {
                        filter: grayscale(100%) brightness(2);
                        transition: 0.3s ease-in-out;
                    }

                    h4,
                    p {
                        color: #fff;
                        transition: 0.3s ease-in-out;
                    }
                }
            }
        }

        @media (width < 991px) {
            padding-top: 98px;
            padding-bottom: 60px;

            .title-section {
                margin-bottom: 20px;
            }

            p {
                font-size: 14px;
                line-height: 18px;
            }

            .blocos {
                margin-top: 44px;

                .row {
                    flex-direction: column;
                    gap: 20px;
                }

                .card {
                    height: 203px;
                    max-width: Min(100%, 340px);
                    margin-inline: auto;
                    padding: 32px;

                    h4 {
                        font-size: 25px;
                        line-height: 37px;
                    }

                    p {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
        }
    }

    .section6 {
        position: relative;
        padding-top: 349px;
        padding-bottom: 395px;
        @media (max-width: 1400px) {
            padding-bottom: 12.573vw;
        }
        .title-section {
            margin-bottom: 23px;
            margin-top: 79px;
        }

        p {
            color: #383838;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 621px;
            margin-bottom: 26px;

            strong {
                color: #00AEEF;
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
        }

        .video-section {
            position: absolute;
            width: 843px;
            height: 745px;
            top: 189px;
            left: 0;

            @media(max-width: 1600px){
                max-width: 48%;
            }

            .video {
                position: relative;

                .img-video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }


                .player img {
                    position: absolute;
                    top: calc(50% - 39px);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                .linhas {
                    position: absolute;
                    left: 0;
                    bottom: -26px;

                    img {
                        transform: rotate(180deg);
                    }
                }

                a{
                    display: inline-block;
                    max-width: 100%;
                }
            }
        }

        .texto-section {
            font-family: "Revx Neue", sans-serif;
            font-size: 14vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #849550;
            text-stroke: 2px #849550;
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: 122px;
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: end;
        }

        @media (width < 991px) {
            padding-top: 0;
            padding-bottom: 60px;

            &>.container {
                margin: 0;
                max-width: 100%;
                padding: 0;

                &>.row {
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: start !important;
                    gap: 195px;

                    .col-lg-6 {
                        padding-inline: 25px;
                    }
                }
            }

            .title-section {
                margin-bottom: 32px;
                margin-top: 0;
            }

            .video-section {
                position: relative;
                padding: 0 0 0 25px;
                top: 0;
                width: Min(100%, 400px);
                max-width: Min(100%, 400px);
                height: Min(100%, 350px);
                align-self: end;

                .video {
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .player img {
                        max-width: 60px;
                        max-height: 60px;
                    }

                    .linhas {
                        display: none;
                    }
                }
            }

            .texto-section {
                font-size: 80px;
                line-height: 80px;
                top: 317px;
                left: 7px;
                right: auto;
                max-width: 375px;
                text-align: start;                
            }

            p {
                font-size: 14px;
                line-height: 18px;
                max-width: 100%;
            }
        }
    }

    .realidade {
        position: relative;
        padding-top: 158px;
        padding-bottom: 171px;
        background-color: #F5F5F5;

        .texto-section {
            font-family: "Revx Neue", sans-serif;
            font-size: 14vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #849550;
            text-stroke: 2px #849550;
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: -57px;
            left: 0;
            text-align: start;
        }

        .title-section {
            text-align: center;

            &::after {
                left: 0;
                right: 0;
                margin: 0 auto;
                background-color: #849550;
            }
        }

        p {
            color: #4F4F4F;
            text-align: center;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 627px;
            margin: 0 auto;
        }

        .cards {
            margin-top: 82px;

            .bloco {
                padding: 40px 20px;
                text-align: center;
                margin-bottom: 30px;
                max-width: 406px;
                height: 271px;
                border-radius: 20px 0px;
                background: #FFF;
                box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);

                h3 {
                    color: #5E6A39;
                    font-family: 'Revx Neue', sans-serif;
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                    margin-bottom: 20px;
                    margin-top: 12px;
                }

                p {
                    color: #383838;
                    text-align: center;
                    font-family: 'Roboto', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                }

                &:hover {
                    background-color: #5E6A39;
                    transition: 0.3s ease-in-out;

                    img {
                        filter: grayscale(100%) brightness(2);
                        transition: 0.3s ease-in-out;
                    }

                    h3,
                    p {
                        color: #fff;
                        transition: 0.3s ease-in-out;
                    }
                }
            }

        }

        @media (width < 991px) {
            padding-top: 98px;
            padding-bottom: 60px;

            .title-section {
                margin-bottom: 20px;
            }

            p {
                font-size: 14px;
                line-height: 18px;
            }

            .cards {
                margin-top: 44px;

                .row {
                    flex-direction: column;
                    gap: 20px;
                }

                .bloco {
                    height: 217px;
                    max-width: Min(100%, 340px);
                    margin-inline: auto;
                    padding: 32px;
                    margin-bottom: 0;

                    img {
                        max-width: 32px;
                        aspect-ratio: 1;
                    }

                    h3 {
                        font-size: 25px;
                        line-height: 37px;
                        margin-bottom: 20px;
                        margin-top: 12px;
                    }

                    p {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }

            .texto-section {
                display: none;
            }
        }
    }
}
