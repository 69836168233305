#header {
    position: absolute;
    width: 100%;
    padding-top: 20px;
    top: 0;
    left: 0;
    z-index: 99;

    .logo-fixed{
        display: none;
    }

    &.fixed {
        position: fixed;
        background: #fff;
        padding-top: 0;
        -webkit-box-shadow: 0px 3px 5px -1px rgba(126, 126, 126, 0.75);
        -moz-box-shadow: 0px 3px 5px -1px rgba(126, 126, 126, 0.75);
        box-shadow: 0px 3px 5px -1px rgba(126, 126, 126, 0.75);
        z-index: 999999;

        .logo-fixed{
            display: block;
        }

        .logo-topo{
            display: none;
        }

        .menu .link {
            color: #00AEEF;
        }

        .redes-header a {
            transition: all 0.3s ease;

            svg path {
                fill: #00AEEF;
                transition: all 0.3s ease;
            }
        }

        .redes-header a:hover {
            transition: all 0.3s ease;

            svg path {
                fill: #00AEEF;
                transition: all 0.3s ease;
            }
        }
    }

    @media (max-width: 1100px) {
        background: #00AEEF;
        padding-top: 11px;
        padding-bottom: 18px;

        &.fixed {
            background: #00AEEF;
        }
    }

    .active {
        display: none;
    }

    .logo {
        width: 165px;
        height: 80px;

        img {
            max-width: 160px;
        }

        @media (max-width: 1100px) {
            width: 115px;
            height: 54px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .wrapper-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .menu {
        display: flex;
        gap: 24px;
        margin-left: 62px;

        @media (max-width: 1400px) {
            gap: 18px;
            margin-left: 40px;
        }

        @media (max-width: 1100px) {
            display: none;
        }

        .link {
            cursor: pointer;
            color: var(--tbl-default-white, #FFF);
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            position: relative;

            //overflow: hidden;
            @media (max-width: 1400px) {
                font-size: 16px;
            }

            .plus {
                color: var(--tbl-primary-primary-500, #00AEEF);
                display: inline-block;
                width: 10px;
                margin-left: 5px;
            }

            &::after {
                content: '';
                position: absolute;
                width: 0;
                height: 4px;
                background-color: #00AEEF;
                top: -30px;
                left: 0;
                opacity: 0;
                transition: width 0.3s ease-in-out;
                transform-origin: left;
            }

            &:hover::after {
                width: 100%;
                opacity: 1;
            }
        }


        .dropdown-content {
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 99;
            flex-direction: column;
            transition: all 0.5s ease;
            opacity: 0;
            visibility: hidden;
            height: 0;
            transition: opacity 0.5s ease-in, height 0.5s ease-in;
            white-space: nowrap;
            margin-top: 30px;
            z-index: -1;
        }

        .dropdown-content.show {
            opacity: 1;
            height: auto;
            z-index: 999;
            visibility: visible;
            transition: opacity 0.5s ease-in, height 0.5s ease-in;
        }

        .dropdown-content a {
            color: var(--tbl-default-black, #383838);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration: none;
            display: block;
            padding: 12px 24px 12px 24px;
        }

        .dropdown-content a:hover {
            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            color: #fff;
        }

        .dropdown-btn:hover .plus {}

    }

    .cta-header {
        margin-left: 24px;
        height: 50px;
        width: 166px;
        background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
        color: var(--tbl-default-white, #FFF);
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 47px;
        text-decoration: none;
        position: relative;
        z-index: 1;
        overflow: hidden;

        @media (max-width: 1400px) {
            font-size: 16px;
            width: 135px;
            margin-right: 20px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            z-index: -1;
            transition: 0.4s;
            transition: transform 0.3s ease;
            transform-origin: 0 100%;
            will-change: transform;
            transform: scaleY(0);
        }

        &:hover {
            &::after {
                transform: scaleY(1);
            }
        }

        @media (max-width: 1100px) {
            display: none;
        }
    }

    .redes-header {
        display: flex;
        gap: 25px;
        align-items: center;

        @media (max-width: 1100px) {
            display: none;
        }

        a {
            transition: all 0.3s ease;

            svg path {
                transition: all 0.3s ease;
            }
        }

        a:hover {
            transition: all 0.3s ease;

            svg path {
                fill: #0084B5;
                transition: all 0.3s ease;
            }
        }
    }

    #nav-icon1,
    #nav-icon2,
    #nav-icon3,
    #nav-icon4 {
        width: 60px;
        height: 45px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
        transform: scale(.8);
        display: none;

        @media (max-width: 1100px) {
            display: block;
        }
    }

    #nav-icon1 span,
    #nav-icon3 span,
    #nav-icon4 span {
        display: block;
        position: absolute;
        height: 9px;
        width: 100%;
        background: #fff;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }

    #nav-icon3 span:nth-child(1) {
        top: 0px;
    }

    #nav-icon3 span:nth-child(2),
    #nav-icon3 span:nth-child(3) {
        top: 18px;
    }

    #nav-icon3 span:nth-child(4) {
        top: 36px;
    }

    #nav-icon3.open span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
    }

    #nav-icon3.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    #nav-icon3.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    #nav-icon3.open span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
}

.menu-mobile {
    position: fixed;
    top: 67px;
    min-height: 100%;
    width: 100%;
    background: #fff;
    padding-left: 64px;
    padding-top: 43px;
    z-index: 999;
    margin-left: -100%;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 31px;
    padding-bottom: 30px;

    &.open {
        margin-left: 0;
    }

    .box-links {
        display: flex;
        flex-direction: column;
        gap: 14px;
        padding-top: 30px;
        padding-bottom: 20px;

        a {
            text-decoration: none;
            color: #383838;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    summary {
        color: #383838;
        font-family: 'Roboto';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        list-style: none;
        position: relative;
        width: fit-content;

        &::after {
            content: '+';
            position: absolute;
            right: -20px;
            top: 0;
            font-size: 20px;
            color: #00AEEF;
        }
    }

    #summmaryModais {
        &::after {
            display: none;
        }
    }

    summary::-webkit-details-marker {
        display: none;
    }

    details[open] summary::after {
        content: '-';
    }
}
