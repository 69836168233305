#contract {
    // result table api
    .table-container {
        width: 100%;
        margin: 20px 0;
        overflow-x: auto;
    }

    table {
    width: 100%;
    border-collapse: collapse;
    }

    thead {
    background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
    color: white;
    }

    th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 16px;

    }

    th {
    height: 50px;
    }

    td {
    height: 40px;
    font-weight: bold;
    }
    .shipment-tracking{
        th{
            border-left: 1px solid #fff;
        }
    }

    .grid-header{
        display: grid;
        grid-template-columns: 1fr .5fr .33fr;
        font-size: 16px;
        font-weight: bold;
        div{
            border: 1px solid #383838;
            padding: 5px;

        }
    }
    .grid-footer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: bold;
        div{
            border: 1px solid #383838;
            padding: 5px;
        }
    }

    .consulta {
        padding: 96px 77px 152px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        &-container {
            width: Min(1280px, 100%);
            display: flex;
            justify-content: space-between;
            gap: 32px;
            flex-wrap: wrap;
            &--text {
                max-width: 416px;

                .divider {
                    height: 4px;
                    width: 63px;
                    background: #00AEEF;
                    margin-bottom: 35px;
                }
                h2 {
                    color: #383838;

                    font-family: 'Revx Neue', sans-serif;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 50px; /* 111.111% */

                    max-width: 290px;
                    margin-bottom: 32px;

                    strong {
                        font-family: inherit;
                        font-weight: 700;
                    }
                }
                .description {
                    p {
                        color: var(--tbl-default-black, #383838);

                        font-family: inherit;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25px;

                        strong {
                            color: var(--tbl-primary-primary-500, #00AEEF);
                            font-weight: 700;
                        }
                    }
                }
            }
            &--forms {
                width: Min(100%, 625px);
                align-self: center;

                .form-row + .form-row {
                    margin-top: 20px;
                }
                .form-control {
                    padding: 24px 32px;
                    background: var(--tbl-default-off-white, #F5F5F5);
                    min-width: 100%;
                    border: none;
                    border-radius: 0;

                    &,
                    &::placeholder {
                        color: var(--tbl-neutral-neutral-300, #898989);

                        font-family: inherit;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                    }
                    &:focus {
                        box-shadow: 0 0 0 0.25rem #0085b575;
                    }
                }
                button > a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 50px;
                    width: 185px;
                    margin-top: 32px;
                    background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                    color: var(--tbl-default-white, #FFF);
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    z-index: 1;
                    text-decoration: none;
                    overflow: hidden;
                    position: relative;
                    &::after{
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                        z-index: -1;
                        transition: 0.4s;
                        transition: transform 0.3s ease;
                        transform-origin: 0 100%;
                        will-change: transform;
                        transform: scaleY(0);
                    }
                    &:hover{
                        &::after{
                            transform: scaleY(1);
                            }
                        }
                }
            }
        }

        @media (width < 991px) {
            padding: 32px 25px 60px;

            &-container {
                flex-direction: column;
                gap: 40px;

                &--text {
                    max-width: 100%;

                    h2 {
                        font-size: 28px;
                        line-height: 1;
                        max-width: 180px;
                    }
                    p {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
                &--forms {
                    button,
                    button > a {
                        min-width: 100%;
                    }
                }
            }
        }
    }
    .our-news {
        background-color: #F5F5F5;

        @media (max-width: 1100px) {
            padding-top: 120px;
        }
    }
}
