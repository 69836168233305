#canal{
    .title-section {
        color: var(--tbl-default-black, #383838);
        font-family: 'Revx Neue', sans-serif;
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        margin-bottom: 43px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 63px;
            height: 4px;
            background-color: #00AEEF;
            top: -38px;
            left: 0;
        }

        strong {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue', sans-serif;
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px;
        }
    }

 .oportunidade{
    position: relative;
    padding-bottom: 130px;
    padding-top: 220px;
    .wrap-img{
        position: absolute;
        right: 0;
        bottom: 130px;
        aspect-ratio: 1.1315;
        max-width: 843px;
        img{
            width: Min(843px, 47vw);
            height: 100%;
            object-fit: cover;
        }
        .listras{
            position: absolute;
            width: 256px;
            height: 39px;
            bottom: -15px;
            right: 0;
            object-fit: contain;
            object-position: right;
        }
    }
    .text-absolute{
        top: 80px;
        right: 0;
        width: 100%;
        overflow: hidden;
        height: 250px;
        position: absolute;
        h3{
            font-family: 'Revx Neue', sans-serif;
            font-size: 250px;
            font-style: normal;
            font-weight: 700;
            line-height: 100px; /* 40% */
            color: transparent;
            -webkit-text-stroke: 2px #00AEEF; /* width and color of the stroke */
            text-stroke: 2px #00AEEF; /* Standard syntax, if supported */
            background-clip: text;
            position: relative;
            right: -41px;
            text-align: end;
            bottom: -80px;
            filter: blur(0);
        }
    }
    .content-text{
        padding-top: 240px;
        h2{
            color: #383838;
            font-family: 'Revx Neue', sans-serif;
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px; /* 111.111% */
            margin-bottom: 26px;
            position: relative;
            &::after{
                position: absolute;
                top: -35px;
                left: 0;
                width: 63px;
                height: 4px;
                background: #00AEEF;
                content: '';
            }
            span{
                color: #383838;
                /* H2 - Title bold */
                font-family: 'Revx Neue', sans-serif;
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }
        }
        p + p { margin-top: 26px; }
        p{
            color: var(--tbl-default-black, #383838);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 156.25% */
            margin-bottom: 0;
            span{
                color: var(--tbl-primary-primary-500, #00AEEF);
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
        }
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: 185px;
            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            color: var(--tbl-default-white, #FFF);
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            z-index: 1;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            margin-top: 50px;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                z-index: -1;
                transition: 0.4s;
                transition: transform 0.3s ease;
                transform-origin: 0 100%;
                will-change: transform;
                transform: scaleY(0);
            }
            &:hover{
                &::after{
                    transform: scaleY(1);
                    }
                }
        }
    }

    @media (width < 991px) {
        padding-block: 33px 60px !important;

        .wrap-img {
            position: relative;
            padding-left: 25px;
            width: 100%;
            bottom: 0;

            display: block;
            margin-inline-start: auto;

            img {
                width: 100%;
            }
            .listras { display: none; }
        }
        .text-absolute {
            position: relative;
            top: 0;
            height: 83px;
            max-width: 100%;

            h3 {
                font-size: 73px;
                line-height: 1;
                right: 2px;
                top: 14px;
                max-width: 100%;
            }
        }
        & > .container {
            margin: 0;
            max-width: 100%;
            padding-inline: 25px;
        }
        .content-text {
            padding-top: 79px;

            h2 {
                font-size: 28px;
                line-height: 1;
                margin-bottom: 32px;

                span { font-size: 28px; }
            }
            p + p { margin-top: 20px; }
            p {
                font-size: 14px;
                line-height: 18px;
            }
            a {
                width: 100%;
                font-size: 14px;
                line-height: normal;
            }
        }
    }
}

    .esg {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 210px;
        padding-bottom: 162px;

        .content {
            margin-left: 116px;
        }
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: 185px;
            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            color: var(--tbl-default-white, #FFF);
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            z-index: 1;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                z-index: -1;
                transition: 0.4s;
                transition: transform 0.3s ease;
                transform-origin: 0 100%;
                will-change: transform;
                transform: scaleY(0);
            }
            &:hover{
                &::after{
                    transform: scaleY(1);
                    }
                }
        }
        .title-section {
            color: #FFF;
            margin-bottom: 32px;

            strong {
                color: #FFF;
            }
        }

        p {
            color: var(--tbl-default-white, #FFF);
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
        }

        .btn-section {
            margin-top: 67px;
        }
    }
}
