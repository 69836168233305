@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

#home {
    @media (max-width: 1100px) {
        margin-top: 83px;
    }

    img {
        max-width: 100%;
    }

    .title-section {
        color: var(--tbl-default-black, #383838);
        font-family: 'Revx Neue';
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        margin-bottom: 43px;
        position: relative;

        @media (max-width: 1100px) {
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            /* 100% */
            margin-bottom: 32px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 63px;
            height: 4px;
            background-color: #00AEEF;
            top: -38px;
            left: 0;
        }

        strong {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px;

            @media (max-width: 1100px) {
                font-size: 28px;
                font-style: normal;
                line-height: 28px;
                /* 100% */
            }
        }
    }

    .btn-section {
        height: 50px;
        width: 190px;
        background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
        color: var(--tbl-default-white, #FFF);
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 51px;
        margin-right: 47px;
        text-decoration: none;
        position: relative;
        z-index: 1;
        overflow: hidden;

        @media (max-width: 1100px) {
            width: calc(100% - 35px);
            margin: 0 auto;
            margin-bottom: 1rem;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            z-index: -1;
            transition: 0.4s;
            transition: transform 0.3s ease;
            transform-origin: 0 100%;
            will-change: transform;
            transform: scaleY(0);
        }

        &:hover {
            &::after {
                transform: scaleY(1);
            }
        }
    }

    .slide {
        position: relative;
    }

    .banners {
        .banner-ind {
            padding-top: 100px;
            position: relative;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            height: max(655px, 50.781vw);

            @media (max-width: 1100px) {
                height: 508px;
                padding-top: 0;
            }

            .layer {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.95) 18.24%, rgba(0, 0, 0, 0.00) 81.3%);
                @media (max-width: 1100px) {
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: linear-gradient(144deg, rgba(0, 0, 0, 0.95) -10.05%, rgba(0, 0, 0, 0.80) 38.22%, rgba(0, 0, 0, 0.00) 93.61%);
                    }
                    &::before{
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: linear-gradient(0deg, #000 -19.62%, rgba(0, 0, 0, 0.00) 61.41%);
                    }
                }
            }
        }

        .conteudo-banner {
            padding-top: 7.135vw;
            padding-bottom: 14.375vw;
            position: relative;
            z-index: 2;

            @media (max-width: 1100px) {
                padding-inline: 17.5px;
                padding-top: 55px;
                padding-bottom: 0;
            }
        }

        h2 {
            font-family: 'Revx Neue';
            font-size: 3.646vw;
            font-style: normal;
            font-weight: 700;
            line-height: 3.385vw;
            color: $white;
            margin-bottom: 32px;
            max-width: 430px;

            @media (max-width: 1100px) {
                font-size: 35px;
                font-style: normal;
                font-weight: 700;
                line-height: 35px;
                /* 100% */
                max-width: 85%;
                margin-bottom: 20px;
            }
        }

        p {
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            color: $white;
            margin-bottom: 85px;
            max-width: 430px;

            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                /* 128.571% */
                max-width: 85%;
                margin-bottom: 60px;
            }
        }

        .btn-banner {
            height: 50px;
            min-width: 185px;
            width: fit-content;
            padding-inline: 20px;
            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            color: var(--tbl-default-white, #FFF);
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 47px;
            text-decoration: none;
            position: relative;
            z-index: 1;
            overflow: hidden;

            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                z-index: -1;
                transition: 0.4s;
                transition: transform 0.3s ease;
                transform-origin: 0 100%;
                will-change: transform;
                transform: scaleY(0);
            }

            &:hover {
                &::after {
                    transform: scaleY(1);
                }
            }
        }

        .slick-slide {
            &[data-slick-index="0"] {
                .conteudo-banner {
                    @media (max-width: 1400px) {
                        padding-top: 4vw;
                    }
                }
                h2 {
                    max-width: 570px;
                }
                p {
                    max-width: 490px;

                    @media (max-width: 1600px) {
                        max-width: 570px;
                        margin-bottom: 32px;
                    }
                    @media (max-width: 1450px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .banner-prev,
    .banner-next {
        position: absolute;
        top: 47%;
        cursor: pointer;
    }

    .banner-prev {
        left: 83px;
    }

    .banner-next {
        right: 83px;
    }

    @media(max-width: 1600px) {
        .banner-prev {
            left: 5px;
        }

        .banner-next {
            right: 5px;
        }

    }

    @media (max-width: 1100px) {

        .banner-prev,
        .banner-next {
            display: none !important;
        }
    }


    .dots-banners {
        position: relative;

        .slick-dots {
            width: auto;
            bottom: 111px;
            left: 0px;
            z-index: 99;

            @media (max-width: 1500px) {
                bottom: 70px;
            }
            @media (max-width: 1100px) {
                left: 50%;
                transform: translateX(-50%);
                bottom: 59px;
            }

            li {
                margin: 0 3px;
            }

            li,
            button {
                width: 24px;
                height: 5px;
                padding: 0;
                border-radius: 3px;

                @media (max-width: 1100px) {
                    width: 33px;
                    height: 5.5px;
                }
            }

            button::before {
                display: none;
            }

            li button {
                background-color: #fff;
            }

            li.slick-active button {
                background-color: #0084B5;
            }
        }
    }

    .btn-scroll {
        position: absolute;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        bottom: 91px;

        @media (max-width: 1500px) {
            bottom: 60px;
        }
        @media (max-width: 1100px) {
            display: none;
        }
    }

    .about {
        position: relative;
        padding-top: 105px;
        padding-bottom: 255px;

        @media (max-width: 1100px) {
            padding-top: 32px;
            padding-bottom: 60px;
        }

        .row {
            @media (max-width: 1100px) {
                flex-direction: column-reverse;
            }
        }

        .bloco-azul {
            width: 875px;
            height: 582px;
            background-color: #0084B5;
            position: absolute;
            top: 228px;
            left: -42px;

            @media(max-width: 1600px) {
                max-width: 61%;
            }

            @media (max-width: 1100px) {
                width: 80%;
                max-width: 100%;
                height: 271px;
                bottom: 228px;
                left: 0;
                position: absolute;
                top: inherit;
            }
        }

        .col-bloco-cinza {
            @media (max-width: 1100px) {
                padding-inline: 25px;
            }
        }

        .bloco-cinza {
            width: 100%;
            max-width: 868px;
            border-radius: 22px;
            background: #F5F5F5;
            padding: 49px 98px 49px 98px;
            position: absolute;
            left: 151px;
            top: 95px;

            @media(max-width: 1600px) {
                left: 0;
                max-width: 55%;
            }

            @media (max-width: 1100px) {
                width: 100%;
                max-width: 100%;
                top: 0;
                left: 0;
                position: relative;
                padding: 14px 25px;
                margin-bottom: 96px;
            }

            h3 {
                font-family: 'Revx Neue';
                font-size: 25px;
                font-style: normal;
                font-weight: 600;
                line-height: 27px;
                color: var(--tbl-default-black, #383838);
                text-align: center;
                margin-bottom: 27px;

                @media (max-width: 1100px) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 15px;
                    margin-bottom: 7px;
                }

                strong {
                    color: var(--tbl-primary-primary-500, #00AEEF);
                }
            }

            .video {
                width: 100%;
                max-width: 671px;
                border-radius: 10px;
                margin-bottom: 33px;
                position: relative;

                @media (max-width: 1100px) {
                    height: 142px;
                    margin-bottom: 14px;

                    img {
                        height: 100%;
                    }
                }

                img {
                    border-radius: 10px;
                }

                .play {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 39%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            p {
                color: var(--tbl-default-black, #383838);
                text-align: center;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;

                @media (max-width: 1100px) {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    /* 116.667% */
                }
            }
        }

        .content {
            margin-left: 87px;

            @media (max-width: 1100px) {
                margin-left: 0;
            }

            p {
                color: var(--tbl-default-black, #383838);
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                max-width: 376px;
                margin-bottom: 26px;

                strong {
                    color: var(--tbl-primary-primary-500, #00AEEF);
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 25px;
                }
            }
        }
    }

    .cards {
        .row {
            gap: 59px;

            @media (max-width: 1100px) {
                gap: 10px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                overflow-x: scroll;
                padding-bottom: 1rem;
            }
        }

        .mini-box {
            border-radius: 20px 0px;
            background: #FFF;
            box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
            padding: 24px 28px;
            max-width: 208px;
            height: 200px;
            transition: 0.3s ease;

            .icone {
                height: 28px;
            }

            .numero {
                color: var(--tbl-secondary-secondary-500, #0084B5);
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
                margin-top: 29px;
                transition: 0.3s ease;
            }

            .desc {
                color: var(--tbl-default-black, #383838);
                font-family: 'Revx Neue';
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;
                transition: 0.3s ease;
            }

            &:hover {
                background-color: #0084B5;
                transition: 0.3s ease;

                img {
                    filter: grayscale(100%) brightness(2);
                    transition: 0.3s ease;
                }

                .numero,
                .desc {
                    color: #fff;
                    transition: 0.3s ease;
                }
            }
        }
    }

    .completa {
        margin-top: 117px;
        padding-top: 91px;
        padding-bottom: 250px;
        background-color: #F5F5F5;
        position: relative;

        @media (max-width: 1100px) {
            margin-top: 60px;
            padding-bottom: 90px;
        }

        .title-section {
            text-align: center;
            margin-bottom: 31px;

            &::after {
                left: 47%;
            }
        }

        p {
            color: var(--tbl-neutral-neutral-500, #4F4F4F);
            text-align: center;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 627px;
            margin: 0 auto;

            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                /* 128.571% */
            }
        }

        .lines {
            position: absolute;
            right: 0;
            top: 30px;

            @media (max-width: 1100px) {
                display: none;
            }
        }

        .slider-section {
            position: relative;
            margin-top: 70px;

            @media (max-width: 1100px) {
                margin-top: 35px;
            }

            .slider.center {
                .cl {
                    height: 28.333vw;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;

                    @media (max-width: 1100px) {
                        height: 379px;
                    }

                    .content {
                        width: 100%;
                        height: 100%;
                        position: relative;

                        .icone {
                            @media (max-width: 1100px) {
                                img {
                                    transform: scale(.65);
                                }
                            }
                        }

                        .texto {
                            position: absolute;
                            bottom: 69px;
                            left: 2.604vw;
                            max-width: 339px;
                            z-index: 2;

                            @media (max-width: 1100px) {
                                bottom: 44px;
                                left: 32px;
                            }

                            h4 {
                                color: #F5F5F5;
                                font-family: 'Revx Neue';
                                font-size: 30px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 30px;
                                margin: 20px 0;

                                @media (max-width: 1100px) {
                                    font-size: 19.382px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 32.303px;
                                    /* 166.667% */
                                    margin-bottom: 0;
                                    margin-top: 11px;
                                }
                            }

                            p {
                                color: #F5F5F5;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 25px;
                                text-align: left;

                                @media (max-width: 1100px) {
                                    font-size: 10.337px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 16.151px;
                                    /* 156.25% */
                                }
                            }
                        }

                        .layer {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: var(--tbl-gradients-effects-gradient-linear, linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%));
                            mix-blend-mode: multiply;
                        }
                    }
                }

                .slick-active {
                    padding: 30px 0;
                }

                .slick-center {
                    transform: scale(1.1);
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    z-index: 99;
                    position: relative;
                    max-width: 540px;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 4px;
                        background-color: #0084B5;
                        bottom: 34px;
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    }
                }

                .slick-slide:not(.slick-active) {
                    margin: 30px 0;
                }

                .child {
                    width: 100%;
                }

                .slide:not(.slick-active) {
                    cursor: pointer;
                }

                .layer-blue {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0.00) 100%);
                }
            }

            .center-prev,
            .center-next {
                position: absolute;
                top: calc(50% - 64px);
                cursor: pointer;
                z-index: 2;

                @media (max-width: 1100px) {
                    display: none !important;
                }
            }

            .center-prev {
                left: 88px;
            }

            .center-next {
                right: 88px
            }

            .layer-blue {
                position: absolute;
                width: 476px;
                height: calc(100% - 64px);
                top: 30px;
                background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0.00) 100%);
                z-index: 1;

                @media (max-width: 1100px) {
                    display: none;
                }
            }

            .layer-blue-second {
                position: absolute;
                width: 476px;
                height: calc(100% - 64px);
                top: 30px;
                right: 0;
                background: linear-gradient(90deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%);
                z-index: 1;

                @media (max-width: 1100px) {
                    display: none;
                }
            }
        }
    }

    .caminho {
        position: relative;
        padding-bottom: 238px;
        @media (max-width: 1100px) {
            padding-bottom: 50px;
        }
        .img-right {
            position: absolute;
            width: 972px;
            height: 749px;
            left: 0;
            top: 0;

            @media(max-width: 1600px) {
                left: -200px;
            }

            @media (max-width: 1100px) {
                position: relative;
                width: 100%;
                height: 350px;
                left: 0;
                top: 0;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .lines {
            position: absolute;
            top: 101px;
            left: 0;
            transform: rotate(180deg);

            @media (max-width: 1100px) {
                display: none;
            }
        }

        .texto-section {
            font-family: "Revx Neue";
            font-size: 13vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #60cbf2;
            text-stroke: 2px #60cbf2;
            -webkit-background-clip: text;
            background-clip: text;
            position: relative;
            right: 62px;
            text-align: end;
            top: -50px;
            z-index: 99;

            @media (max-width: 1100px) {
                font-size: 90px;
                left: 0;
            }
        }

        .content {
            padding-top: 116px;
            padding-left: 193px;

            @media (max-width: 1100px) {
                padding-left: 0;
                padding-top: 86px;
            }
        }

        .title-section {
            max-width: 292px;
            @media (max-width: 1100px) {
                padding-inline: 17.5px;
            }
        }

        p {
            color: var(--tbl-default-black, #383838);
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 327px;
            margin-bottom: 26px;

            strong {
                color: var(--tbl-primary-primary-500, #00AEEF);
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
            @media (max-width: 1100px) {
                padding-inline: 17.5px;
            }
        }

        .btn-section {
            width: 236px;
            margin-top: 63px;

            @media (max-width: 1100px) {
                width: calc(100% - 35px);
            }
        }
    }

    .local {
        position: relative;
        overflow: hidden;
        padding-bottom: 240px;
        @media (max-width: 1400px) {
            padding-bottom: 100px;
        }
        @media (max-width: 1100px) {
            padding-bottom: 0;
        }

        p {
            color: var(--tbl-default-black, #383838);
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 406px;
            margin-bottom: 26px;

            strong {
                color: var(--tbl-primary-primary-500, #00AEEF);
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
        }

        .btn-section {
            margin-top: 89px;
            @media (max-width: 1100px) {
                margin-bottom: 50px;
            }
        }

        .retangulo {
            position: absolute;
            bottom: -137px;
            right: 0;
            width: 896px;
            height: 656px;
            background: #F1F1F1;

            @media(max-width: 1600px) {
                max-width: 50%;
            }
            @media (max-width: 1399px) {
                bottom: 0;
            }
            @media (max-width: 1100px) {
                display: none;
            }
        }

        .mapa {
            position: absolute;
            right: 0;
            top: -263px;
            img{
                transform: translate(100px, 120px) scale(0.75);
            }
            @media(max-width: 1600px) {
                top: -197px;
                width: 68%;
            }

            @media (max-width: 1100px) {
                position: relative;
                width: 100%;
                top: 0;
                right: 0;
                height: 400px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: none;
                }
            }

        }
    }

    .esg {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 10.938vw;
        padding-bottom: 8.438vw;
        position: relative;

        @media (max-width: 1100px) {
            padding-top: 81px;
            padding-bottom: 44px;
        }

        .content {
            margin-left: 116px;

            @media (max-width: 1100px) {
                margin-left: 0;
                padding-inline: 25px;
            }
        }

        .title-section {
            color: #FFF;
            margin-bottom: 32px;

            strong {
                color: #FFF;
            }
        }

        p {
            color: var(--tbl-default-white, #FFF);
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
        }

        .btn-section {
            margin-top: 67px;

            @media (max-width: 1100px) {
                margin-left: 0;
                width: 150px;
                height: 46px;
            }
        }

        .content {
            position: relative;
            z-index: 2;
        }

        .layer {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(260deg, #000 -13.46%, rgba(0, 0, 0, 0.00) 78.89%);
            z-index: 1;
        }
    }
}

// popup aviso
.modal {
    z-index: 100000;
    
    &-dialog {
        max-width: 600px;
    }
    &-header {
        padding: 20px;
        border: none !important;
        justify-content: end;
    }
    &-body {
        padding: 0 25px 0;

        h1 {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue', sans-serif;
            font-size: 3vw;
            font-style: normal;
            font-weight: 700;
            line-height: 1;
            
            @media (max-width: 1100px) {
                font-size: 28px;
                line-height: 28px;
            }
        }
        svg path {
            fill: var(--tbl-default-black, #383838);
        }
        p {
            font-family: inherit;
            font-size: 18px;
            font-style: normal;
            line-height: 25px;
            font-weight: 400;
            margin-top: 32px;

            @media (max-width: 1100px) {
                font-size: 16px;
                line-height: 20px;
                margin-top: 20px;
            }
            strong {
                font-family: inherit;
                color: var(--tbl-primary-primary-500);
                font-weight: 700;
            }
        }
    }
    &-footer {
        text-align: center;
        display: flex;
        justify-content: center;
        border: none !important;
        padding: 0 25px 20px;
    }
    .button {
        height: 50px;
        width: 190px;
        background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
        color: var(--tbl-default-white, #FFF);
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 32px;
        text-decoration: none;
        position: relative;
        z-index: 1;
        overflow: hidden;

        @media (max-width: 1100px) {
            width: 100%;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            z-index: -1;
            transition: 0.4s;
            transition: transform 0.3s ease;
            transform-origin: 0 100%;
            will-change: transform;
            transform: scaleY(0);
        }

        &:hover {
            &::after {
                transform: scaleY(1);
            }
        }
    }
}