#esg_complice {
    overflow: hidden;

    .title-section {
        color: var(--tbl-default-black, #383838);
        font-family: 'Revx Neue';
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        margin-bottom: 34px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 63px;
            height: 4px;
            background-color: #00AEEF;
            top: -38px;
            left: 0;
        }

        strong {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px;
        }

        @media (width < 991px) {
            &,
            & strong {
                font-size: 28px;
                line-height: 1;
            }
        }
    }

    .section1 {
        position: relative;
        padding-top: 338px;
        padding-bottom: 164px;
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            color: var(--tbl-default-white, #FFF);
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            z-index: 1;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            display: flex;
            padding: 15px 50px;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: fit-content;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                z-index: -1;
                transition: 0.4s;
                transition: transform 0.3s ease;
                transform-origin: 0 100%;
                will-change: transform;
                transform: scaleY(0);
            }
            &:hover{
                &::after{
                    transform: scaleY(1);
                    }
                }
                @media (max-width: 1100px) {
                    width: 100%;
                }
        }
        .title-section {
            margin-bottom: 23px;
        }

        p {
            color: #383838;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 621px;
            margin-bottom: 26px;

            strong {
                color: #00AEEF;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
        }

        .video-section {
            position: absolute;
            width: Min(843px, 47vw);
            height: 745px;
            top: 59px;
            right: 0;
            padding: 0;

            @media(max-width: 1401px) {
                width: Min(843px, 44vw);
            }

            .video {
                position: relative;

                .img-video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .player img {
                    position: absolute;
                    top: calc(50% - 39px);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                .linhas {
                    position: absolute;
                    right: 0;
                    bottom: -12px;
                }
            }
        }

        .texto-section {
            font-family: "Revx Neue";
            font-size: 250px;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #00AEEF; /* Para navegadores WebKit (Safari, Chrome antigo) */
            text-stroke: 2px #00AEEF; /* Padrão */
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: 0;
            left: -351px;
            right: 0;
            margin: 0 auto;
            text-align: center;
            filter: blur(0px);
        }

        @media (width < 991px) {
            padding-top: 40px;
            padding-bottom: 44px;

            & > .container {
                max-width: 100%;
                padding-inline-start: 25px;
                margin-inline: 0;

                & .row {
                    display: flex;
                    flex-direction: column-reverse;
                }
            }
            .col-lg-7 { padding-inline-end: 25px; }
            p {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }
            .texto-section {
                font-size: 73px;
                font-style: normal;
                font-weight: 700;
                line-height: 73px;

                position: static;
                margin-block: 8px 79px;
                text-align: end;
            }
            .video-section {
                width: 100% !important;
                height: fit-content;
                position: static;

                .linhas { display: none; }
            }
        }
    }

    .section2 {
        position: relative;

        .video {
            padding: 84px;
            position: relative;
            border-radius: 22px;
            background: #F5F5F5;
            z-index: 2;

            .img-video {
                width: 100%;
                height: 456px;
                border-radius: 10px;
                object-fit: cover;
            }

            .player img {
                position: absolute;
                top: calc(50% - 39px);
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }

        .quadrado {
            width: 948px;
            max-width: 100%;
            height: 546px;
            background: #0084B5;
            position: absolute;
            top: 186px;
            left: 0;
            z-index: 1;
        }

        @media (width < 991px) {
            & > .container {
                max-width: 100%;
                padding-inline: 25px;
                margin-inline: 0;
            }

            .video {
                padding: 20px;
                border-radius: 8px;

                .img-video { height: 213px; }
                .player img {
                    max-width: 40px;
                    aspect-ratio: 1;
                }
            }
            .quadrado {
                max-width: Min(306px, 80vw);
                height: 240px;
                top: 85px;
            }
        }
    }

    .section3 {
        position: relative;
        padding-top: 461px;
        padding-bottom: 253px;
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            color: var(--tbl-default-white, #FFF);
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            z-index: 1;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            display: flex;
            padding: 15px 50px;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: fit-content;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                z-index: -1;
                transition: 0.4s;
                transition: transform 0.3s ease;
                transform-origin: 0 100%;
                will-change: transform;
                transform: scaleY(0);
            }
            &:hover{
                &::after{
                    transform: scaleY(1);
                    }
                }
                @media (max-width: 1100px) {
                    width: 100%;
                }
        }
        .title-section {
            margin-bottom: 23px;
            margin-top: 79px;
        }

        p {
            color: #383838;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 621px;
            margin-bottom: 26px;

            strong {
                color: #00AEEF;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
        }

        .video-section {
            position: absolute;
            width: 843px;
            height: 745px;
            top: 274px;
            left: 0;
            padding: 0;

            @media(max-width: 1710px) {
                max-width: 48%;
            }

            .video {
                position: relative;

                .img-video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }


                .player img {
                    position: absolute;
                    top: calc(50% - 39px);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                .linhas {
                    position: absolute;
                    left: 0;
                    bottom: -33px;

                    img {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .texto-section {
            font-family: "Revx Neue";
            font-size: 250px;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #00AEEF;
            text-stroke: 2px #00AEEF;
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: 204px;
            left: 0;
            right: 0;
            text-align: end;
            filter: blur(0);
        }

        @media (width < 991px) {
            padding-top: calc(47px + 85px);
            padding-bottom: calc(44px + 35px);

            & > .container {
                max-width: 100%;
                padding-inline-start: 25px;
                margin-inline: 0;

                & .row {
                    display: flex;
                    flex-direction: column-reverse;
                }
            }
            .col-lg-6 { padding-inline-end: 25px; }
            p {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }
            .texto-section {
                font-size: 60px;
                font-style: normal;
                font-weight: 700;
                line-height: 73px;
                position: static;
                margin-block: 8px 79px;
                text-align: end;
            }
            .title-section {
                margin-top: 0;
                margin-bottom: 32px;
            }
            .video-section {
                max-width: 100% !important;
                height: fit-content;
                position: static;

                .linhas { display: none; }
            }
        }
    }

    .section4 {
        position: relative;
        padding-top: 156px;
        padding-bottom: 111px;
        background-color: #F5F5F5;

        .title-section {
            text-align: center;

            &::after {
                right: 0;
                left: 0;
                margin: 0 auto;
            }
        }

        p {
            color: #4F4F4F;
            text-align: center;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 627px;
            margin: 0 auto;
        }

        .blocos {
            margin-top: 83px;

            .bloco {
                max-width: 406px;
                height: 326px;
                border-radius: 20px 0px;
                background: #FFF;
                box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
                text-align: center;
                padding: 40px 20px;
                margin-bottom: 30px;

                h3 {
                    color: #0084B5;
                    font-family: 'Revx Neue';
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                    margin-top: 12px;
                    margin-bottom: 20px;
                }

                p {
                    color: #383838;
                    text-align: center;
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    margin-bottom: 20px;
                }

                a {
                    display: inline-block;
                    text-decoration: none;
                    color: #00AEEF;
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 25px;
                }

                &:hover {
                    background-color: #0084B5;
                    transition: 0.3s ease;

                    img {
                        filter: grayscale(100%) brightness(2);
                        transition: 0.3s ease;
                    }

                    h3,
                    p,
                    a {
                        color: #fff;
                        transition: 0.3s ease;
                    }
                }
            }
        }

        .texto-section {
            font-family: "Revx Neue";
            font-size: 250px;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #00AEEF;
            text-stroke: 2px #00AEEF;
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: -60px;
            left: -30px;
            right: 0;
            margin: 0 auto;
            text-align: start;
            filter: blur(0);
        }

        @media (width < 991px) {
            padding-top: 0;
            padding-bottom: 60px;

            & > .container {
                max-width: 100%;
                padding-inline: 25px;
                margin-inline: 0;
            }
            .texto-section {
                font-size: 73px;
                font-style: normal;
                font-weight: 700;
                line-height: 73px;

                position: static;
                margin-block: 0 calc(62px - 35px);
                transform: translateY(-35px) !important;
                padding-left: 15px;
                text-align: start;
            }
            .title-section { margin-bottom: 20px; }
            p {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                text-align: center;
            }
            .blocos {
                margin-top: 59px;

                .bloco {
                    height: 257px;
                    margin-bottom: 20px;

                    img {
                        max-width: 28px;
                        aspect-ratio: 1;
                    }
                    h3 {
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 37px;
                    }
                    p {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        margin: 0;
                    }
                }
            }
        }
    }
}
