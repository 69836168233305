.know-company{
    .banner-normal {
        &::after {
            background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 7.21%, rgba(0, 132, 181, 0.00) 51.34%) !important;
            mix-blend-mode: multiply;
        }
    }

    padding-top: 135px;
    padding-bottom: 200px;
    position: relative;
    @media (max-width: 1399px) {
        padding-bottom: 0;
    }
    @media (max-width: 1100px) {
        padding-bottom: 69px;
        padding-top: 70px;
        padding-inline: 17.5px;
    }
    .row{
        @media (max-width: 1100px) {
            flex-direction: column-reverse;
        }
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 45.573vw;
        height: 31.25vw;
        background: var(--tbl-secondary-secondary-500, #0084B5);
        z-index: -1;
        @media (max-width: 1100px) {
            width: 75.573vw;
            height: 240px;
        }
    }

    .wrapper-know {
        position: relative;
        @media (max-width: 1100px) {
            max-width: 300px;
            margin: 0 auto;
        }
        .bg-destaque {
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-97px, -20px);

            @media (max-width: 1400px) {
                width: 120%;
                transform: translate(-8%, -1%);
            }
            @media (max-width: 1100px) {
                width: 115%;
                transform: translate(-7%, 1%);
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .know-img {
            border-radius: 10px;
            position: relative;
            z-index: 9;
            margin-top: 61px;
            max-height: 410px;

            @media (max-width: 1400px) {
                width: 100%;
            }
            @media (max-width: 1100px) {
                margin-top: 15px;
            }
        }
    }

    .content-text {
        h1 {
            color: #383838;
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            /* 111.111% */
            position: relative;
            margin-bottom: 32px;
            &::after {
                content: '';
                position: absolute;
                top: -35px;
                left: 0;
                width: 63px;
                height: 4px;
                background: #00AEEF;
            }

            span {
                color: #383838;
                /* H2 - Title bold */
                font-family: inherit;
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }
            @media (max-width: 1100px) {
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px; /* 100% */
                span{
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
        }

        p {
            color: var(--tbl-default-black, #383838);
            /* H6 - Text Body */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            /* 156.25% */
            margin-bottom: 20px;

            &:last-of-type { margin-bottom: 56px; }
            @media (max-width: 1100px) {
                margin-bottom: 20px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
            }
            span {
                color: var(--tbl-primary-primary-500, #00AEEF);
                font-weight: 700;
                line-height: 25px;
                @media (max-width: 1100px) {
                    line-height: 18px; /* 128.571% */
                }
            }
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: 185px;
            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            color: var(--tbl-default-white, #FFF);
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            z-index: 1;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            @media (max-width: 1100px) {
                margin-bottom: 40px;
                width: 100%;
            }
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                z-index: -1;
                transition: 0.4s;
                transition: transform 0.3s ease;
                transform-origin: 0 100%;
                will-change: transform;
                transform: scaleY(0);
            }

            &:hover {
                &::after {
                    transform: scaleY(1);
                }
            }
        }
    }
}

.our-news {
    padding-top: 105px;
    padding-bottom: 160px;
    @media (max-width: 1100px) {
        padding-bottom: 82px;
    }
    .btn-news{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: calc(100% - 35px);
        background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
        color: var(--tbl-default-white, #FFF);
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        z-index: 1;
        text-decoration: none;
        overflow: hidden;
        position: relative;
        margin: 0 auto;
        margin-top: 30px;
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            z-index: -1;
            transition: 0.4s;
            transition: transform 0.3s ease;
            transform-origin: 0 100%;
            will-change: transform;
            transform: scaleY(0);
        }
        &:hover{
            &::after{
                transform: scaleY(1);
            }
        }
    }
    .content-destaque{
        margin-bottom: 70px;
        @media (max-width: 1100px) {
                margin-bottom: 28px;
        }
        h1{
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px; /* 111.111% */
            margin-bottom: 32px;
            position: relative;
            text-align: center;
            &::after{
                content: '';
                position: absolute;
                top: -35px;
                width: 63px;
                height: 4px;
                background: #00AEEF;
                left: 50%; /* Move to 50% of the container's width */
                transform: translateX(-50%); /* Shift back to the left by 50% of its own width */
            }
            span{
                color: var(--tbl-default-black, #383838);
                font-family: 'Revx Neue', sans-serif;
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }
            @media (max-width: 1100px) {
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px; /* 100% */
                span{
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px; /* 100% */
                }
            }
        }
        p{
            color: var(--tbl-neutral-neutral-500, #4F4F4F);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 156.25% */
            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
            }
        }
    }
    .grid-news {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 26px;
        grid-row-gap: 10px;
        height: 466px;
        @media (max-width: 1100px) {
            display: flex;
            flex-direction: column;
            height: auto;
            gap: 26px;
        }

        a{
            display: contents;
        }
    }
    .div1, .div2, .div3, .div4 {
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: flex-end;
        @media (max-width: 1100px) {
            width: 100%;
            height: 289px;
        }
        &:hover{
            img{
                transform: scale(1.1);
            }
        }
        .tag{
            position: absolute;
            top: 0;
            left: 0;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 120px;
            background: var(--tbl-primary-primary-500, #00AEEF);
            color: var(--tbl-default-white, #FFF);
            font-family: 'Revx Neue';
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px; /* 187.5% */
            z-index: 9;
            text-transform: capitalize;
            padding-inline: 21px;

            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                padding-inline: 21px;
            }
        }
        .plus{
            position: absolute;
            bottom: 0;
            right: 0;
            width: 59px;
            height: 52.578px;
            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            color: #FFF;
            font-size: 35px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            z-index: 9;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @media (max-width: 1100px) {
                width: 36px;
                height: 32px;
            }
            }
            img{
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.3s ease;
            }
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(0deg, #000 11.62%, rgba(0, 0, 0, 0.00) 96.06%);
            }
            .content-text{
                position:relative;
                z-index: 9;
                max-width: 80%;
                padding-bottom: 25px;
                padding-left: 36px;
                @media (max-width: 1100px) {
                    padding-left: 25px;
                    padding-bottom: 16px;
                }
                h2{
                    color: var(--tbl-default-white-off, #F5F5F5);
                    font-family: 'Revx Neue';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 22px; /* 110% */
                    @media (max-width: 1100px) {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 25px; /* 125% */
                    }
                }
                p{
                    color: var(--tbl-default-white-off, #F5F5F5);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px; /* 156.25% */
                    @media (max-width: 1100px) {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px; /* 128.571% */
                    }
                }
                .date{
                    color: var(--tbl-primary-primary-500, #00AEEF);
                    font-family: 'Revx Neue', sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px; /* 166.667% */
                    margin-top: 10px;
                    @media (max-width: 1100px) {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 30px; /* 214.286% */
                    }
                }
        }
    }
    .div1 {
        grid-area: 1 / 7 / 5 / 9;
    }
    .div2 {
        grid-area: 1 / 5 / 3 / 7;
    }
    .div3 {
        grid-area: 3 / 5 / 5 / 7;
    }
    .div4 {
        grid-area: 1 / 1 / 5 / 5;
        h2{
            color: var(--tbl-default-white-off, #F5F5F5);
            font-family: 'Revx Neue';
            font-size: 30px !important;
            font-style: normal;
            font-weight: 700;
            line-height: 30px !important; /* 100% */
            @media (max-width: 1100px) {
                font-size: 20px !important;
                font-style: normal;
                font-weight: 700;
                line-height: 25px !important; /* 125% */
            }
        }
        .date{
            margin-top: 22px !important;
            @media (max-width: 1100px) {
                margin-top: 10px !important;
            }
        }
        p{
            margin-top: 10px;
        }
    }

}

#who-empresa {
    min-height: 500dvh;
}
