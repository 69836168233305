#agregado{

    .oportunidade{
        position: relative;
        padding-bottom: 130px;
        padding-top: 220px;
        @media (max-width: 1100px) {
            padding-bottom: 60px;
            padding-top: 25px;
        }
        .btn-cadastro{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: 185px;
            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            color: var(--tbl-default-white, #FFF);
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            z-index: 1;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            margin-top: 20px;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                z-index: -1;
                transition: 0.4s;
                transition: transform 0.3s ease;
                transform-origin: 0 100%;
                will-change: transform;
                transform: scaleY(0);
            }
            &:hover{
                &::after{
                    transform: scaleY(1);
                    }
                }
                @media (max-width: 1100px) {
                    width: calc(100% - 35px);
                    margin: 0 auto;
                    margin-top: 20px;
                }
        }
        .wrap-img{
            position: absolute;
            right: 0;
            aspect-ratio: 1.1315;
            max-width: 43.906vw;
            @media (max-width: 1100px) {
                position: relative;
                display: flex;
                justify-content: flex-start;
                max-width: inherit;
                max-width: inherit;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                @media (max-width: 1100px) {
                    width: 93%;
                }
            }
            .listras{
                position: absolute;
                width: 256px;
                height: 39px;
                bottom: -15px;
                right: 0;
                object-fit: contain;
                object-position: right;
                @media (max-width: 1100px) {
                    display: none;
                }
            }
        }
        .text-absolute{
            top: 80px;
            right: 0;
            width: 100%;
            overflow: visible;
            height: 250px;
            position: absolute;
            h3{
                font-family: 'Revx Neue';
                font-size: 14vw;
                font-style: normal;
                font-weight: 700;
                line-height: 100px; /* 40% */
                color: transparent;
                -webkit-text-stroke: 2px #00AEEF; /* width and color of the stroke */
                text-stroke: 2px #00AEEF; /* Standard syntax, if supported */
                background-clip: text;
                position: relative;
                right: 0px;
                text-align: end;
                bottom: -80px;
                filter: blur(0);
            }
            @media (max-width: 1100px) {
                height: auto;
                width: 100%;
                top: 95%;
                z-index: 9;
                h3{
                    font-size: 14vw;
                    line-height: 90px;
                    top: 0;
                    right: 15px;
                    word-break: break-word;
                }
            }
        }
        .content-text{
            padding-top: 240px;

            @media (max-width: 1100px) {
                padding-inline: 17.5px;
                padding-top: 78px;
            }
            h2{
                color: #383838;
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 400;
                line-height: 50px; /* 111.111% */
                margin-bottom: 26px;
                position: relative;
                &::after{
                    position: absolute;
                    top: -35px;
                    left: 0;
                    width: 63px;
                    height: 4px;
                    background: #00AEEF;
                    content: '';
                }
                span{
                    color: #383838;
                    /* H2 - Title bold */
                    font-family: 'Revx Neue';
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 50px;
                }
                @media (max-width: 1100px) {
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28px; /* 100% */
                    span{
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 28px;
                    }
                }
            }
            p{
                color: var(--tbl-default-black, #383838);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px; /* 156.25% */
                margin-bottom: 80px;
                span{
                    color: var(--TBL-Primary-primary-500, #00AEEF);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 25px;
                }
                @media (max-width: 1100px) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px; /* 128.571% */
                    margin-bottom: 20px;
                    span{
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                    }
                }
            }
            a{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 185px;
                background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                color: var(--tbl-default-white, #FFF);
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                z-index: 1;
                text-decoration: none;
                overflow: hidden;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                    z-index: -1;
                    transition: 0.4s;
                    transition: transform 0.3s ease;
                    transform-origin: 0 100%;
                    will-change: transform;
                    transform: scaleY(0);
                }
                &:hover{
                    &::after{
                        transform: scaleY(1);
                        }
                    }
                    @media (max-width: 1100px) {
                        width: 100%;
                    }
            }
        }
    }
    .form-agregado{
        padding-top: 275px;
        background: #f5f5f5;
        padding-bottom: 150px;
        @media (max-width: 1100px) {
            padding-top: 90px;
            padding-inline: 17.5px;
            padding-bottom: 75px;
        }
        .destaque-title{
            color: var(--tbl-default-black, #383838);
            text-align: center;
            /* H2 - Title Regular */
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px; /* 111.111% */
            position: relative;
            span{
                color: var(--tbl-default-black, #383838);
                /* H2 - Title bold */
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }
            &::after{
                position: absolute;
                top: -35px;
                width: 63px;
                height: 4px;
                background: #00AEEF;
                content: '';
                left: 50%;
                transform: translateX(-50%);
            }
            @media (max-width: 1100px) {
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px; /* 100% */
                span{
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
        }
        .destaque-text{
            margin-top: 30px;
            color: var(--tbl-neutral-neutral-500, #4F4F4F);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 156.25% */
            @media (max-width: 1100px) {
                margin-top: 20px;
                margin-bottom: 40px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
            }
        }
        .input-box{
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            textarea{
                height: 200px;
                background: var(--tbl-default-white, #FFF);
                border: none;
                padding-inline: 32px;
                padding-top: 32px;
                color: var(--tbl-neutral-neutral-300, #898989);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 16px */
                margin-bottom: 24px;
                &::placeholder{
                    color: var(--tbl-neutral-neutral-300, #898989);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%; /* 16px */
                }
            }
            p{
                color: var(--tbl-neutral-neutral-300, #898989);
                font-family: 'Revx Neue';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px; /* 128.571% */
                margin-top: 12px;
            }
            label{
                color: var(--tbl-default-black, #383838);
                font-family: 'Revx Neue';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px; /* 128.571% */
                margin-bottom: 12px;
            }
            select,
            input{
                background: var(--tbl-default-white, #FFF);
                border: none;
                height: 72px;
                padding-inline: 32px;
                color: var(--tbl-neutral-neutral-300, #898989);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 16px */
                &::placeholder{
                    color: var(--tbl-neutral-neutral-300, #898989);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%; /* 16px */
                }
            }
        }
        .form-check{
            margin-top: 20px;
        }
        .form-check-input{
            --blue-primary: #00AEEF;
            border: 1px solid var(--blue-primary);
            border-radius: 1px !important;
            background-color: var(--blue-50, #F1FAFE);
            box-shadow: 0px 20px 44px 0px rgba(0, 0, 0, 0.20);
            width: 24px;
            height: 24px;
            &:checked {
                background-color: var(--blue-primary);
                border-color: var(--blue-primary);
            }
            &:focus {
                border-color: var(--blue-primary);
                outline: 0;
                box-shadow: 0 0 0 1px var(--blue-primary);
            }
        }
        .form-check-label {
            color: var(--tbl-neutral-neutral-300, #898989);
            /* Desktop/btn */
            font-family: 'Revx Neue';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px; /* 100% */
            margin-top: 10px;
            margin-left: 20px;
            @media (max-width: 1100px) {
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px; /* 133.333% */
            }
            a {
                &, &:hover {
                    color: inherit;
                    font-size: inherit;
                    text-decoration: underline;
                }
            }
        }
        button{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: 185px;
            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            color: var(--tbl-default-white, #FFF);
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            z-index: 1;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                z-index: -1;
                transition: 0.4s;
                transition: transform 0.3s ease;
                transform-origin: 0 100%;
                will-change: transform;
                transform: scaleY(0);
            }
            &:hover{
                &::after{
                    transform: scaleY(1);
                    }
                }
                @media (max-width: 1100px) {
                    width: 100%;
                    margin-top: 40px;
                }
        }
    }
}
