#explore {
    .title-section {
        color: var(--tbl-default-black, #383838);
        font-family: 'Revx Neue';
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        margin-bottom: 34px !important;
        position: relative;
        text-align: center;
        @media (max-width: 1100px) {
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 100% */
            padding-inline: 17.5px;
        }
        &::after {
            content: '';
            position: absolute;
            width: 63px;
            height: 4px;
            background-color: #00AEEF;
            top: -38px !important;
            left: 48% !important;

            @media (max-width: 1100px) {
                left: 50% !important;
                transform: translateX(-50%);
            }
        }

        strong {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px;
            @media (max-width: 1100px) {
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
            }
        }
    }

    p {
        color: var(--tbl-neutral-neutral-500, #4F4F4F);
        text-align: center;
        font-family: 'Roboto';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        max-width: 657px;
        margin: 0 auto;

        @media (max-width: 1100px) {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 128.571% */
            padding-inline: 17.5px;
        }
    }

    .redes {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 43px;
        margin-top: 62px;
        margin-bottom: 76px;

        @media (max-width: 1100px) {
            margin-top: 52px;
            margin-bottom: 40px;
            gap: 25px;
        }

        a {
            text-decoration: none;
            display: inline-block;

            transition: .3s ease-in;

            &:hover {
                transition: .3s ease-in;

                svg path {
                    fill: #0084B5;
                    transition: .3s ease-in;
                }
            }
        }
    }

    .galeria {
        width: 100%;
        height: 384px;
        object-fit: cover;
    }
}
