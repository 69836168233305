#page404{
    background: url('../img/bg-404.webp') no-repeat center;
    background-size: cover;
    min-height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1100px) {
        background: #000 url('../img/bg-mobile-404.webp') no-repeat center;
        background-size: cover;
    }
    .content-text{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%; /* Position the top edge of the element in the middle of the container */
        left: 50%; /* Position the left edge of the element in the middle of the container */
        transform: translate(-50%, -50%); /* Shift the element left and up by half its own width and height */
        align-items: center;
        h1{
            color: var(--tbl-default-white-off, #F5F5F5);
            font-family: 'Revx Neue';
            font-size: 150px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 150px */
        }
        .sub-title{
            display: flex;
            gap: 20px;
            color: #FFF;
            font-family: 'Revx Neue';
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
            line-height: 27px; /* 108% */
            margin-top: 32px;
            margin-bottom: 32px;
        }
        .text{
            color: var(--tbl-default-white, #FFF);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 156.25% */
            max-width: 494px;
        }
        a{
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--tbl-default-white-off, #F5F5F5);
            color: var(--tbl-secondary-secondary-500, #0084B5);
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            max-width: fit-content;
            padding-inline: 50px;
            text-decoration: none;
            margin-top: 56px;
        }
    }
}
