$img: '../img/';

// z-index
$zIndex-modal: 10000;


/// BOOTSTRAP VARIABLES
$breadcrumb-divider: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='8'><path d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='#fff'/></svg>");

/* Custom CSS to override Bootstrap container widths */

@media (min-width: 576px) {
    .container, .container-sm {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container, .container-md {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container, .container-lg {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container, .container-xl {
      max-width: 1140px;
    }
  }

  /* If you also want to change the container for XXL (Bootstrap 5) */
  @media (min-width: 1400px) {
    .container, .container-xxl {
      max-width: 1280px;
    }
  }

/* Customizing the breadcrumb background */



  /* Customizing the breadcrumb separator */
  .breadcrumb-item + .breadcrumb-item::before {
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.33301 14.1666L12.4997 9.99992L8.33301 5.83325' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    display: inline-block; /* Ensures the SVG is displayed inline */
    width: 20px; /* Width of your SVG */
    height: 20px; /* Height of your SVG */
  }
