.breadcrumb-component {
    margin-bottom: 73px;
    z-index: 9;
    position: relative;

    li {
        color: var(--tbl-default-white, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 16px */
        display: flex;
        align-items: center;
        @media (width < 991px) {
            font-size: 14px;
        }
    }

    .breadcrumb-item.active {
        text-decoration: underline 2px;
        color: #fff;
        text-decoration-color: #00AEEF;
        text-underline-offset: 8px;
    }

    @media (width < 991px) {
        background: rgba(0, 174, 239, 0.10);
        padding: 20px 33px 20px 33px;
        margin-bottom: 0;
    }
}

// banner hero
.banner-normal {
    min-height: 95dvh;
    position: relative;
    padding-top: 9.375vw;
    padding-bottom: 10.417vw;
    @media (width < 991px) {
        padding-bottom: 0;
        display: flex;
        align-items: flex-end;
    }
    .black-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, #000 -19.62%, rgba(0, 0, 0, 0.00) 61.41%);
        top: 0;
        z-index: 2;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 18.24%, rgba(0, 132, 181, 0.00) 81.3%);
        mix-blend-mode: multiply;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 18.24%, rgba(0, 132, 181, 0.00) 81.3%);
        mix-blend-mode: multiply;
        z-index: 2;
    }

    .banner-img {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        object-fit: cover;
        height: 100%;
    }

    .container {
        display: flex;
        flex-direction: column;
    }

    .down-section {
        position: absolute;
        left: 50%;
        /* Move to 50% of the container's width */
        transform: translateX(-50%);
        /* Shift back to the left by 50% of its own width */
        z-index: 9;
        bottom: 60px;

        @media(max-width: 1600px){
            bottom: 105px;
        }
    }

    .content-text {
        position: relative;
        z-index: 9;
        display: flex;
        flex-direction: column;
        max-width: 583px;
        margin-bottom: 50px;

        h1 {
            color: #FFF;
            font-family: 'Revx Neue';
            font-size: 3.646vw;
            font-style: normal;
            font-weight: 700;
            line-height: 3.385vw;
            /* 92.857% */
            margin-bottom: 33px;
            strong{
                font-size: 3.646vw;
                font-style: normal;
                font-weight: 700;
                line-height: 3.385vw;
            }
        }

        p {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            /* 156.25% */
            margin-bottom: 44px;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            color: var(--tbl-default-white, #FFF);
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            z-index: 1;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            text-align: center;
            width: fit-content;
            display: flex;
            padding: 15px 50px;
            justify-content: center;
            align-items: center;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                z-index: -1;
                transition: 0.4s;
                transition: transform 0.3s ease;
                transform-origin: 0 100%;
                will-change: transform;
                transform: scaleY(0);
            }

            &:hover {
                &::after {
                    transform: scaleY(1);
                }
            }
        }

        .links {
            display: flex;
            gap: 46px;
        }

        .player {
            a {
                width: auto;
                height: auto;
                background: transparent;
                padding: 0;
                transform: translateY(-5px);

                &::after {
                    display: none;
                }
            }
        }
    }

    @media (width < 991px) {
        min-height: Min(95vh, calc(83px + 508px));
        padding-top: 138px;
        padding-bottom: 0;

        .container {
            flex-direction: column-reverse !important;
            justify-content: space-between;
            gap: 60px;
            height: 100%;
            padding-inline: 0;
            margin-inline: 0;
        }

        .content-text {
            max-width: 342px;
            padding-inline: 25px;

            h1 {
                font-size: 35px;
                line-height: 35px;
                margin-bottom: 20px;
            }

            p {
                font-size: 14px;
                line-height: 18px;
                margin: 0 0 60px 0;
                max-width: 292px;
            }
        }

        .down-section {
            display: none;
        }
    }
}

// banner on page
.banner-on-page {
    position: relative;
    padding-top: 8.854vw;
    padding-bottom: 7.292vw;

    @media (width < 991px) {
        padding-top: 109px;
        padding-bottom: 72px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--TBL-Gradients-Effects-gradienteDiagonal, linear-gradient(260deg, #000 -13.46%, rgba(0, 0, 0, 0.00) 78.89%));

        @media(max-width: 991px){
            background: linear-gradient(90deg, #000000 -13.46%, rgba(0, 0, 0, 0) 78.89%);
        }
    }

    .banner-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    .content-text {
        position: relative;
        z-index: 9;

        @media (width < 991px) {
            padding-inline: 17.5px;
        }

        h1 {
            color: #fff;
            font-family: 'Revx Neue', sans-serif;
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            /* 111.111% */
            position: relative;
            margin-bottom: 32px;

            &::after {
                content: '';
                position: absolute;
                top: -35px;
                left: 0;
                width: 63px;
                height: 4px;
                background: #00AEEF;
            }

            span {
                color: #fff;
                /* H2 - Title bold */
                font-family: inherit;
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }

            @media (max-width: 1100px) {
                font-size: 35px;
                font-style: normal;
                font-weight: 400;
                line-height: 35px;

                /* 100% */
                span {
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 35px;
                }
            }
        }

        p {
            color: #fff;
            /* H6 - Text Body */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            /* 156.25% */
            margin-bottom: 56px;

            span {
                color: #fff;
                font-weight: 700;
                line-height: 25px;
            }

            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;

                /* 128.571% */
                span {
                    font-size: 14px;
                    font-style: normal;
                    line-height: 18px;
                    /* 128.571% */
                }
            }
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            color: var(--tbl-default-white, #FFF);
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            z-index: 1;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            width: fit-content;
            padding: 15px 50px;

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                z-index: -1;
                transition: 0.4s;
                transition: transform 0.3s ease;
                transform-origin: 0 100%;
                will-change: transform;
                transform: scaleY(0);
            }

            &:hover {
                &::after {
                    transform: scaleY(1);
                }
            }

            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }
}
