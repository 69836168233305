#services {
    .breadcrumb {
        margin-top: 7px;
        gap: 5px;
    }

    .breadcrumb-component {
        margin-bottom: 70px;

        @media(max-width: 991px) {
            margin-bottom: 0;
        }
    }

    .banner-normal {
        height: 915px;
        position: relative;
        padding-top: 180px;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 18.24%, rgba(0, 132, 181, 0.00) 81.3%);
            mix-blend-mode: multiply;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 18.24%, rgba(0, 132, 181, 0.00) 81.3%);
            mix-blend-mode: multiply;
            z-index: 2;
        }

        .banner-img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            object-fit: cover;
            height: 100%;
        }

        .content-text {
            position: relative;
            z-index: 9;
            display: flex;
            flex-direction: column;
            max-width: 583px;

            h1 {
                color: #FFF;
                font-family: 'Revx Neue', sans-serif;
                font-size: 70px;
                font-style: normal;
                font-weight: 700;
                line-height: 65px;
                /* 92.857% */
                margin-bottom: 37px;
            }

            p {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                /* 156.25% */
                margin-bottom: 76px;
                max-width: 538px;
            }

            .links {
                display: flex;
                gap: 46px;
            }

            .player {
                a {
                    width: auto;
                    height: auto;
                    background: transparent;

                    &::after {
                        display: none;
                    }

                    @media(max-width: 991px){
                        padding: 0;
                    }
                }
            }
        }

        @media (width < 991px) {
            height: Min(95vh, calc(83px + 508px));
            padding-top: 138px;

            .container {
                flex-direction: column-reverse !important;
                justify-content: space-between;
                gap: 20px;
                height: 100%;

                padding-inline: 0;
                margin-inline: 0;
            }

            .content-text {
                max-width: 342px;
                padding-inline: 25px;

                h1 {
                    font-size: 35px;
                    line-height: 35px;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 14px;
                    line-height: 18px;
                    margin: 0 0 60px 0;
                    max-width: 292px;
                }
            }

            .down-section {
                display: none;
            }
        }
    }

    .btn-scroll {
        position: absolute;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        bottom: 59px;

        @media(max-width: 991px) {
            display: none;
        }
    }

    .title-section {
        color: var(--tbl-default-black, #383838);
        font-family: 'Revx Neue';
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        margin-bottom: 43px;
        position: relative;

        @media(max-width: 991px) {
            font-size: 28px;
            line-height: 28px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 63px;
            height: 4px;
            background-color: #00AEEF;
            top: -38px;
            left: 0;
        }

        strong {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px;

            @media(max-width: 991px) {
                font-size: 28px;
                line-height: 28px;
            }
        }
    }

    .btn-section {
        height: 50px;
        width: 190px;
        background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
        color: var(--tbl-default-white, #FFF);
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 51px;
        margin-right: 47px;
        text-decoration: none;
        position: relative;
        z-index: 1;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            z-index: -1;
            transition: 0.4s;
            transition: transform 0.3s ease;
            transform-origin: 0 100%;
            will-change: transform;
            transform: scaleY(0);
        }

        &:hover {
            &::after {
                transform: scaleY(1);
            }
        }
    }

    .section1 {
        position: relative;
        margin-top: 96px;

        @media(max-width: 991px) {
            margin-top: 0px;

            .row {
                flex-direction: column-reverse;
            }
        }

        .video {
            padding: 84px;
            position: relative;
            border-radius: 22px;
            background: #F5F5F5;
            z-index: 2;

            @media(max-width: 991px) {
                padding: 20px;
                border-radius: 8px;
            }

            .img-video {
                width: 100%;
                height: 456px;
                border-radius: 10px;
                object-fit: cover;

                @media(max-width: 991px) {
                    height: 213px;
                }
            }


            .player img {
                position: absolute;
                top: calc(50% - 39px);
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }

        .quadrado {
            width: 948px;
            height: 815px;
            background: #0084B5;
            position: absolute;
            top: 186px;
            left: 0;
            z-index: 1;

            @media(max-width: 1600px) {
                width: 50%;
            }

            @media(max-width: 991px) {
                width: 80%;
                height: 240px;
                top: inherit;
                bottom: -74px;
            }
        }

        .conteudo {
            margin-top: 70px;

            p {
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                margin-bottom: 20px;

                strong {
                    color: #00AEEF;
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 25px;
                }
            }
        }
    }

    .slides {
        margin-top: 175px;
        margin-bottom: 150px;

        @media(max-width: 991px) {
            margin-bottom: 0px;
        }

        .title-section {
            text-align: center;

            &::after {
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }

        p {
            color: #4F4F4F;
            text-align: center;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 627px;
            margin: 0 auto;
        }

        .slider-section {
            position: relative;
            margin-top: 82px;

            .slider.center {
                .cl {
                    height: 544px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;

                    .content {
                        width: 100%;
                        height: 100%;
                        position: relative;

                        .texto {
                            position: absolute;
                            bottom: 69px;
                            left: 50px;
                            max-width: 339px;
                            z-index: 2;

                            h4 {
                                color: #F5F5F5;
                                font-family: 'Revx Neue';
                                font-size: 30px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 30px;
                                margin: 20px 0;
                            }

                            p {
                                color: #F5F5F5;
                                font-family: 'Roboto';
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 25px;
                                text-align: left;
                            }

                            a {
                                display: inline-block;
                                text-decoration: none;
                                color: #FFF;
                                font-family: 'Roboto';
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 25px;
                                margin-top: 32px;

                                svg {
                                    margin-left: 12px;
                                }
                            }
                        }

                        .layer {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: var(--tbl-gradients-effects-gradient-linear, linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%));
                            mix-blend-mode: multiply;
                        }
                    }
                }

                .slick-active {
                    padding: 30px 0;
                }

                .slick-center {
                    transform: scale(1.1);
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    z-index: 99;
                    position: relative;
                    max-width: 540px;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 4px;
                        background-color: #0084B5;
                        bottom: 34px;
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    }
                }

                .slick-slide:not(.slick-active) {
                    margin: 30px 0;
                }

                .child {
                    width: 100%;
                }

                .slide:not(.slick-active) {
                    cursor: pointer;
                }

                .layer-blue {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0.00) 100%);
                }
            }

            .center-prev,
            .center-next {
                position: absolute;
                top: calc(50% - 64px);
                cursor: pointer;
                z-index: 2;

                @media(max-width: 991px) {
                    display: none;
                }
            }

            .center-prev {
                left: 88px;
            }

            .center-next {
                right: 88px
            }

            .layer-green {
                position: absolute;
                width: 476px;
                height: calc(100% - 64px);
                top: 30px;
                background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0.00) 100%);
                z-index: 1;

                @media(max-width: 991px) {
                    display: none;
                }
            }

            .layer-green-second {
                position: absolute;
                width: 476px;
                height: calc(100% - 64px);
                top: 30px;
                right: 0;
                background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0.00) 100%);
                z-index: 1;
                transform: rotate(180deg);


                @media(max-width: 991px) {
                    display: none;
                }
            }
        }
    }

    .section3 {
        position: relative;
        overflow: hidden;
        padding-top: 200px;
        padding-bottom: 170px;

        @media(max-width: 991px) {
            padding-top: 90px;
            padding-bottom: 80px;
        }

        p {
            color: var(--tbl-default-black, #383838);
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 406px;
            margin-bottom: 26px;

            strong {
                color: var(--tbl-primary-primary-500, #00AEEF);
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
        }

        .btn-section {
            margin-top: 80px;

            @media(max-width: 991px) {
                width: 100%;
            }
        }

        .mapa {
            position: absolute;
            right: 0;
            top: -165px;

            @media(max-width: 1600px) {
                top: -225px;
                width: 68%;
            }

            @media(max-width: 991px) {
                position: relative;
                width: 100%;
                top: 50px;
                right: 50px;

                img {
                    width: 100%;
                    transform: scale(1.5);
                }
            }

        }
    }
}
