///////////////////////////////////
////////// FONT-FACE //////////////
///////////////////////////////////

//SE FOR GOOGLEFONTES COLOCAR NO APP.BLADE.PHP

/*@font-face {
	font-family: 'Branding-Medium';
	src: url('../fonts/Branding/Branding-Medium.woff2') format('woff2'),
	url('../fonts/Branding/Branding-Medium.woff') format('woff'),
	url('../fonts/Branding/Branding-Medium.eot') format('eot'),
	url('../fonts/Branding/Branding-Medium.svg') format('svg');
}*/


@font-face {
    font-family: 'Revx Neue';
    src: url('../fonts/revx/RevxNeue-Bold.woff2') format('woff2'),
        url('../fonts/revx/RevxNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Revx Neue';
    src: url('../fonts/revx/RevxNeue-BlackItalic.woff2') format('woff2'),
        url('../fonts/revx/RevxNeue-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Revx Neue';
    src: url('../fonts/revx/RevxNeue-Black.woff2') format('woff2'),
        url('../fonts/revx/RevxNeue-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Revx Neue';
    src: url('../fonts/revx/RevxNeue-Regular.woff2') format('woff2'),
        url('../fonts/revx/RevxNeue-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Revx Neue';
    src: url('../fonts/revx/RevxNeue-BoldItalic.woff2') format('woff2'),
        url('../fonts/revx/RevxNeue-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Revx Neue';
    src: url('../fonts/revx/RevxNeue-Italic.woff2') format('woff2'),
        url('../fonts/revx/RevxNeue-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Revx Neue';
    src: url('../fonts/revx/RevxNeue-MediumItalic.woff2') format('woff2'),
        url('../fonts/revx/RevxNeue-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Revx Neue';
    src: url('../fonts/revx/RevxNeue-Medium.woff2') format('woff2'),
        url('../fonts/revx/RevxNeue-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Revx Neue';
    src: url('../fonts/revx/RevxNeue-LightItalic.woff2') format('woff2'),
        url('../fonts/revx/RevxNeue-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Revx Neue';
    src: url('../fonts/revx/RevxNeue-Light.woff2') format('woff2'),
        url('../fonts/revx/RevxNeue-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Revx Neue';
    src: url('../fonts/revx/RevxNeue-ThinItalic.woff2') format('woff2'),
        url('../fonts/revx/RevxNeue-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Revx Neue';
    src: url('../fonts/revx/RevxNeue-SemiBold.woff2') format('woff2'),
        url('../fonts/revx/RevxNeue-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Revx Neue';
    src: url('../fonts/revx/RevxNeue-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/revx/RevxNeue-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Revx Neue';
    src: url('../fonts/revx/RevxNeue-Thin.woff2') format('woff2'),
        url('../fonts/revx/RevxNeue-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}



$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;

html {
    font-size: 62.5%;

    // 12px = 1.2rem
    // 20px = 2.0rem
    // 35px = 3.5rem
    // 46px = 4.6rem
	// 91px = 9.1rem
}

@mixin fontTemplate($size, $height, $color, $weight, $family, $align: start) {
    font-size: $size;
    line-height: $height;
    color: $color;
    font-weight: $weight;
    font-family: $family;
	text-align: $align;
}
