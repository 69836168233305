.redirectModal {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 999;

    .continueButton {
        height: 50px;
        width: 166px;
        background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
        color: var(--tbl-default-white, #FFF);
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        position: relative;
        z-index: 1;
        overflow: hidden;
        margin: 10px auto;

        @media (max-width: 1400px) {
            font-size: 16px;
            width: 100%;
            margin-right: 20px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            z-index: -1;
            transition: 0.4s;
            transition: transform 0.3s ease;
            transform-origin: 0 100%;
            will-change: transform;
            transform: scaleY(0);
        }

        &:hover {
            &::after {
                transform: scaleY(1);
            }
        }
    }

    .closeModal {
        cursor: pointer;
        float: right;
        font-size: 18px;
    }
}

.redirectModal p {
    margin: 0 auto;
    color: #4F4F4F;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    max-width: 80%;
    margin: 20px auto;
}

.redirectModal button {
    margin-top: 10px;
}

.redirectLayer {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999999999999;
}
