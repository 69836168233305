#rodoviario {
    overflow: hidden;
    .rodoviario {
        padding-block-start: calc(170px - 157px);

        .rotas-container,
        .frotas-container,
        .cargas-container {
            display: flex;

            .clip-text {
                width: 100%;
                height: 250px;
                z-index: 2;

                h3 {
                    font-family: 'Revx Neue', sans-serif;
                    font-size: 11vw;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100px; /* 40% */

                    color: transparent;
                    -webkit-text-stroke: 2px #00AEEF; /* width and color of the stroke */
                    text-stroke: 2px #00AEEF; /* Standard syntax, if supported */
                    background-clip: text;
                    position: relative;
                    bottom: -80px;
                    left: 0;
                    filter: blur(0);
                    @media (max-width: 991px) {
                        font-size: 77px;
                    }
                    @media (max-width: 550px) {
                        font-size: 16vw;
                    }
                }
            }
            &--img {
                position: relative;
                height: fit-content;

                img { max-width: 100%; }
                .icon-link {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    translate: -50% -50%;
                }
                .listras {
                    position: absolute;
                }
            }
            &--text {
                .divider {
                    height: 4px;
                    width: 63px;
                    background: #00AEEF;
                    margin-bottom: 35px;
                }
                h2 {
                    color: #383838;
                    font-family: 'Revx Neue', sans-serif;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 50px;
                    margin-block-end: 26px;
                    strong {
                        font-family: inherit;
                        font-weight: 700;
                    }
                }
                .text {
                    p + p { margin-top: 32px; }
                    p {
                        color: #383838;
                        font-family: inherit;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25px; /* 156.25% */

                        strong {
                            color: #00AEEF;
                            font-family: inherit;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
        .rotas-container {
            gap: 130px;
            padding-inline-end: 77px;
            @media (max-width: 1100px){
                padding-inline-end: 0;
                gap: 0;
                flex-direction: column;
            }
            .clip-text {
                top: -130px;
                right: -208px;
                @media (max-width: 1100px){
                    left: 0;
                }
            }
            &--text {
                max-width: 621px;
                align-self: end;
                padding-bottom: 32px;

                @media(max-width: 1600px){
                    margin-top: 150px;
                }
                @media (max-width: 1100px){
                    margin-top: 100px;
                    padding-inline: 24px;
                }
            }
            .listras {
                left: 0;
                top: -15px;
                transform: rotate(180deg);
                @media (max-width: 1100px){
                    display: none;
                }
            }
        }
        .frotas-container {
            gap: 131px;
            padding-inline-start: 77px;
            flex-direction: row-reverse;

            .clip-text {
                top: -80px;
                left: 359px;
                @media (max-width: 1100px){
                    left: 15px;
                    top: -140px;
                }
            }
            &--text {
                max-width: 546px;
                align-self: center;
                padding-top: 32px;
                @media (max-width: 1100px){
                    padding-inline: 24px;
                }
            }
            .listras {
                right: 0;
                bottom: -19px;
                @media (max-width: 1100px){
                    display: none;
                }
            }
        }
        .cargas-container {
            gap: 132px;
            padding-inline-end: 77px;
            @media (max-width: 1100px){
                padding-inline-end: 0;
                gap: 0;
                flex-direction: column;
            }
            .clip-text {
                top: -130px;
                left: 22.5%;
                @media (max-width: 1100px){
                    left: 8.5%;
                }
            }
            &--text {
                max-width: 621px;
                align-self: center;
                padding-top: 32px;

                @media(max-width: 1600px){
                    margin-top: 150px;
                    padding-bottom: 32px;
                }
                @media (max-width: 1100px){
                    margin-top: 100px;
                    padding-inline: 24px;
                    padding-top: 0;
                }
            }
            .listras {
                left: 0;
                bottom: -25px;
                transform: rotate(180deg);
                @media (max-width: 1100px){
                    display: none;
                }
            }
        }
        .rodoviario-item + .rodoviario-item { margin-top: 208px ;
            @media (max-width: 1100px){
                margin-top: 100px;
                flex-direction: column;
                gap: 50px;
                padding: 0;
            }
        }
    }
    .pracas {
        background: var(--tbl-default-off-white, #F5F5F5);
        padding: 100px 0 100px 77px;
        display: flex;
        justify-content: end;
        max-height: 886px;
        overflow: hidden;
        @media (max-width: 1100px){
            padding-inline: 24px;
            padding-top: 50px;
            padding-bottom: 50px;
        }
        &--text {
            max-width: 407px;
            align-self: center;

            .divider {
                height: 4px;
                width: 63px;
                background: #00AEEF;
                margin-bottom: 35px;
            }
            h2 {
                color: #383838;

                font-family: 'Revx Neue', sans-serif;
                font-size: 45px;
                font-style: normal;
                font-weight: 400;
                line-height: 50px;
                max-width: 223px;
                margin-block-end: 44px;

                strong {
                    font-family: inherit;
                    font-weight: 700;
                }
            }
            .text {
                p {
                    color: #383838;

                    font-family: inherit;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px; /* 156.25% */

                    strong {
                        color: #00AEEF;
                        font-family: inherit;
                        font-weight: 700;
                    }
                }
            }
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 185px;
                margin-top: 50px;
                background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                color: var(--tbl-default-white, #FFF);
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                z-index: 1;
                text-decoration: none;
                overflow: hidden;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                    z-index: -1;
                    transition: 0.4s;
                    transition: transform 0.3s ease;
                    transform-origin: 0 100%;
                    will-change: transform;
                    transform: scaleY(0);
                }
                &:hover{
                    &::after{
                        transform: scaleY(1);
                        }
                    }
            }
        }
        &--img {
            img {
                max-width: Min(100%, 65vw);
                transform: translate(60px, -75px);
                z-index: -1;
                @media (max-width: 1100px) {
                    display: none;
                }
            }
        }
    }
}
