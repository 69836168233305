#gente-treinee {
    @media (max-width: 991px) {
        overflow: hidden;
    }
    .breadcrumb {
        margin-top: 7px;
        gap: 5px;
    }

    .breadcrumb-component {
        margin-bottom: 70px;

        @media(max-width: 991px) {
            margin-bottom: 0;
        }
    }

    .banner-normal {
        height: 770px;
        position: relative;
        padding-top: 180px;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 18.24%, rgba(0, 132, 181, 0.00) 81.3%);
            mix-blend-mode: multiply;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, #000 -19.62%, rgba(0, 0, 0, 0.00) 61.41%);
            z-index: 2;
        }

        .banner-img {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            object-fit: cover;
            height: 100%;
        }

        .content-text {
            position: relative;
            z-index: 9;
            display: flex;
            flex-direction: column;
            max-width: 583px;

            h1 {
                color: #FFF;
                font-family: 'Revx Neue', sans-serif;
                font-size: 70px;
                font-style: normal;
                font-weight: 700;
                line-height: 65px;
                /* 92.857% */
                margin-bottom: 37px;
            }

            p {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                /* 156.25% */
                margin-bottom: 49px;
                max-width: 538px;
            }

            .links {
                display: flex;
                gap: 46px;
            }

            a {
                width: 239px;
            }

            .player {
                a {
                    width: auto;
                    height: auto;
                    background: transparent;

                    &::after {
                        display: none;
                    }
                }
            }
        }

        @media (width < 991px) {
            height: Min(95vh, calc(83px + 508px));
            padding-top: 138px;

            .container {
                flex-direction: column-reverse !important;
                justify-content: space-between;
                gap: 20px;
                height: 100%;

                padding-inline: 0;
                margin-inline: 0;
            }

            .content-text {
                max-width: 342px;
                padding-inline: 25px;

                h1 {
                    font-size: 35px;
                    line-height: 35px;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 14px;
                    line-height: 18px;
                    margin: 0 0 60px 0;
                    max-width: 292px;
                }
            }

            .down-section {
                display: none;
            }
        }
    }

    // componente cardProfessionals
    #cardProfessionals {
        max-width: Min(1270px, 100%);
        position: relative;
        z-index: 3;

        .card-professionals-container {
            gap: 0 8px;
            margin-block: -198px 157px;
            isolation: isolate;

            @media(max-width: 1600px) {
                margin-block: -102px 157px;
            }

            @media (max-width: 1100px) {
                overflow-y: scroll;
            }

            .card-professional {
                padding: 34px 47px;
                color: #F5F5F5;
                height: 311px;
                mix-blend-mode: multiply;
                display: flex;
                flex-direction: column;
                justify-content: end;

                @media (max-width: 1100px) {
                    aspect-ratio: 1.1;
                }

                .conteudo {
                    position: unset;
                    z-index: 2;
                }

                &::after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    z-index: -1;
                    background: linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%);
                    mix-blend-mode: multiply;
                    transition: all .3s ease-in;
                }

                &:hover {
                    transition: all .3s ease-in;
                    margin-top: -15px;

                    ::after {
                        content: '';
                        position: absolute;
                        inset: 0;
                        z-index: -1;
                        background: linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%);
                        transition: all .3s ease-in;
                    }
                }

                &-img {
                    inset: 0;
                    object-fit: cover;
                    height: 100%;
                    z-index: -2;
                }

                &-title {
                    font-family: 'Revx Neue', sans-serif;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px;
                    /* 100% */
                    margin-bottom: 11px;
                }

                &-text {
                    font-family: inherit;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    /* 156.25% */
                }

                &-title,
                &-text {
                    z-index: 2;
                }
            }
        }
    }

    .title-section {
        color: var(--tbl-default-black, #383838);
        font-family: 'Revx Neue';
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        margin-bottom: 34px;
        position: relative;

        @media(max-width: 991px) {
            font-size: 28px;
            line-height: 28px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 63px;
            height: 4px;
            background-color: #00AEEF;
            top: -38px;
            left: 0;
        }

        strong {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px;

            @media(max-width: 991px) {
                font-size: 28px;
                line-height: 28px;
            }

        }
    }

    .section1 {
        position: relative;
        padding-top: 16.719vw;
        padding-bottom: 114px;

        @media(max-width: 991px) {
            padding-top: 0;
            padding-bottom: 0;

            .row {
                flex-direction: column-reverse;
            }
        }

        .title-section {
            margin-bottom: 23px;

            @media(max-width: 991px) {
                margin-top: 200px;
            }
        }

        p {
            color: #383838;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 621px;
            margin-bottom: 26px;

            @media(max-width: 991px) {
                font-size: 14px;
                line-height: 18px;
            }

            strong {
                color: #00AEEF;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;

                @media(max-width: 991px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

        .video-section {
            position: absolute;
            width: 843px;
            height: 745px;
            top: 59px;
            right: 0;
            padding: 0;

            @media(max-width: 1600px) {
                max-width: 48%;
            }
            @media (max-width: 1400px) {
                width: 43.906vw;
                height: 38.802vw;
            }
            @media(max-width: 991px) {
                position: relative;
                max-width: 90%;
                width: 90%;
                height: 342px;
            }


            .video {
                position: relative;

                .img-video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }


                .player img {
                    position: absolute;
                    top: calc(50% - 39px);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                .linhas {
                    position: absolute;
                    right: 0;
                    bottom: -12px;

                    @media(max-width: 991px) {
                        display: none;
                    }
                }
            }
        }


        .texto-section {
            font-family: "Revx Neue";
            font-size: 14vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #60cbf2;
            text-stroke: 2px #60cbf2;
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: 0px;
            left: -51px;
            right: 0;
            margin: 0 auto;
            text-align: center;
            filter: blur(0);

            @media(max-width: 991px) {
                font-size: 73px;
                line-height: 73px;
                top: 400px;
                text-align: left;
                left: -15px;
            }
        }


    }

    .section2 {
        position: relative;
        padding-top: 221px;
        padding-bottom: 224px;

        @media (max-width: 1400px) {
            padding-bottom: 150px;
            padding-top: 100px;
        }
        @media(max-width: 991px) {
            padding-top: 0;
            padding-bottom: 0;

            .row {
                flex-direction: column-reverse;
            }
        }

        .title-section {
            margin-bottom: 23px;
            margin-top: 79px;

            @media(max-width: 991px) {
                margin-top: 149px;
            }
        }

        p {
            color: #383838;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 621px;
            margin-bottom: 26px;

            @media(max-width: 991px) {
                font-size: 14px;
                line-height: 18px;
            }

            strong {
                color: #00AEEF;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;

                @media(max-width: 991px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

        .video-section {
            position: absolute;
            width: 843px;
            height: 745px;
            top: 40px;
            left: 0;
            padding: 0;

            @media(max-width: 1600px) {
                max-width: 48%;
            }
            @media (max-width: 1400px) {
                width: 43.906vw;
                height: 38.802vw;
            }
            @media(max-width: 991px) {
                position: relative;
                max-width: 100%;
                width: 100%;
                height: 342px;
            }

            .video {
                position: relative;

                .img-video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }


                .player img {
                    position: absolute;
                    top: calc(50% - 39px);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                .linhas {
                    position: absolute;
                    left: 0;
                    bottom: -33px;

                    img {
                        transform: rotate(180deg);
                    }

                    @media(max-width: 991px) {
                        display: none;
                    }
                }
            }
        }
    }

    .section3 {
        position: relative;
        padding-top: 197px;
        padding-bottom: 286px;
        @media (max-width: 1400px) {
            padding-bottom: 14.896vw;
            padding-top: 7.26vw;
        }
        @media(max-width: 991px) {
            padding-top: 100px;
            padding-bottom: 100px;

            .row {
                flex-direction: column-reverse;
            }
        }

        .title-section {
            margin-bottom: 23px;

            @media(max-width: 991px) {
                margin-top: 30px;
            }
        }

        p {
            color: #383838;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 621px;
            margin-bottom: 26px;

            @media(max-width: 991px) {
                font-size: 14px;
                line-height: 18px;
            }

            strong {
                color: #00AEEF;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;

                @media(max-width: 991px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

        .video-section {
            position: absolute;
            width: 843px;
            height: 745px;
            top: -62px;
            right: 0;
            padding: 0;

            @media(max-width: 1600px) {
                max-width: 48%;
            }
            @media (max-width: 1400px) {
                width: 43.906vw;
                height: 38.802vw;
            }
            @media(max-width: 991px) {
                position: relative;
                max-width: 100%;
                width: 100%;
                height: 342px;
            }


            .video {
                position: relative;

                .img-video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }


                .player img {
                    position: absolute;
                    top: calc(50% - 39px);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                .linhas {
                    position: absolute;
                    right: 0;
                    bottom: -20px;

                    @media(max-width: 991px) {
                        display: none;
                    }
                }
            }
        }


        .texto-section {
            font-family: "Revx Neue";
            font-size: 250px;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #00AEEF;
            text-stroke: 2px #00AEEF;
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: 0px;
            left: -51px;
            right: 0;
            margin: 0 auto;
            text-align: center;
            filter: blur(0);

            @media(max-width: 991px) {
                font-size: 73px;
                line-height: 73px;
            }
        }


    }

    .section4 {
        position: relative;
        padding-top: 156px;
        padding-bottom: 111px;
        background-color: #F5F5F5;
        @media (max-width: 991px) {
            padding-top: 115px;
        }
        .title-section {
            text-align: center;

            &::after {
                right: 0;
                left: 0;
                margin: 0 auto;
            }
        }

        p {
            color: #4F4F4F;
            text-align: center;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 627px;
            margin: 0 auto;

            @media(max-width: 991px) {
                font-size: 14px;
                line-height: 18px;
            }
        }

        .blocos {
            margin-top: 83px;

            .bloco {
                max-width: 406px;
                min-height: 317px;
                border-radius: 20px 0px;
                background: #FFF;
                box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
                text-align: center;
                padding: 40px 20px;
                margin-bottom: 30px;
                @media (max-width: 991px) {
                    min-height: auto;
                }

                h3 {
                    color: #0084B5;
                    font-family: 'Revx Neue';
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                    margin-top: 12px;
                    margin-bottom: 20px;

                    @media(max-width: 991px) {
                        font-size: 22px;
                        line-height: 37px;
                    }
                }

                p {
                    color: #383838;
                    text-align: center;
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;

                    @media(max-width: 991px) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }

                &:hover {
                    background-color: #0084B5;
                    transition: 0.3s ease;

                    img,
                    svg {
                        filter: grayscale(100%) brightness(2);
                        transition: 0.3s ease;
                    }

                    h3,
                    p {
                        color: #fff;
                        transition: 0.3s ease;
                    }
                }
            }
        }


        .texto-section {
            font-family: "Revx Neue";
            font-size: 14vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #00AEEF;
            text-stroke: 2px #00AEEF;
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: -60px;
            left: 0px;
            right: 0;
            margin: 0 auto;
            text-align: start;
            filter: blur(0);

            @media(max-width: 991px) {
                font-size: 73px;
                line-height: 73px;
                left: 0;
                top: -40px;
            }
        }
    }

}
