@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

div, a, li {
  text-underline-offset: 8px;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
  font-weight: normal;
  margin: 0;
}

:focus,
button:focus,
a:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0 !important;
}

li {
  list-style: none;
}

a {
  color: inherit;
}
a:focus, a:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

form {
  margin: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
}
button:focus, button:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

@custom-media --desktop_big (width >= 1920px)

@custom-media --desktop (width <= 1399px)

@custom-media --desktop_min (width <= 1199px)

@custom-media --tablet_big (width <= 999px)

@custom-media --tablet (width <= 799px)

@custom-media --mobile_big (width <= 599px)

@custom-media --mobile (width <= 499px);
/* Custom CSS to override Bootstrap container widths */
@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container, .container-md {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container, .container-lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container, .container-xl {
    max-width: 1140px;
  }
}
/* If you also want to change the container for XXL (Bootstrap 5) */
@media (min-width: 1400px) {
  .container, .container-xxl {
    max-width: 1280px;
  }
}
/* Customizing the breadcrumb background */
/* Customizing the breadcrumb separator */
.breadcrumb-item + .breadcrumb-item::before {
  content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.33301 14.1666L12.4997 9.99992L8.33301 5.83325' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  display: inline-block;
  /* Ensures the SVG is displayed inline */
  width: 20px;
  /* Width of your SVG */
  height: 20px;
  /* Height of your SVG */
}

/*  */
/*------------- Hover background gradient --------------*/
@property --myColor1 {
  syntax: "<color>";
  initial-value: #FCA32D;
  inherits: false;
}
@property --myColor2 {
  syntax: "<color>";
  initial-value: #FF6196;
  inherits: false;
}
.button-hover-linearGradient {
  background-image: linear-gradient(105deg, var(--myColor1) 7%, var(--myColor2) 78%);
  transition: --myColor1 0.7s, --myColor2 0.7s;
}
.button-hover-linearGradient:hover {
  --myColor1: #ff933c;
  --myColor2: #ff933c;
}

.button-hover-linearGradient-others-browsers {
  background-image: linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -o-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -moz-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
}

/*@font-face {
	font-family: 'Branding-Medium';
	src: url('../fonts/Branding/Branding-Medium.woff2') format('woff2'),
	url('../fonts/Branding/Branding-Medium.woff') format('woff'),
	url('../fonts/Branding/Branding-Medium.eot') format('eot'),
	url('../fonts/Branding/Branding-Medium.svg') format('svg');
}*/
@font-face {
  font-family: "Revx Neue";
  src: url("../fonts/revx/RevxNeue-Bold.woff2") format("woff2"), url("../fonts/revx/RevxNeue-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Revx Neue";
  src: url("../fonts/revx/RevxNeue-BlackItalic.woff2") format("woff2"), url("../fonts/revx/RevxNeue-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Revx Neue";
  src: url("../fonts/revx/RevxNeue-Black.woff2") format("woff2"), url("../fonts/revx/RevxNeue-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Revx Neue";
  src: url("../fonts/revx/RevxNeue-Regular.woff2") format("woff2"), url("../fonts/revx/RevxNeue-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Revx Neue";
  src: url("../fonts/revx/RevxNeue-BoldItalic.woff2") format("woff2"), url("../fonts/revx/RevxNeue-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Revx Neue";
  src: url("../fonts/revx/RevxNeue-Italic.woff2") format("woff2"), url("../fonts/revx/RevxNeue-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Revx Neue";
  src: url("../fonts/revx/RevxNeue-MediumItalic.woff2") format("woff2"), url("../fonts/revx/RevxNeue-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Revx Neue";
  src: url("../fonts/revx/RevxNeue-Medium.woff2") format("woff2"), url("../fonts/revx/RevxNeue-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Revx Neue";
  src: url("../fonts/revx/RevxNeue-LightItalic.woff2") format("woff2"), url("../fonts/revx/RevxNeue-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Revx Neue";
  src: url("../fonts/revx/RevxNeue-Light.woff2") format("woff2"), url("../fonts/revx/RevxNeue-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Revx Neue";
  src: url("../fonts/revx/RevxNeue-ThinItalic.woff2") format("woff2"), url("../fonts/revx/RevxNeue-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Revx Neue";
  src: url("../fonts/revx/RevxNeue-SemiBold.woff2") format("woff2"), url("../fonts/revx/RevxNeue-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Revx Neue";
  src: url("../fonts/revx/RevxNeue-SemiBoldItalic.woff2") format("woff2"), url("../fonts/revx/RevxNeue-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Revx Neue";
  src: url("../fonts/revx/RevxNeue-Thin.woff2") format("woff2"), url("../fonts/revx/RevxNeue-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
html {
  font-size: 62.5%;
}

.form-group {
  position: relative;
  margin: 0;
}

.text-decoration-underline {
  text-decoration-color: #00AEEF;
}

:root {
  scroll-behavior: unset;
}

input.error, textarea.error, select.error {
  background-color: #fce4e4;
  border: 1px solid #cc0033;
  outline: none;
}

label.error {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
}

.grecaptcha-badge {
  display: none !important;
}

/*
    SUMMARY
        0 - Set Transitions
        1 - Hovers
        2 - Enter Animations
        3 - Keyframes

*/
img.zoom--hover,
img.up--hover,
img.arrow--hover {
  transition: 0.4s;
}

img.zoom--hover:hover {
  transform: scale(1.05);
}

img.up--hover:hover {
  transform: translateY(-30px);
}

.double {
  display: grid !important;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.double__img {
  grid-area: 1/1/-1/-1;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

.enter-image-style {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  object-fit: cover;
}

a:hover img.arrow--hover,
button:hover img.arrow--hover {
  transform: translateX(10px);
}

.animated-element[data-animation] {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

.scroll-down-animation {
  animation-name: scrollDown;
  animation-iteration-count: infinite;
  animation-duration: 3s;
}

[data-animation] {
  opacity: 0 !important;
  transition: 1s;
}

[data-animation=right] {
  transform: translateX(-50px);
}

[data-animation=left] {
  transform: translateX(50px);
}
@media (max-width: 999px) {
  [data-animation=left] {
    transform: translateX(-50px) !important;
  }
}

[data-animation=up] {
  transform: translateY(50px);
}

[data-animation=down] {
  transform: translateY(-50px);
}

[data-sequencial] [data-sequencial-stagger] {
  opacity: 0;
}
[data-sequencial] [data-sequencial-stagger=right] {
  transform: translateX(-50px);
}
[data-sequencial] [data-sequencial-stagger=left] {
  transform: translateX(50px);
}
[data-sequencial] [data-sequencial-stagger=up] {
  transform: translateY(50px);
}
[data-sequencial] [data-sequencial-stagger=down] {
  transform: translateY(50px);
}

[data-animation-banner] {
  opacity: 0 !important;
  transition: 1s;
}
[data-animation-banner].animated-element {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

body:not(.no-js) .reveal {
  position: relative;
  overflow: hidden;
}

body:not(.no-js) .image-wrap {
  transition: 1s ease-out;
  transition-delay: 0.2s;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  visibility: hidden;
}

body:not(.no-js) .image-wrap img {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .image-wrap .enter-image-style {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .animating .image-wrap {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  visibility: visible;
  transform: skewY(0);
}

body:not(.no-js) .animating img {
  transform: scale(1);
  transition: 4s ease-out;
}

body:not(.no-js) .animating .enter-image-style {
  transform: scale(1);
  transition: 4s ease-out;
}

@keyframes scrollDown {
  0% {
    transform: translate(13.062px, 20.843px);
  }
  50% {
    transform: translate(13.062px, 9.843px);
  }
  100% {
    transform: translate(13.062px, 20.843px);
  }
}
#header {
  position: absolute;
  width: 100%;
  padding-top: 20px;
  top: 0;
  left: 0;
  z-index: 99;
}
#header .logo-fixed {
  display: none;
}
#header.fixed {
  position: fixed;
  background: #fff;
  padding-top: 0;
  -webkit-box-shadow: 0px 3px 5px -1px rgba(126, 126, 126, 0.75);
  -moz-box-shadow: 0px 3px 5px -1px rgba(126, 126, 126, 0.75);
  box-shadow: 0px 3px 5px -1px rgba(126, 126, 126, 0.75);
  z-index: 999999;
}
#header.fixed .logo-fixed {
  display: block;
}
#header.fixed .logo-topo {
  display: none;
}
#header.fixed .menu .link {
  color: #00AEEF;
}
#header.fixed .redes-header a {
  transition: all 0.3s ease;
}
#header.fixed .redes-header a svg path {
  fill: #00AEEF;
  transition: all 0.3s ease;
}
#header.fixed .redes-header a:hover {
  transition: all 0.3s ease;
}
#header.fixed .redes-header a:hover svg path {
  fill: #00AEEF;
  transition: all 0.3s ease;
}
@media (max-width: 1100px) {
  #header {
    background: #00AEEF;
    padding-top: 11px;
    padding-bottom: 18px;
  }
  #header.fixed {
    background: #00AEEF;
  }
}
#header .active {
  display: none;
}
#header .logo {
  width: 165px;
  height: 80px;
}
#header .logo img {
  max-width: 160px;
}
@media (max-width: 1100px) {
  #header .logo {
    width: 115px;
    height: 54px;
  }
  #header .logo img {
    width: 100%;
    height: 100%;
  }
}
#header .wrapper-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#header .menu {
  display: flex;
  gap: 24px;
  margin-left: 62px;
}
@media (max-width: 1400px) {
  #header .menu {
    gap: 18px;
    margin-left: 40px;
  }
}
@media (max-width: 1100px) {
  #header .menu {
    display: none;
  }
}
#header .menu .link {
  cursor: pointer;
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
}
@media (max-width: 1400px) {
  #header .menu .link {
    font-size: 16px;
  }
}
#header .menu .link .plus {
  color: var(--tbl-primary-primary-500, #00AEEF);
  display: inline-block;
  width: 10px;
  margin-left: 5px;
}
#header .menu .link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 4px;
  background-color: #00AEEF;
  top: -30px;
  left: 0;
  opacity: 0;
  transition: width 0.3s ease-in-out;
  transform-origin: left;
}
#header .menu .link:hover::after {
  width: 100%;
  opacity: 1;
}
#header .menu .dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99;
  flex-direction: column;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: opacity 0.5s ease-in, height 0.5s ease-in;
  white-space: nowrap;
  margin-top: 30px;
  z-index: -1;
}
#header .menu .dropdown-content.show {
  opacity: 1;
  height: auto;
  z-index: 999;
  visibility: visible;
  transition: opacity 0.5s ease-in, height 0.5s ease-in;
}
#header .menu .dropdown-content a {
  color: var(--tbl-default-black, #383838);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  display: block;
  padding: 12px 24px 12px 24px;
}
#header .menu .dropdown-content a:hover {
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: #fff;
}
#header .cta-header {
  margin-left: 24px;
  height: 50px;
  width: 166px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 47px;
  text-decoration: none;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 1400px) {
  #header .cta-header {
    font-size: 16px;
    width: 135px;
    margin-right: 20px;
  }
}
#header .cta-header::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#header .cta-header:hover::after {
  transform: scaleY(1);
}
@media (max-width: 1100px) {
  #header .cta-header {
    display: none;
  }
}
#header .redes-header {
  display: flex;
  gap: 25px;
  align-items: center;
}
@media (max-width: 1100px) {
  #header .redes-header {
    display: none;
  }
}
#header .redes-header a {
  transition: all 0.3s ease;
}
#header .redes-header a svg path {
  transition: all 0.3s ease;
}
#header .redes-header a:hover {
  transition: all 0.3s ease;
}
#header .redes-header a:hover svg path {
  fill: #0084B5;
  transition: all 0.3s ease;
}
#header #nav-icon1,
#header #nav-icon2,
#header #nav-icon3,
#header #nav-icon4 {
  width: 60px;
  height: 45px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  transform: scale(0.8);
  display: none;
}
@media (max-width: 1100px) {
  #header #nav-icon1,
#header #nav-icon2,
#header #nav-icon3,
#header #nav-icon4 {
    display: block;
  }
}
#header #nav-icon1 span,
#header #nav-icon3 span,
#header #nav-icon4 span {
  display: block;
  position: absolute;
  height: 9px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
#header #nav-icon3 span:nth-child(1) {
  top: 0px;
}
#header #nav-icon3 span:nth-child(2),
#header #nav-icon3 span:nth-child(3) {
  top: 18px;
}
#header #nav-icon3 span:nth-child(4) {
  top: 36px;
}
#header #nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}
#header #nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
#header #nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#header #nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.menu-mobile {
  position: fixed;
  top: 67px;
  min-height: 100%;
  width: 100%;
  background: #fff;
  padding-left: 64px;
  padding-top: 43px;
  z-index: 999;
  margin-left: -100%;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 31px;
  padding-bottom: 30px;
}
.menu-mobile.open {
  margin-left: 0;
}
.menu-mobile .box-links {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-top: 30px;
  padding-bottom: 20px;
}
.menu-mobile .box-links a {
  text-decoration: none;
  color: #383838;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.menu-mobile summary {
  color: #383838;
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  list-style: none;
  position: relative;
  width: fit-content;
}
.menu-mobile summary::after {
  content: "+";
  position: absolute;
  right: -20px;
  top: 0;
  font-size: 20px;
  color: #00AEEF;
}
.menu-mobile #summmaryModais::after {
  display: none;
}
.menu-mobile summary::-webkit-details-marker {
  display: none;
}
.menu-mobile details[open] summary::after {
  content: "-";
}

#footer .news {
  padding: 99px 0 75px 0;
  background: #00AEEF;
}
@media (max-width: 1100px) {
  #footer .news {
    padding: 30px 17.5px 33px 17.5px;
  }
}
#footer .news h3 {
  color: #FFF;
  font-family: "Revx Neue";
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
}
#footer .news h3 strong {
  color: #FFF;
  font-family: "Revx Neue";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}
@media (max-width: 1100px) {
  #footer .news h3 {
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 24px;
    max-width: 80%;
  }
  #footer .news h3 br {
    display: none;
  }
  #footer .news h3 strong {
    font-size: 25px;
    font-style: normal;
    line-height: 30px;
  }
}
#footer .news form input,
#footer .news form input::placeholder {
  color: #575756;
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
@media (max-width: 1100px) {
  #footer .news form input,
#footer .news form input::placeholder {
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 59px;
  }
}
#footer .news form input {
  border: none;
  padding: 9px 27px;
  width: 100%;
}
#footer .news form input[type=submit] {
  padding: 15px 0px;
  background: #0084B5;
  color: var(--tbl-default-white, #FFF);
  font-family: "Roboto";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
#footer .news form .aceite {
  display: flex;
  align-items: start;
  margin-top: 28px;
  gap: 15px;
}
#footer .news form .aceite p {
  color: #00668B;
  font-family: "Roboto";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
@media (max-width: 1100px) {
  #footer .news form .aceite p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}
#footer .news form .btn-section {
  height: 50px;
  width: 190px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #036f96 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-right: 0;
  text-decoration: none;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
#footer .news form .btn-section::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #036f96 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#footer .news form .btn-section:hover::after {
  transform: scaleY(1);
}
#footer .news .checkbox.style-c {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#footer .news .checkbox.style-c input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#footer .news .checkbox.style-c input:checked ~ .checkbox__checkmark {
  background-color: #0084B5;
}
#footer .news .checkbox.style-c input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
}
#footer .news .checkbox.style-c:hover input ~ .checkbox__checkmark {
  background-color: #eee;
}
#footer .news .checkbox.style-c:hover input:checked ~ .checkbox__checkmark {
  background-color: #0084B5;
}
#footer .news .checkbox.style-c .checkbox__checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  transition: background-color 0.25s ease;
  border-radius: 4px;
}
#footer .news .checkbox.style-c .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}
#footer .news .checkbox.style-c .checkbox__body {
  color: #fff;
  line-height: 1.4;
  font-size: 16px;
}
#footer .links {
  padding-top: 111px;
  border-bottom: solid 1px #FFF;
  background: #0084B5;
}
@media (max-width: 1100px) {
  #footer .links {
    padding-inline: 25px;
    padding-top: 68px;
  }
}
#footer .links .logos {
  display: grid;
  gap: 42px;
}
@media (max-width: 1100px) {
  #footer .links .logos {
    grid-template-columns: 50% 50%;
    gap: 15px;
    align-items: center;
    margin-bottom: 45px;
  }
  #footer .links .logos img {
    width: 100%;
  }
}
#footer .links h4 {
  color: #FFF;
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 30px;
}
@media (max-width: 1100px) {
  #footer .links h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
#footer .links ul {
  padding-left: 0;
}
#footer .links ul li {
  margin-bottom: 14px;
}
#footer .links ul li a {
  display: inline-block;
  text-decoration: none;
  color: #FFF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.3s ease-in;
  position: relative;
}
#footer .links ul li a:after {
  content: "";
  width: 100%;
  border-bottom: 1px solid #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}
#footer .links ul li a:hover {
  text-decoration: underline 2px #f5f5f5;
  text-underline-offset: 6px;
  transition: 0.3s ease-out;
}
@media (max-width: 1100px) {
  #footer .links ul li a {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
#footer .links .parte2 {
  margin-top: 60px;
  margin-bottom: 29px;
}
@media (max-width: 1100px) {
  #footer .links .parte2 {
    margin-bottom: 43px;
  }
}
#footer .links .parte2 p {
  color: #FFF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
@media (max-width: 1100px) {
  #footer .links .parte2 p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
#footer .links .parte2 p a {
  color: inherit;
  font-size: inherit;
  text-decoration: none;
  transition: all 0.3s ease-out;
}
#footer .links .parte2 p a:hover {
  text-decoration: underline 2px #f5f5f5;
  text-underline-offset: 6px;
}
#footer .copy {
  padding: 21px 0;
  background: #0084B5;
}
@media (max-width: 1100px) {
  #footer .copy {
    padding-inline: 25px;
    padding-top: 30px;
    padding-bottom: 17px;
  }
}
#footer .copy a {
  display: inline-block;
  text-decoration: none;
}
#footer .copy .redes {
  display: flex;
  gap: 20px;
}
@media (max-width: 1100px) {
  #footer .copy .redes {
    display: none;
  }
}
#footer .copy .politcas {
  display: flex;
  gap: 41px;
  margin-left: 56px;
}
@media (max-width: 1600px) {
  #footer .copy .politcas {
    margin-left: 0px;
  }
}
@media (max-width: 1100px) {
  #footer .copy .politcas {
    flex-direction: column;
    gap: 0;
  }
}
#footer .copy .politcas a {
  color: #FFF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
#footer .copy .politcas p {
  color: #FFF;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
#footer .error--message {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

#modal-zoom-images {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transform: scale(0);
  opacity: 0;
  transition: 0.4s;
  z-index: -100;
  display: flex;
  align-items: center;
  justify-content: center;
}
#modal-zoom-images.activate-modal {
  opacity: 1;
  transform: scale(1);
  z-index: 10000;
}
#modal-zoom-images #btn-close__zoom-modal {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 599px) {
  #modal-zoom-images #btn-close__zoom-modal {
    top: 10px;
    right: 10px;
  }
}
#modal-zoom-images #btn-close__zoom-modal i {
  font-size: 25px;
  color: rgb(226, 226, 226);
}
#modal-zoom-images #image__zoom-modal {
  width: 80vw;
  height: 80vh;
  object-fit: contain;
}
@media (max-width: 599px) {
  #modal-zoom-images #image__zoom-modal {
    width: 90vw;
  }
}

.images--zoom {
  cursor: pointer;
}

.redirectModal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 999;
}
.redirectModal .continueButton {
  height: 50px;
  width: 166px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin: 10px auto;
}
@media (max-width: 1400px) {
  .redirectModal .continueButton {
    font-size: 16px;
    width: 100%;
    margin-right: 20px;
  }
}
.redirectModal .continueButton::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
.redirectModal .continueButton:hover::after {
  transform: scaleY(1);
}
.redirectModal .closeModal {
  cursor: pointer;
  float: right;
  font-size: 18px;
}

.redirectModal p {
  margin: 0 auto;
  color: #4F4F4F;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 80%;
  margin: 20px auto;
}

.redirectModal button {
  margin-top: 10px;
}

.redirectLayer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999999999999;
}

.show--error input {
  color: red !important;
  border: 1px solid red !important;
}
.show--error input::placeholder {
  color: red !important;
}
.show--error .error--message {
  display: block;
  color: red !important;
}

.error--message {
  display: none;
}

.error-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.error-message-forms.open-modal {
  display: flex;
}
.error-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 450px;
  padding: 30px 50px;
  gap: 20px;
}
.error-message-forms--container #btn--close-error-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.error-message-forms--container #btn--close-error-message ion-icon {
  font-size: 3.4rem;
  color: 900;
}
.error-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 4.5rem;
  color: white;
}
.error-message-forms--container .description {
  font-size: 2rem;
  line-height: 1.7;
  color: 900;
  font-weight: 400;
  font-family: "";
  text-align: center;
}
.error-message-forms--container .confirm {
  font-size: 1.6rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  font-family: "";
  text-align: center;
  padding: 20px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
@media (max-width: 799px) {
  .error-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .error-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .error-message-forms--container #btn--close-error-message img {
    width: 20px;
  }
  .error-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .error-message-forms--container .description {
    font-size: 1.4rem;
    line-height: 20px;
  }
  .error-message-forms--container .confirm {
    margin-top: 0px;
    width: 150px;
    height: 50px;
  }
}

.success-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.success-message-forms.open-modal {
  display: flex;
}
.success-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 500px;
  padding: 30px 50px;
  gap: 20px;
}
.success-message-forms--container #btn--close-success-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.success-message-forms--container #btn--close-success-message ion-icon {
  font-size: 3.4rem;
  color: 900;
}
.success-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5vw;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 4.5rem;
  color: white;
}
.success-message-forms--container .description {
  font-size: 2rem;
  line-height: 1.7;
  color: 900;
  font-weight: 400;
  font-family: "";
  text-align: center;
}
.success-message-forms--container .confirm {
  font-size: 1.6rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  font-family: "";
  text-align: center;
  padding: 20px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
@media (max-width: 799px) {
  .success-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .success-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .success-message-forms--container #btn--close-success-message img {
    width: 20px;
  }
  .success-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .success-message-forms--container .description {
    font-size: 1.4rem;
    line-height: 20px;
  }
  .success-message-forms--container .confirm {
    margin-top: 0px;
    width: 150px;
    height: 50px;
  }
}

.loading-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.loading-forms.open-modal {
  display: flex;
}
.loading-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 30vw;
  height: 30vh;
  clip-path: polygon(0 8%, 72% 8%, 75% 0, 100% 0, 100% 100%, 19% 100%, 16% 92%, 0 92%);
}
.loading-forms .custom-loader {
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  background: conic-gradient(rgba(0, 0, 0, 0) 10%, 900);
  -webkit-mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@media (max-width: 799px) {
  .loading-forms--container {
    width: 95%;
    padding: 0 !important;
    height: 200px;
  }
  .loading-forms .custom-loader {
    width: 40px;
    height: 40px;
  }
}

#explore .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 34px !important;
  position: relative;
  text-align: center;
}
@media (max-width: 1100px) {
  #explore .title-section {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
    padding-inline: 17.5px;
  }
}
#explore .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px !important;
  left: 48% !important;
}
@media (max-width: 1100px) {
  #explore .title-section::after {
    left: 50% !important;
    transform: translateX(-50%);
  }
}
#explore .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  #explore .title-section strong {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}
#explore p {
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 657px;
  margin: 0 auto;
}
@media (max-width: 1100px) {
  #explore p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    padding-inline: 17.5px;
  }
}
#explore .redes {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 43px;
  margin-top: 62px;
  margin-bottom: 76px;
}
@media (max-width: 1100px) {
  #explore .redes {
    margin-top: 52px;
    margin-bottom: 40px;
    gap: 25px;
  }
}
#explore .redes a {
  text-decoration: none;
  display: inline-block;
  transition: 0.3s ease-in;
}
#explore .redes a:hover {
  transition: 0.3s ease-in;
}
#explore .redes a:hover svg path {
  fill: #0084B5;
  transition: 0.3s ease-in;
}
#explore .galeria {
  width: 100%;
  height: 384px;
  object-fit: cover;
}

.breadcrumb-component {
  margin-bottom: 73px;
  z-index: 9;
  position: relative;
}
.breadcrumb-component li {
  color: var(--tbl-default-white, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 16px */
  display: flex;
  align-items: center;
}
@media (width < 991px) {
  .breadcrumb-component li {
    font-size: 14px;
  }
}
.breadcrumb-component .breadcrumb-item.active {
  text-decoration: underline 2px;
  color: #fff;
  text-decoration-color: #00AEEF;
  text-underline-offset: 8px;
}
@media (width < 991px) {
  .breadcrumb-component {
    background: rgba(0, 174, 239, 0.1);
    padding: 20px 33px 20px 33px;
    margin-bottom: 0;
  }
}

.banner-normal {
  min-height: 95dvh;
  position: relative;
  padding-top: 9.375vw;
  padding-bottom: 10.417vw;
}
@media (width < 991px) {
  .banner-normal {
    padding-bottom: 0;
    display: flex;
    align-items: flex-end;
  }
}
.banner-normal .black-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #000 -19.62%, rgba(0, 0, 0, 0) 61.41%);
  top: 0;
  z-index: 2;
}
.banner-normal::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 18.24%, rgba(0, 132, 181, 0) 81.3%);
  mix-blend-mode: multiply;
}
.banner-normal::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 18.24%, rgba(0, 132, 181, 0) 81.3%);
  mix-blend-mode: multiply;
  z-index: 2;
}
.banner-normal .banner-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.banner-normal .container {
  display: flex;
  flex-direction: column;
}
.banner-normal .down-section {
  position: absolute;
  left: 50%;
  /* Move to 50% of the container's width */
  transform: translateX(-50%);
  /* Shift back to the left by 50% of its own width */
  z-index: 9;
  bottom: 60px;
}
@media (max-width: 1600px) {
  .banner-normal .down-section {
    bottom: 105px;
  }
}
.banner-normal .content-text {
  position: relative;
  z-index: 9;
  display: flex;
  flex-direction: column;
  max-width: 583px;
  margin-bottom: 50px;
}
.banner-normal .content-text h1 {
  color: #FFF;
  font-family: "Revx Neue";
  font-size: 3.646vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3.385vw;
  /* 92.857% */
  margin-bottom: 33px;
}
.banner-normal .content-text h1 strong {
  font-size: 3.646vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3.385vw;
}
.banner-normal .content-text p {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 44px;
}
.banner-normal .content-text a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: fit-content;
  display: flex;
  padding: 15px 50px;
  justify-content: center;
  align-items: center;
}
.banner-normal .content-text a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
.banner-normal .content-text a:hover::after {
  transform: scaleY(1);
}
.banner-normal .content-text .links {
  display: flex;
  gap: 46px;
}
.banner-normal .content-text .player a {
  width: auto;
  height: auto;
  background: transparent;
  padding: 0;
  transform: translateY(-5px);
}
.banner-normal .content-text .player a::after {
  display: none;
}
@media (width < 991px) {
  .banner-normal {
    min-height: min(95vh, 591px);
    padding-top: 138px;
    padding-bottom: 0;
  }
  .banner-normal .container {
    flex-direction: column-reverse !important;
    justify-content: space-between;
    gap: 60px;
    height: 100%;
    padding-inline: 0;
    margin-inline: 0;
  }
  .banner-normal .content-text {
    max-width: 342px;
    padding-inline: 25px;
  }
  .banner-normal .content-text h1 {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 20px;
  }
  .banner-normal .content-text p {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 60px 0;
    max-width: 292px;
  }
  .banner-normal .down-section {
    display: none;
  }
}

.banner-on-page {
  position: relative;
  padding-top: 8.854vw;
  padding-bottom: 7.292vw;
}
@media (width < 991px) {
  .banner-on-page {
    padding-top: 109px;
    padding-bottom: 72px;
  }
}
.banner-on-page::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--TBL-Gradients-Effects-gradienteDiagonal, linear-gradient(260deg, #000 -13.46%, rgba(0, 0, 0, 0) 78.89%));
}
@media (max-width: 991px) {
  .banner-on-page::after {
    background: linear-gradient(90deg, #000000 -13.46%, rgba(0, 0, 0, 0) 78.89%);
  }
}
.banner-on-page .banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.banner-on-page .content-text {
  position: relative;
  z-index: 9;
}
@media (width < 991px) {
  .banner-on-page .content-text {
    padding-inline: 17.5px;
  }
}
.banner-on-page .content-text h1 {
  color: #fff;
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  position: relative;
  margin-bottom: 32px;
}
.banner-on-page .content-text h1::after {
  content: "";
  position: absolute;
  top: -35px;
  left: 0;
  width: 63px;
  height: 4px;
  background: #00AEEF;
}
.banner-on-page .content-text h1 span {
  color: #fff;
  /* H2 - Title bold */
  font-family: inherit;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  .banner-on-page .content-text h1 {
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    /* 100% */
  }
  .banner-on-page .content-text h1 span {
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
  }
}
.banner-on-page .content-text p {
  color: #fff;
  /* H6 - Text Body */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 56px;
}
.banner-on-page .content-text p span {
  color: #fff;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 1100px) {
  .banner-on-page .content-text p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
  .banner-on-page .content-text p span {
    font-size: 14px;
    font-style: normal;
    line-height: 18px;
    /* 128.571% */
  }
}
.banner-on-page .content-text a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  width: fit-content;
  padding: 15px 50px;
}
.banner-on-page .content-text a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
.banner-on-page .content-text a:hover::after {
  transform: scaleY(1);
}
@media (max-width: 1100px) {
  .banner-on-page .content-text a {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

/*------------------------- Containers -----------------------*/
.termsfeed-com---palette-dark.termsfeed-com---nb {
  background: #0084b5;
  background-color: #0084b5;
}

.termsfeed-com---palette-light.termsfeed-com---nb {
  background-color: white;
}

.termsfeed-com---palette-light.termsfeed-com---nb {
  box-shadow: 0px 3px 10px #222222;
}

/*-------------------------TEXTS -----------------------*/
/*------------------------ BUTTONS -----------------------*/
.termsfeed-com---palette-dark .cc-nb-okagree {
  background: #222222;
  color: #fff;
  margin-right: 1rem !important;
  transition: background-color 1s;
}
.termsfeed-com---palette-dark .cc-nb-okagree:hover {
  background-color: #0084b5;
}

.termsfeed-com---palette-dark .cc-nb-reject {
  color: #fff;
  background: linear-gradient(141deg, #0084b5 31.89%, transparent) #0084b5 34%;
  margin-right: 1rem !important;
  transition: background-color 1s;
}
.termsfeed-com---palette-dark .cc-nb-reject:hover {
  background-color: #0084b5;
}

.termsfeed-com---palette-light .cc-nb-okagree {
  background: #0084b5;
  color: #fff;
  margin-right: 1rem !important;
  transition: background-color 1s;
}
.termsfeed-com---palette-light .cc-nb-okagree:hover {
  background-color: #0084b5;
}

.termsfeed-com---palette-light .cc-nb-reject {
  color: #fff;
  background: linear-gradient(141deg, #0084b5 31.89%, transparent) #0084b5 34%;
  margin-right: 1rem !important;
  transition: background-color 1s;
}
.termsfeed-com---palette-light .cc-nb-reject:hover {
  background-color: #0084b5;
}

.termsfeed-com---palette-dark .cc-nb-okagree,
.termsfeed-com---palette-light .cc-nb-okagree {
  background-color: #0084b5;
}

.termsfeed-com---palette-dark .cc-nb-title,
.termsfeed-com---palette-light .cc-nb-title {
  font-family: "Revx Neue", sans-serif;
  font-weight: 700;
}

.termsfeed-com---nb-simple {
  left: inherit;
  right: 20vw;
  bottom: 2vh;
  border: 0.2px solid #ffffff;
  transition: 0.4s;
}
.termsfeed-com---nb-simple::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #0084b5;
  position: absolute;
  left: 0;
  bottom: 0;
}

.termsfeed-com---palette-light .cc-nb-okagree {
  border-radius: 0px !important;
  padding: 10px 20px !important;
}

.termsfeed-com---palette-light .cc-nb-reject {
  border-radius: 0px !important;
  padding: 10px 20px !important;
}

.termsfeed-com---palette-light .cc-nb-changep {
  border-radius: 0px !important;
  padding: 10px 20px !important;
}

#page404 {
  background: url("../img/bg-404.webp") no-repeat center;
  background-size: cover;
  min-height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1100px) {
  #page404 {
    background: #000 url("../img/bg-mobile-404.webp") no-repeat center;
    background-size: cover;
  }
}
#page404 .content-text {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  /* Position the top edge of the element in the middle of the container */
  left: 50%;
  /* Position the left edge of the element in the middle of the container */
  transform: translate(-50%, -50%);
  /* Shift the element left and up by half its own width and height */
  align-items: center;
}
#page404 .content-text h1 {
  color: var(--tbl-default-white-off, #F5F5F5);
  font-family: "Revx Neue";
  font-size: 150px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 150px */
}
#page404 .content-text .sub-title {
  display: flex;
  gap: 20px;
  color: #FFF;
  font-family: "Revx Neue";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  /* 108% */
  margin-top: 32px;
  margin-bottom: 32px;
}
#page404 .content-text .text {
  color: var(--tbl-default-white, #FFF);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  max-width: 494px;
}
#page404 .content-text a {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--tbl-default-white-off, #F5F5F5);
  color: var(--tbl-secondary-secondary-500, #0084B5);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: fit-content;
  padding-inline: 50px;
  text-decoration: none;
  margin-top: 56px;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

@media (max-width: 1100px) {
  #home {
    margin-top: 83px;
  }
}
#home img {
  max-width: 100%;
}
#home .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 43px;
  position: relative;
}
@media (max-width: 1100px) {
  #home .title-section {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
    margin-bottom: 32px;
  }
}
#home .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
}
#home .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  #home .title-section strong {
    font-size: 28px;
    font-style: normal;
    line-height: 28px;
    /* 100% */
  }
}
#home .btn-section {
  height: 50px;
  width: 190px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 51px;
  margin-right: 47px;
  text-decoration: none;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 1100px) {
  #home .btn-section {
    width: calc(100% - 35px);
    margin: 0 auto;
    margin-bottom: 1rem;
  }
}
#home .btn-section::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#home .btn-section:hover::after {
  transform: scaleY(1);
}
#home .slide {
  position: relative;
}
#home .banners .banner-ind {
  padding-top: 100px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: max(655px, 50.781vw);
}
@media (max-width: 1100px) {
  #home .banners .banner-ind {
    height: 508px;
    padding-top: 0;
  }
}
#home .banners .banner-ind .layer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.95) 18.24%, rgba(0, 0, 0, 0) 81.3%);
}
@media (max-width: 1100px) {
  #home .banners .banner-ind .layer {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #home .banners .banner-ind .layer::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(144deg, rgba(0, 0, 0, 0.95) -10.05%, rgba(0, 0, 0, 0.8) 38.22%, rgba(0, 0, 0, 0) 93.61%);
  }
  #home .banners .banner-ind .layer::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, #000 -19.62%, rgba(0, 0, 0, 0) 61.41%);
  }
}
#home .banners .conteudo-banner {
  padding-top: 7.135vw;
  padding-bottom: 14.375vw;
  position: relative;
  z-index: 2;
}
@media (max-width: 1100px) {
  #home .banners .conteudo-banner {
    padding-inline: 17.5px;
    padding-top: 55px;
    padding-bottom: 0;
  }
}
#home .banners h2 {
  font-family: "Revx Neue";
  font-size: 3.646vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3.385vw;
  color: #ffffff;
  margin-bottom: 32px;
  max-width: 430px;
}
@media (max-width: 1100px) {
  #home .banners h2 {
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    /* 100% */
    max-width: 85%;
    margin-bottom: 20px;
  }
}
#home .banners p {
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  color: #ffffff;
  margin-bottom: 85px;
  max-width: 430px;
}
@media (max-width: 1100px) {
  #home .banners p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    max-width: 85%;
    margin-bottom: 60px;
  }
}
#home .banners .btn-banner {
  height: 50px;
  min-width: 185px;
  width: fit-content;
  padding-inline: 20px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 47px;
  text-decoration: none;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 1100px) {
  #home .banners .btn-banner {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
#home .banners .btn-banner::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#home .banners .btn-banner:hover::after {
  transform: scaleY(1);
}
@media (max-width: 1400px) {
  #home .banners .slick-slide[data-slick-index="0"] .conteudo-banner {
    padding-top: 4vw;
  }
}
#home .banners .slick-slide[data-slick-index="0"] h2 {
  max-width: 570px;
}
#home .banners .slick-slide[data-slick-index="0"] p {
  max-width: 490px;
}
@media (max-width: 1600px) {
  #home .banners .slick-slide[data-slick-index="0"] p {
    max-width: 570px;
    margin-bottom: 32px;
  }
}
@media (max-width: 1450px) {
  #home .banners .slick-slide[data-slick-index="0"] p {
    font-size: 14px;
  }
}
#home .banner-prev,
#home .banner-next {
  position: absolute;
  top: 47%;
  cursor: pointer;
}
#home .banner-prev {
  left: 83px;
}
#home .banner-next {
  right: 83px;
}
@media (max-width: 1600px) {
  #home .banner-prev {
    left: 5px;
  }
  #home .banner-next {
    right: 5px;
  }
}
@media (max-width: 1100px) {
  #home .banner-prev,
#home .banner-next {
    display: none !important;
  }
}
#home .dots-banners {
  position: relative;
}
#home .dots-banners .slick-dots {
  width: auto;
  bottom: 111px;
  left: 0px;
  z-index: 99;
}
@media (max-width: 1500px) {
  #home .dots-banners .slick-dots {
    bottom: 70px;
  }
}
@media (max-width: 1100px) {
  #home .dots-banners .slick-dots {
    left: 50%;
    transform: translateX(-50%);
    bottom: 59px;
  }
}
#home .dots-banners .slick-dots li {
  margin: 0 3px;
}
#home .dots-banners .slick-dots li,
#home .dots-banners .slick-dots button {
  width: 24px;
  height: 5px;
  padding: 0;
  border-radius: 3px;
}
@media (max-width: 1100px) {
  #home .dots-banners .slick-dots li,
#home .dots-banners .slick-dots button {
    width: 33px;
    height: 5.5px;
  }
}
#home .dots-banners .slick-dots button::before {
  display: none;
}
#home .dots-banners .slick-dots li button {
  background-color: #fff;
}
#home .dots-banners .slick-dots li.slick-active button {
  background-color: #0084B5;
}
#home .btn-scroll {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  bottom: 91px;
}
@media (max-width: 1500px) {
  #home .btn-scroll {
    bottom: 60px;
  }
}
@media (max-width: 1100px) {
  #home .btn-scroll {
    display: none;
  }
}
#home .about {
  position: relative;
  padding-top: 105px;
  padding-bottom: 255px;
}
@media (max-width: 1100px) {
  #home .about {
    padding-top: 32px;
    padding-bottom: 60px;
  }
}
@media (max-width: 1100px) {
  #home .about .row {
    flex-direction: column-reverse;
  }
}
#home .about .bloco-azul {
  width: 875px;
  height: 582px;
  background-color: #0084B5;
  position: absolute;
  top: 228px;
  left: -42px;
}
@media (max-width: 1600px) {
  #home .about .bloco-azul {
    max-width: 61%;
  }
}
@media (max-width: 1100px) {
  #home .about .bloco-azul {
    width: 80%;
    max-width: 100%;
    height: 271px;
    bottom: 228px;
    left: 0;
    position: absolute;
    top: inherit;
  }
}
@media (max-width: 1100px) {
  #home .about .col-bloco-cinza {
    padding-inline: 25px;
  }
}
#home .about .bloco-cinza {
  width: 100%;
  max-width: 868px;
  border-radius: 22px;
  background: #F5F5F5;
  padding: 49px 98px 49px 98px;
  position: absolute;
  left: 151px;
  top: 95px;
}
@media (max-width: 1600px) {
  #home .about .bloco-cinza {
    left: 0;
    max-width: 55%;
  }
}
@media (max-width: 1100px) {
  #home .about .bloco-cinza {
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    position: relative;
    padding: 14px 25px;
    margin-bottom: 96px;
  }
}
#home .about .bloco-cinza h3 {
  font-family: "Revx Neue";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  color: var(--tbl-default-black, #383838);
  text-align: center;
  margin-bottom: 27px;
}
@media (max-width: 1100px) {
  #home .about .bloco-cinza h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    margin-bottom: 7px;
  }
}
#home .about .bloco-cinza h3 strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
}
#home .about .bloco-cinza .video {
  width: 100%;
  max-width: 671px;
  border-radius: 10px;
  margin-bottom: 33px;
  position: relative;
}
@media (max-width: 1100px) {
  #home .about .bloco-cinza .video {
    height: 142px;
    margin-bottom: 14px;
  }
  #home .about .bloco-cinza .video img {
    height: 100%;
  }
}
#home .about .bloco-cinza .video img {
  border-radius: 10px;
}
#home .about .bloco-cinza .video .play {
  position: absolute;
  left: 0;
  right: 0;
  top: 39%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#home .about .bloco-cinza p {
  color: var(--tbl-default-black, #383838);
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
@media (max-width: 1100px) {
  #home .about .bloco-cinza p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 116.667% */
  }
}
#home .about .content {
  margin-left: 87px;
}
@media (max-width: 1100px) {
  #home .about .content {
    margin-left: 0;
  }
}
#home .about .content p {
  color: var(--tbl-default-black, #383838);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 376px;
  margin-bottom: 26px;
}
#home .about .content p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
#home .cards .row {
  gap: 59px;
}
@media (max-width: 1100px) {
  #home .cards .row {
    gap: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding-bottom: 1rem;
  }
}
#home .cards .mini-box {
  border-radius: 20px 0px;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 24px 28px;
  max-width: 208px;
  height: 200px;
  transition: 0.3s ease;
}
#home .cards .mini-box .icone {
  height: 28px;
}
#home .cards .mini-box .numero {
  color: var(--tbl-secondary-secondary-500, #0084B5);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  margin-top: 29px;
  transition: 0.3s ease;
}
#home .cards .mini-box .desc {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  transition: 0.3s ease;
}
#home .cards .mini-box:hover {
  background-color: #0084B5;
  transition: 0.3s ease;
}
#home .cards .mini-box:hover img {
  filter: grayscale(100%) brightness(2);
  transition: 0.3s ease;
}
#home .cards .mini-box:hover .numero,
#home .cards .mini-box:hover .desc {
  color: #fff;
  transition: 0.3s ease;
}
#home .completa {
  margin-top: 117px;
  padding-top: 91px;
  padding-bottom: 250px;
  background-color: #F5F5F5;
  position: relative;
}
@media (max-width: 1100px) {
  #home .completa {
    margin-top: 60px;
    padding-bottom: 90px;
  }
}
#home .completa .title-section {
  text-align: center;
  margin-bottom: 31px;
}
#home .completa .title-section::after {
  left: 47%;
}
#home .completa p {
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 627px;
  margin: 0 auto;
}
@media (max-width: 1100px) {
  #home .completa p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}
#home .completa .lines {
  position: absolute;
  right: 0;
  top: 30px;
}
@media (max-width: 1100px) {
  #home .completa .lines {
    display: none;
  }
}
#home .completa .slider-section {
  position: relative;
  margin-top: 70px;
}
@media (max-width: 1100px) {
  #home .completa .slider-section {
    margin-top: 35px;
  }
}
#home .completa .slider-section .slider.center .cl {
  height: 28.333vw;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1100px) {
  #home .completa .slider-section .slider.center .cl {
    height: 379px;
  }
}
#home .completa .slider-section .slider.center .cl .content {
  width: 100%;
  height: 100%;
  position: relative;
}
@media (max-width: 1100px) {
  #home .completa .slider-section .slider.center .cl .content .icone img {
    transform: scale(0.65);
  }
}
#home .completa .slider-section .slider.center .cl .content .texto {
  position: absolute;
  bottom: 69px;
  left: 2.604vw;
  max-width: 339px;
  z-index: 2;
}
@media (max-width: 1100px) {
  #home .completa .slider-section .slider.center .cl .content .texto {
    bottom: 44px;
    left: 32px;
  }
}
#home .completa .slider-section .slider.center .cl .content .texto h4 {
  color: #F5F5F5;
  font-family: "Revx Neue";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin: 20px 0;
}
@media (max-width: 1100px) {
  #home .completa .slider-section .slider.center .cl .content .texto h4 {
    font-size: 19.382px;
    font-style: normal;
    font-weight: 700;
    line-height: 32.303px;
    /* 166.667% */
    margin-bottom: 0;
    margin-top: 11px;
  }
}
#home .completa .slider-section .slider.center .cl .content .texto p {
  color: #F5F5F5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
}
@media (max-width: 1100px) {
  #home .completa .slider-section .slider.center .cl .content .texto p {
    font-size: 10.337px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.151px;
    /* 156.25% */
  }
}
#home .completa .slider-section .slider.center .cl .content .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--tbl-gradients-effects-gradient-linear, linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%));
  mix-blend-mode: multiply;
}
#home .completa .slider-section .slider.center .slick-active {
  padding: 30px 0;
}
#home .completa .slider-section .slider.center .slick-center {
  transform: scale(1.1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 99;
  position: relative;
  max-width: 540px;
}
#home .completa .slider-section .slider.center .slick-center::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #0084B5;
  bottom: 34px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
#home .completa .slider-section .slider.center .slick-slide:not(.slick-active) {
  margin: 30px 0;
}
#home .completa .slider-section .slider.center .child {
  width: 100%;
}
#home .completa .slider-section .slider.center .slide:not(.slick-active) {
  cursor: pointer;
}
#home .completa .slider-section .slider.center .layer-blue {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0) 100%);
}
#home .completa .slider-section .center-prev,
#home .completa .slider-section .center-next {
  position: absolute;
  top: calc(50% - 64px);
  cursor: pointer;
  z-index: 2;
}
@media (max-width: 1100px) {
  #home .completa .slider-section .center-prev,
#home .completa .slider-section .center-next {
    display: none !important;
  }
}
#home .completa .slider-section .center-prev {
  left: 88px;
}
#home .completa .slider-section .center-next {
  right: 88px;
}
#home .completa .slider-section .layer-blue {
  position: absolute;
  width: 476px;
  height: calc(100% - 64px);
  top: 30px;
  background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0) 100%);
  z-index: 1;
}
@media (max-width: 1100px) {
  #home .completa .slider-section .layer-blue {
    display: none;
  }
}
#home .completa .slider-section .layer-blue-second {
  position: absolute;
  width: 476px;
  height: calc(100% - 64px);
  top: 30px;
  right: 0;
  background: linear-gradient(90deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%);
  z-index: 1;
}
@media (max-width: 1100px) {
  #home .completa .slider-section .layer-blue-second {
    display: none;
  }
}
#home .caminho {
  position: relative;
  padding-bottom: 238px;
}
@media (max-width: 1100px) {
  #home .caminho {
    padding-bottom: 50px;
  }
}
#home .caminho .img-right {
  position: absolute;
  width: 972px;
  height: 749px;
  left: 0;
  top: 0;
}
@media (max-width: 1600px) {
  #home .caminho .img-right {
    left: -200px;
  }
}
@media (max-width: 1100px) {
  #home .caminho .img-right {
    position: relative;
    width: 100%;
    height: 350px;
    left: 0;
    top: 0;
  }
}
#home .caminho .img-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#home .caminho .lines {
  position: absolute;
  top: 101px;
  left: 0;
  transform: rotate(180deg);
}
@media (max-width: 1100px) {
  #home .caminho .lines {
    display: none;
  }
}
#home .caminho .texto-section {
  font-family: "Revx Neue";
  font-size: 13vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #60cbf2;
  text-stroke: 2px #60cbf2;
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
  right: 62px;
  text-align: end;
  top: -50px;
  z-index: 99;
}
@media (max-width: 1100px) {
  #home .caminho .texto-section {
    font-size: 90px;
    left: 0;
  }
}
#home .caminho .content {
  padding-top: 116px;
  padding-left: 193px;
}
@media (max-width: 1100px) {
  #home .caminho .content {
    padding-left: 0;
    padding-top: 86px;
  }
}
#home .caminho .title-section {
  max-width: 292px;
}
@media (max-width: 1100px) {
  #home .caminho .title-section {
    padding-inline: 17.5px;
  }
}
#home .caminho p {
  color: var(--tbl-default-black, #383838);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 327px;
  margin-bottom: 26px;
}
#home .caminho p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #home .caminho p {
    padding-inline: 17.5px;
  }
}
#home .caminho .btn-section {
  width: 236px;
  margin-top: 63px;
}
@media (max-width: 1100px) {
  #home .caminho .btn-section {
    width: calc(100% - 35px);
  }
}
#home .local {
  position: relative;
  overflow: hidden;
  padding-bottom: 240px;
}
@media (max-width: 1400px) {
  #home .local {
    padding-bottom: 100px;
  }
}
@media (max-width: 1100px) {
  #home .local {
    padding-bottom: 0;
  }
}
#home .local p {
  color: var(--tbl-default-black, #383838);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 406px;
  margin-bottom: 26px;
}
#home .local p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
#home .local .btn-section {
  margin-top: 89px;
}
@media (max-width: 1100px) {
  #home .local .btn-section {
    margin-bottom: 50px;
  }
}
#home .local .retangulo {
  position: absolute;
  bottom: -137px;
  right: 0;
  width: 896px;
  height: 656px;
  background: #F1F1F1;
}
@media (max-width: 1600px) {
  #home .local .retangulo {
    max-width: 50%;
  }
}
@media (max-width: 1399px) {
  #home .local .retangulo {
    bottom: 0;
  }
}
@media (max-width: 1100px) {
  #home .local .retangulo {
    display: none;
  }
}
#home .local .mapa {
  position: absolute;
  right: 0;
  top: -263px;
}
#home .local .mapa img {
  transform: translate(100px, 120px) scale(0.75);
}
@media (max-width: 1600px) {
  #home .local .mapa {
    top: -197px;
    width: 68%;
  }
}
@media (max-width: 1100px) {
  #home .local .mapa {
    position: relative;
    width: 100%;
    top: 0;
    right: 0;
    height: 400px;
  }
  #home .local .mapa img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: none;
  }
}
#home .esg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 10.938vw;
  padding-bottom: 8.438vw;
  position: relative;
}
@media (max-width: 1100px) {
  #home .esg {
    padding-top: 81px;
    padding-bottom: 44px;
  }
}
#home .esg .content {
  margin-left: 116px;
}
@media (max-width: 1100px) {
  #home .esg .content {
    margin-left: 0;
    padding-inline: 25px;
  }
}
#home .esg .title-section {
  color: #FFF;
  margin-bottom: 32px;
}
#home .esg .title-section strong {
  color: #FFF;
}
#home .esg p {
  color: var(--tbl-default-white, #FFF);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
#home .esg .btn-section {
  margin-top: 67px;
}
@media (max-width: 1100px) {
  #home .esg .btn-section {
    margin-left: 0;
    width: 150px;
    height: 46px;
  }
}
#home .esg .content {
  position: relative;
  z-index: 2;
}
#home .esg .layer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(260deg, #000 -13.46%, rgba(0, 0, 0, 0) 78.89%);
  z-index: 1;
}

.modal {
  z-index: 100000;
}
.modal-dialog {
  max-width: 600px;
}
.modal-header {
  padding: 20px;
  border: none !important;
  justify-content: end;
}
.modal-body {
  padding: 0 25px 0;
}
.modal-body h1 {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue", sans-serif;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}
@media (max-width: 1100px) {
  .modal-body h1 {
    font-size: 28px;
    line-height: 28px;
  }
}
.modal-body svg path {
  fill: var(--tbl-default-black, #383838);
}
.modal-body p {
  font-family: inherit;
  font-size: 18px;
  font-style: normal;
  line-height: 25px;
  font-weight: 400;
  margin-top: 32px;
}
@media (max-width: 1100px) {
  .modal-body p {
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
  }
}
.modal-body p strong {
  font-family: inherit;
  color: var(--tbl-primary-primary-500);
  font-weight: 700;
}
.modal-footer {
  text-align: center;
  display: flex;
  justify-content: center;
  border: none !important;
  padding: 0 25px 20px;
}
.modal .button {
  height: 50px;
  width: 190px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  text-decoration: none;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 1100px) {
  .modal .button {
    width: 100%;
  }
}
.modal .button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
.modal .button:hover::after {
  transform: scaleY(1);
}

#canal .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 43px;
  position: relative;
}
#canal .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
}
#canal .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
#canal .oportunidade {
  position: relative;
  padding-bottom: 130px;
  padding-top: 220px;
}
#canal .oportunidade .wrap-img {
  position: absolute;
  right: 0;
  bottom: 130px;
  aspect-ratio: 1.1315;
  max-width: 843px;
}
#canal .oportunidade .wrap-img img {
  width: min(843px, 47vw);
  height: 100%;
  object-fit: cover;
}
#canal .oportunidade .wrap-img .listras {
  position: absolute;
  width: 256px;
  height: 39px;
  bottom: -15px;
  right: 0;
  object-fit: contain;
  object-position: right;
}
#canal .oportunidade .text-absolute {
  top: 80px;
  right: 0;
  width: 100%;
  overflow: hidden;
  height: 250px;
  position: absolute;
}
#canal .oportunidade .text-absolute h3 {
  font-family: "Revx Neue", sans-serif;
  font-size: 250px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  /* 40% */
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* width and color of the stroke */
  text-stroke: 2px #00AEEF;
  /* Standard syntax, if supported */
  background-clip: text;
  position: relative;
  right: -41px;
  text-align: end;
  bottom: -80px;
  filter: blur(0);
}
#canal .oportunidade .content-text {
  padding-top: 240px;
}
#canal .oportunidade .content-text h2 {
  color: #383838;
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  margin-bottom: 26px;
  position: relative;
}
#canal .oportunidade .content-text h2::after {
  position: absolute;
  top: -35px;
  left: 0;
  width: 63px;
  height: 4px;
  background: #00AEEF;
  content: "";
}
#canal .oportunidade .content-text h2 span {
  color: #383838;
  /* H2 - Title bold */
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
#canal .oportunidade .content-text p + p {
  margin-top: 26px;
}
#canal .oportunidade .content-text p {
  color: var(--tbl-default-black, #383838);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 0;
}
#canal .oportunidade .content-text p span {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
#canal .oportunidade .content-text a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  margin-top: 50px;
}
#canal .oportunidade .content-text a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#canal .oportunidade .content-text a:hover::after {
  transform: scaleY(1);
}
@media (width < 991px) {
  #canal .oportunidade {
    padding-block: 33px 60px !important;
  }
  #canal .oportunidade .wrap-img {
    position: relative;
    padding-left: 25px;
    width: 100%;
    bottom: 0;
    display: block;
    margin-inline-start: auto;
  }
  #canal .oportunidade .wrap-img img {
    width: 100%;
  }
  #canal .oportunidade .wrap-img .listras {
    display: none;
  }
  #canal .oportunidade .text-absolute {
    position: relative;
    top: 0;
    height: 83px;
    max-width: 100%;
  }
  #canal .oportunidade .text-absolute h3 {
    font-size: 73px;
    line-height: 1;
    right: 2px;
    top: 14px;
    max-width: 100%;
  }
  #canal .oportunidade > .container {
    margin: 0;
    max-width: 100%;
    padding-inline: 25px;
  }
  #canal .oportunidade .content-text {
    padding-top: 79px;
  }
  #canal .oportunidade .content-text h2 {
    font-size: 28px;
    line-height: 1;
    margin-bottom: 32px;
  }
  #canal .oportunidade .content-text h2 span {
    font-size: 28px;
  }
  #canal .oportunidade .content-text p + p {
    margin-top: 20px;
  }
  #canal .oportunidade .content-text p {
    font-size: 14px;
    line-height: 18px;
  }
  #canal .oportunidade .content-text a {
    width: 100%;
    font-size: 14px;
    line-height: normal;
  }
}
#canal .esg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 210px;
  padding-bottom: 162px;
}
#canal .esg .content {
  margin-left: 116px;
}
#canal .esg a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#canal .esg a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#canal .esg a:hover::after {
  transform: scaleY(1);
}
#canal .esg .title-section {
  color: #FFF;
  margin-bottom: 32px;
}
#canal .esg .title-section strong {
  color: #FFF;
}
#canal .esg p {
  color: var(--tbl-default-white, #FFF);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
#canal .esg .btn-section {
  margin-top: 67px;
}

#cultura .banner-normal {
  min-height: 95dvh;
  position: relative;
  padding-top: 180px;
  padding-bottom: 200px;
}
@media (width < 991px) {
  #cultura .banner-normal {
    height: auto;
    min-height: 420px;
    overflow-x: hidden;
    margin-top: 83px;
    padding-top: 134px;
    padding-bottom: 0;
  }
}
#cultura .banner-normal::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 18.24%, rgba(0, 132, 181, 0) 81.3%);
  mix-blend-mode: multiply;
}
@media (width < 991px) {
  #cultura .banner-normal::after {
    background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 97.21%, rgba(0, 132, 181, 0) 51.34%);
    mix-blend-mode: multiply;
    width: 103%;
  }
}
#cultura .banner-normal::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #000 -19.62%, rgba(0, 0, 0, 0) 61.41%);
  z-index: 2;
}
@media (width < 991px) {
  #cultura .banner-normal::before {
    background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 7.21%, rgba(0, 132, 181, 0) 51.34%);
    mix-blend-mode: multiply;
  }
}
#cultura .banner-normal .banner-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
}
#cultura .banner-normal .content-text {
  position: relative;
  z-index: 9;
  display: flex;
  flex-direction: column;
  max-width: 583px;
}
#cultura .banner-normal .content-text h1 {
  color: #FFF;
  font-family: "Revx Neue", sans-serif;
  font-size: 3.646vw;
  font-style: normal;
  font-weight: 700;
  line-height: 3.385vw;
  /* 92.857% */
  margin-bottom: 33px;
}
@media (max-width: 1100px) {
  #cultura .banner-normal .content-text h1 {
    margin-bottom: 20px;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    /* 100% */
  }
}
#cultura .banner-normal .content-text p {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 44px;
  max-width: 408px;
}
@media (max-width: 1100px) {
  #cultura .banner-normal .content-text p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}
#cultura #cardProfessionals {
  max-width: min(1270px, 100%);
  position: relative;
  z-index: 3;
}
#cultura #cardProfessionals .card-professionals-container {
  gap: 0 8px;
  margin-block: -198px 157px;
  isolation: isolate;
}
@media (max-width: 1600px) {
  #cultura #cardProfessionals .card-professionals-container {
    margin-block: -102px 157px;
  }
}
@media (max-width: 1100px) {
  #cultura #cardProfessionals .card-professionals-container {
    overflow-y: scroll;
  }
}
#cultura #cardProfessionals .card-professionals-container .card-professional {
  padding: 34px 47px;
  color: #F5F5F5;
  height: 311px;
  mix-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
@media (max-width: 1100px) {
  #cultura #cardProfessionals .card-professionals-container .card-professional {
    aspect-ratio: 1.1;
  }
}
#cultura #cardProfessionals .card-professionals-container .card-professional .conteudo {
  position: unset;
  z-index: 2;
}
#cultura #cardProfessionals .card-professionals-container .card-professional::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%);
  mix-blend-mode: multiply;
  transition: all 0.3s ease-in;
}
#cultura #cardProfessionals .card-professionals-container .card-professional:hover {
  transition: all 0.3s ease-in;
  margin-top: -15px;
}
#cultura #cardProfessionals .card-professionals-container .card-professional:hover ::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%);
  transition: all 0.3s ease-in;
}
#cultura #cardProfessionals .card-professionals-container .card-professional-img {
  inset: 0;
  object-fit: cover;
  height: 100%;
  z-index: -2;
}
#cultura #cardProfessionals .card-professionals-container .card-professional-title {
  font-family: "Revx Neue", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 100% */
  margin-bottom: 11px;
}
#cultura #cardProfessionals .card-professionals-container .card-professional-text {
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#cultura #cardProfessionals .card-professionals-container .card-professional-title, #cultura #cardProfessionals .card-professionals-container .card-professional-text {
  z-index: 2;
}
#cultura .know-company {
  padding-top: 0px;
  padding-bottom: 10.417vw;
  position: relative;
}
@media (max-width: 1399px) {
  #cultura .know-company {
    padding-bottom: 5vw;
  }
}
@media (max-width: 1100px) {
  #cultura .know-company {
    padding-bottom: 80px;
  }
}
#cultura .know-company::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 45.573vw;
  height: 31.25vw;
  background: var(--tbl-secondary-secondary-500, #0084B5);
  z-index: -1;
}
@media (max-width: 1100px) {
  #cultura .know-company::after {
    height: 240px;
    width: 78.573vw;
  }
}
#cultura .know-company .wrapper-know {
  position: relative;
}
#cultura .know-company .wrapper-know .bg-destaque {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-97px, -20px);
}
@media (max-width: 1400px) {
  #cultura .know-company .wrapper-know .bg-destaque {
    width: 120%;
    transform: translate(-8%, -1%);
  }
}
@media (max-width: 1100px) {
  #cultura .know-company .wrapper-know .bg-destaque {
    width: 110%;
    transform: translate(-5%, 3%);
  }
}
#cultura .know-company .wrapper-know .bg-destaque img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#cultura .know-company .wrapper-know .know-img {
  border-radius: 10px;
  position: relative;
  z-index: 9;
  margin-top: 61px;
  max-height: 410px;
}
@media (max-width: 1400px) {
  #cultura .know-company .wrapper-know .know-img {
    width: 100%;
  }
}
@media (max-width: 1100px) {
  #cultura .know-company .wrapper-know .know-img {
    margin-top: 30px;
  }
}
@media (max-width: 1100px) {
  #cultura .know-company .content-text {
    padding-top: 40px;
  }
}
#cultura .know-company .content-text h1 {
  color: #383838;
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  position: relative;
  margin-bottom: 32px;
}
#cultura .know-company .content-text h1::after {
  content: "";
  position: absolute;
  top: -35px;
  left: 0;
  width: 63px;
  height: 4px;
  background: #00AEEF;
}
#cultura .know-company .content-text h1 span {
  color: #383838;
  /* H2 - Title bold */
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  #cultura .know-company .content-text h1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
    margin-bottom: 20px;
  }
  #cultura .know-company .content-text h1 span {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}
#cultura .know-company .content-text p {
  color: var(--tbl-default-black, #383838);
  /* H6 - Text Body */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 56px;
}
#cultura .know-company .content-text p span {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #cultura .know-company .content-text p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
  #cultura .know-company .content-text p span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
#cultura .know-company .content-text a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#cultura .know-company .content-text a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#cultura .know-company .content-text a:hover::after {
  transform: scaleY(1);
}
#cultura .oportunidade {
  position: relative;
  padding-bottom: 6.771vw;
  padding-top: 11.458vw;
}
@media (max-width: 1100px) {
  #cultura .oportunidade {
    padding-top: 45px;
  }
}
#cultura .oportunidade .wrap-img {
  position: absolute;
  right: 0;
  aspect-ratio: 1.1315;
  max-width: 43.906vw;
}
@media (max-width: 1399px) {
  #cultura .oportunidade .wrap-img {
    top: 17vw;
  }
}
@media (max-width: 1100px) {
  #cultura .oportunidade .wrap-img {
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: inherit;
  }
}
#cultura .oportunidade .wrap-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1100px) {
  #cultura .oportunidade .wrap-img img {
    width: 94%;
  }
}
#cultura .oportunidade .wrap-img .listras {
  position: absolute;
  width: 256px;
  height: 39px;
  bottom: -15px;
  right: 0;
  object-fit: contain;
  object-position: right;
}
#cultura .oportunidade .text-absolute {
  top: 80px;
  right: 0;
  width: 100%;
  overflow: hidden;
  height: 250px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1100px) {
  #cultura .oportunidade .text-absolute {
    height: auto;
  }
}
#cultura .oportunidade .text-absolute h3 {
  font-family: "Revx Neue";
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  /* 40% */
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* width and color of the stroke */
  text-stroke: 2px #00AEEF;
  /* Standard syntax, if supported */
  background-clip: text;
  position: relative;
  right: 0;
  text-align: end;
  bottom: -80px;
  filter: blur(0);
}
@media (max-width: 1100px) {
  #cultura .oportunidade .text-absolute h3 {
    font-size: 80px;
    line-height: 80px;
  }
}
#cultura .oportunidade .content-text {
  padding-top: 240px;
  margin-bottom: 80px;
}
@media (max-width: 1399px) {
  #cultura .oportunidade .content-text {
    min-height: 550px;
  }
}
#cultura .oportunidade .content-text h2 {
  color: #383838;
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  margin-bottom: 26px;
  position: relative;
}
#cultura .oportunidade .content-text h2::after {
  position: absolute;
  top: -35px;
  left: 0;
  width: 63px;
  height: 4px;
  background: #00AEEF;
  content: "";
}
#cultura .oportunidade .content-text h2 span {
  color: #383838;
  /* H2 - Title bold */
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
#cultura .oportunidade .content-text p {
  color: var(--tbl-default-black, #383838);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 0;
}
#cultura .oportunidade .content-text p span {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
#cultura .oportunidade .content-text a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#cultura .oportunidade .content-text a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#cultura .oportunidade .content-text a:hover::after {
  transform: scaleY(1);
}
@media (max-width: 1100px) {
  #cultura .treinamento {
    padding-bottom: 0;
  }
  #cultura .treinamento .content-text {
    margin-bottom: 0;
  }
}
@media (max-width: 1100px) {
  #cultura .treinamento .text-absolute {
    top: 37%;
    display: flex;
    justify-content: flex-end;
  }
}
#cultura .treinamento .text-absolute h3 {
  right: 0;
  text-align: start;
  width: fit-content;
  left: inherit;
}
@media (max-width: 1600px) {
  #cultura .treinamento .text-absolute h3 {
    right: 0;
    text-align: end;
    font-size: 14vw;
  }
}
@media (max-width: 1100px) {
  #cultura .treinamento .text-absolute h3 {
    text-align: end;
    width: 70%;
    right: 15px;
    font-size: 70px;
    line-height: 66px;
  }
}
@media (max-width: 1399px) {
  #cultura .campanhas {
    padding-top: 0;
  }
}
@media (max-width: 1100px) {
  #cultura .campanhas {
    padding-bottom: 0;
  }
}
@media (max-width: 1399px) {
  #cultura .campanhas .content-text {
    padding-top: 220px;
  }
}
@media (max-width: 1100px) {
  #cultura .campanhas .content-text {
    padding-top: 230px;
    margin-bottom: 0;
  }
}
@media (max-width: 1399px) {
  #cultura .campanhas .text-absolute {
    top: -79px;
  }
}
@media (max-width: 1100px) {
  #cultura .campanhas .text-absolute {
    top: 37%;
    display: flex;
    justify-content: flex-start;
  }
  #cultura .campanhas .text-absolute h3 {
    width: 70%;
    text-align: start;
    right: -15px;
  }
}
#cultura .campanhas .wrap-img {
  left: 0;
}
@media (max-width: 1399px) {
  #cultura .campanhas .wrap-img {
    top: 7vw;
  }
}
@media (max-width: 1100px) {
  #cultura .campanhas .wrap-img {
    justify-content: flex-start;
    max-width: inherit;
  }
}
#cultura .campanhas .wrap-img .listras {
  transform: rotate(180deg);
  bottom: 96px;
  right: inherit;
  left: 0;
}
@media (max-width: 1399px) {
  #cultura .liderança {
    padding-top: 100px;
    padding-bottom: 0;
  }
  #cultura .liderança .wrap-img {
    top: 4vw;
  }
  #cultura .liderança .text-absolute {
    top: -140px;
  }
  #cultura .liderança .content-text {
    padding-top: 80px;
  }
}
@media (max-width: 1100px) {
  #cultura .liderança {
    padding-bottom: 60px;
  }
  #cultura .liderança .text-absolute {
    top: 37%;
    display: flex;
    justify-content: flex-end;
  }
  #cultura .liderança .text-absolute h3 {
    right: 15px;
  }
  #cultura .liderança .content-text {
    margin-bottom: 0;
    padding-top: 164px;
  }
}
#cultura .liderança .text-absolute h3 {
  right: 31px;
  text-align: center;
}

#agregado .oportunidade {
  position: relative;
  padding-bottom: 130px;
  padding-top: 220px;
}
@media (max-width: 1100px) {
  #agregado .oportunidade {
    padding-bottom: 60px;
    padding-top: 25px;
  }
}
#agregado .oportunidade .btn-cadastro {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
}
#agregado .oportunidade .btn-cadastro::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#agregado .oportunidade .btn-cadastro:hover::after {
  transform: scaleY(1);
}
@media (max-width: 1100px) {
  #agregado .oportunidade .btn-cadastro {
    width: calc(100% - 35px);
    margin: 0 auto;
    margin-top: 20px;
  }
}
#agregado .oportunidade .wrap-img {
  position: absolute;
  right: 0;
  aspect-ratio: 1.1315;
  max-width: 43.906vw;
}
@media (max-width: 1100px) {
  #agregado .oportunidade .wrap-img {
    position: relative;
    display: flex;
    justify-content: flex-start;
    max-width: inherit;
    max-width: inherit;
  }
}
#agregado .oportunidade .wrap-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1100px) {
  #agregado .oportunidade .wrap-img img {
    width: 93%;
  }
}
#agregado .oportunidade .wrap-img .listras {
  position: absolute;
  width: 256px;
  height: 39px;
  bottom: -15px;
  right: 0;
  object-fit: contain;
  object-position: right;
}
@media (max-width: 1100px) {
  #agregado .oportunidade .wrap-img .listras {
    display: none;
  }
}
#agregado .oportunidade .text-absolute {
  top: 80px;
  right: 0;
  width: 100%;
  overflow: visible;
  height: 250px;
  position: absolute;
}
#agregado .oportunidade .text-absolute h3 {
  font-family: "Revx Neue";
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  /* 40% */
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* width and color of the stroke */
  text-stroke: 2px #00AEEF;
  /* Standard syntax, if supported */
  background-clip: text;
  position: relative;
  right: 0px;
  text-align: end;
  bottom: -80px;
  filter: blur(0);
}
@media (max-width: 1100px) {
  #agregado .oportunidade .text-absolute {
    height: auto;
    width: 100%;
    top: 95%;
    z-index: 9;
  }
  #agregado .oportunidade .text-absolute h3 {
    font-size: 14vw;
    line-height: 90px;
    top: 0;
    right: 15px;
    word-break: break-word;
  }
}
#agregado .oportunidade .content-text {
  padding-top: 240px;
}
@media (max-width: 1100px) {
  #agregado .oportunidade .content-text {
    padding-inline: 17.5px;
    padding-top: 78px;
  }
}
#agregado .oportunidade .content-text h2 {
  color: #383838;
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  margin-bottom: 26px;
  position: relative;
}
#agregado .oportunidade .content-text h2::after {
  position: absolute;
  top: -35px;
  left: 0;
  width: 63px;
  height: 4px;
  background: #00AEEF;
  content: "";
}
#agregado .oportunidade .content-text h2 span {
  color: #383838;
  /* H2 - Title bold */
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  #agregado .oportunidade .content-text h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
  }
  #agregado .oportunidade .content-text h2 span {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}
#agregado .oportunidade .content-text p {
  color: var(--tbl-default-black, #383838);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 80px;
}
#agregado .oportunidade .content-text p span {
  color: var(--TBL-Primary-primary-500, #00AEEF);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #agregado .oportunidade .content-text p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    margin-bottom: 20px;
  }
  #agregado .oportunidade .content-text p span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
#agregado .oportunidade .content-text a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#agregado .oportunidade .content-text a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#agregado .oportunidade .content-text a:hover::after {
  transform: scaleY(1);
}
@media (max-width: 1100px) {
  #agregado .oportunidade .content-text a {
    width: 100%;
  }
}
#agregado .form-agregado {
  padding-top: 275px;
  background: #f5f5f5;
  padding-bottom: 150px;
}
@media (max-width: 1100px) {
  #agregado .form-agregado {
    padding-top: 90px;
    padding-inline: 17.5px;
    padding-bottom: 75px;
  }
}
#agregado .form-agregado .destaque-title {
  color: var(--tbl-default-black, #383838);
  text-align: center;
  /* H2 - Title Regular */
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  position: relative;
}
#agregado .form-agregado .destaque-title span {
  color: var(--tbl-default-black, #383838);
  /* H2 - Title bold */
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
#agregado .form-agregado .destaque-title::after {
  position: absolute;
  top: -35px;
  width: 63px;
  height: 4px;
  background: #00AEEF;
  content: "";
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 1100px) {
  #agregado .form-agregado .destaque-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
  }
  #agregado .form-agregado .destaque-title span {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}
#agregado .form-agregado .destaque-text {
  margin-top: 30px;
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
@media (max-width: 1100px) {
  #agregado .form-agregado .destaque-text {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}
#agregado .form-agregado .input-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
#agregado .form-agregado .input-box textarea {
  height: 200px;
  background: var(--tbl-default-white, #FFF);
  border: none;
  padding-inline: 32px;
  padding-top: 32px;
  color: var(--tbl-neutral-neutral-300, #898989);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 16px */
  margin-bottom: 24px;
}
#agregado .form-agregado .input-box textarea::placeholder {
  color: var(--tbl-neutral-neutral-300, #898989);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 16px */
}
#agregado .form-agregado .input-box p {
  color: var(--tbl-neutral-neutral-300, #898989);
  font-family: "Revx Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
  margin-top: 12px;
}
#agregado .form-agregado .input-box label {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
  margin-bottom: 12px;
}
#agregado .form-agregado .input-box select,
#agregado .form-agregado .input-box input {
  background: var(--tbl-default-white, #FFF);
  border: none;
  height: 72px;
  padding-inline: 32px;
  color: var(--tbl-neutral-neutral-300, #898989);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 16px */
}
#agregado .form-agregado .input-box select::placeholder,
#agregado .form-agregado .input-box input::placeholder {
  color: var(--tbl-neutral-neutral-300, #898989);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 16px */
}
#agregado .form-agregado .form-check {
  margin-top: 20px;
}
#agregado .form-agregado .form-check-input {
  --blue-primary: #00AEEF;
  border: 1px solid var(--blue-primary);
  border-radius: 1px !important;
  background-color: var(--blue-50, #F1FAFE);
  box-shadow: 0px 20px 44px 0px rgba(0, 0, 0, 0.2);
  width: 24px;
  height: 24px;
}
#agregado .form-agregado .form-check-input:checked {
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
}
#agregado .form-agregado .form-check-input:focus {
  border-color: var(--blue-primary);
  outline: 0;
  box-shadow: 0 0 0 1px var(--blue-primary);
}
#agregado .form-agregado .form-check-label {
  color: var(--tbl-neutral-neutral-300, #898989);
  /* Desktop/btn */
  font-family: "Revx Neue";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 100% */
  margin-top: 10px;
  margin-left: 20px;
}
@media (max-width: 1100px) {
  #agregado .form-agregado .form-check-label {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
  }
}
#agregado .form-agregado .form-check-label a, #agregado .form-agregado .form-check-label a:hover {
  color: inherit;
  font-size: inherit;
  text-decoration: underline;
}
#agregado .form-agregado button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#agregado .form-agregado button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#agregado .form-agregado button:hover::after {
  transform: scaleY(1);
}
@media (max-width: 1100px) {
  #agregado .form-agregado button {
    width: 100%;
    margin-top: 40px;
  }
}

#esg_complice {
  overflow: hidden;
}
#esg_complice .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 34px;
  position: relative;
}
#esg_complice .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
}
#esg_complice .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (width < 991px) {
  #esg_complice .title-section, #esg_complice .title-section strong {
    font-size: 28px;
    line-height: 1;
  }
}
#esg_complice .section1 {
  position: relative;
  padding-top: 338px;
  padding-bottom: 164px;
}
#esg_complice .section1 a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  display: flex;
  padding: 15px 50px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: fit-content;
}
#esg_complice .section1 a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#esg_complice .section1 a:hover::after {
  transform: scaleY(1);
}
@media (max-width: 1100px) {
  #esg_complice .section1 a {
    width: 100%;
  }
}
#esg_complice .section1 .title-section {
  margin-bottom: 23px;
}
#esg_complice .section1 p {
  color: #383838;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 621px;
  margin-bottom: 26px;
}
#esg_complice .section1 p strong {
  color: #00AEEF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
#esg_complice .section1 .video-section {
  position: absolute;
  width: min(843px, 47vw);
  height: 745px;
  top: 59px;
  right: 0;
  padding: 0;
}
@media (max-width: 1401px) {
  #esg_complice .section1 .video-section {
    width: min(843px, 44vw);
  }
}
#esg_complice .section1 .video-section .video {
  position: relative;
}
#esg_complice .section1 .video-section .video .img-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#esg_complice .section1 .video-section .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#esg_complice .section1 .video-section .video .linhas {
  position: absolute;
  right: 0;
  bottom: -12px;
}
#esg_complice .section1 .texto-section {
  font-family: "Revx Neue";
  font-size: 250px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* Para navegadores WebKit (Safari, Chrome antigo) */
  text-stroke: 2px #00AEEF;
  /* Padrão */
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: 0;
  left: -351px;
  right: 0;
  margin: 0 auto;
  text-align: center;
  filter: blur(0px);
}
@media (width < 991px) {
  #esg_complice .section1 {
    padding-top: 40px;
    padding-bottom: 44px;
  }
  #esg_complice .section1 > .container {
    max-width: 100%;
    padding-inline-start: 25px;
    margin-inline: 0;
  }
  #esg_complice .section1 > .container .row {
    display: flex;
    flex-direction: column-reverse;
  }
  #esg_complice .section1 .col-lg-7 {
    padding-inline-end: 25px;
  }
  #esg_complice .section1 p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  #esg_complice .section1 .texto-section {
    font-size: 73px;
    font-style: normal;
    font-weight: 700;
    line-height: 73px;
    position: static;
    margin-block: 8px 79px;
    text-align: end;
  }
  #esg_complice .section1 .video-section {
    width: 100% !important;
    height: fit-content;
    position: static;
  }
  #esg_complice .section1 .video-section .linhas {
    display: none;
  }
}
#esg_complice .section2 {
  position: relative;
}
#esg_complice .section2 .video {
  padding: 84px;
  position: relative;
  border-radius: 22px;
  background: #F5F5F5;
  z-index: 2;
}
#esg_complice .section2 .video .img-video {
  width: 100%;
  height: 456px;
  border-radius: 10px;
  object-fit: cover;
}
#esg_complice .section2 .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#esg_complice .section2 .quadrado {
  width: 948px;
  max-width: 100%;
  height: 546px;
  background: #0084B5;
  position: absolute;
  top: 186px;
  left: 0;
  z-index: 1;
}
@media (width < 991px) {
  #esg_complice .section2 > .container {
    max-width: 100%;
    padding-inline: 25px;
    margin-inline: 0;
  }
  #esg_complice .section2 .video {
    padding: 20px;
    border-radius: 8px;
  }
  #esg_complice .section2 .video .img-video {
    height: 213px;
  }
  #esg_complice .section2 .video .player img {
    max-width: 40px;
    aspect-ratio: 1;
  }
  #esg_complice .section2 .quadrado {
    max-width: min(306px, 80vw);
    height: 240px;
    top: 85px;
  }
}
#esg_complice .section3 {
  position: relative;
  padding-top: 461px;
  padding-bottom: 253px;
}
#esg_complice .section3 a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  display: flex;
  padding: 15px 50px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: fit-content;
}
#esg_complice .section3 a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#esg_complice .section3 a:hover::after {
  transform: scaleY(1);
}
@media (max-width: 1100px) {
  #esg_complice .section3 a {
    width: 100%;
  }
}
#esg_complice .section3 .title-section {
  margin-bottom: 23px;
  margin-top: 79px;
}
#esg_complice .section3 p {
  color: #383838;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 621px;
  margin-bottom: 26px;
}
#esg_complice .section3 p strong {
  color: #00AEEF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
#esg_complice .section3 .video-section {
  position: absolute;
  width: 843px;
  height: 745px;
  top: 274px;
  left: 0;
  padding: 0;
}
@media (max-width: 1710px) {
  #esg_complice .section3 .video-section {
    max-width: 48%;
  }
}
#esg_complice .section3 .video-section .video {
  position: relative;
}
#esg_complice .section3 .video-section .video .img-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#esg_complice .section3 .video-section .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#esg_complice .section3 .video-section .video .linhas {
  position: absolute;
  left: 0;
  bottom: -33px;
}
#esg_complice .section3 .video-section .video .linhas img {
  transform: rotate(180deg);
}
#esg_complice .section3 .texto-section {
  font-family: "Revx Neue";
  font-size: 250px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  text-stroke: 2px #00AEEF;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: 204px;
  left: 0;
  right: 0;
  text-align: end;
  filter: blur(0);
}
@media (width < 991px) {
  #esg_complice .section3 {
    padding-top: 132px;
    padding-bottom: 79px;
  }
  #esg_complice .section3 > .container {
    max-width: 100%;
    padding-inline-start: 25px;
    margin-inline: 0;
  }
  #esg_complice .section3 > .container .row {
    display: flex;
    flex-direction: column-reverse;
  }
  #esg_complice .section3 .col-lg-6 {
    padding-inline-end: 25px;
  }
  #esg_complice .section3 p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  #esg_complice .section3 .texto-section {
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 73px;
    position: static;
    margin-block: 8px 79px;
    text-align: end;
  }
  #esg_complice .section3 .title-section {
    margin-top: 0;
    margin-bottom: 32px;
  }
  #esg_complice .section3 .video-section {
    max-width: 100% !important;
    height: fit-content;
    position: static;
  }
  #esg_complice .section3 .video-section .linhas {
    display: none;
  }
}
#esg_complice .section4 {
  position: relative;
  padding-top: 156px;
  padding-bottom: 111px;
  background-color: #F5F5F5;
}
#esg_complice .section4 .title-section {
  text-align: center;
}
#esg_complice .section4 .title-section::after {
  right: 0;
  left: 0;
  margin: 0 auto;
}
#esg_complice .section4 p {
  color: #4F4F4F;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 627px;
  margin: 0 auto;
}
#esg_complice .section4 .blocos {
  margin-top: 83px;
}
#esg_complice .section4 .blocos .bloco {
  max-width: 406px;
  height: 326px;
  border-radius: 20px 0px;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 40px 20px;
  margin-bottom: 30px;
}
#esg_complice .section4 .blocos .bloco h3 {
  color: #0084B5;
  font-family: "Revx Neue";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-top: 12px;
  margin-bottom: 20px;
}
#esg_complice .section4 .blocos .bloco p {
  color: #383838;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 20px;
}
#esg_complice .section4 .blocos .bloco a {
  display: inline-block;
  text-decoration: none;
  color: #00AEEF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}
#esg_complice .section4 .blocos .bloco:hover {
  background-color: #0084B5;
  transition: 0.3s ease;
}
#esg_complice .section4 .blocos .bloco:hover img {
  filter: grayscale(100%) brightness(2);
  transition: 0.3s ease;
}
#esg_complice .section4 .blocos .bloco:hover h3,
#esg_complice .section4 .blocos .bloco:hover p,
#esg_complice .section4 .blocos .bloco:hover a {
  color: #fff;
  transition: 0.3s ease;
}
#esg_complice .section4 .texto-section {
  font-family: "Revx Neue";
  font-size: 250px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  text-stroke: 2px #00AEEF;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: -60px;
  left: -30px;
  right: 0;
  margin: 0 auto;
  text-align: start;
  filter: blur(0);
}
@media (width < 991px) {
  #esg_complice .section4 {
    padding-top: 0;
    padding-bottom: 60px;
  }
  #esg_complice .section4 > .container {
    max-width: 100%;
    padding-inline: 25px;
    margin-inline: 0;
  }
  #esg_complice .section4 .texto-section {
    font-size: 73px;
    font-style: normal;
    font-weight: 700;
    line-height: 73px;
    position: static;
    margin-block: 0 27px;
    transform: translateY(-35px) !important;
    padding-left: 15px;
    text-align: start;
  }
  #esg_complice .section4 .title-section {
    margin-bottom: 20px;
  }
  #esg_complice .section4 p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }
  #esg_complice .section4 .blocos {
    margin-top: 59px;
  }
  #esg_complice .section4 .blocos .bloco {
    height: 257px;
    margin-bottom: 20px;
  }
  #esg_complice .section4 .blocos .bloco img {
    max-width: 28px;
    aspect-ratio: 1;
  }
  #esg_complice .section4 .blocos .bloco h3 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 37px;
  }
  #esg_complice .section4 .blocos .bloco p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
  }
}

#workWithUs .oportunidade {
  position: relative;
  padding-bottom: 130px;
  padding-top: 220px;
}
@media (max-width: 1100px) {
  #workWithUs .oportunidade {
    padding-bottom: 60px;
    padding-top: 25px;
  }
}
#workWithUs .oportunidade .wrap-img {
  position: absolute;
  right: 0;
  aspect-ratio: 1.1315;
  max-width: 43.906vw;
}
@media (max-width: 1100px) {
  #workWithUs .oportunidade .wrap-img {
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: inherit;
  }
}
#workWithUs .oportunidade .wrap-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1100px) {
  #workWithUs .oportunidade .wrap-img img {
    width: 90%;
  }
}
#workWithUs .oportunidade .wrap-img .listras {
  position: absolute;
  width: 256px;
  height: 39px;
  bottom: -15px;
  right: 0;
  object-fit: contain;
  object-position: right;
}
@media (max-width: 1100px) {
  #workWithUs .oportunidade .wrap-img .listras {
    display: none;
  }
}
#workWithUs .oportunidade .text-absolute {
  top: 80px;
  right: 0;
  width: 100%;
  overflow: hidden;
  height: 250px;
  position: absolute;
}
#workWithUs .oportunidade .text-absolute h3 {
  font-family: "Revx Neue";
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  /* 40% */
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* width and color of the stroke */
  text-stroke: 2px #00AEEF;
  /* Standard syntax, if supported */
  background-clip: text;
  position: relative;
  right: 0px;
  text-align: end;
  bottom: -80px;
  filter: blur(0);
}
@media (max-width: 1400px) {
  #workWithUs .oportunidade .text-absolute h3 {
    font-size: 14vw;
  }
}
@media (max-width: 1100px) {
  #workWithUs .oportunidade .text-absolute {
    height: auto;
    width: 90%;
    top: 35%;
    right: 15px;
  }
  #workWithUs .oportunidade .text-absolute h3 {
    font-size: 70px;
    line-height: 90px;
    top: 0;
    right: 0;
    word-break: break-word;
  }
}
#workWithUs .oportunidade .content-text {
  padding-top: 240px;
}
@media (max-width: 1399px) {
  #workWithUs .oportunidade .content-text {
    padding-top: 100px;
  }
}
@media (max-width: 1100px) {
  #workWithUs .oportunidade .content-text {
    padding-inline: 17.5px;
  }
}
#workWithUs .oportunidade .content-text h2 {
  color: #383838;
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  margin-bottom: 26px;
  position: relative;
}
#workWithUs .oportunidade .content-text h2::after {
  position: absolute;
  top: -35px;
  left: 0;
  width: 63px;
  height: 4px;
  background: #00AEEF;
  content: "";
}
#workWithUs .oportunidade .content-text h2 span {
  color: #383838;
  /* H2 - Title bold */
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  #workWithUs .oportunidade .content-text h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
  }
  #workWithUs .oportunidade .content-text h2 span {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}
#workWithUs .oportunidade .content-text p {
  color: var(--tbl-default-black, #383838);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#workWithUs .oportunidade .content-text p span {
  color: var(--TBL-Primary-primary-500, #00AEEF);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #workWithUs .oportunidade .content-text p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    margin-bottom: 20px;
  }
  #workWithUs .oportunidade .content-text p span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
#workWithUs .oportunidade .content-text a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  margin-top: 80px;
}
#workWithUs .oportunidade .content-text a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#workWithUs .oportunidade .content-text a:hover::after {
  transform: scaleY(1);
}
@media (max-width: 1100px) {
  #workWithUs .oportunidade .content-text a {
    width: 100%;
  }
}
#workWithUs .vagas-destaque {
  padding-top: 190px;
  padding-bottom: 14.844vw;
  background: var(--tbl-default-white-off, #F5F5F5);
}
@media (max-width: 1100px) {
  #workWithUs .vagas-destaque {
    padding-top: 100px;
    padding-inline: 17.5px;
    padding-bottom: 60px;
  }
}
#workWithUs .vagas-destaque .destaque-title {
  color: var(--tbl-default-black, #383838);
  text-align: center;
  /* H2 - Title Regular */
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  margin-bottom: 32px;
  position: relative;
}
#workWithUs .vagas-destaque .destaque-title span {
  color: var(--tbl-default-black, #383838);
  /* H2 - Title bold */
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
#workWithUs .vagas-destaque .destaque-title::after {
  position: absolute;
  top: -35px;
  width: 63px;
  height: 4px;
  background: #00AEEF;
  content: "";
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 1100px) {
  #workWithUs .vagas-destaque .destaque-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
  }
  #workWithUs .vagas-destaque .destaque-title span {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}
#workWithUs .vagas-destaque .destaque-text {
  color: var(--TBL-Neutral-neutral-500, #4F4F4F);
  text-align: center;
  /* H6 - TextBody */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 128.571% */
  margin-bottom: 50px;
}
#workWithUs .vagas-destaque .card-destaque {
  display: flex;
  padding: 40px 32px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  background: #fff;
  border-radius: 20px 0px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}
@media (max-width: 1100px) {
  #workWithUs .vagas-destaque .card-destaque {
    margin-bottom: 20px;
  }
}
#workWithUs .vagas-destaque .card-destaque .title {
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  /* H5 - Description */
  font-family: "Revx Neue";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 100% */
  margin-bottom: 20px;
}
#workWithUs .vagas-destaque .card-destaque p {
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  text-align: center;
  /* H6 - Text Body */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 40px;
}
@media (max-width: 1100px) {
  #workWithUs .vagas-destaque .card-destaque p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}
#workWithUs .vagas-destaque .card-destaque a {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  /* 156.25% */
  text-decoration: none;
}
#workWithUs .vagas-destaque .card-destaque a:hover {
  text-decoration: underline;
}
@media (max-width: 1100px) {
  #workWithUs .text-vagas {
    padding-inline: 17.5px;
  }
}
#workWithUs .text-vagas h1 {
  color: #383838;
  /* H2 - Title Regular */
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  margin-bottom: 26px;
  position: relative;
}
#workWithUs .text-vagas h1::after {
  position: absolute;
  top: -35px;
  left: 0;
  width: 63px;
  height: 4px;
  background: #00AEEF;
  content: "";
}
#workWithUs .text-vagas h1 span {
  color: #383838;
  /* H2 - Title bold */
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  #workWithUs .text-vagas h1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
  }
  #workWithUs .text-vagas h1 span {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}
#workWithUs .text-vagas p {
  color: var(--tbl-default-black, #383838);
  /* H6 - Text Body */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 62px;
}
@media (max-width: 1100px) {
  #workWithUs .text-vagas p {
    margin-bottom: 32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}
#workWithUs .nossas-vagas {
  position: relative;
  padding-top: 190px;
  padding-bottom: 237px;
  /* Filter Button */
  /* Filter Panel - initially hidden */
}
@media (max-width: 1100px) {
  #workWithUs .nossas-vagas {
    padding-bottom: 75px;
  }
}
#workWithUs .nossas-vagas .btn-load-more {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: calc(100% - 35px);
  margin: 0 auto;
  margin-top: 40px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#workWithUs .nossas-vagas .btn-load-more::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#workWithUs .nossas-vagas .btn-load-more:hover::after {
  transform: scaleY(1);
}
@media (max-width: 1100px) {
  #workWithUs .nossas-vagas .btn-load-more {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
#workWithUs .nossas-vagas .accordions-box .accordion-button {
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  /* H5 - Description */
  font-family: "Revx Neue";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 100% */
  padding-top: 32px;
  padding-bottom: 32px;
  width: 100%;
}
#workWithUs .nossas-vagas .accordions-box .accordion-button::after {
  background-size: contain;
  filter: invert(55%) sepia(71%) saturate(3790%) hue-rotate(164deg) brightness(101%) contrast(101%);
  margin-left: 32px;
  width: 32px;
  height: 20px;
}
#workWithUs .nossas-vagas .accordions-box .accordion-button:focus {
  box-shadow: none;
}
@media (max-width: 1100px) {
  #workWithUs .nossas-vagas .accordions-box .accordion-button {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 100% */
    padding-bottom: 20px;
    padding-top: 20px;
  }
}
#workWithUs .nossas-vagas .accordions-box .accordion-button:not(.collapsed) {
  background: #fff;
  box-shadow: none;
  color: #00AEEF;
}
#workWithUs .nossas-vagas .accordions-box .text-accordion {
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  /* H6 - Text Body */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
@media (max-width: 1100px) {
  #workWithUs .nossas-vagas .accordions-box .text-accordion {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}
#workWithUs .nossas-vagas .accordions-box .accordion-body {
  display: flex;
  gap: 32px;
  flex-direction: column;
  padding-bottom: 32px;
}
@media (max-width: 1100px) {
  #workWithUs .nossas-vagas .accordions-box .accordion-body {
    gap: 20px;
  }
}
#workWithUs .nossas-vagas .accordions-box .detail-accordion-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media (max-width: 1100px) {
  #workWithUs .nossas-vagas .accordions-box .detail-accordion-box {
    align-items: flex-start;
    flex-direction: column;
    gap: 32px;
  }
}
#workWithUs .nossas-vagas .accordions-box .detail-accordion-box a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#workWithUs .nossas-vagas .accordions-box .detail-accordion-box a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#workWithUs .nossas-vagas .accordions-box .detail-accordion-box a:hover::after {
  transform: scaleY(1);
}
@media (max-width: 1100px) {
  #workWithUs .nossas-vagas .accordions-box .detail-accordion-box a {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
#workWithUs .nossas-vagas .accordions-box .details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
#workWithUs .nossas-vagas .accordions-box .details p {
  color: var(--bertolini-support-support-400, #758187);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}
#workWithUs .nossas-vagas .accordions-box .details p span {
  color: var(--bertolini-support-support-400, #758187);
  /* Desktop/H6 - Text Body */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
@media (max-width: 1100px) {
  #workWithUs .nossas-vagas .accordions-box .details p {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 128.571% */
  }
  #workWithUs .nossas-vagas .accordions-box .details p span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
#workWithUs .nossas-vagas .text-absolute {
  top: -130px;
  right: 0;
  width: 100%;
  overflow: hidden;
  height: 250px;
  position: absolute;
}
#workWithUs .nossas-vagas .text-absolute h3 {
  font-family: "Revx Neue";
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  /* 40% */
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* width and color of the stroke */
  text-stroke: 2px #00AEEF;
  /* Standard syntax, if supported */
  background-clip: text;
  position: relative;
  right: 0px;
  text-align: end;
  bottom: -80px;
  filter: blur(0);
}
@media (max-width: 1400px) {
  #workWithUs .nossas-vagas .text-absolute h3 {
    font-size: 14vw;
  }
}
@media (max-width: 1100px) {
  #workWithUs .nossas-vagas .text-absolute {
    height: auto;
    width: 80%;
    top: -40px;
    right: 15px;
  }
  #workWithUs .nossas-vagas .text-absolute h3 {
    font-size: 85px;
    line-height: 85px;
    left: 0;
    right: 0;
    word-break: break-all;
    top: 0;
  }
}
#workWithUs .nossas-vagas .box-filter {
  display: flex;
  justify-content: space-between;
  height: 60px;
}
#workWithUs .nossas-vagas .box-filter .input-group button {
  position: absolute;
  right: 32px;
  transform: translate(0, -50%);
  top: 50%;
}
#workWithUs .nossas-vagas .box-filter .input-group button span {
  display: none;
  color: var(--bertolini-white, #FFF);
  /* Desktop/Labels */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}
#workWithUs .nossas-vagas .box-filter .input-filter {
  width: 100%;
  height: 100%;
  padding-left: 32px;
  border: none;
  background: var(--tbl-primary-primary-500, #00AEEF);
  color: #FFF;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 17px */
  transition: all 0.3s ease-in-out;
}
#workWithUs .nossas-vagas .box-filter .input-filter::placeholder {
  color: #FFF;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 17px */
}
#workWithUs .nossas-vagas .box-filter .input-filter:focus-visible {
  background: var(--tbl-primary-primary-700, #007CAA);
}
#workWithUs .nossas-vagas .filter-btn {
  background: var(--tbl-neutral-neutral-200, #AEAEAE);
  color: #fff;
  border: 2px solid #A9A9A9;
  /* Border color */
  cursor: pointer;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 17px */
  letter-spacing: 1.7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 80px;
  padding-inline: 18px;
}
@media (max-width: 1100px) {
  #workWithUs .nossas-vagas .filter-btn {
    padding-inline: 25px;
  }
}
#workWithUs .nossas-vagas .filter-icon {
  margin-left: 5px;
}
#workWithUs .nossas-vagas .filter-panel {
  display: none;
  /* Hidden by default */
  width: 100%;
  border: 1px solid #E0E0E0;
  background-color: #F5F5F5;
  position: relative;
  z-index: 9;
}
#workWithUs .nossas-vagas .filter-header {
  background-color: #fff;
  /* Dark gray background */
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 17px */
  letter-spacing: 1.7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  position: relative;
  padding-inline: 20px;
}
#workWithUs .nossas-vagas .filter-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  width: 83%;
  height: 1px;
  background: #AEAEAE;
}
#workWithUs .nossas-vagas .filter-header span {
  font-weight: bold;
}
#workWithUs .nossas-vagas .close-button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}
#workWithUs .nossas-vagas .filter-options {
  padding-inline: 20px;
  background: #fff;
}
#workWithUs .nossas-vagas .filter-options label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  height: 58px;
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 1.5px;
  border-bottom: 1px solid #AEAEAE;
}
#workWithUs .nossas-vagas .filter-options input[type=checkbox] {
  margin-right: 10px;
}
#workWithUs .nossas-vagas .accordions-box {
  margin-top: 80px;
}
@media (max-width: 1100px) {
  #workWithUs .nossas-vagas .accordions-box {
    margin-top: 40px;
  }
}
#workWithUs .candidate {
  padding-top: 200px;
  background: var(--tbl-default-white-off, #F5F5F5);
  position: relative;
}
@media (max-width: 1100px) {
  #workWithUs .candidate {
    padding-top: 150px;
  }
}
#workWithUs .candidate .text-absolute {
  top: -135px;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 250px;
  position: absolute;
}
#workWithUs .candidate .text-absolute h3 {
  font-family: "Revx Neue";
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  /* 40% */
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* width and color of the stroke */
  text-stroke: 2px #00AEEF;
  /* Standard syntax, if supported */
  background-clip: text;
  position: relative;
  text-align: start;
  bottom: -80px;
  left: 0px;
  filter: blur(0);
}
@media (max-width: 1400px) {
  #workWithUs .candidate .text-absolute h3 {
    font-size: 14vw;
  }
}
@media (max-width: 1100px) {
  #workWithUs .candidate .text-absolute {
    height: auto;
    width: 100%;
    right: 0;
    left: inherit;
    top: -40px;
  }
  #workWithUs .candidate .text-absolute h3 {
    font-size: 76px;
    line-height: 85px;
    top: 0;
    right: 0;
    word-break: break-word;
    left: inherit;
    text-align: end;
  }
}
#workWithUs .form-candidate {
  background: var(--tbl-default-white-off, #F5F5F5);
  padding-bottom: 165px;
}
@media (max-width: 1100px) {
  #workWithUs .form-candidate {
    padding-bottom: 82px;
  }
}
#workWithUs .form-candidate .form-select {
  height: 72px;
  background: var(--tbl-primary-primary-500, #00AEEF);
  color: var(--bertolini-white, #FFF);
  font-family: "Roboto";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 17px */
  --bs-form-select-bg-img: url("../img/arrow-down.svg");
  padding-inline: 32px;
  background-image: url("../img/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 24px center;
  background-size: 24px;
}
#workWithUs .form-candidate .box-selected-job {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 56px;
}
#workWithUs .form-candidate .box-selected-job .selected-job {
  height: 72px;
  background: var(--tbl-primary-primary-700, #007CAA);
  padding-inline: 32px;
  color: var(--bertolini-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 17px */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#workWithUs .form-candidate .box-selected-job .selected-job button {
  color: var(--bertolini-white, #FFF);
  /* Desktop/Labels */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}
@media (max-width: 1100px) {
  #workWithUs .form-candidate .form-box {
    padding-inline: 17.5px;
  }
}
#workWithUs .form-candidate .form-box .btn-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#workWithUs .form-candidate .form-box .btn-submit::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#workWithUs .form-candidate .form-box .btn-submit:hover::after {
  transform: scaleY(1);
}
@media (max-width: 1100px) {
  #workWithUs .form-candidate .form-box .btn-submit {
    width: 100%;
    margin-top: 56px;
  }
}
#workWithUs .form-candidate .form-box .input-box {
  display: flex;
  flex-direction: column;
}
#workWithUs .form-candidate .form-box .input-box textarea {
  height: 200px;
  background: var(--tbl-default-white, #FFF);
  border: none;
  padding-inline: 32px;
  padding-top: 32px;
  color: var(--tbl-neutral-neutral-300, #898989);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 16px */
  margin-bottom: 24px;
}
#workWithUs .form-candidate .form-box .input-box textarea::placeholder {
  color: var(--tbl-neutral-neutral-300, #898989);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 16px */
}
#workWithUs .form-candidate .form-box .input-box p {
  color: var(--tbl-neutral-neutral-300, #898989);
  font-family: "Revx Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
  margin-top: 12px;
}
#workWithUs .form-candidate .form-box .input-box label {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
  margin-bottom: 12px;
}
#workWithUs .form-candidate .form-box .input-box input {
  background: var(--tbl-default-white, #FFF);
  border: none;
  height: 72px;
  padding-inline: 32px;
  color: var(--tbl-neutral-neutral-300, #898989);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 16px */
}
#workWithUs .form-candidate .form-box .input-box input::placeholder {
  color: var(--tbl-neutral-neutral-300, #898989);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 16px */
}
#workWithUs .form-candidate .form-box .form-check-input {
  --blue-primary: #00AEEF;
  border: 1px solid var(--blue-primary);
  border-radius: 1px !important;
  background-color: var(--blue-50, #F1FAFE);
  box-shadow: 0px 20px 44px 0px rgba(0, 0, 0, 0.2);
  width: 24px;
  height: 24px;
}
#workWithUs .form-candidate .form-box .form-check-input:checked {
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
}
#workWithUs .form-candidate .form-box .form-check-input:focus {
  border-color: var(--blue-primary);
  outline: 0;
  box-shadow: 0 0 0 1px var(--blue-primary);
}
#workWithUs .form-candidate .form-box .form-check-label {
  color: var(--tbl-neutral-neutral-300, #898989);
  /* Desktop/btn */
  font-family: "Revx Neue";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 100% */
  margin-top: 10px;
  margin-left: 20px;
}
#workWithUs .form-candidate .form-box .form-check-label a, #workWithUs .form-candidate .form-box .form-check-label a:hover {
  color: inherit;
  font-size: inherit;
  text-decoration: underline;
}
.know-company {
  padding-top: 135px;
  padding-bottom: 200px;
  position: relative;
}
.know-company .banner-normal::after {
  background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 7.21%, rgba(0, 132, 181, 0) 51.34%) !important;
  mix-blend-mode: multiply;
}
@media (max-width: 1399px) {
  .know-company {
    padding-bottom: 0;
  }
}
@media (max-width: 1100px) {
  .know-company {
    padding-bottom: 69px;
    padding-top: 70px;
    padding-inline: 17.5px;
  }
}
@media (max-width: 1100px) {
  .know-company .row {
    flex-direction: column-reverse;
  }
}
.know-company::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 45.573vw;
  height: 31.25vw;
  background: var(--tbl-secondary-secondary-500, #0084B5);
  z-index: -1;
}
@media (max-width: 1100px) {
  .know-company::after {
    width: 75.573vw;
    height: 240px;
  }
}
.know-company .wrapper-know {
  position: relative;
}
@media (max-width: 1100px) {
  .know-company .wrapper-know {
    max-width: 300px;
    margin: 0 auto;
  }
}
.know-company .wrapper-know .bg-destaque {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-97px, -20px);
}
@media (max-width: 1400px) {
  .know-company .wrapper-know .bg-destaque {
    width: 120%;
    transform: translate(-8%, -1%);
  }
}
@media (max-width: 1100px) {
  .know-company .wrapper-know .bg-destaque {
    width: 115%;
    transform: translate(-7%, 1%);
  }
}
.know-company .wrapper-know .bg-destaque img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.know-company .wrapper-know .know-img {
  border-radius: 10px;
  position: relative;
  z-index: 9;
  margin-top: 61px;
  max-height: 410px;
}
@media (max-width: 1400px) {
  .know-company .wrapper-know .know-img {
    width: 100%;
  }
}
@media (max-width: 1100px) {
  .know-company .wrapper-know .know-img {
    margin-top: 15px;
  }
}
.know-company .content-text h1 {
  color: #383838;
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  position: relative;
  margin-bottom: 32px;
}
.know-company .content-text h1::after {
  content: "";
  position: absolute;
  top: -35px;
  left: 0;
  width: 63px;
  height: 4px;
  background: #00AEEF;
}
.know-company .content-text h1 span {
  color: #383838;
  /* H2 - Title bold */
  font-family: inherit;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  .know-company .content-text h1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
  }
  .know-company .content-text h1 span {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}
.know-company .content-text p {
  color: var(--tbl-default-black, #383838);
  /* H6 - Text Body */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 20px;
}
.know-company .content-text p:last-of-type {
  margin-bottom: 56px;
}
@media (max-width: 1100px) {
  .know-company .content-text p {
    margin-bottom: 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}
.know-company .content-text p span {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 1100px) {
  .know-company .content-text p span {
    line-height: 18px;
    /* 128.571% */
  }
}
.know-company .content-text a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
@media (max-width: 1100px) {
  .know-company .content-text a {
    margin-bottom: 40px;
    width: 100%;
  }
}
.know-company .content-text a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
.know-company .content-text a:hover::after {
  transform: scaleY(1);
}

.our-news {
  padding-top: 105px;
  padding-bottom: 160px;
}
@media (max-width: 1100px) {
  .our-news {
    padding-bottom: 82px;
  }
}
.our-news .btn-news {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: calc(100% - 35px);
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  margin-top: 30px;
}
.our-news .btn-news::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
.our-news .btn-news:hover::after {
  transform: scaleY(1);
}
.our-news .content-destaque {
  margin-bottom: 70px;
}
@media (max-width: 1100px) {
  .our-news .content-destaque {
    margin-bottom: 28px;
  }
}
.our-news .content-destaque h1 {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  margin-bottom: 32px;
  position: relative;
  text-align: center;
}
.our-news .content-destaque h1::after {
  content: "";
  position: absolute;
  top: -35px;
  width: 63px;
  height: 4px;
  background: #00AEEF;
  left: 50%;
  /* Move to 50% of the container's width */
  transform: translateX(-50%);
  /* Shift back to the left by 50% of its own width */
}
.our-news .content-destaque h1 span {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  .our-news .content-destaque h1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
  }
  .our-news .content-destaque h1 span {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 100% */
  }
}
.our-news .content-destaque p {
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
@media (max-width: 1100px) {
  .our-news .content-destaque p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}
.our-news .grid-news {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 26px;
  grid-row-gap: 10px;
  height: 466px;
}
@media (max-width: 1100px) {
  .our-news .grid-news {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 26px;
  }
}
.our-news .grid-news a {
  display: contents;
}
.our-news .div1, .our-news .div2, .our-news .div3, .our-news .div4 {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-end;
}
@media (max-width: 1100px) {
  .our-news .div1, .our-news .div2, .our-news .div3, .our-news .div4 {
    width: 100%;
    height: 289px;
  }
}
.our-news .div1:hover img, .our-news .div2:hover img, .our-news .div3:hover img, .our-news .div4:hover img {
  transform: scale(1.1);
}
.our-news .div1 .tag, .our-news .div2 .tag, .our-news .div3 .tag, .our-news .div4 .tag {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  background: var(--tbl-primary-primary-500, #00AEEF);
  color: var(--tbl-default-white, #FFF);
  font-family: "Revx Neue";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 187.5% */
  z-index: 9;
  text-transform: capitalize;
  padding-inline: 21px;
}
@media (max-width: 1100px) {
  .our-news .div1 .tag, .our-news .div2 .tag, .our-news .div3 .tag, .our-news .div4 .tag {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    padding-inline: 21px;
  }
}
.our-news .div1 .plus, .our-news .div2 .plus, .our-news .div3 .plus, .our-news .div4 .plus {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 59px;
  height: 52.578px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: #FFF;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 1100px) {
  .our-news .div1 .plus, .our-news .div2 .plus, .our-news .div3 .plus, .our-news .div4 .plus {
    width: 36px;
    height: 32px;
  }
}
.our-news .div1 img, .our-news .div2 img, .our-news .div3 img, .our-news .div4 img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}
.our-news .div1::after, .our-news .div2::after, .our-news .div3::after, .our-news .div4::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, #000 11.62%, rgba(0, 0, 0, 0) 96.06%);
}
.our-news .div1 .content-text, .our-news .div2 .content-text, .our-news .div3 .content-text, .our-news .div4 .content-text {
  position: relative;
  z-index: 9;
  max-width: 80%;
  padding-bottom: 25px;
  padding-left: 36px;
}
@media (max-width: 1100px) {
  .our-news .div1 .content-text, .our-news .div2 .content-text, .our-news .div3 .content-text, .our-news .div4 .content-text {
    padding-left: 25px;
    padding-bottom: 16px;
  }
}
.our-news .div1 .content-text h2, .our-news .div2 .content-text h2, .our-news .div3 .content-text h2, .our-news .div4 .content-text h2 {
  color: var(--tbl-default-white-off, #F5F5F5);
  font-family: "Revx Neue";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  /* 110% */
}
@media (max-width: 1100px) {
  .our-news .div1 .content-text h2, .our-news .div2 .content-text h2, .our-news .div3 .content-text h2, .our-news .div4 .content-text h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    /* 125% */
  }
}
.our-news .div1 .content-text p, .our-news .div2 .content-text p, .our-news .div3 .content-text p, .our-news .div4 .content-text p {
  color: var(--tbl-default-white-off, #F5F5F5);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
@media (max-width: 1100px) {
  .our-news .div1 .content-text p, .our-news .div2 .content-text p, .our-news .div3 .content-text p, .our-news .div4 .content-text p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}
.our-news .div1 .content-text .date, .our-news .div2 .content-text .date, .our-news .div3 .content-text .date, .our-news .div4 .content-text .date {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-family: "Revx Neue", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 166.667% */
  margin-top: 10px;
}
@media (max-width: 1100px) {
  .our-news .div1 .content-text .date, .our-news .div2 .content-text .date, .our-news .div3 .content-text .date, .our-news .div4 .content-text .date {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    /* 214.286% */
  }
}
.our-news .div1 {
  grid-area: 1/7/5/9;
}
.our-news .div2 {
  grid-area: 1/5/3/7;
}
.our-news .div3 {
  grid-area: 3/5/5/7;
}
.our-news .div4 {
  grid-area: 1/1/5/5;
}
.our-news .div4 h2 {
  color: var(--tbl-default-white-off, #F5F5F5);
  font-family: "Revx Neue";
  font-size: 30px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 30px !important;
  /* 100% */
}
@media (max-width: 1100px) {
  .our-news .div4 h2 {
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 25px !important;
    /* 125% */
  }
}
.our-news .div4 .date {
  margin-top: 22px !important;
}
@media (max-width: 1100px) {
  .our-news .div4 .date {
    margin-top: 10px !important;
  }
}
.our-news .div4 p {
  margin-top: 10px;
}

#who-empresa {
  min-height: 500dvh;
}

#whoStructure {
  overflow: hidden;
}
@media (max-width: 1100px) {
  #whoStructure .content {
    padding-inline: 17.5px;
  }
}
#whoStructure .content .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 43px;
  position: relative;
}
#whoStructure .content .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
}
#whoStructure .content .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  #whoStructure .content .title-section {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
  }
  #whoStructure .content .title-section strong {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}
#whoStructure .content p {
  color: var(--tbl-default-black, #383838);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 376px;
  margin-bottom: 26px;
}
#whoStructure .content p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #whoStructure .content p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
  #whoStructure .content p strong {
    line-height: 18px;
    /* 128.571% */
    font-size: 14px;
  }
}
#whoStructure .about {
  position: relative;
  padding-top: 105px;
  padding-bottom: 255px;
}
@media (max-width: 1399px) {
  #whoStructure .about {
    padding-bottom: 50px;
  }
}
@media (max-width: 1100px) {
  #whoStructure .about {
    padding-top: 70px;
    padding-bottom: 0px;
  }
}
@media (max-width: 1100px) {
  #whoStructure .about .row {
    flex-direction: column-reverse;
  }
}
#whoStructure .about .bloco-azul {
  width: 875px;
  height: 30.313vw;
  background-color: #0084B5;
  position: absolute;
  top: 228px;
  left: -42px;
}
@media (max-width: 1600px) {
  #whoStructure .about .bloco-azul {
    max-width: 61%;
  }
}
@media (max-width: 1100px) {
  #whoStructure .about .bloco-azul {
    width: 80%;
    max-width: 100%;
    height: 240px;
    left: 0;
    position: absolute;
    top: inherit;
    bottom: 30px;
  }
}
@media (max-width: 1100px) {
  #whoStructure .about .col-bloco-cinza {
    padding-inline: 25px;
  }
}
#whoStructure .about .bloco-cinza {
  width: 100%;
  max-width: 868px;
  border-radius: 22px;
  background: #F5F5F5;
  padding: 49px 98px 49px 98px;
  position: absolute;
  left: 151px;
  top: 95px;
}
@media (max-width: 1600px) {
  #whoStructure .about .bloco-cinza {
    left: 0;
    max-width: 55%;
  }
}
@media (max-width: 1100px) {
  #whoStructure .about .bloco-cinza {
    width: calc(100% - 35px);
    max-width: 100%;
    top: 0;
    left: 0;
    position: relative;
    padding: 14px 25px;
    margin: 0 auto;
    margin-bottom: 96px;
    height: 253px;
  }
}
#whoStructure .about .bloco-cinza h3 {
  font-family: "Revx Neue";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  color: var(--tbl-default-black, #383838);
  text-align: center;
  margin-bottom: 27px;
}
@media (max-width: 1100px) {
  #whoStructure .about .bloco-cinza h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    margin-bottom: 7px;
  }
}
#whoStructure .about .bloco-cinza h3 strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
}
#whoStructure .about .bloco-cinza .video {
  width: 100%;
  max-width: 671px;
  border-radius: 10px;
  margin-bottom: 33px;
  position: relative;
}
@media (max-width: 1100px) {
  #whoStructure .about .bloco-cinza .video {
    height: 100%;
    margin-bottom: 14px;
  }
  #whoStructure .about .bloco-cinza .video img {
    height: 100%;
    width: 100%;
  }
}
#whoStructure .about .bloco-cinza .video img {
  border-radius: 10px;
  width: 100%;
}
#whoStructure .about .bloco-cinza .video .play {
  position: absolute;
  left: 0;
  right: 0;
  top: 39%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
#whoStructure .about .bloco-cinza p {
  color: var(--tbl-default-black, #383838);
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
@media (max-width: 1100px) {
  #whoStructure .about .bloco-cinza p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 116.667% */
  }
}
#whoStructure .about .content {
  margin-left: 87px;
}
@media (max-width: 1100px) {
  #whoStructure .about .content {
    margin-left: 0;
    padding-inline: 17.5px;
  }
}
#whoStructure .about .content p {
  color: var(--tbl-default-black, #383838);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 376px;
  margin-bottom: 26px;
}
#whoStructure .about .content p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
#whoStructure .content-destaque {
  margin-bottom: 70px;
}
#whoStructure .content-destaque h1 {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  margin-bottom: 32px;
  position: relative;
  text-align: center;
}
#whoStructure .content-destaque h1::after {
  content: "";
  position: absolute;
  top: -35px;
  width: 63px;
  height: 4px;
  background: #00AEEF;
  left: 50%;
  /* Move to 50% of the container's width */
  transform: translateX(-50%);
  /* Shift back to the left by 50% of its own width */
}
#whoStructure .content-destaque h1 span {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  #whoStructure .content-destaque h1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  #whoStructure .content-destaque h1 span {
    font-size: 28px;
    line-height: 28px;
  }
}
#whoStructure .content-destaque p {
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
@media (max-width: 1100px) {
  #whoStructure .content-destaque p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding-inline: 17.5px;
  }
}
#whoStructure .cards {
  padding-top: 160px;
  padding-bottom: 200px;
}
@media (max-width: 1100px) {
  #whoStructure .cards {
    padding-top: 95px;
    padding-bottom: 0px;
  }
}
#whoStructure .cards .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 30px;
  position: relative;
  text-align: center;
}
#whoStructure .cards .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
#whoStructure .cards .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  #whoStructure .cards .title-section {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
    padding-inline: 17.5px;
  }
  #whoStructure .cards .title-section strong {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}
#whoStructure .cards p {
  color: var(--tbl-default-black, #383838);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 630px;
  margin-bottom: 26px;
  margin: 0 auto;
  text-align: center;
}
#whoStructure .cards p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #whoStructure .cards p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    padding-inline: 17.5px;
  }
  #whoStructure .cards p strong {
    line-height: 18px;
    /* 128.571% */
    font-size: 14px;
  }
}
#whoStructure .cards .row {
  gap: 59px;
}
@media (max-width: 1100px) {
  #whoStructure .cards .row {
    gap: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding-bottom: 1rem;
    padding-inline: 20px;
  }
}
#whoStructure .cards .mini-box {
  border-radius: 20px 0px;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 24px 28px;
  max-width: 208px;
  height: 200px;
  margin-top: 56px;
}
#whoStructure .cards .mini-box .icone {
  height: 28px;
}
#whoStructure .cards .mini-box .numero {
  color: var(--tbl-secondary-secondary-500, #0084B5);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  margin-top: 29px;
}
#whoStructure .cards .mini-box .desc {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
#whoStructure .cards .mini-box:hover {
  background-color: #0084B5;
  transition: 0.3s ease-in-out;
}
#whoStructure .cards .mini-box:hover img {
  filter: grayscale(100%) brightness(2);
  transition: 0.3s ease-in-out;
}
#whoStructure .cards .mini-box:hover .numero,
#whoStructure .cards .mini-box:hover .desc {
  color: #fff;
  transition: 0.3s ease-in-out;
}
#whoStructure .caminho {
  position: relative;
  padding-bottom: 300px;
}
@media (max-width: 1100px) {
  #whoStructure .caminho {
    padding-bottom: 0;
  }
}
#whoStructure .caminho .img-right {
  position: absolute;
  width: 43.906vw;
  height: 745px;
  left: 0;
  top: 0;
}
@media (max-width: 1600px) {
  #whoStructure .caminho .img-right {
    left: -200px;
  }
}
@media (max-width: 1100px) {
  #whoStructure .caminho .img-right {
    position: relative;
    width: 100%;
    height: 342px;
    display: flex;
    justify-content: flex-end;
    left: 0;
    top: -100px;
  }
}
#whoStructure .caminho .img-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1100px) {
  #whoStructure .caminho .img-right img {
    width: 94%;
  }
}
#whoStructure .caminho .lines {
  position: absolute;
  top: -16px;
  left: 0;
  transform: rotate(180deg);
}
@media (max-width: 1100px) {
  #whoStructure .caminho .lines {
    display: none;
  }
}
#whoStructure .caminho .texto-section {
  font-family: "Revx Neue";
  font-size: 215px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  text-stroke: 2px #00AEEF;
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
  right: -25px;
  text-align: end;
  top: -50px;
  z-index: 99;
  filter: blur(0);
}
@media (max-width: 1100px) {
  #whoStructure .caminho .texto-section {
    font-size: 80px;
    line-height: 80px;
    top: 417px;
    width: 70%;
    right: 0;
    word-wrap: break-word;
    left: inherit;
    right: -25%;
  }
}
#whoStructure .caminho .content {
  padding-top: 116px;
}
@media (max-width: 1100px) {
  #whoStructure .caminho .content {
    padding-inline: 25px;
  }
}
#whoStructure .caminho .title-section {
  max-width: 292px;
}
#whoStructure .caminho p {
  color: var(--tbl-default-black, #383838);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 626px;
  margin-bottom: 26px;
}
#whoStructure .caminho p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #whoStructure .caminho p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  #whoStructure .caminho p strong {
    font-size: 14px;
    line-height: 18px;
  }
}
#whoStructure .caminho .btn-section {
  width: 236px;
  margin-top: 63px;
}
#whoStructure .unidades {
  position: relative;
  padding-top: 160px;
}
@media (max-width: 1100px) {
  #whoStructure .unidades {
    padding-top: 200px;
  }
}
#whoStructure .unidades .text-absolute {
  top: -140px;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 250px;
  position: absolute;
}
#whoStructure .unidades .text-absolute h3 {
  font-family: "Revx Neue";
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  text-stroke: 2px #00AEEF;
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
  right: 0px;
  text-align: start;
  bottom: -80px;
  filter: blur(0);
}
@media (max-width: 1100px) {
  #whoStructure .unidades .text-absolute {
    height: auto;
    display: flex;
    justify-content: flex-end;
    top: 0;
  }
  #whoStructure .unidades .text-absolute h3 {
    font-size: 80px;
    line-height: 80px;
    bottom: 0;
    right: 15px;
    width: 65%;
    word-break: break-word;
    text-align: end;
  }
}
#whoStructure .unidades .map-unity {
  position: absolute;
  right: 50px;
  bottom: 0;
  z-index: -1;
  width: 35vw;
}
@media (max-width: 1100px) {
  #whoStructure .unidades .map-unity {
    display: none;
  }
}
#whoStructure .unidades .form-select {
  height: 54px;
  background: var(--tbl-primary-primary-500, #00AEEF);
  color: var(--bertolini-white, #FFF);
  font-family: "Roboto";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 17px */
  --bs-form-select-bg-img: url("../img/arrow-down.svg");
  padding-inline: 32px;
  background-image: url("../img/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 24px center;
  background-size: 24px;
  margin-bottom: 24px;
}
#whoStructure .unidades .form-select:focus {
  box-shadow: none;
  border: none;
}
#whoStructure .unidades-local {
  padding: 84px 0;
  background: #F5F5F5;
  position: relative;
  z-index: 99;
}
@media (max-width: 1100px) {
  #whoStructure .unidades-local {
    padding-bottom: 3rem;
  }
}
#whoStructure .unidades-local .box-unidades #no-unit {
  display: flex;
  align-items: center;
  gap: 12px;
}
#whoStructure .unidades-local .box-unidades #no-unit h2 {
  color: #383838;
  font-family: "Revx Neue", sans-serif;
  font-size: 30px;
  line-height: 42px;
  font-weight: 600;
}
@media (max-width: 1100px) {
  #whoStructure .unidades-local .box-unidades #no-unit h2 {
    font-size: 22px;
    line-height: 30px;
  }
}
#whoStructure .unidades-local .box-unidades #no-unit ion-icon {
  color: #383838;
  font-size: 28px;
}
@media (max-width: 1100px) {
  #whoStructure .unidades-local .box-unidades #no-unit ion-icon {
    font-size: 22px;
  }
}
#whoStructure .unidades-local .card-unidade {
  border-bottom: solid 2px #00AEEF;
  padding: 35px 0 20px 0;
  height: 100%;
  border-radius: 20px 0px;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 24px 28px;
  max-width: 358px;
  height: 200px;
  margin-top: 56px;
}
@media (max-width: 1100px) {
  #whoStructure .unidades-local .card-unidade {
    padding-inline: 55px;
    padding-bottom: 3rem;
    height: auto;
  }
}
#whoStructure .unidades-local .card-unidade a {
  text-decoration: none;
}
#whoStructure .unidades-local .card-unidade h2 {
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  /* H5 - Description */
  font-family: "Revx Neue";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 100% */
  margin-bottom: 32px;
}
#whoStructure .unidades-local .card-unidade p {
  display: flex;
  gap: 12px;
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  /* H6 - Text Body */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 12px;
}
#whoStructure .unidades-local .redes {
  display: flex;
  gap: 20px;
  padding-top: 23px;
  border-top: 2px solid #fff;
  margin-top: 8px;
}
#whoStructure .unidades-local .redes a {
  text-decoration: none;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

#who-story {
  overflow-y: hidden;
}
#who-story .ambiental-video {
  margin-bottom: 166px;
  padding-top: 90px;
}
@media (max-width: 991px) {
  #who-story .ambiental-video {
    display: flex;
    flex-direction: column-reverse;
  }
}
#who-story .ambiental-video .content .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 43px;
  position: relative;
}
#who-story .ambiental-video .content .title-section strong {
  color: var(--TBL-Default-black, #383838);
  /* H2 - Title bold */
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 991px) {
  #who-story .ambiental-video .content .title-section {
    font-size: 28px;
    line-height: 28px;
    padding-inline: 17.5px;
  }
}
#who-story .ambiental-video .content .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
}
#who-story .ambiental-video .content p {
  color: var(--tbl-default-black, #383838);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 26px;
}
#who-story .ambiental-video .content p strong {
  color: var(--TBL-Primary-primary-500, #00AEEF);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 991px) {
  #who-story .ambiental-video .content p {
    font-size: 14px;
    line-height: 18px;
    padding-inline: 17.5px;
  }
  #who-story .ambiental-video .content p strong {
    color: var(--TBL-Primary-primary-500, #00AEEF);
    /* H6 - TextBody */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
#who-story .ambiental-video .bg-blue {
  left: 0;
  top: 310px;
  background: var(--tbl-secondary-secondary-500, #0084B5);
  width: min(948px, 50vw);
  height: 815px;
  z-index: -1;
}
@media (max-width: 991px) {
  #who-story .ambiental-video .bg-blue {
    width: 80%;
    height: 240px;
    top: inherit;
    bottom: -50px;
  }
}
#who-story .ambiental-video .video-wrapper {
  display: grid;
  place-items: center;
  background-color: #F5F5F5;
  border-radius: 22px;
  padding: 84px;
  margin-bottom: 75px;
}
@media (max-width: 991px) {
  #who-story .ambiental-video .video-wrapper {
    padding: 20px;
    max-width: 339px;
  }
}
#who-story .ambiental-video .video-wrapper .video {
  border-radius: 8px;
}
#who-story .ambiental-video .video-wrapper .play-icon {
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  z-index: 2;
}
#who-story .completa {
  margin-top: 370px;
  padding-top: 91px;
  background-color: #fff;
  position: relative;
}
@media (max-width: 991px) {
  #who-story .completa {
    margin-top: 200px;
    padding-top: 50px;
  }
}
#who-story .completa .text-absolute {
  top: -190px;
  right: 0;
  width: 100%;
  overflow: hidden;
  height: 250px;
  position: absolute;
}
@media (max-width: 991px) {
  #who-story .completa .text-absolute {
    right: 15px;
  }
}
#who-story .completa .text-absolute h3 {
  font-family: "Revx Neue";
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  /* 40% */
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* width and color of the stroke */
  text-stroke: 2px #00AEEF;
  /* Standard syntax, if supported */
  background-clip: text;
  position: relative;
  right: inherit;
  text-align: start;
  bottom: -80px;
  left: -14px;
  filter: blur(0);
}
@media (max-width: 991px) {
  #who-story .completa .text-absolute h3 {
    font-size: 14vw;
    line-height: 98px;
    left: 0;
    text-align: end;
    -webkit-text-stroke: 1.5px #00AEEF;
  }
}
#who-story .completa .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 43px;
  position: relative;
}
@media (max-width: 991px) {
  #who-story .completa .title-section {
    font-size: 28px;
    line-height: 28px;
  }
}
#who-story .completa .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 50%;
  transform: translateX(-50%);
}
#who-story .completa .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 991px) {
  #who-story .completa .title-section strong {
    font-size: 28px;
    line-height: 28px;
  }
}
#who-story .completa .title-section {
  text-align: center;
  margin-bottom: 31px;
}
#who-story .completa p {
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 627px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  #who-story .completa p {
    font-size: 14px;
    line-height: 18px;
    padding-inline: 17.5px;
  }
}
#who-story .completa .lines {
  position: absolute;
  right: 0;
  top: 30px;
}
@media (max-width: 991px) {
  #who-story .completa .lines {
    display: none;
  }
}
#who-story .completa .slider-section {
  position: relative;
  margin-top: 70px;
}
#who-story .completa .slider-section .slider.center .cl {
  height: 544px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
@media (max-width: 991px) {
  #who-story .completa .slider-section .slider.center .cl {
    height: 415px;
  }
}
#who-story .completa .slider-section .slider.center .cl .content {
  width: 100%;
  height: 100%;
  position: relative;
}
#who-story .completa .slider-section .slider.center .cl .content .texto {
  position: absolute;
  bottom: 0;
  left: 43px;
  max-width: 339px;
  z-index: 2;
  height: 100%;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 43px;
}
@media (max-width: 991px) {
  #who-story .completa .slider-section .slider.center .cl .content .texto {
    left: 15px;
  }
}
#who-story .completa .slider-section .slider.center .cl .content .texto div {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
@media (max-width: 991px) {
  #who-story .completa .slider-section .slider.center .cl .content .texto div {
    gap: 15px;
  }
}
#who-story .completa .slider-section .slider.center .cl .content .texto .text-top {
  margin: 0;
  color: #D9D9D9;
  font-family: "Revx Neue";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 160% */
  display: none;
}
@media (max-width: 991px) {
  #who-story .completa .slider-section .slider.center .cl .content .texto .text-top {
    padding-inline: 17.5px;
  }
}
#who-story .completa .slider-section .slider.center .cl .content .texto h3 {
  color: #FFF;
  font-family: "Revx Neue";
  font-size: 130px;
  font-style: normal;
  font-weight: 700;
  line-height: 130px;
  /* 100% */
  text-decoration: none;
  position: absolute;
  bottom: 50px;
  left: 90px;
}
#who-story .completa .slider-section .slider.center .cl .content .texto p {
  color: #F5F5F5;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  display: none;
}
@media (max-width: 991px) {
  #who-story .completa .slider-section .slider.center .cl .content .texto p {
    font-size: 14px;
    line-height: 18px;
    padding-inline: 17.5px;
  }
}
#who-story .completa .slider-section .slider.center .cl .content .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--tbl-gradients-effects-gradient-linear, linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%));
  mix-blend-mode: multiply;
}
#who-story .completa .slider-section .slider.center .slick-active {
  padding: 30px 0;
}
#who-story .completa .slider-section .slider.center .slick-current .texto h3 {
  color: #FFF;
  font-family: "Revx Neue";
  font-size: 90px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 90px !important;
  /* 100% */
  text-decoration: underline #00AEEF !important;
  position: relative !important;
  bottom: 0 !important;
  left: 0 !important;
  text-decoration-thickness: 3px !important;
}
@media (max-width: 991px) {
  #who-story .completa .slider-section .slider.center .slick-current .texto h3 {
    font-size: 58.205px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 58.205px !important;
    /* 100% */
    padding-inline: 17.5px;
  }
}
#who-story .completa .slider-section .slider.center .slick-current .texto p {
  display: block !important;
}
#who-story .completa .slider-section .slider.center .slick-current .texto .text-top {
  display: block !important;
}
#who-story .completa .slider-section .slider.center .slick-current .cl .content .layer {
  background: var(--TBL-Gradients-Effects-gradienteDiagonal, linear-gradient(260deg, #000 -58.46%, rgba(0, 0, 0, 0) 124.89%));
}
#who-story .completa .slider-section .slider.center .slick-center {
  transform: scale(1.1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 99;
  position: relative;
  max-width: 540px;
}
#who-story .completa .slider-section .slider.center .slick-center::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #0084B5;
  bottom: 34px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
#who-story .completa .slider-section .slider.center .slick-slide:not(.slick-active) {
  margin: 30px 0;
}
#who-story .completa .slider-section .slider.center .child {
  width: 100%;
}
#who-story .completa .slider-section .slider.center .slide:not(.slick-active) {
  cursor: pointer;
}
#who-story .completa .slider-section .slider.center .layer-blue {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0) 100%);
}
#who-story .completa .slider-section .center-prev,
#who-story .completa .slider-section .center-next {
  position: absolute;
  top: calc(50% - 64px);
  cursor: pointer;
  z-index: 2;
}
@media (max-width: 991px) {
  #who-story .completa .slider-section .center-prev,
#who-story .completa .slider-section .center-next {
    display: none;
  }
}
#who-story .completa .slider-section .center-prev {
  left: 88px;
}
#who-story .completa .slider-section .center-next {
  right: 88px;
}
#who-story .completa .slider-section .layer-blue {
  position: absolute;
  width: 476px;
  height: calc(100% - 64px);
  top: 30px;
  background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0) 100%);
  z-index: 1;
}
@media (max-width: 991px) {
  #who-story .completa .slider-section .layer-blue {
    display: none;
  }
}
#who-story .completa .slider-section .layer-blue-second {
  position: absolute;
  width: 476px;
  height: calc(100% - 64px);
  top: 30px;
  right: 0;
  background: linear-gradient(90deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%);
  z-index: 1;
}
@media (max-width: 991px) {
  #who-story .completa .slider-section .layer-blue-second {
    display: none;
  }
}
#who-story .oportunidade {
  position: relative;
  padding-bottom: 130px;
  padding-top: 220px;
}
@media (max-width: 1600px) {
  #who-story .oportunidade {
    padding-bottom: 0px;
  }
}
@media (max-width: 991px) {
  #who-story .oportunidade {
    padding-bottom: 50px;
    padding-top: 120px;
  }
}
#who-story .oportunidade .wrap-img {
  position: absolute;
  right: 0;
  aspect-ratio: 1.1315;
  width: 44.896vw;
}
@media (max-width: 991px) {
  #who-story .oportunidade .wrap-img {
    position: relative;
    width: 100%;
    max-width: inherit;
  }
}
#who-story .oportunidade .wrap-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#who-story .oportunidade .wrap-img .listras {
  position: absolute;
  width: 256px;
  height: 39px;
  bottom: inherit;
  top: -15px;
  right: 0;
  object-fit: contain;
  object-position: right;
}
#who-story .oportunidade .text-absolute {
  top: 80px;
  right: 0;
  width: 100%;
  overflow: visible;
  height: 250px;
  position: absolute;
}
@media (max-width: 991px) {
  #who-story .oportunidade .text-absolute {
    overflow: visible;
  }
}
#who-story .oportunidade .text-absolute h3 {
  font-family: "Revx Neue";
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  /* 40% */
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* width and color of the stroke */
  text-stroke: 2px #00AEEF;
  /* Standard syntax, if supported */
  background-clip: text;
  position: relative;
  right: -41px;
  text-align: end;
  bottom: -80px;
  filter: blur(0);
}
@media (max-width: 991px) {
  #who-story .oportunidade .text-absolute h3 {
    right: 0px;
    line-height: 90px;
    font-size: 14vw;
    text-align: start;
    bottom: 0;
    top: 530px;
    left: 15px;
  }
}
#who-story .oportunidade .content-text {
  padding-top: 240px;
  margin-bottom: 80px;
}
@media (max-width: 991px) {
  #who-story .oportunidade .content-text {
    padding-top: 111px;
    margin-bottom: 0px;
  }
}
#who-story .oportunidade .content-text h2 {
  color: #383838;
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  margin-bottom: 26px;
  position: relative;
}
@media (max-width: 991px) {
  #who-story .oportunidade .content-text h2 {
    font-size: 25px;
    line-height: 25px;
  }
}
#who-story .oportunidade .content-text h2::after {
  position: absolute;
  top: -35px;
  left: 0;
  width: 63px;
  height: 4px;
  background: #00AEEF;
  content: "";
}
#who-story .oportunidade .content-text h2 span {
  color: #383838;
  /* H2 - Title bold */
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 991px) {
  #who-story .oportunidade .content-text h2 span {
    font-size: 25px;
    line-height: 25px;
  }
}
#who-story .oportunidade .content-text p {
  color: var(--tbl-default-black, #383838);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 0;
}
@media (max-width: 991px) {
  #who-story .oportunidade .content-text p {
    font-size: 14px;
    line-height: 18px;
  }
}
#who-story .oportunidade .content-text p span {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 991px) {
  #who-story .oportunidade .content-text p span {
    font-size: 14px;
    line-height: 18px;
  }
}
#who-story .oportunidade .content-text a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#who-story .oportunidade .content-text a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#who-story .oportunidade .content-text a:hover::after {
  transform: scaleY(1);
}
#who-story .treinamento {
  margin-top: 68px;
}
@media (max-width: 1600px) {
  #who-story .treinamento {
    padding-top: 100px;
  }
}
@media (max-width: 991px) {
  #who-story .treinamento {
    margin-top: 0;
    padding-top: 0;
  }
}
#who-story .treinamento .text-absolute {
  top: 135px;
}
@media (max-width: 991px) {
  #who-story .treinamento .text-absolute {
    top: 320px;
    height: auto;
  }
}
#who-story .treinamento .text-absolute h3 {
  right: 0;
  text-align: start;
}
@media (max-width: 1600px) {
  #who-story .treinamento .text-absolute h3 {
    bottom: 58px;
  }
}
@media (max-width: 991px) {
  #who-story .treinamento .text-absolute h3 {
    top: 0;
  }
}
@media (max-width: 991px) {
  #who-story .campanhas .text-absolute {
    height: auto;
    top: 450px;
  }
}
#who-story .campanhas .text-absolute h3 {
  right: 0px;
  text-align: right;
}
@media (max-width: 991px) {
  #who-story .campanhas .text-absolute h3 {
    right: 0px;
    line-height: 90px;
    font-size: 14vw;
    text-align: start;
    bottom: 0;
    top: 0;
    left: 15px;
  }
}
#who-story .campanhas .wrap-img {
  left: 0;
}
#who-story .campanhas .wrap-img .listras {
  transform: rotate(180deg);
  bottom: inherit;
  right: inherit;
  left: 0;
}
#who-story .section4 {
  position: relative;
  padding-top: 156px;
  padding-bottom: 111px;
  background-color: #F5F5F5;
}
#who-story .section4 .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 34px;
  position: relative;
}
@media (max-width: 991px) {
  #who-story .section4 .title-section {
    font-size: 28px;
    line-height: 28px;
  }
}
#who-story .section4 .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
}
#who-story .section4 .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 991px) {
  #who-story .section4 .title-section strong {
    font-size: 28px;
    line-height: 28px;
  }
}
#who-story .section4 .title-section {
  text-align: center;
}
#who-story .section4 .title-section::after {
  right: 0;
  left: 0;
  margin: 0 auto;
}
#who-story .section4 p {
  color: #4F4F4F;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 627px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  #who-story .section4 p {
    font-size: 14px;
    line-height: 18px;
  }
}
#who-story .section4 .blocos {
  margin-top: 83px;
}
#who-story .section4 .blocos .bloco {
  max-width: 406px;
  height: 271px;
  border-radius: 20px 0px;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 40px 20px;
  margin-bottom: 30px;
}
#who-story .section4 .blocos .bloco h3 {
  color: #0084B5;
  font-family: "Revx Neue";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-top: 12px;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  #who-story .section4 .blocos .bloco h3 {
    font-size: 22px;
    line-height: 37px;
  }
}
#who-story .section4 .blocos .bloco p {
  color: #383838;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
@media (max-width: 991px) {
  #who-story .section4 .blocos .bloco p {
    font-size: 14px;
    line-height: 18px;
  }
}
#who-story .section4 .blocos .bloco:hover {
  background-color: #0084B5;
  transition: 0.3s ease;
}
#who-story .section4 .blocos .bloco:hover img {
  filter: grayscale(100%) brightness(2);
  transition: 0.3s ease;
}
#who-story .section4 .blocos .bloco:hover p,
#who-story .section4 .blocos .bloco:hover h3 {
  color: #fff;
  transition: 0.3s ease;
}
#who-story .section4 .texto-section {
  font-family: "Revx Neue";
  font-size: 250px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  text-stroke: 2px #00AEEF;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: -60px;
  left: -30px;
  right: 0;
  margin: 0 auto;
  text-align: start;
  filter: blur(0);
}

#esg {
  overflow: hidden;
}
#esg .section-margin {
  margin-bottom: 200px;
}
@media (max-width: 1100px) {
  #esg .section-margin {
    margin-bottom: 100px;
  }
}
#esg .s5 {
  width: 100px;
  height: 100px;
}
#esg .download-pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  min-width: 185px;
  margin-top: 56px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  padding-inline: 20px;
  width: fit-content;
  margin-bottom: 30px;
}
#esg .download-pdf button {
  color: #fff;
  display: flex;
  gap: 15px;
  align-items: center;
}
#esg .download-pdf button span {
  filter: invert(1);
}
#esg .download-pdf::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#esg .download-pdf:hover::after {
  transform: scaleY(1);
}
#esg .download-e-selo {
  padding: 180px 0;
}
@media (max-width: 991px) {
  #esg .download-e-selo {
    padding: 80px 0;
  }
}
#esg .download-e-selo .title {
  color: #383838;
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  position: relative;
}
#esg .download-e-selo .title::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
#esg .download-e-selo .content-download {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
#esg .download-e-selo .content-download a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  min-width: 185px;
  margin-top: 56px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  padding-inline: 20px;
}
#esg .download-e-selo .content-download a button {
  color: #fff;
  display: flex;
  gap: 15px;
  align-items: center;
}
#esg .download-e-selo .content-download a button span {
  filter: invert(1);
}
#esg .download-e-selo .content-download a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#esg .download-e-selo .content-download a:hover::after {
  transform: scaleY(1);
}
#esg .download-e-selo .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-top: 100px;
}
#esg .download-e-selo .content div {
  display: flex;
  gap: 20px;
}
#esg .download-e-selo .content div img {
  width: 50px;
  height: 50px;
  filter: invert(48%) sepia(69%) saturate(1777%) hue-rotate(162deg) brightness(98%) contrast(101%);
}
#esg .download-e-selo .text-container {
  max-width: 50%;
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  #esg .download-e-selo .text-container {
    max-width: 100%;
  }
}
#esg .download-e-selo .text-container p {
  color: var(--tbl-default-black, #383838);
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#esg .download-e-selo .text-container p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
}
@media (max-width: 991px) {
  #esg .download-e-selo .text-container p {
    text-align: center;
  }
}
#esg .download-e-selo .s5 {
  float: right;
  margin-right: 50px;
}
@media (max-width: 991px) {
  #esg .download-e-selo .s5 {
    float: inherit;
    margin: 20px auto;
    display: flex;
  }
}
#esg .download-e-selo .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 30px;
  position: relative;
  text-align: center;
  margin-bottom: 80px;
}
@media (max-width: 991px) {
  #esg .download-e-selo .title-section {
    margin-bottom: 30px;
  }
}
#esg .download-e-selo .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
#esg .download-e-selo .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  #esg .download-e-selo .title-section {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
    padding-inline: 17.5px;
  }
  #esg .download-e-selo .title-section strong {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}
#esg .cards {
  width: 100%;
  padding-top: 150px;
}
@media (max-width: 1100px) {
  #esg .cards {
    padding-top: 95px;
    padding-bottom: 0px;
  }
}
#esg .cards .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 30px;
  position: relative;
  text-align: center;
}
#esg .cards .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
#esg .cards .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  #esg .cards .title-section {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
    padding-inline: 17.5px;
  }
  #esg .cards .title-section strong {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}
#esg .cards p {
  color: var(--tbl-default-black, #383838);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 630px;
  margin-bottom: 26px;
  margin: 0 auto;
  text-align: center;
}
#esg .cards p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #esg .cards p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    padding-inline: 17.5px;
  }
  #esg .cards p strong {
    line-height: 18px;
    /* 128.571% */
    font-size: 14px;
  }
}
#esg .cards .row {
  gap: 59px;
  row-gap: 0;
}
@media (max-width: 1100px) {
  #esg .cards .row {
    gap: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding-bottom: 1rem;
    padding-inline: 20px;
  }
}
#esg .cards .mini-box {
  border-radius: 20px 0px;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 24px 28px;
  max-width: 350px;
  height: auto;
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#esg .cards .mini-box .icone {
  height: 28px;
}
#esg .cards .mini-box .numero {
  color: var(--tbl-secondary-secondary-500, #0084B5);
  font-family: "Revx Neue";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-top: 29px;
}
@media (max-width: 1400px) {
  #esg .cards .mini-box .numero {
    font-size: 25px;
  }
}
#esg .cards .mini-box .desc {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 18px;
  font-style: normal;
  line-height: 16px;
}
#esg .cards .mini-box:hover {
  background-color: #0084B5;
  transition: 0.3s ease-in-out;
}
#esg .cards .mini-box:hover img {
  filter: grayscale(100%) brightness(2);
  transition: 0.3s ease-in-out;
}
#esg .cards .mini-box:hover .numero,
#esg .cards .mini-box:hover .desc {
  color: #fff;
  transition: 0.3s ease-in-out;
}
#esg .slider-section {
  position: relative;
  margin-top: 70px;
}
#esg .slider-section .slider.center .cl {
  height: 544px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
@media (max-width: 991px) {
  #esg .slider-section .slider.center .cl {
    height: 415px;
  }
}
#esg .slider-section .slider.center .cl .content {
  width: 100%;
  height: 100%;
  position: relative;
}
#esg .slider-section .slider.center .cl .content .texto {
  position: absolute;
  bottom: 0;
  left: 43px;
  max-width: 339px;
  z-index: 2;
  height: 100%;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 43px;
}
@media (max-width: 991px) {
  #esg .slider-section .slider.center .cl .content .texto {
    left: 15px;
  }
}
#esg .slider-section .slider.center .cl .content .texto div {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
@media (max-width: 991px) {
  #esg .slider-section .slider.center .cl .content .texto div {
    gap: 15px;
  }
}
#esg .slider-section .slider.center .cl .content .texto .text-top {
  margin: 0;
  color: #D9D9D9;
  font-family: "Revx Neue";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 160% */
  display: none;
}
@media (max-width: 991px) {
  #esg .slider-section .slider.center .cl .content .texto .text-top {
    padding-inline: 17.5px;
  }
}
#esg .slider-section .slider.center .cl .content .texto h3 {
  color: #FFF;
  font-family: "Revx Neue";
  font-size: 130px;
  font-style: normal;
  font-weight: 700;
  line-height: 130px;
  /* 100% */
  text-decoration: none;
  position: absolute;
  bottom: 50px;
  left: 90px;
}
#esg .slider-section .slider.center .cl .content .texto p {
  color: #F5F5F5;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  display: none;
}
@media (max-width: 991px) {
  #esg .slider-section .slider.center .cl .content .texto p {
    font-size: 14px;
    line-height: 18px;
    padding-inline: 17.5px;
  }
}
#esg .slider-section .slider.center .cl .content .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--tbl-gradients-effects-gradient-linear, linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%));
  mix-blend-mode: multiply;
}
#esg .slider-section .slider.center .slick-active {
  padding: 30px 0;
}
#esg .slider-section .slider.center .slick-current .texto h3 {
  color: #FFF;
  font-family: "Revx Neue";
  font-size: 90px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 90px !important;
  /* 100% */
  text-decoration: underline #00AEEF !important;
  position: relative !important;
  bottom: 0 !important;
  left: 0 !important;
  text-decoration-thickness: 3px !important;
}
@media (max-width: 991px) {
  #esg .slider-section .slider.center .slick-current .texto h3 {
    font-size: 58.205px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 58.205px !important;
    /* 100% */
    padding-inline: 17.5px;
  }
}
#esg .slider-section .slider.center .slick-current .texto p {
  display: block !important;
}
#esg .slider-section .slider.center .slick-current .texto .text-top {
  display: block !important;
}
#esg .slider-section .slider.center .slick-current .cl .content .layer {
  background: var(--TBL-Gradients-Effects-gradienteDiagonal, linear-gradient(260deg, #000 -58.46%, rgba(0, 0, 0, 0) 124.89%));
}
#esg .slider-section .slider.center .slick-center {
  transform: scale(1.1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 99;
  position: relative;
  max-width: 540px;
}
#esg .slider-section .slider.center .slick-center::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #0084B5;
  bottom: 34px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
#esg .slider-section .slider.center .slick-slide:not(.slick-active) {
  margin: 30px 0;
}
#esg .slider-section .slider.center .child {
  width: 100%;
}
#esg .slider-section .slider.center .slide:not(.slick-active) {
  cursor: pointer;
}
#esg .slider-section .slider.center .layer-blue {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0) 100%);
}
#esg .slider-section .slider.center-first .cl {
  height: 544px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
@media (max-width: 991px) {
  #esg .slider-section .slider.center-first .cl {
    height: 415px;
  }
}
#esg .slider-section .slider.center-first .cl .content {
  width: 100%;
  height: 100%;
  position: relative;
}
#esg .slider-section .slider.center-first .cl .content .texto {
  position: absolute;
  bottom: 0;
  left: 43px;
  max-width: 339px;
  z-index: 2;
  height: 100%;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 43px;
}
@media (max-width: 991px) {
  #esg .slider-section .slider.center-first .cl .content .texto {
    left: 15px;
  }
}
#esg .slider-section .slider.center-first .cl .content .texto div {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
@media (max-width: 991px) {
  #esg .slider-section .slider.center-first .cl .content .texto div {
    gap: 15px;
  }
}
#esg .slider-section .slider.center-first .cl .content .texto .text-top {
  margin: 0;
  color: #D9D9D9;
  font-family: "Revx Neue";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 160% */
  display: none;
}
@media (max-width: 991px) {
  #esg .slider-section .slider.center-first .cl .content .texto .text-top {
    padding-inline: 17.5px;
  }
}
#esg .slider-section .slider.center-first .cl .content .texto h3 {
  color: #FFF;
  font-family: "Revx Neue";
  font-size: 130px;
  font-style: normal;
  font-weight: 700;
  line-height: 130px;
  /* 100% */
  text-decoration: none;
  position: absolute;
  bottom: 50px;
  left: 90px;
}
#esg .slider-section .slider.center-first .cl .content .texto p {
  color: #F5F5F5;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  display: none;
}
@media (max-width: 991px) {
  #esg .slider-section .slider.center-first .cl .content .texto p {
    font-size: 14px;
    line-height: 18px;
    padding-inline: 17.5px;
  }
}
#esg .slider-section .slider.center-first .cl .content .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--tbl-gradients-effects-gradient-linear, linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%));
  mix-blend-mode: multiply;
}
#esg .slider-section .slider.center-first .slick-active {
  padding: 30px 0;
}
#esg .slider-section .slider.center-first .slick-current .texto h3 {
  color: #FFF;
  font-family: "Revx Neue";
  font-size: 90px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 90px !important;
  /* 100% */
  text-decoration: underline #00AEEF !important;
  position: relative !important;
  bottom: 0 !important;
  left: 0 !important;
  text-decoration-thickness: 3px !important;
}
@media (max-width: 991px) {
  #esg .slider-section .slider.center-first .slick-current .texto h3 {
    font-size: 58.205px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 58.205px !important;
    /* 100% */
    padding-inline: 17.5px;
  }
}
#esg .slider-section .slider.center-first .slick-current .texto p {
  display: block !important;
}
#esg .slider-section .slider.center-first .slick-current .texto .text-top {
  display: block !important;
}
#esg .slider-section .slider.center-first .slick-current .cl .content .layer {
  background: var(--TBL-Gradients-Effects-gradienteDiagonal, linear-gradient(260deg, #000 -58.46%, rgba(0, 0, 0, 0) 124.89%));
}
#esg .slider-section .slider.center-first .slick-center {
  transform: scale(1.1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 99;
  position: relative;
  max-width: 540px;
}
#esg .slider-section .slider.center-first .slick-center::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #0084B5;
  bottom: 34px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
#esg .slider-section .slider.center-first .slick-slide:not(.slick-active) {
  margin: 30px 0;
}
#esg .slider-section .slider.center-first .child {
  width: 100%;
}
#esg .slider-section .slider.center-first .slide:not(.slick-active) {
  cursor: pointer;
}
#esg .slider-section .slider.center-first .layer-blue {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0) 100%);
}
#esg .slider-section .center-prev,
#esg .slider-section .center-next,
#esg .slider-section .slider-1-next,
#esg .slider-section .slider-1-prev,
#esg .slider-section .slider-2-next,
#esg .slider-section .slider-2-prev,
#esg .slider-section .slider-3-next,
#esg .slider-section .slider-3-prev {
  position: absolute;
  top: calc(50% - 64px);
  cursor: pointer;
  z-index: 2;
}
@media (max-width: 991px) {
  #esg .slider-section .center-prev,
#esg .slider-section .center-next,
#esg .slider-section .slider-1-next,
#esg .slider-section .slider-1-prev,
#esg .slider-section .slider-2-next,
#esg .slider-section .slider-2-prev,
#esg .slider-section .slider-3-next,
#esg .slider-section .slider-3-prev {
    display: none;
  }
}
#esg .slider-section .center-prev,
#esg .slider-section .slider-1-prev,
#esg .slider-section .slider-2-prev,
#esg .slider-section .slider-3-prev {
  left: 88px;
}
#esg .slider-section .center-next,
#esg .slider-section .slider-1-next,
#esg .slider-section .slider-2-next,
#esg .slider-section .slider-3-next {
  right: 88px;
}
#esg .slider-section .first-prev,
#esg .slider-section .first-next {
  position: absolute;
  top: calc(50% - 64px);
  cursor: pointer;
  z-index: 2;
}
@media (max-width: 991px) {
  #esg .slider-section .first-prev,
#esg .slider-section .first-next {
    display: none;
  }
}
#esg .slider-section .first-prev {
  left: 88px;
}
#esg .slider-section .first-next {
  right: 88px;
}
#esg .slider-section .layer-blue {
  position: absolute;
  width: 476px;
  height: calc(100% - 64px);
  top: 30px;
  background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0) 100%);
  z-index: 1;
}
@media (max-width: 991px) {
  #esg .slider-section .layer-blue {
    display: none;
  }
}
#esg .slider-section .layer-blue-second {
  position: absolute;
  width: 476px;
  height: calc(100% - 64px);
  top: 30px;
  right: 0;
  background: linear-gradient(90deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%);
  z-index: 1;
}
@media (max-width: 991px) {
  #esg .slider-section .layer-blue-second {
    display: none;
  }
}
#esg .ambiental {
  padding: 0 0 0px 77px;
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
}
#esg .ambiental-container {
  gap: 133px;
}
#esg .ambiental-container--text {
  max-width: 621px;
  padding-top: 200px;
}
@media (max-width: 991px) {
  #esg .ambiental-container--text {
    margin-top: 0px;
    padding-top: 0;
  }
}
#esg .ambiental-container--text .divider {
  height: 4px;
  width: 63px;
  background: #00AEEF;
  margin-bottom: 35px;
}
#esg .ambiental-container--text h2 {
  color: #383838;
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  max-width: 268px;
  margin-bottom: 26px;
}
#esg .ambiental-container--text h2 strong {
  font-family: inherit;
  font-weight: 700;
}
#esg .ambiental-container--text .text-container p {
  color: var(--tbl-default-black, #383838);
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#esg .ambiental-container--text .text-container p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
}
#esg .ambiental-container--text .text-container p + p {
  margin-top: 26px;
}
#esg .ambiental-container--text .text-container a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  margin-top: 56px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#esg .ambiental-container--text .text-container a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#esg .ambiental-container--text .text-container a:hover::after {
  transform: scaleY(1);
}
#esg .ambiental-container--img {
  height: fit-content;
  z-index: -1;
}
#esg .ambiental-container--img .listras {
  right: 0;
  bottom: -16px;
}
#esg .ambiental .mask-text {
  top: -135px;
  left: 112px;
  width: min-content;
  overflow: hidden;
  height: 250px;
}
#esg .ambiental .mask-text h3 {
  font-family: "Revx Neue", sans-serif;
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  /* 40% */
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* width and color of the stroke */
  text-stroke: 2px #00AEEF;
  /* Standard syntax, if supported */
  background-clip: text;
  position: relative;
  bottom: -80px;
  left: 0;
  filter: blur(0);
}
#esg .ambiental-video {
  margin-bottom: 166px;
}
#esg .ambiental-video .bg-blue {
  left: 0;
  bottom: 0;
  background: var(--tbl-secondary-secondary-500, #0084B5);
  width: min(948px, 50vw);
  height: 546px;
  z-index: -1;
}
#esg .ambiental-video .video-wrapper {
  display: grid;
  place-items: center;
  background-color: #F5F5F5;
  border-radius: 22px;
  padding: 84px;
  translate: 0 -108px;
}
#esg .ambiental-video .video-wrapper .video {
  border-radius: 8px;
  object-fit: cover;
}
#esg .ambiental-video .video-wrapper .play-icon {
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  z-index: 2;
}
#esg .social {
  padding: 0 77px 0px 0;
  display: flex;
  flex-wrap: wrap;
}
#esg .social-container {
  gap: 111px;
}
#esg .social-container--text {
  max-width: 621px;
  padding-top: 200px;
}
@media (max-width: 991px) {
  #esg .social-container--text {
    margin-top: 0px;
    padding-top: 0;
  }
}
#esg .social-container--text .divider {
  height: 4px;
  width: 63px;
  background: #00AEEF;
  margin-bottom: 35px;
}
#esg .social-container--text h2 {
  color: #383838;
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  max-width: 268px;
  margin-bottom: 26px;
}
#esg .social-container--text h2 strong {
  font-family: inherit;
  font-weight: 700;
}
#esg .social-container--text .text-container p {
  color: var(--tbl-default-black, #383838);
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#esg .social-container--text .text-container p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
}
#esg .social-container--text .text-container p + p {
  margin-top: 26px;
}
#esg .social-container--text .text-container a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  margin-top: 56px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#esg .social-container--text .text-container a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#esg .social-container--text .text-container a:hover::after {
  transform: scaleY(1);
}
#esg .social-container--img {
  height: fit-content;
  z-index: -1;
}
#esg .social-container--img .listras {
  left: 0;
  bottom: -20px;
  transform: rotate(180deg);
}
#esg .social .mask-text {
  top: -121px;
  right: 0;
  width: min-content;
  overflow: hidden;
  height: 250px;
}
#esg .social .mask-text h3 {
  font-family: "Revx Neue", sans-serif;
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  /* 40% */
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* width and color of the stroke */
  text-stroke: 2px #00AEEF;
  /* Standard syntax, if supported */
  background-clip: text;
  position: relative;
  bottom: -80px;
  left: 0;
  filter: blur(0);
}
#esg .compliance {
  padding: 0 0 0px 77px;
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
}
@media (max-width: 991px) {
  #esg .compliance {
    padding-bottom: 0;
  }
}
#esg .compliance-container {
  gap: 138px;
}
#esg .compliance-container--text {
  max-width: 621px;
  padding-top: 200px;
}
@media (max-width: 991px) {
  #esg .compliance-container--text {
    margin-top: 0px;
    padding-top: 0;
  }
}
#esg .compliance-container--text .divider {
  height: 4px;
  width: 63px;
  background: #00AEEF;
  margin-bottom: 35px;
}
#esg .compliance-container--text h2 {
  color: #383838;
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  max-width: 268px;
  margin-bottom: 26px;
}
#esg .compliance-container--text h2 strong {
  font-family: inherit;
  font-weight: 700;
}
#esg .compliance-container--text .text-container p {
  color: var(--tbl-default-black, #383838);
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#esg .compliance-container--text .text-container p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
}
#esg .compliance-container--text .text-container p + p {
  margin-top: 26px;
}
#esg .compliance-container--text .text-container a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  margin-top: 56px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#esg .compliance-container--text .text-container a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#esg .compliance-container--text .text-container a:hover::after {
  transform: scaleY(1);
}
#esg .compliance-container--img {
  height: fit-content;
  z-index: -1;
}
#esg .compliance-container--img .listras {
  right: 0;
  bottom: -16px;
}
#esg .compliance .mask-text {
  top: -137px;
  left: 80px;
  width: min-content;
  overflow: hidden;
  height: 250px;
}
#esg .compliance .mask-text h3 {
  font-family: "Revx Neue", sans-serif;
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  /* 40% */
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* width and color of the stroke */
  text-stroke: 2px #00AEEF;
  /* Standard syntax, if supported */
  background-clip: text;
  position: relative;
  bottom: -80px;
  left: 0;
  filter: blur(0);
}
#esg .cta-sobre {
  padding: 132.5px 321px 132.5px 77px;
}
#esg .cta-sobre > img {
  inset: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: -2;
}
#esg .cta-sobre::after {
  content: "";
  position: absolute;
  inset: 0;
  background: var(--tbl-gradients-effects-gradiente-diagonal, linear-gradient(260deg, #000 -13.46%, rgba(0, 0, 0, 0) 78.89%));
  z-index: -1;
}
#esg .cta-sobre-container {
  justify-content: end;
}
#esg .cta-sobre-container--text {
  max-width: 408px;
  color: #FFF;
}
#esg .cta-sobre-container--text h2 {
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  /* 111.111% */
  max-width: 321px;
}
#esg .cta-sobre-container--text h2 span {
  font-family: inherit;
  font-weight: 400;
}
#esg .cta-sobre-container--text .text-container p {
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-block: 30px 56px;
}
#esg .cta-sobre-container--text .text-container a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  margin-top: 56px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#esg .cta-sobre-container--text .text-container a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#esg .cta-sobre-container--text .text-container a:hover::after {
  transform: scaleY(1);
}
#esg .cta-sobre .lines-detail {
  left: 0;
  bottom: 90px;
  top: auto;
  max-width: 765px;
  max-height: 230px;
}
@media (width < 991px) {
  #esg .ambiental {
    padding: 0 0 0 0;
  }
  #esg .ambiental-container {
    flex-direction: column-reverse;
    gap: 0;
  }
  #esg .ambiental-container--text {
    max-width: 100%;
    padding-inline: 25px;
  }
  #esg .ambiental-container--text h2 {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 32px;
    max-width: 167px;
  }
  #esg .ambiental-container--text .text-container p {
    font-size: 14px;
    line-height: 18px;
  }
  #esg .ambiental-container--text .text-container p + p {
    margin-top: 20px;
  }
  #esg .ambiental-container--text .text-container a {
    min-width: min(100%, 600px);
    margin-inline: auto;
    font-size: 14px;
    line-height: normal;
  }
  #esg .ambiental-container--img {
    padding-right: 25px;
  }
  #esg .ambiental .mask-text {
    position: static !important;
    height: fit-content;
    text-align: center;
    width: 100%;
    margin-block: 10px 40px;
  }
  #esg .ambiental .mask-text h3 {
    font-size: 14vw;
    max-width: 100%;
    position: static;
  }
}
@media (width < 991px) and (width < 400px) {
  #esg .ambiental .mask-text h3 {
    font-size: 64px;
  }
}
@media (width < 991px) and (width < 310px) {
  #esg .ambiental .mask-text h3 {
    font-size: 55px;
  }
}
@media (width < 991px) {
  #esg .ambiental-video {
    margin-bottom: 44px;
    padding-inline: 25px;
  }
  #esg .ambiental-video .bg-blue {
    height: 240px;
    width: min(306px, 90vw);
  }
  #esg .ambiental-video .video-wrapper {
    padding: 20px;
    border-radius: 8px;
    max-width: min(500px, 100%);
    translate: 0 -90px;
  }
  #esg .ambiental-video .video-wrapper .video {
    height: 213px;
  }
  #esg .ambiental-video .play-icon {
    max-width: 40px;
    aspect-ratio: 1;
  }
}
@media (width < 991px) {
  #esg .social {
    padding: 0 0 60px 25px;
    padding-bottom: 0;
  }
  #esg .social-container {
    flex-direction: column-reverse !important;
    gap: 0;
  }
  #esg .social-container--text {
    max-width: 100%;
    padding-right: 25px;
  }
  #esg .social-container--text h2 {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 32px;
    max-width: 167px;
  }
  #esg .social-container--text .text-container p {
    font-size: 14px;
    line-height: 18px;
  }
  #esg .social-container--text .text-container p + p {
    margin-top: 20px;
  }
  #esg .social-container--text .text-container a {
    min-width: min(100%, 600px);
    margin-inline: auto;
    font-size: 14px;
    line-height: normal;
  }
  #esg .social .mask-text {
    position: static !important;
    height: fit-content;
    text-align: end;
    width: 100%;
    padding-inline-end: 15px;
    margin-block: 12px 0px;
  }
  #esg .social .mask-text h3 {
    font-size: 14vw;
    max-width: 100%;
    position: static;
  }
}
@media (width < 991px) {
  #esg .compliance {
    padding: 0 0 60px 0;
  }
  #esg .compliance-container {
    flex-direction: column-reverse;
    gap: 0;
  }
  #esg .compliance-container--text {
    max-width: 100%;
    padding-inline: 25px;
  }
  #esg .compliance-container--text h2 {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 32px;
    max-width: 167px;
  }
  #esg .compliance-container--text .text-container p {
    font-size: 14px;
    line-height: 18px;
  }
  #esg .compliance-container--text .text-container p + p {
    margin-top: 20px;
  }
  #esg .compliance-container--text .text-container a {
    min-width: min(100%, 600px);
    margin-inline: auto;
    font-size: 14px;
    line-height: normal;
  }
  #esg .compliance-container--img {
    padding-right: 25px;
  }
  #esg .compliance .mask-text {
    position: static !important;
    height: fit-content;
    text-align: center;
    width: 100%;
    margin-block: 12px 0px;
  }
  #esg .compliance .mask-text h3 {
    font-size: 14vw;
    max-width: 100%;
    position: static;
  }
}
@media (width < 991px) and (width < 435px) {
  #esg .compliance .mask-text h3 {
    font-size: 14vw;
  }
}
@media (width < 991px) and (width < 350px) {
  #esg .compliance .mask-text h3 {
    font-size: 55px;
  }
}
@media (width < 991px) {
  #esg .listras {
    display: none;
  }
}

#cardProfessionals {
  max-width: min(1270px, 100%);
  position: relative;
  z-index: 3;
}
#cardProfessionals .card-professionals-container {
  gap: 0 8px;
  margin-block: -198px 157px;
  isolation: isolate;
}
@media (max-width: 1600px) {
  #cardProfessionals .card-professionals-container {
    margin-block: -102px 157px;
  }
}
@media (max-width: 1100px) {
  #cardProfessionals .card-professionals-container {
    overflow-y: scroll;
  }
}
#cardProfessionals .card-professionals-container a {
  text-decoration: none;
}
#cardProfessionals .card-professionals-container .card-professional {
  padding: 34px 47px;
  color: #F5F5F5;
  height: 311px;
  max-width: 418px;
  mix-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
#cardProfessionals .card-professionals-container .card-professional .conteudo {
  position: unset;
  z-index: 2;
}
#cardProfessionals .card-professionals-container .card-professional::after, #cardProfessionals .card-professionals-container .card-professional::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 0;
  background: linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%);
  mix-blend-mode: multiply;
  transition: all 0.3s ease-in;
}
#cardProfessionals .card-professionals-container .card-professional:hover {
  transition: all 0.3s ease-in;
  margin-top: -15px;
  cursor: pointer;
}
#cardProfessionals .card-professionals-container .card-professional:hover ::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%);
  transition: all 0.3s ease-in;
}
#cardProfessionals .card-professionals-container .card-professional-img {
  inset: 0;
  object-fit: cover;
  height: 100%;
  z-index: -2;
}
#cardProfessionals .card-professionals-container .card-professional-title {
  font-family: "Revx Neue", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 100% */
  margin-bottom: 11px;
}
#cardProfessionals .card-professionals-container .card-professional-text {
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#cardProfessionals .card-professionals-container .card-professional-title, #cardProfessionals .card-professionals-container .card-professional-text {
  z-index: 2;
}
@media (width < 991px) {
  #cardProfessionals {
    max-width: 100%;
  }
  #cardProfessionals .card-professionals-container {
    margin-block: 32px 44px !important;
    margin-inline: 0 !important;
  }
  #cardProfessionals .card-professionals-container .card-professional {
    padding: 28.5px 26.5px;
    height: 252px;
    width: 341px;
  }
  #cardProfessionals .card-professionals-container .card-professional-text {
    font-size: 14px;
    line-height: 18px;
  }
}

html {
  scroll-behavior: smooth;
}

#contact {
  overflow: hidden;
}
#contact .mask-text {
  margin-block: 45px 120px;
  text-align: end;
  height: fit-content;
}
#contact .mask-text h3 {
  font-family: "Revx Neue", sans-serif;
  font-size: 13vw;
  font-style: normal;
  font-weight: 700;
  line-height: 120px;
  line-clamp: 1;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* width and color of the stroke */
  text-stroke: 2px #00AEEF;
  /* Standard syntax, if supported */
  background-clip: text;
  position: relative;
  bottom: -50px;
  left: auto;
  right: 0;
  filter: blur(0);
}
@media (width < 991px) {
  #contact .mask-text h3 {
    font-size: 90px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
  }
}
#contact .form-contato-container {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
#contact .form-contato-container--text .divider {
  height: 4px;
  width: 63px;
  background: #00AEEF;
  margin-bottom: 35px;
}
#contact .form-contato-container--text h2 {
  color: #383838;
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  max-width: 200px;
  margin-block-end: 26px;
}
#contact .form-contato-container--text h2 strong {
  font-family: inherit;
  font-weight: 700;
}
#contact .form-contato-container--text .content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#contact .form-contato-container--text .content-container .text {
  max-width: 521px;
}
#contact .form-contato-container--text .content-container .text p {
  color: var(--tbl-default-black, #383838);
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#contact .form-contato-container--text .content-container .select-wrapper {
  width: 406px;
}
#contact .form-contato-container--text .content-container .select-wrapper .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-color: var(--tbl-primary-primary-500, #00AEEF);
  background-position: right 24px center;
  background-size: 24px;
  padding: 24px 32px;
  border: none;
  border-radius: 0 !important;
  text-align: start;
  width: 100%;
}
#contact .form-contato-container--text .content-container .select-wrapper .form-select, #contact .form-contato-container--text .content-container .select-wrapper .form-select::placeholder {
  color: var(--bertolini-white, #FFF);
  font-family: inherit;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 17px */
}
#contact .form-contato-container--text .content-container .select-wrapper .options {
  padding: 14px 0;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  background-color: #006083;
}
#contact .form-contato-container--text .content-container .select-wrapper .options:not(:last-child) {
  border-bottom: 1px solid #339DC4;
}
#contact .form-contato-container--forms .form-container .form-row + .form-row {
  margin-top: 20px;
}
#contact .form-contato-container--forms .form-container .form-row {
  gap: 32px;
}
#contact .form-contato-container--forms .form-container .form-row > div:nth-child(1) {
  flex-grow: 1;
}
#contact .form-contato-container--forms .form-container .form-row > div:nth-child(2) {
  width: min(100%, 406px) !important;
}
#contact .form-contato-container--forms .form-container .form-row .form-label {
  color: var(--tbl-default-black, #383838);
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
  margin-bottom: 12px;
}
#contact .form-contato-container--forms .form-container .form-row .form-control {
  padding: 24px 32px;
  background: var(--tbl-default-off-white, #F5F5F5);
  min-width: 100%;
  border: none;
  border-radius: 0;
}
#contact .form-contato-container--forms .form-container .form-row .form-control, #contact .form-contato-container--forms .form-container .form-row .form-control::placeholder {
  color: var(--tbl-neutral-neutral-300, #898989);
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
#contact .form-contato-container--forms .form-container .form-row .form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 133, 181, 0.4588235294);
}
#contact .form-contato-container--forms .form-container .form-row .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-color: var(--tbl-default-off-white, #F5F5F5);
  background-position: right 24px center;
  background-repeat: no-repeat;
  background-size: 24px;
}
#contact .form-contato-container--forms .form-container .form-row textarea {
  resize: none;
  width: 100%;
  height: 200px;
}
#contact .form-contato-container--forms .form-container .form-check {
  display: flex;
  align-items: center;
  gap: 8px;
}
#contact .form-contato-container--forms .form-container .form-check .form-check-input {
  border-radius: 1px;
  border: 1px solid var(--tbl-primary-primary-500, #00AEEF);
  width: 24px;
  height: 24px;
  box-shadow: 0px 10px 32px 0px rgba(0, 0, 0, 0.1);
}
#contact .form-contato-container--forms .form-container .form-check .form-check-input:checked {
  background-color: #00AEEF;
}
#contact .form-contato-container--forms .form-container .form-check .form-check-input:focus {
  outline: 0;
}
#contact .form-contato-container--forms .form-container .form-check .form-check-label {
  color: var(--tbl-neutral-neutral-300, #898989);
  font-family: "Revx Neue", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 100% */
}
#contact .form-contato-container--forms .form-container .btn-submit {
  margin-top: 80px;
}
#contact .form-contato-container--forms .form-container .btn-submit a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  margin-top: 50px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#contact .form-contato-container--forms .form-container .btn-submit a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#contact .form-contato-container--forms .form-container .btn-submit a:hover::after {
  transform: scaleY(1);
}
@media (width < 991px) {
  #contact .form-contato {
    gap: 56px;
  }
  #contact .form-contato .container {
    margin: 0;
    padding: 0;
    max-width: 100%;
  }
  #contact .form-contato-container--text h2,
#contact .form-contato-container--text .divider {
    margin-inline: 25px;
  }
  #contact .form-contato-container--text h2 {
    font-size: 28px;
    line-height: 28px;
    max-width: 120px;
  }
  #contact .form-contato-container--text h2 strong {
    font-size: 28px;
    line-height: 28px;
  }
  #contact .form-contato-container--text .content-container {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 40px;
  }
  #contact .form-contato-container--text .content-container .text {
    max-width: 100%;
    padding-inline: 25px;
  }
  #contact .form-contato-container--text .content-container .text p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  #contact .form-contato-container--text .content-container .select-wrapper {
    width: 100%;
    margin-inline: auto;
  }
  #contact .form-contato-container--text .content-container .select-wrapper .form-select {
    font-size: 14px;
    line-height: 100%;
  }
  #contact .form-contato-container--forms {
    padding-inline: 25px;
  }
  #contact .form-contato-container--forms .form-container .form-row {
    flex-direction: column;
  }
  #contact .form-contato-container--forms .form-container .form-row > div:nth-child(2) {
    width: 100% !important;
  }
  #contact .form-contato-container--forms .form-container .form-check {
    gap: 20px;
  }
  #contact .form-contato-container--forms .form-container .form-check-label {
    font-size: 12px;
    line-height: 16px;
  }
  #contact .form-contato-container--forms .form-container .btn-submit {
    margin-top: 40px;
  }
  #contact .form-contato-container--forms .form-container .btn-submit a,
#contact .form-contato-container--forms .form-container .btn-submit button {
    width: 100%;
  }
  #contact .form-contato-container--forms .form-container .btn-submit a {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0;
  }
}
#contact .unidades .mask-text {
  inset: 170px auto auto 0;
}
#contact .unidades .mask-text h3 {
  font-family: "Revx Neue", sans-serif;
  font-size: 13vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  /* 40% */
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* width and color of the stroke */
  text-stroke: 2px #00AEEF;
  /* Standard syntax, if supported */
  background-clip: text;
  position: relative;
  bottom: 150px;
  left: 0;
  filter: blur(0);
}
@media (min-width: 1900px) {
  #contact .unidades .mask-text h3 {
    gap: -50px;
  }
}
#contact .unidades-container {
  display: flex;
  justify-content: end;
  gap: 97.5px;
  padding-left: 207px;
}
@media (max-width: 1399px) {
  #contact .unidades-container {
    padding-top: 100px;
  }
}
#contact .unidades-container--text {
  width: 434px;
  align-self: center;
}
#contact .unidades-container--text .divider {
  height: 4px;
  width: 63px;
  background: #00AEEF;
  margin-bottom: 35px;
}
#contact .unidades-container--text h2 {
  color: #383838;
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  max-width: 432px;
  margin-block-end: 44px;
}
#contact .unidades-container--text h2 strong {
  font-family: inherit;
  font-weight: 700;
}
#contact .unidades-container--text p {
  color: var(--tbl-default-black, #383838);
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 32px;
}
#contact .unidades-container--text .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-color: var(--tbl-primary-primary-500, #00AEEF);
  background-position: right 50px center;
  background-size: 24px;
  padding: 15px 50px;
  border: none;
  border-radius: 0 !important;
  color: var(--bertolini-white, #FFF);
  font-family: inherit;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 17px */
  width: 100%;
}
#contact .unidades-container--img,
#contact .unidades-container--img img {
  max-width: 100%;
  z-index: -1;
  transform: scale(0.7);
}
#contact .unidades-list {
  background: var(--tbl-default-off-white, #F5F5F5);
  display: flex;
  justify-content: start;
  padding-inline: 77px 168px;
  z-index: 2;
  transform: translateY(-207px);
}
#contact .unidades-list--container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 80px;
  padding-block: 84px;
  justify-content: space-between;
}
@media (max-width: 1600px) {
  #contact .unidades-list--container {
    gap: 15px;
  }
}
#contact .unidades-list--container .unidade-item {
  width: 400px;
  border-bottom: solid 2px #00AEEF;
  padding: 35px 0 20px 0;
  height: 100%;
  border-radius: 20px 0px;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 24px 28px;
  max-width: 358px;
  height: 220px;
  margin-top: 56px;
}
#contact .unidades-list--container .unidade-item--title {
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  font-family: "Revx Neue", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin-block: 32px;
}
#contact .unidades-list--container .unidade-item ul {
  width: 100%;
}
#contact .unidades-list--container .unidade-item ul li + li {
  margin-top: 12px;
}
#contact .unidades-list--container .unidade-item--info a {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
#contact .unidades-list--container .unidade-item--info a i,
#contact .unidades-list--container .unidade-item--info a ion-icon {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-size: 20px;
}
#contact .unidades-list--container .unidade-item .socials {
  display: flex;
  gap: 20px;
  margin-block-start: 40px;
}
#contact .unidades-list--container .unidade-item .socials a {
  display: flex;
  align-items: center;
  gap: 12px;
}
#contact .unidades-list--container .unidade-item .socials a i {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-size: 20px;
}
@media (width < 991px) {
  #contact .unidades .mask-text {
    inset: -40px auto auto 0;
    position: relative !important;
    margin-block: 64px 32px;
    width: fit-content;
    display: block;
    margin-left: auto;
  }
  #contact .unidades .mask-text h3 {
    font-size: 90px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    text-align: end;
    word-break: break-all;
    inset: 0 15px 0 auto !important;
    max-width: 280px;
  }
  #contact .unidades-container {
    flex-direction: column;
    gap: 0;
    padding: 0;
    justify-content: start;
  }
  #contact .unidades-container--text {
    width: 100%;
    padding-inline: 25px;
  }
  #contact .unidades-container--text h2 {
    font-size: 28px;
    line-height: 28px;
    max-width: 323px;
  }
  #contact .unidades-container--text h2 strong {
    font-size: 28px;
    line-height: 28px;
  }
  #contact .unidades-container--text p {
    font-size: 14px;
    line-height: 18px;
    max-width: 100%;
  }
  #contact .unidades-list {
    padding-inline: 25px;
  }
  #contact .unidades-list--container {
    padding-block: 0 60px;
    justify-content: center;
  }
}
#contact .duvidas {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  gap: 48px;
  padding: 207px 0 80px 77px;
  z-index: 99;
  position: relative;
}
#contact .duvidas .mask-text {
  inset: -351px auto auto 107px;
}
#contact .duvidas .mask-text h3 {
  font-family: "Revx Neue", sans-serif;
  font-size: 13vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  /* 40% */
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* width and color of the stroke */
  text-stroke: 2px #00AEEF;
  /* Standard syntax, if supported */
  background-clip: text;
  position: relative;
  bottom: -50px;
  left: 0;
  filter: blur(0);
}
#contact .duvidas-container {
  display: flex;
  justify-content: end;
  gap: 133px;
  max-width: 1598px;
}
#contact .duvidas-container--text {
  max-width: 621px;
  align-self: center;
  position: relative;
}
#contact .duvidas-container--text .divider {
  height: 4px;
  width: 63px;
  background: #00AEEF;
  margin-bottom: 35px;
}
#contact .duvidas-container--text h2 {
  color: #383838;
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  max-width: 235px;
  margin-block-end: 26px;
}
#contact .duvidas-container--text h2 strong {
  font-family: inherit;
  font-weight: 700;
}
#contact .duvidas-container--text .text-container p + p {
  margin-top: 26px;
}
#contact .duvidas-container--text .text-container p {
  color: var(--tbl-default-black, #383838);
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#contact .duvidas-container--text .text-container p strong {
  font-weight: 700;
  color: var(--tbl-primary-primary-500, #00AEEF);
}
#contact .duvidas-container--img {
  z-index: -1;
}
#contact .duvidas-container--img img {
  max-width: 100%;
}
#contact .duvidas-accordion-container {
  width: min(100%, 1597px);
  padding-inline-end: 77px;
}
#contact .duvidas-accordion-container .accordion-item {
  max-width: 1278px;
}
#contact .duvidas-accordion-container .accordion-button {
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  font-family: "Revx Neue", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 100% */
  padding-top: 32px;
  padding-bottom: 32px;
  width: 100%;
}
#contact .duvidas-accordion-container .accordion-button::after {
  background-size: contain;
  filter: invert(55%) sepia(71%) saturate(3790%) hue-rotate(164deg) brightness(101%) contrast(101%);
  margin-left: 32px;
  width: 32px;
  height: 20px;
}
#contact .duvidas-accordion-container .accordion-button:focus {
  box-shadow: none;
}
#contact .duvidas-accordion-container .accordion-button:not(.collapsed) {
  background: #fff;
  box-shadow: none;
  color: #00AEEF;
}
#contact .duvidas-accordion-container .text-accordion {
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#contact .duvidas-accordion-container .accordion-body {
  display: flex;
  gap: 32px;
  flex-direction: column;
  padding-bottom: 32px;
  border-bottom: 1px solid var(--bertolini-secondary-secondary-50, #E6F3F8);
}
#contact .duvidas .btn-container {
  padding-inline-end: 77px;
}
#contact .duvidas .btn-container a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  margin-top: 80px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#contact .duvidas .btn-container a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#contact .duvidas .btn-container a:hover::after {
  transform: scaleY(1);
}
@media (width < 991px) {
  #contact .duvidas {
    padding: 0 0 60px 25px;
  }
  #contact .duvidas .mask-text {
    inset: -40px auto auto 0;
    position: relative !important;
    margin-block: 64px 32px;
    width: fit-content;
    display: block;
    margin-left: auto;
  }
  #contact .duvidas .mask-text h3 {
    font-size: 90px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    text-align: end;
    inset: 0 15px 0 auto !important;
  }
  #contact .duvidas-container {
    flex-direction: column-reverse;
    gap: 0;
  }
  #contact .duvidas-container--text {
    max-width: 100%;
    align-self: start;
    padding-inline-end: 25px;
  }
  #contact .duvidas-container--text h2 {
    font-size: 28px;
    line-height: 28px;
    max-width: 147px;
    margin-bottom: 32px;
  }
  #contact .duvidas-container--text h2 strong {
    font-size: 28px;
    line-height: 28px;
  }
  #contact .duvidas-container--text .text-container p + p {
    margin-top: 20px;
  }
  #contact .duvidas-container--text .text-container p,
#contact .duvidas-container--text .text-container p > strong {
    font-size: 14px;
    line-height: 18px;
  }
  #contact .duvidas-accordion-container {
    padding-inline-end: 25px;
  }
  #contact .duvidas-accordion-container .accordion-button {
    padding-inline: 0 25px;
    justify-content: space-between;
  }
  #contact .duvidas-accordion-container .accordion-body {
    padding: 0 25px 20px 0;
  }
  #contact .duvidas .btn-container {
    padding-inline: 0 25px;
    width: 100%;
  }
  #contact .duvidas .btn-container a {
    margin-top: 0;
    width: 100%;
  }
}
#contact .unidades-local {
  padding: 84px 0;
  background: #F5F5F5;
  position: relative;
  z-index: 99;
  padding-top: 4.375vw;
}
@media (max-width: 1100px) {
  #contact .unidades-local {
    padding-bottom: 3rem;
  }
}
#contact .unidades-local .card-unidade {
  border-bottom: solid 2px #00AEEF;
  padding: 35px 0 20px 0;
  height: 100%;
  border-radius: 20px 0px;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 24px 28px;
  max-width: 358px;
  height: 200px;
  margin-top: 56px;
}
@media (max-width: 1100px) {
  #contact .unidades-local .card-unidade {
    padding-inline: 55px;
    padding-bottom: 3rem;
    height: auto;
  }
}
#contact .unidades-local .card-unidade a {
  text-decoration: none;
}
#contact .unidades-local .card-unidade h2 {
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  /* H5 - Description */
  font-family: "Revx Neue";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 100% */
  margin-bottom: 32px;
}
#contact .unidades-local .card-unidade p {
  display: flex;
  gap: 12px;
  color: var(--tbl-neutral-neutral-500, #4F4F4F);
  /* H6 - Text Body */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 12px;
}
#contact .unidades-local .redes {
  display: flex;
  gap: 20px;
  padding-top: 23px;
  border-top: 2px solid #fff;
  margin-top: 8px;
}
#contact .unidades-local .redes a {
  text-decoration: none;
}

#itucuma {
  overflow: hidden;
}
#itucuma .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 34px;
  position: relative;
}
#itucuma .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
}
#itucuma .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (width < 991px) {
  #itucuma .title-section, #itucuma .title-section strong {
    font-size: 28px;
    line-height: 28px;
  }
}
#itucuma .conheca-itucuma {
  position: relative;
}
#itucuma .conheca-itucuma .video {
  padding: 84px;
  position: relative;
  border-radius: 22px;
  background: #F5F5F5;
  z-index: 2;
}
#itucuma .conheca-itucuma .video .img-video {
  width: 100%;
  height: 456px;
  border-radius: 10px;
  object-fit: cover;
}
#itucuma .conheca-itucuma .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#itucuma .conheca-itucuma .quadrado {
  width: 839px;
  height: 783px;
  background: #849550;
  position: absolute;
  top: 186px;
  left: 0;
  z-index: 1;
}
@media (max-width: 1600px) {
  #itucuma .conheca-itucuma .quadrado {
    max-width: 45%;
  }
}
#itucuma .conheca-itucuma .texto-section {
  font-family: "Revx Neue", sans-serif;
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #849550;
  text-stroke: 2px #849550;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  bottom: -50px;
  left: 0;
}
@media (max-width: 1600px) {
  #itucuma .conheca-itucuma .texto-section {
    font-size: 12vw;
    bottom: -30px;
  }
}
#itucuma .conheca-itucuma .conteudo {
  margin-top: 70px;
}
#itucuma .conheca-itucuma .conteudo p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 20px;
}
#itucuma .conheca-itucuma .conteudo p strong {
  color: #00AEEF;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (width < 991px) {
  #itucuma .conheca-itucuma > .container {
    margin: 0;
    padding-inline: 25px;
    max-width: 100%;
  }
  #itucuma .conheca-itucuma > .container > .row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start !important;
    gap: 20px;
  }
  #itucuma .conheca-itucuma .video {
    padding: 20px;
    border-radius: 8px;
    max-width: min(100%, 500px);
    margin-inline: auto;
  }
  #itucuma .conheca-itucuma .video .img-video {
    border-radius: inherit;
    height: 213px;
  }
  #itucuma .conheca-itucuma .video .player img {
    max-width: 40px;
    aspect-ratio: 1;
    top: calc(50% - 18px);
  }
  #itucuma .conheca-itucuma .quadrado {
    width: min(306px, 80vw);
    height: 240px;
    top: auto;
    right: auto;
    bottom: -70px;
    left: 0;
  }
  #itucuma .conheca-itucuma .texto-section {
    font-size: 90px;
    line-height: 100%;
    max-width: 100%;
    bottom: -172px;
    left: 43px;
  }
  #itucuma .conheca-itucuma .conteudo p {
    font-size: 14px;
    line-height: 18px;
  }
}
#itucuma .videos {
  margin-top: 132px;
  position: relative;
  padding-bottom: 184px;
}
@media (width < 1400px) {
  #itucuma .videos {
    padding-bottom: 9.583vw;
  }
}
#itucuma .videos #myTab {
  margin-bottom: 80px;
}
#itucuma .videos .nav-tabs {
  border-bottom: none;
}
#itucuma .videos .nav-tabs .nav-item.show .nav-link,
#itucuma .videos .nav-tabs .nav-link.active {
  border: none;
  background-color: transparent;
}
#itucuma .videos .nav-tabs .nav-link:focus,
#itucuma .videos .nav-tabs .nav-link:hover {
  border: none;
}
#itucuma .videos .nav-tabs .nav-link {
  margin-bottom: 0;
  border: none;
  border-top-left-radius: none;
  border-top-right-radius: none;
}
#itucuma .videos .nav-link {
  padding: 40px;
  border-radius: 20px 0px;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  max-width: 406px;
  cursor: pointer;
}
#itucuma .videos .nav-link h4 {
  color: #849550;
  font-family: "Revx Neue", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 32px;
  text-align: center;
}
#itucuma .videos .nav-link p {
  color: #383838;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
#itucuma .videos .nav-link.active {
  background: #849550;
}
#itucuma .videos .nav-link.active h4,
#itucuma .videos .nav-link.active p {
  color: #fff;
}
#itucuma .videos .conteudo .video {
  padding: 84px;
  position: relative;
  border-radius: 22px;
  background: #F5F5F5;
  z-index: 2;
}
#itucuma .videos .conteudo .video .img-video {
  width: 100%;
  height: 456px;
  border-radius: 10px;
  object-fit: cover;
}
#itucuma .videos .conteudo .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#itucuma .videos .quadrado {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 948px;
  height: 622px;
  background: #849550;
  z-index: 1;
}
@media (width < 991px) {
  #itucuma .videos {
    margin-top: 212px;
    padding-bottom: 0;
  }
  #itucuma .videos > .container {
    padding-inline: 25px;
  }
  #itucuma .videos > .container #myTab {
    margin-bottom: 44px;
  }
  #itucuma .videos > .container #myTab .col-lg-4 + .col-lg-4 {
    margin-top: 20px;
  }
  #itucuma .videos > .container #myTab .col-lg-4 .nav-link {
    margin-inline: auto;
  }
  #itucuma .videos > .container .tab-content .video + .video {
    margin-top: 20px;
  }
  #itucuma .videos > .container .tab-content .video {
    padding: 20px;
    border-radius: 8px;
  }
  #itucuma .videos > .container .tab-content .video .img-video {
    border-radius: inherit;
    height: 213px;
  }
  #itucuma .videos > .container .tab-content .video .player img {
    max-width: 40px;
    aspect-ratio: 1;
    top: calc(50% - 18px);
  }
  #itucuma .videos > .container .tab-content .texto-section {
    font-size: 90px;
    line-height: 100%;
    max-width: 100%;
    bottom: -172px;
    left: 43px;
  }
  #itucuma .videos .nav-link h4 {
    font-size: 22px;
    line-height: 37px;
    margin-bottom: 32px;
  }
  #itucuma .videos .nav-link p {
    font-size: 14px;
    line-height: 18px;
  }
  #itucuma .videos .quadrado {
    width: min(306px, 80vw);
    height: 240px;
    top: auto;
    left: auto;
    bottom: -70px;
    right: 0;
  }
}
#itucuma .slides {
  margin-top: 195px;
  margin-bottom: 150px;
}
#itucuma .slides .title-section {
  text-align: center;
}
#itucuma .slides .title-section::after {
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #849550;
}
#itucuma .slides p {
  color: #4F4F4F;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 627px;
  margin: 0 auto;
}
#itucuma .slides .slider-section {
  position: relative;
  margin-top: 82px;
}
#itucuma .slides .slider-section .slider.center .cl {
  height: 544px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#itucuma .slides .slider-section .slider.center .cl .content {
  width: 100%;
  height: 100%;
  position: relative;
}
#itucuma .slides .slider-section .slider.center .cl .content .texto {
  position: absolute;
  bottom: 69px;
  left: 50px;
  max-width: 339px;
  z-index: 2;
}
#itucuma .slides .slider-section .slider.center .cl .content .texto h4 {
  color: #F5F5F5;
  font-family: "Revx Neue", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin: 20px 0;
}
#itucuma .slides .slider-section .slider.center .cl .content .texto p {
  color: #F5F5F5;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
}
#itucuma .slides .slider-section .slider.center .cl .content .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--tbl-gradients-effects-gradient-linear, linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #849550 100%));
  mix-blend-mode: multiply;
}
#itucuma .slides .slider-section .slider.center .slick-active {
  padding: 30px 0;
}
#itucuma .slides .slider-section .slider.center .slick-center {
  transform: scale(1.1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 99;
  position: relative;
  max-width: 540px;
}
#itucuma .slides .slider-section .slider.center .slick-center::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: transparent;
  bottom: 34px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
#itucuma .slides .slider-section .slider.center .slick-slide:not(.slick-active) {
  margin: 30px 0;
}
#itucuma .slides .slider-section .slider.center .child {
  width: 100%;
}
#itucuma .slides .slider-section .slider.center .slide:not(.slick-active) {
  cursor: pointer;
}
#itucuma .slides .slider-section .slider.center .layer-blue {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0) 100%);
}
#itucuma .slides .slider-section .center-prev,
#itucuma .slides .slider-section .center-next {
  position: absolute;
  top: calc(50% - 64px);
  cursor: pointer;
  z-index: 2;
}
#itucuma .slides .slider-section .center-prev {
  left: 88px;
}
#itucuma .slides .slider-section .center-next {
  right: 88px;
}
#itucuma .slides .slider-section .layer-green {
  position: absolute;
  width: 476px;
  height: calc(100% - 64px);
  top: 30px;
  background: linear-gradient(90deg, #849550 0%, rgba(0, 132, 181, 0) 100%);
  z-index: 1;
}
#itucuma .slides .slider-section .layer-green-second {
  position: absolute;
  width: 476px;
  height: calc(100% - 64px);
  top: 30px;
  right: 0;
  background: linear-gradient(90deg, rgba(0, 132, 181, 0) 0%, #849550 100%);
  z-index: 1;
}
@media (width < 991px) {
  #itucuma .slides {
    margin-top: 168px;
    margin-bottom: 60px;
  }
  #itucuma .slides p {
    font-size: 14px;
    line-height: 18px;
  }
  #itucuma .slides .slider-section {
    margin-top: 56px;
  }
  #itucuma .slides .slider-section .cl .texto {
    bottom: 40px !important;
    left: 33px !important;
  }
  #itucuma .slides .slider-section .cl .texto .icone > img {
    max-width: 28.5px;
    aspect-ratio: 1;
  }
  #itucuma .slides .slider-section .cl .texto h4 {
    font-size: 29.102px;
    line-height: 32.336px;
    margin-block: 11px 12px;
  }
  #itucuma .slides .layer-green,
#itucuma .slides .layer-green-second,
#itucuma .slides .center-prev,
#itucuma .slides .center-next {
    display: none;
  }
}
#itucuma .gente {
  position: relative;
  padding-top: 349px;
  padding-bottom: 114px;
}
@media (max-width: 1400px) {
  #itucuma .gente {
    padding-bottom: 5.938vw;
  }
}
#itucuma .gente .title-section {
  margin-bottom: 23px;
}
#itucuma .gente p {
  color: #383838;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 621px;
  margin-bottom: 26px;
}
#itucuma .gente p strong {
  color: #00AEEF;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
#itucuma .gente .video-section {
  position: absolute;
  width: min(43vw, 843px);
  height: 745px;
  top: 106px;
  right: 0;
}
#itucuma .gente .video-section .video {
  position: relative;
}
#itucuma .gente .video-section .video .img-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#itucuma .gente .video-section .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#itucuma .gente .video-section .video .linhas {
  position: absolute;
  right: 0;
  bottom: -26px;
}
#itucuma .gente .texto-section {
  font-family: "Revx Neue", sans-serif;
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #849550;
  text-stroke: 2px #849550;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: 41px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}
@media (width < 991px) {
  #itucuma .gente {
    padding-block: 60px !important;
  }
  #itucuma .gente > .container {
    margin: 0;
    max-width: 100%;
    padding: 0;
  }
  #itucuma .gente > .container > .row {
    display: flex;
    flex-direction: column-reverse;
    gap: 175px;
  }
  #itucuma .gente > .container > .row .col-lg-7 {
    padding-inline: 25px;
  }
  #itucuma .gente p {
    font-size: 14px;
    line-height: 18px;
    max-width: 100%;
  }
  #itucuma .gente .video-section {
    position: relative;
    padding: 0 0 0 25px;
    top: 0;
    width: min(100%, 400px);
    height: min(100%, 350px);
    align-self: end;
  }
  #itucuma .gente .video-section .video {
    width: 100%;
    height: 100%;
  }
  #itucuma .gente .video-section .video img {
    width: 100%;
    height: 100%;
  }
  #itucuma .gente .video-section .video .player img {
    max-width: 60px;
    max-height: 60px;
  }
  #itucuma .gente .video-section .video .linhas {
    display: none;
  }
  #itucuma .gente .texto-section {
    font-size: 90px;
    line-height: 80%;
    top: 446px;
    left: auto;
    right: 0;
    word-wrap: break-word;
    text-align: end;
  }
}
#itucuma .sustentabilidade {
  padding-top: 157px;
  padding-bottom: 205px;
  background-color: #F5F5F5;
}
@media (max-width: 1400px) {
  #itucuma .sustentabilidade {
    padding-bottom: 10.677vw;
  }
}
#itucuma .sustentabilidade .title-section {
  text-align: center;
  margin-bottom: 29px;
}
#itucuma .sustentabilidade .title-section::after {
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #849550;
}
#itucuma .sustentabilidade p {
  color: #4F4F4F;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 627px;
  margin: 0 auto;
}
#itucuma .sustentabilidade .container-fluid {
  max-width: 1521px;
}
#itucuma .sustentabilidade .blocos {
  margin-top: 80px;
}
#itucuma .sustentabilidade .blocos .row {
  gap: 30px;
}
#itucuma .sustentabilidade .blocos .card {
  border: none;
  max-width: 280px;
  height: 250px;
  border-radius: 20px 0px;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding-left: 40px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
#itucuma .sustentabilidade .blocos .card h4 {
  color: #5E6A39;
  font-family: "Revx Neue", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-top: 14px;
  margin-bottom: 20px;
  text-align: center;
}
#itucuma .sustentabilidade .blocos .card p {
  color: #383838;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 240px;
}
#itucuma .sustentabilidade .blocos .card:hover {
  background-color: #5E6A39;
  transition: 0.3s ease-in-out;
}
#itucuma .sustentabilidade .blocos .card:hover img {
  filter: grayscale(100%) brightness(2);
  transition: 0.3s ease-in-out;
}
#itucuma .sustentabilidade .blocos .card:hover h4,
#itucuma .sustentabilidade .blocos .card:hover p {
  color: #fff;
  transition: 0.3s ease-in-out;
}
@media (width < 991px) {
  #itucuma .sustentabilidade {
    padding-top: 98px;
    padding-bottom: 60px;
  }
  #itucuma .sustentabilidade .title-section {
    margin-bottom: 20px;
  }
  #itucuma .sustentabilidade p {
    font-size: 14px;
    line-height: 18px;
  }
  #itucuma .sustentabilidade .blocos {
    margin-top: 44px;
  }
  #itucuma .sustentabilidade .blocos .row {
    flex-direction: column;
    gap: 20px;
  }
  #itucuma .sustentabilidade .blocos .card {
    height: 203px;
    max-width: min(100%, 340px);
    margin-inline: auto;
    padding: 32px;
  }
  #itucuma .sustentabilidade .blocos .card h4 {
    font-size: 25px;
    line-height: 37px;
  }
  #itucuma .sustentabilidade .blocos .card p {
    font-size: 14px;
    line-height: 18px;
  }
}
#itucuma .section6 {
  position: relative;
  padding-top: 349px;
  padding-bottom: 395px;
}
@media (max-width: 1400px) {
  #itucuma .section6 {
    padding-bottom: 12.573vw;
  }
}
#itucuma .section6 .title-section {
  margin-bottom: 23px;
  margin-top: 79px;
}
#itucuma .section6 p {
  color: #383838;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 621px;
  margin-bottom: 26px;
}
#itucuma .section6 p strong {
  color: #00AEEF;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
#itucuma .section6 .video-section {
  position: absolute;
  width: 843px;
  height: 745px;
  top: 189px;
  left: 0;
}
@media (max-width: 1600px) {
  #itucuma .section6 .video-section {
    max-width: 48%;
  }
}
#itucuma .section6 .video-section .video {
  position: relative;
}
#itucuma .section6 .video-section .video .img-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#itucuma .section6 .video-section .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#itucuma .section6 .video-section .video .linhas {
  position: absolute;
  left: 0;
  bottom: -26px;
}
#itucuma .section6 .video-section .video .linhas img {
  transform: rotate(180deg);
}
#itucuma .section6 .video-section .video a {
  display: inline-block;
  max-width: 100%;
}
#itucuma .section6 .texto-section {
  font-family: "Revx Neue", sans-serif;
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #849550;
  text-stroke: 2px #849550;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: 122px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: end;
}
@media (width < 991px) {
  #itucuma .section6 {
    padding-top: 0;
    padding-bottom: 60px;
  }
  #itucuma .section6 > .container {
    margin: 0;
    max-width: 100%;
    padding: 0;
  }
  #itucuma .section6 > .container > .row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start !important;
    gap: 195px;
  }
  #itucuma .section6 > .container > .row .col-lg-6 {
    padding-inline: 25px;
  }
  #itucuma .section6 .title-section {
    margin-bottom: 32px;
    margin-top: 0;
  }
  #itucuma .section6 .video-section {
    position: relative;
    padding: 0 0 0 25px;
    top: 0;
    width: min(100%, 400px);
    max-width: min(100%, 400px);
    height: min(100%, 350px);
    align-self: end;
  }
  #itucuma .section6 .video-section .video {
    width: 100%;
    height: 100%;
  }
  #itucuma .section6 .video-section .video img {
    width: 100%;
    height: 100%;
  }
  #itucuma .section6 .video-section .video .player img {
    max-width: 60px;
    max-height: 60px;
  }
  #itucuma .section6 .video-section .video .linhas {
    display: none;
  }
  #itucuma .section6 .texto-section {
    font-size: 80px;
    line-height: 80px;
    top: 317px;
    left: 7px;
    right: auto;
    max-width: 375px;
    text-align: start;
  }
  #itucuma .section6 p {
    font-size: 14px;
    line-height: 18px;
    max-width: 100%;
  }
}
#itucuma .realidade {
  position: relative;
  padding-top: 158px;
  padding-bottom: 171px;
  background-color: #F5F5F5;
}
#itucuma .realidade .texto-section {
  font-family: "Revx Neue", sans-serif;
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #849550;
  text-stroke: 2px #849550;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: -57px;
  left: 0;
  text-align: start;
}
#itucuma .realidade .title-section {
  text-align: center;
}
#itucuma .realidade .title-section::after {
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #849550;
}
#itucuma .realidade p {
  color: #4F4F4F;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 627px;
  margin: 0 auto;
}
#itucuma .realidade .cards {
  margin-top: 82px;
}
#itucuma .realidade .cards .bloco {
  padding: 40px 20px;
  text-align: center;
  margin-bottom: 30px;
  max-width: 406px;
  height: 271px;
  border-radius: 20px 0px;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
#itucuma .realidade .cards .bloco h3 {
  color: #5E6A39;
  font-family: "Revx Neue", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 20px;
  margin-top: 12px;
}
#itucuma .realidade .cards .bloco p {
  color: #383838;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
#itucuma .realidade .cards .bloco:hover {
  background-color: #5E6A39;
  transition: 0.3s ease-in-out;
}
#itucuma .realidade .cards .bloco:hover img {
  filter: grayscale(100%) brightness(2);
  transition: 0.3s ease-in-out;
}
#itucuma .realidade .cards .bloco:hover h3,
#itucuma .realidade .cards .bloco:hover p {
  color: #fff;
  transition: 0.3s ease-in-out;
}
@media (width < 991px) {
  #itucuma .realidade {
    padding-top: 98px;
    padding-bottom: 60px;
  }
  #itucuma .realidade .title-section {
    margin-bottom: 20px;
  }
  #itucuma .realidade p {
    font-size: 14px;
    line-height: 18px;
  }
  #itucuma .realidade .cards {
    margin-top: 44px;
  }
  #itucuma .realidade .cards .row {
    flex-direction: column;
    gap: 20px;
  }
  #itucuma .realidade .cards .bloco {
    height: 217px;
    max-width: min(100%, 340px);
    margin-inline: auto;
    padding: 32px;
    margin-bottom: 0;
  }
  #itucuma .realidade .cards .bloco img {
    max-width: 32px;
    aspect-ratio: 1;
  }
  #itucuma .realidade .cards .bloco h3 {
    font-size: 25px;
    line-height: 37px;
    margin-bottom: 20px;
    margin-top: 12px;
  }
  #itucuma .realidade .cards .bloco p {
    font-size: 14px;
    line-height: 18px;
  }
  #itucuma .realidade .texto-section {
    display: none;
  }
}

@media (max-width: 991px) {
  #gente-treinee {
    overflow: hidden;
  }
}
#gente-treinee .breadcrumb {
  margin-top: 7px;
  gap: 5px;
}
#gente-treinee .breadcrumb-component {
  margin-bottom: 70px;
}
@media (max-width: 991px) {
  #gente-treinee .breadcrumb-component {
    margin-bottom: 0;
  }
}
#gente-treinee .banner-normal {
  height: 770px;
  position: relative;
  padding-top: 180px;
}
#gente-treinee .banner-normal::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 18.24%, rgba(0, 132, 181, 0) 81.3%);
  mix-blend-mode: multiply;
}
#gente-treinee .banner-normal::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #000 -19.62%, rgba(0, 0, 0, 0) 61.41%);
  z-index: 2;
}
#gente-treinee .banner-normal .banner-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
}
#gente-treinee .banner-normal .content-text {
  position: relative;
  z-index: 9;
  display: flex;
  flex-direction: column;
  max-width: 583px;
}
#gente-treinee .banner-normal .content-text h1 {
  color: #FFF;
  font-family: "Revx Neue", sans-serif;
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: 65px;
  /* 92.857% */
  margin-bottom: 37px;
}
#gente-treinee .banner-normal .content-text p {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 49px;
  max-width: 538px;
}
#gente-treinee .banner-normal .content-text .links {
  display: flex;
  gap: 46px;
}
#gente-treinee .banner-normal .content-text a {
  width: 239px;
}
#gente-treinee .banner-normal .content-text .player a {
  width: auto;
  height: auto;
  background: transparent;
}
#gente-treinee .banner-normal .content-text .player a::after {
  display: none;
}
@media (width < 991px) {
  #gente-treinee .banner-normal {
    height: min(95vh, 591px);
    padding-top: 138px;
  }
  #gente-treinee .banner-normal .container {
    flex-direction: column-reverse !important;
    justify-content: space-between;
    gap: 20px;
    height: 100%;
    padding-inline: 0;
    margin-inline: 0;
  }
  #gente-treinee .banner-normal .content-text {
    max-width: 342px;
    padding-inline: 25px;
  }
  #gente-treinee .banner-normal .content-text h1 {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 20px;
  }
  #gente-treinee .banner-normal .content-text p {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 60px 0;
    max-width: 292px;
  }
  #gente-treinee .banner-normal .down-section {
    display: none;
  }
}
#gente-treinee #cardProfessionals {
  max-width: min(1270px, 100%);
  position: relative;
  z-index: 3;
}
#gente-treinee #cardProfessionals .card-professionals-container {
  gap: 0 8px;
  margin-block: -198px 157px;
  isolation: isolate;
}
@media (max-width: 1600px) {
  #gente-treinee #cardProfessionals .card-professionals-container {
    margin-block: -102px 157px;
  }
}
@media (max-width: 1100px) {
  #gente-treinee #cardProfessionals .card-professionals-container {
    overflow-y: scroll;
  }
}
#gente-treinee #cardProfessionals .card-professionals-container .card-professional {
  padding: 34px 47px;
  color: #F5F5F5;
  height: 311px;
  mix-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
@media (max-width: 1100px) {
  #gente-treinee #cardProfessionals .card-professionals-container .card-professional {
    aspect-ratio: 1.1;
  }
}
#gente-treinee #cardProfessionals .card-professionals-container .card-professional .conteudo {
  position: unset;
  z-index: 2;
}
#gente-treinee #cardProfessionals .card-professionals-container .card-professional::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%);
  mix-blend-mode: multiply;
  transition: all 0.3s ease-in;
}
#gente-treinee #cardProfessionals .card-professionals-container .card-professional:hover {
  transition: all 0.3s ease-in;
  margin-top: -15px;
}
#gente-treinee #cardProfessionals .card-professionals-container .card-professional:hover ::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%);
  transition: all 0.3s ease-in;
}
#gente-treinee #cardProfessionals .card-professionals-container .card-professional-img {
  inset: 0;
  object-fit: cover;
  height: 100%;
  z-index: -2;
}
#gente-treinee #cardProfessionals .card-professionals-container .card-professional-title {
  font-family: "Revx Neue", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 100% */
  margin-bottom: 11px;
}
#gente-treinee #cardProfessionals .card-professionals-container .card-professional-text {
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#gente-treinee #cardProfessionals .card-professionals-container .card-professional-title, #gente-treinee #cardProfessionals .card-professionals-container .card-professional-text {
  z-index: 2;
}
#gente-treinee .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 34px;
  position: relative;
}
@media (max-width: 991px) {
  #gente-treinee .title-section {
    font-size: 28px;
    line-height: 28px;
  }
}
#gente-treinee .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
}
#gente-treinee .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 991px) {
  #gente-treinee .title-section strong {
    font-size: 28px;
    line-height: 28px;
  }
}
#gente-treinee .section1 {
  position: relative;
  padding-top: 16.719vw;
  padding-bottom: 114px;
}
@media (max-width: 991px) {
  #gente-treinee .section1 {
    padding-top: 0;
    padding-bottom: 0;
  }
  #gente-treinee .section1 .row {
    flex-direction: column-reverse;
  }
}
#gente-treinee .section1 .title-section {
  margin-bottom: 23px;
}
@media (max-width: 991px) {
  #gente-treinee .section1 .title-section {
    margin-top: 200px;
  }
}
#gente-treinee .section1 p {
  color: #383838;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 621px;
  margin-bottom: 26px;
}
@media (max-width: 991px) {
  #gente-treinee .section1 p {
    font-size: 14px;
    line-height: 18px;
  }
}
#gente-treinee .section1 p strong {
  color: #00AEEF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 991px) {
  #gente-treinee .section1 p strong {
    font-size: 14px;
    line-height: 18px;
  }
}
#gente-treinee .section1 .video-section {
  position: absolute;
  width: 843px;
  height: 745px;
  top: 59px;
  right: 0;
  padding: 0;
}
@media (max-width: 1600px) {
  #gente-treinee .section1 .video-section {
    max-width: 48%;
  }
}
@media (max-width: 1400px) {
  #gente-treinee .section1 .video-section {
    width: 43.906vw;
    height: 38.802vw;
  }
}
@media (max-width: 991px) {
  #gente-treinee .section1 .video-section {
    position: relative;
    max-width: 90%;
    width: 90%;
    height: 342px;
  }
}
#gente-treinee .section1 .video-section .video {
  position: relative;
}
#gente-treinee .section1 .video-section .video .img-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#gente-treinee .section1 .video-section .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#gente-treinee .section1 .video-section .video .linhas {
  position: absolute;
  right: 0;
  bottom: -12px;
}
@media (max-width: 991px) {
  #gente-treinee .section1 .video-section .video .linhas {
    display: none;
  }
}
#gente-treinee .section1 .texto-section {
  font-family: "Revx Neue";
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #60cbf2;
  text-stroke: 2px #60cbf2;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: 0px;
  left: -51px;
  right: 0;
  margin: 0 auto;
  text-align: center;
  filter: blur(0);
}
@media (max-width: 991px) {
  #gente-treinee .section1 .texto-section {
    font-size: 73px;
    line-height: 73px;
    top: 400px;
    text-align: left;
    left: -15px;
  }
}
#gente-treinee .section2 {
  position: relative;
  padding-top: 221px;
  padding-bottom: 224px;
}
@media (max-width: 1400px) {
  #gente-treinee .section2 {
    padding-bottom: 150px;
    padding-top: 100px;
  }
}
@media (max-width: 991px) {
  #gente-treinee .section2 {
    padding-top: 0;
    padding-bottom: 0;
  }
  #gente-treinee .section2 .row {
    flex-direction: column-reverse;
  }
}
#gente-treinee .section2 .title-section {
  margin-bottom: 23px;
  margin-top: 79px;
}
@media (max-width: 991px) {
  #gente-treinee .section2 .title-section {
    margin-top: 149px;
  }
}
#gente-treinee .section2 p {
  color: #383838;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 621px;
  margin-bottom: 26px;
}
@media (max-width: 991px) {
  #gente-treinee .section2 p {
    font-size: 14px;
    line-height: 18px;
  }
}
#gente-treinee .section2 p strong {
  color: #00AEEF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 991px) {
  #gente-treinee .section2 p strong {
    font-size: 14px;
    line-height: 18px;
  }
}
#gente-treinee .section2 .video-section {
  position: absolute;
  width: 843px;
  height: 745px;
  top: 40px;
  left: 0;
  padding: 0;
}
@media (max-width: 1600px) {
  #gente-treinee .section2 .video-section {
    max-width: 48%;
  }
}
@media (max-width: 1400px) {
  #gente-treinee .section2 .video-section {
    width: 43.906vw;
    height: 38.802vw;
  }
}
@media (max-width: 991px) {
  #gente-treinee .section2 .video-section {
    position: relative;
    max-width: 100%;
    width: 100%;
    height: 342px;
  }
}
#gente-treinee .section2 .video-section .video {
  position: relative;
}
#gente-treinee .section2 .video-section .video .img-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#gente-treinee .section2 .video-section .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#gente-treinee .section2 .video-section .video .linhas {
  position: absolute;
  left: 0;
  bottom: -33px;
}
#gente-treinee .section2 .video-section .video .linhas img {
  transform: rotate(180deg);
}
@media (max-width: 991px) {
  #gente-treinee .section2 .video-section .video .linhas {
    display: none;
  }
}
#gente-treinee .section3 {
  position: relative;
  padding-top: 197px;
  padding-bottom: 286px;
}
@media (max-width: 1400px) {
  #gente-treinee .section3 {
    padding-bottom: 14.896vw;
    padding-top: 7.26vw;
  }
}
@media (max-width: 991px) {
  #gente-treinee .section3 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  #gente-treinee .section3 .row {
    flex-direction: column-reverse;
  }
}
#gente-treinee .section3 .title-section {
  margin-bottom: 23px;
}
@media (max-width: 991px) {
  #gente-treinee .section3 .title-section {
    margin-top: 30px;
  }
}
#gente-treinee .section3 p {
  color: #383838;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 621px;
  margin-bottom: 26px;
}
@media (max-width: 991px) {
  #gente-treinee .section3 p {
    font-size: 14px;
    line-height: 18px;
  }
}
#gente-treinee .section3 p strong {
  color: #00AEEF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 991px) {
  #gente-treinee .section3 p strong {
    font-size: 14px;
    line-height: 18px;
  }
}
#gente-treinee .section3 .video-section {
  position: absolute;
  width: 843px;
  height: 745px;
  top: -62px;
  right: 0;
  padding: 0;
}
@media (max-width: 1600px) {
  #gente-treinee .section3 .video-section {
    max-width: 48%;
  }
}
@media (max-width: 1400px) {
  #gente-treinee .section3 .video-section {
    width: 43.906vw;
    height: 38.802vw;
  }
}
@media (max-width: 991px) {
  #gente-treinee .section3 .video-section {
    position: relative;
    max-width: 100%;
    width: 100%;
    height: 342px;
  }
}
#gente-treinee .section3 .video-section .video {
  position: relative;
}
#gente-treinee .section3 .video-section .video .img-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#gente-treinee .section3 .video-section .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#gente-treinee .section3 .video-section .video .linhas {
  position: absolute;
  right: 0;
  bottom: -20px;
}
@media (max-width: 991px) {
  #gente-treinee .section3 .video-section .video .linhas {
    display: none;
  }
}
#gente-treinee .section3 .texto-section {
  font-family: "Revx Neue";
  font-size: 250px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  text-stroke: 2px #00AEEF;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: 0px;
  left: -51px;
  right: 0;
  margin: 0 auto;
  text-align: center;
  filter: blur(0);
}
@media (max-width: 991px) {
  #gente-treinee .section3 .texto-section {
    font-size: 73px;
    line-height: 73px;
  }
}
#gente-treinee .section4 {
  position: relative;
  padding-top: 156px;
  padding-bottom: 111px;
  background-color: #F5F5F5;
}
@media (max-width: 991px) {
  #gente-treinee .section4 {
    padding-top: 115px;
  }
}
#gente-treinee .section4 .title-section {
  text-align: center;
}
#gente-treinee .section4 .title-section::after {
  right: 0;
  left: 0;
  margin: 0 auto;
}
#gente-treinee .section4 p {
  color: #4F4F4F;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 627px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  #gente-treinee .section4 p {
    font-size: 14px;
    line-height: 18px;
  }
}
#gente-treinee .section4 .blocos {
  margin-top: 83px;
}
#gente-treinee .section4 .blocos .bloco {
  max-width: 406px;
  min-height: 317px;
  border-radius: 20px 0px;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 40px 20px;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  #gente-treinee .section4 .blocos .bloco {
    min-height: auto;
  }
}
#gente-treinee .section4 .blocos .bloco h3 {
  color: #0084B5;
  font-family: "Revx Neue";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-top: 12px;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  #gente-treinee .section4 .blocos .bloco h3 {
    font-size: 22px;
    line-height: 37px;
  }
}
#gente-treinee .section4 .blocos .bloco p {
  color: #383838;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
@media (max-width: 991px) {
  #gente-treinee .section4 .blocos .bloco p {
    font-size: 14px;
    line-height: 18px;
  }
}
#gente-treinee .section4 .blocos .bloco:hover {
  background-color: #0084B5;
  transition: 0.3s ease;
}
#gente-treinee .section4 .blocos .bloco:hover img,
#gente-treinee .section4 .blocos .bloco:hover svg {
  filter: grayscale(100%) brightness(2);
  transition: 0.3s ease;
}
#gente-treinee .section4 .blocos .bloco:hover h3,
#gente-treinee .section4 .blocos .bloco:hover p {
  color: #fff;
  transition: 0.3s ease;
}
#gente-treinee .section4 .texto-section {
  font-family: "Revx Neue";
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  text-stroke: 2px #00AEEF;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: -60px;
  left: 0px;
  right: 0;
  margin: 0 auto;
  text-align: start;
  filter: blur(0);
}
@media (max-width: 991px) {
  #gente-treinee .section4 .texto-section {
    font-size: 73px;
    line-height: 73px;
    left: 0;
    top: -40px;
  }
}

@media (max-width: 991px) {
  #ambiental {
    overflow: hidden;
  }
}
#ambiental #cardProfessionals {
  max-width: min(1270px, 100%);
  position: relative;
  z-index: 3;
}
@media (max-width: 991px) {
  #ambiental #cardProfessionals {
    max-width: 100%;
    overflow: scroll;
  }
}
#ambiental #cardProfessionals .card-professionals-container {
  gap: 0 8px;
  margin-block: -201px 100px;
  isolation: isolate;
}
#ambiental #cardProfessionals .card-professionals-container .card-professional {
  padding: 34px 47px;
  color: #F5F5F5;
  height: 311px;
  mix-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
#ambiental #cardProfessionals .card-professionals-container .card-professional .conteudo {
  position: unset;
  z-index: 2;
}
#ambiental #cardProfessionals .card-professionals-container .card-professional::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%);
  mix-blend-mode: multiply;
  transition: all 0.3s ease-in;
}
#ambiental #cardProfessionals .card-professionals-container .card-professional:hover {
  transition: all 0.3s ease-in;
  margin-top: -15px;
}
#ambiental #cardProfessionals .card-professionals-container .card-professional:hover ::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%);
  transition: all 0.3s ease-in;
}
#ambiental #cardProfessionals .card-professionals-container .card-professional-img {
  inset: 0;
  object-fit: cover;
  height: 100%;
  z-index: -2;
}
#ambiental #cardProfessionals .card-professionals-container .card-professional-title {
  font-family: "Revx Neue", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 100% */
  margin-bottom: 11px;
}
#ambiental #cardProfessionals .card-professionals-container .card-professional-text {
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#ambiental #cardProfessionals .card-professionals-container .card-professional-title, #ambiental #cardProfessionals .card-professionals-container .card-professional-text {
  z-index: 2;
}
#ambiental .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 34px;
  position: relative;
}
@media (max-width: 991px) {
  #ambiental .title-section {
    font-size: 28px;
    line-height: 28px;
  }
}
#ambiental .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
}
#ambiental .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 991px) {
  #ambiental .title-section strong {
    font-size: 28px;
    line-height: 28px;
  }
}
#ambiental .section1 {
  position: relative;
  padding-top: 338px;
  padding-bottom: 164px;
}
@media (max-width: 1600px) {
  #ambiental .section1 {
    overflow: hidden;
  }
}
@media (max-width: 991px) {
  #ambiental .section1 {
    padding-top: 0;
    padding-bottom: 44px;
  }
  #ambiental .section1 .row {
    flex-direction: column-reverse;
  }
}
#ambiental .section1 .title-section {
  margin-bottom: 23px;
}
@media (max-width: 991px) {
  #ambiental .section1 .title-section {
    margin-top: 300px;
  }
}
#ambiental .section1 p {
  color: #383838;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 621px;
  margin-bottom: 26px;
}
#ambiental .section1 p strong {
  color: #00AEEF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
#ambiental .section1 .video-section {
  position: absolute;
  width: 843px;
  height: 745px;
  top: 59px;
  right: 0;
  padding: 0;
}
@media (max-width: 1600px) {
  #ambiental .section1 .video-section {
    max-width: 48%;
  }
}
@media (max-width: 911px) {
  #ambiental .section1 .video-section {
    max-width: 100%;
    position: relative;
    height: auto;
  }
}
#ambiental .section1 .video-section .video {
  position: relative;
}
#ambiental .section1 .video-section .video .img-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#ambiental .section1 .video-section .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#ambiental .section1 .video-section .video .linhas {
  position: absolute;
  right: 0;
  bottom: -12px;
}
@media (max-width: 991px) {
  #ambiental .section1 .video-section .video .linhas {
    display: none;
  }
}
#ambiental .section1 .texto-section {
  font-family: "Revx Neue";
  font-size: 250px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  text-stroke: 2px #00AEEF;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: 0px;
  left: -51px;
  right: 0;
  margin: 0 auto;
  text-align: start;
  filter: blur(0);
}
@media (max-width: 1600px) {
  #ambiental .section1 .texto-section {
    top: 53px;
    font-size: 195px;
  }
}
@media (max-width: 991px) {
  #ambiental .section1 .texto-section {
    font-size: 64px;
    word-break: break-word;
    max-width: 94%;
    line-height: 80px;
    top: 390px;
    line-height: 70px;
    left: 0;
  }
}
#ambiental .section2 {
  position: relative;
}
#ambiental .section2 .video {
  padding: 84px;
  position: relative;
  border-radius: 22px;
  background: #F5F5F5;
  z-index: 2;
}
@media (max-width: 991px) {
  #ambiental .section2 .video {
    padding: 30px;
    max-width: 100%;
    height: 253px;
    border-radius: 8px;
  }
}
#ambiental .section2 .video .img-video {
  width: 100%;
  height: 456px;
  border-radius: 10px;
  object-fit: cover;
}
@media (max-width: 991px) {
  #ambiental .section2 .video .img-video {
    height: 100%;
  }
}
#ambiental .section2 .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#ambiental .section2 .quadrado {
  width: 948px;
  max-width: 100%;
  height: 546px;
  background: #0084B5;
  position: absolute;
  top: 186px;
  left: 0;
  z-index: 1;
}
@media (max-width: 991px) {
  #ambiental .section2 .quadrado {
    width: 80%;
    height: 240px;
    top: 50%;
  }
}
#ambiental .section3 {
  position: relative;
  padding-top: 461px;
  padding-bottom: 253px;
}
@media (max-width: 991px) {
  #ambiental .section3 {
    padding-top: 0;
    margin-top: -95px;
    padding-bottom: 50px;
  }
  #ambiental .section3 .row {
    flex-direction: column-reverse;
  }
}
#ambiental .section3 .title-section {
  margin-bottom: 23px;
  margin-top: 79px;
}
@media (max-width: 991px) {
  #ambiental .section3 .title-section {
    margin-top: 450px;
  }
}
#ambiental .section3 p {
  color: #383838;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 621px;
  margin-bottom: 26px;
}
#ambiental .section3 p strong {
  color: #00AEEF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
#ambiental .section3 .video-section {
  position: absolute;
  width: 843px;
  height: 745px;
  top: 274px;
  left: 0;
  padding: 0;
}
@media (max-width: 1600px) {
  #ambiental .section3 .video-section {
    max-width: 48%;
  }
}
@media (max-width: 911px) {
  #ambiental .section3 .video-section {
    max-width: 100%;
    position: relative;
    height: 342px;
  }
}
#ambiental .section3 .video-section .video {
  position: relative;
}
#ambiental .section3 .video-section .video .img-video {
  width: 100%;
  object-fit: cover;
}
@media (max-width: 991px) {
  #ambiental .section3 .video-section .video .img-video {
    height: 342px;
  }
}
#ambiental .section3 .video-section .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#ambiental .section3 .video-section .video .linhas {
  position: absolute;
  left: 0;
  bottom: -33px;
}
#ambiental .section3 .video-section .video .linhas img {
  transform: rotate(180deg);
}
@media (max-width: 991px) {
  #ambiental .section3 .video-section .video .linhas {
    display: none;
  }
}
#ambiental .section3 .texto-section {
  font-family: "Revx Neue";
  font-size: 250px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  text-stroke: 2px #00AEEF;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: 204px;
  left: 0;
  right: 71px;
  margin: 0 auto;
  text-align: end;
  filter: blur(0);
}
@media (max-width: 991px) {
  #ambiental .section3 .texto-section {
    font-size: 64px;
    max-width: 94%;
    text-align: start;
    top: 614px;
    left: 15px;
  }
}
#ambiental .section4 {
  position: relative;
  padding-top: 156px;
  padding-bottom: 111px;
  background-color: #F5F5F5;
}
@media (max-width: 991px) {
  #ambiental .section4 {
    padding-bottom: 60px;
    padding-top: 100px;
  }
}
#ambiental .section4 .title-section {
  text-align: center;
}
#ambiental .section4 .title-section::after {
  right: 0;
  left: 0;
  margin: 0 auto;
}
#ambiental .section4 p {
  color: #4F4F4F;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 627px;
  margin: 0 auto;
}
#ambiental .section4 .blocos {
  margin-top: 83px;
}
#ambiental .section4 .blocos .bloco {
  max-width: 406px;
  height: 271px;
  border-radius: 20px 0px;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 40px 20px;
  margin-bottom: 30px;
}
#ambiental .section4 .blocos .bloco h3 {
  color: #0084B5;
  font-family: "Revx Neue";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-top: 12px;
  margin-bottom: 20px;
}
#ambiental .section4 .blocos .bloco p {
  color: #383838;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
#ambiental .section4 .blocos .bloco:hover {
  background-color: #0084B5;
  transition: 0.3s ease;
}
#ambiental .section4 .blocos .bloco:hover img {
  filter: grayscale(100%) brightness(2);
  transition: 0.3s ease;
}
#ambiental .section4 .blocos .bloco:hover h3,
#ambiental .section4 .blocos .bloco:hover p {
  color: #fff;
  transition: 0.3s ease;
}
#ambiental .section4 .texto-section {
  font-family: "Revx Neue";
  font-size: 250px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  text-stroke: 2px #00AEEF;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: -60px;
  left: -30px;
  right: 0;
  margin: 0 auto;
  text-align: start;
  filter: blur(0);
}
@media (max-width: 991px) {
  #ambiental .section4 .texto-section {
    font-size: 78px;
    word-break: break-word;
    max-width: 94%;
  }
}

#services .breadcrumb {
  margin-top: 7px;
  gap: 5px;
}
#services .breadcrumb-component {
  margin-bottom: 70px;
}
@media (max-width: 991px) {
  #services .breadcrumb-component {
    margin-bottom: 0;
  }
}
#services .banner-normal {
  height: 915px;
  position: relative;
  padding-top: 180px;
}
#services .banner-normal::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 18.24%, rgba(0, 132, 181, 0) 81.3%);
  mix-blend-mode: multiply;
}
#services .banner-normal::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 18.24%, rgba(0, 132, 181, 0) 81.3%);
  mix-blend-mode: multiply;
  z-index: 2;
}
#services .banner-normal .banner-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
}
#services .banner-normal .content-text {
  position: relative;
  z-index: 9;
  display: flex;
  flex-direction: column;
  max-width: 583px;
}
#services .banner-normal .content-text h1 {
  color: #FFF;
  font-family: "Revx Neue", sans-serif;
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: 65px;
  /* 92.857% */
  margin-bottom: 37px;
}
#services .banner-normal .content-text p {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 76px;
  max-width: 538px;
}
#services .banner-normal .content-text .links {
  display: flex;
  gap: 46px;
}
#services .banner-normal .content-text .player a {
  width: auto;
  height: auto;
  background: transparent;
}
#services .banner-normal .content-text .player a::after {
  display: none;
}
@media (max-width: 991px) {
  #services .banner-normal .content-text .player a {
    padding: 0;
  }
}
@media (width < 991px) {
  #services .banner-normal {
    height: min(95vh, 591px);
    padding-top: 138px;
  }
  #services .banner-normal .container {
    flex-direction: column-reverse !important;
    justify-content: space-between;
    gap: 20px;
    height: 100%;
    padding-inline: 0;
    margin-inline: 0;
  }
  #services .banner-normal .content-text {
    max-width: 342px;
    padding-inline: 25px;
  }
  #services .banner-normal .content-text h1 {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 20px;
  }
  #services .banner-normal .content-text p {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 60px 0;
    max-width: 292px;
  }
  #services .banner-normal .down-section {
    display: none;
  }
}
#services .btn-scroll {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  bottom: 59px;
}
@media (max-width: 991px) {
  #services .btn-scroll {
    display: none;
  }
}
#services .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 43px;
  position: relative;
}
@media (max-width: 991px) {
  #services .title-section {
    font-size: 28px;
    line-height: 28px;
  }
}
#services .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
}
#services .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 991px) {
  #services .title-section strong {
    font-size: 28px;
    line-height: 28px;
  }
}
#services .btn-section {
  height: 50px;
  width: 190px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 51px;
  margin-right: 47px;
  text-decoration: none;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
#services .btn-section::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#services .btn-section:hover::after {
  transform: scaleY(1);
}
#services .section1 {
  position: relative;
  margin-top: 96px;
}
@media (max-width: 991px) {
  #services .section1 {
    margin-top: 0px;
  }
  #services .section1 .row {
    flex-direction: column-reverse;
  }
}
#services .section1 .video {
  padding: 84px;
  position: relative;
  border-radius: 22px;
  background: #F5F5F5;
  z-index: 2;
}
@media (max-width: 991px) {
  #services .section1 .video {
    padding: 20px;
    border-radius: 8px;
  }
}
#services .section1 .video .img-video {
  width: 100%;
  height: 456px;
  border-radius: 10px;
  object-fit: cover;
}
@media (max-width: 991px) {
  #services .section1 .video .img-video {
    height: 213px;
  }
}
#services .section1 .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#services .section1 .quadrado {
  width: 948px;
  height: 815px;
  background: #0084B5;
  position: absolute;
  top: 186px;
  left: 0;
  z-index: 1;
}
@media (max-width: 1600px) {
  #services .section1 .quadrado {
    width: 50%;
  }
}
@media (max-width: 991px) {
  #services .section1 .quadrado {
    width: 80%;
    height: 240px;
    top: inherit;
    bottom: -74px;
  }
}
#services .section1 .conteudo {
  margin-top: 70px;
}
#services .section1 .conteudo p {
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 20px;
}
#services .section1 .conteudo p strong {
  color: #00AEEF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
#services .slides {
  margin-top: 175px;
  margin-bottom: 150px;
}
@media (max-width: 991px) {
  #services .slides {
    margin-bottom: 0px;
  }
}
#services .slides .title-section {
  text-align: center;
}
#services .slides .title-section::after {
  left: 0;
  right: 0;
  margin: 0 auto;
}
#services .slides p {
  color: #4F4F4F;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 627px;
  margin: 0 auto;
}
#services .slides .slider-section {
  position: relative;
  margin-top: 82px;
}
#services .slides .slider-section .slider.center .cl {
  height: 544px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#services .slides .slider-section .slider.center .cl .content {
  width: 100%;
  height: 100%;
  position: relative;
}
#services .slides .slider-section .slider.center .cl .content .texto {
  position: absolute;
  bottom: 69px;
  left: 50px;
  max-width: 339px;
  z-index: 2;
}
#services .slides .slider-section .slider.center .cl .content .texto h4 {
  color: #F5F5F5;
  font-family: "Revx Neue";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin: 20px 0;
}
#services .slides .slider-section .slider.center .cl .content .texto p {
  color: #F5F5F5;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
}
#services .slides .slider-section .slider.center .cl .content .texto a {
  display: inline-block;
  text-decoration: none;
  color: #FFF;
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  margin-top: 32px;
}
#services .slides .slider-section .slider.center .cl .content .texto a svg {
  margin-left: 12px;
}
#services .slides .slider-section .slider.center .cl .content .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--tbl-gradients-effects-gradient-linear, linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%));
  mix-blend-mode: multiply;
}
#services .slides .slider-section .slider.center .slick-active {
  padding: 30px 0;
}
#services .slides .slider-section .slider.center .slick-center {
  transform: scale(1.1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 99;
  position: relative;
  max-width: 540px;
}
#services .slides .slider-section .slider.center .slick-center::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #0084B5;
  bottom: 34px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
#services .slides .slider-section .slider.center .slick-slide:not(.slick-active) {
  margin: 30px 0;
}
#services .slides .slider-section .slider.center .child {
  width: 100%;
}
#services .slides .slider-section .slider.center .slide:not(.slick-active) {
  cursor: pointer;
}
#services .slides .slider-section .slider.center .layer-blue {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0) 100%);
}
#services .slides .slider-section .center-prev,
#services .slides .slider-section .center-next {
  position: absolute;
  top: calc(50% - 64px);
  cursor: pointer;
  z-index: 2;
}
@media (max-width: 991px) {
  #services .slides .slider-section .center-prev,
#services .slides .slider-section .center-next {
    display: none;
  }
}
#services .slides .slider-section .center-prev {
  left: 88px;
}
#services .slides .slider-section .center-next {
  right: 88px;
}
#services .slides .slider-section .layer-green {
  position: absolute;
  width: 476px;
  height: calc(100% - 64px);
  top: 30px;
  background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0) 100%);
  z-index: 1;
}
@media (max-width: 991px) {
  #services .slides .slider-section .layer-green {
    display: none;
  }
}
#services .slides .slider-section .layer-green-second {
  position: absolute;
  width: 476px;
  height: calc(100% - 64px);
  top: 30px;
  right: 0;
  background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0) 100%);
  z-index: 1;
  transform: rotate(180deg);
}
@media (max-width: 991px) {
  #services .slides .slider-section .layer-green-second {
    display: none;
  }
}
#services .section3 {
  position: relative;
  overflow: hidden;
  padding-top: 200px;
  padding-bottom: 170px;
}
@media (max-width: 991px) {
  #services .section3 {
    padding-top: 90px;
    padding-bottom: 80px;
  }
}
#services .section3 p {
  color: var(--tbl-default-black, #383838);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 406px;
  margin-bottom: 26px;
}
#services .section3 p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
#services .section3 .btn-section {
  margin-top: 80px;
}
@media (max-width: 991px) {
  #services .section3 .btn-section {
    width: 100%;
  }
}
#services .section3 .mapa {
  position: absolute;
  right: 0;
  top: -165px;
}
@media (max-width: 1600px) {
  #services .section3 .mapa {
    top: -225px;
    width: 68%;
  }
}
@media (max-width: 991px) {
  #services .section3 .mapa {
    position: relative;
    width: 100%;
    top: 50px;
    right: 50px;
  }
  #services .section3 .mapa img {
    width: 100%;
    transform: scale(1.5);
  }
}

#services-detail {
  overflow-x: hidden;
}
@media (max-width: 991px) {
  #services-detail .banner-normal .container {
    justify-content: end;
    gap: 60px;
  }
}
#services-detail .banner-normal .content-text {
  position: relative;
  z-index: 9;
  display: flex;
  flex-direction: column;
  max-width: 583px;
}
#services-detail .banner-normal .content-text h1 {
  color: #FFF;
  font-family: "Revx Neue", sans-serif;
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: 65px;
  /* 92.857% */
  margin-bottom: 37px;
}
@media (max-width: 991px) {
  #services-detail .banner-normal .content-text h1 {
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    /* 100% */
  }
}
#services-detail .banner-normal .content-text p {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
  margin-bottom: 49px;
  max-width: 538px;
}
@media (max-width: 991px) {
  #services-detail .banner-normal .content-text p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}
#services-detail .banner-normal .content-text .links {
  display: flex;
  gap: 46px;
}
@media (max-width: 991px) {
  #services-detail .banner-normal .content-text .links {
    align-items: center;
    gap: 29px;
  }
}
@media (max-width: 991px) {
  #services-detail .banner-normal .content-text .btn-banner a {
    width: fit-content;
    height: 46px;
    padding: 12px 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: pre;
  }
}
#services-detail .banner-normal .content-text a {
  width: 239px;
}
#services-detail .banner-normal .content-text .player a {
  width: auto;
  height: auto;
  background: transparent;
}
#services-detail .banner-normal .content-text .player a::after {
  display: none;
}
@media (max-width: 991px) {
  #services-detail .banner-normal .content-text .player {
    width: 40px;
    height: 40px;
  }
  #services-detail .banner-normal .content-text .player a {
    width: 40px;
    height: 40px;
    background: transparent;
    padding: 0;
  }
}
#services-detail #cardProfessionals {
  max-width: min(1270px, 100%);
  position: relative;
  z-index: 3;
}
#services-detail #cardProfessionals .card-professionals-container {
  gap: 0 8px;
  margin-block: -198px 157px;
  isolation: isolate;
}
@media (max-width: 1600px) {
  #services-detail #cardProfessionals .card-professionals-container {
    margin-block: -102px 157px;
  }
}
@media (max-width: 1100px) {
  #services-detail #cardProfessionals .card-professionals-container {
    overflow-y: scroll;
  }
}
#services-detail #cardProfessionals .card-professionals-container .card-professional {
  padding: 34px 47px;
  color: #F5F5F5;
  height: 311px;
  mix-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
@media (max-width: 1100px) {
  #services-detail #cardProfessionals .card-professionals-container .card-professional {
    aspect-ratio: 1.1;
  }
}
#services-detail #cardProfessionals .card-professionals-container .card-professional .conteudo {
  position: unset;
  z-index: 2;
}
#services-detail #cardProfessionals .card-professionals-container .card-professional::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%);
  mix-blend-mode: multiply;
  transition: all 0.3s ease-in;
}
#services-detail #cardProfessionals .card-professionals-container .card-professional:hover {
  transition: all 0.3s ease-in;
  margin-top: -15px;
}
#services-detail #cardProfessionals .card-professionals-container .card-professional:hover ::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%);
  transition: all 0.3s ease-in;
}
#services-detail #cardProfessionals .card-professionals-container .card-professional-img {
  inset: 0;
  object-fit: cover;
  height: 100%;
  z-index: -2;
}
#services-detail #cardProfessionals .card-professionals-container .card-professional-title {
  font-family: "Revx Neue", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 100% */
  margin-bottom: 11px;
}
#services-detail #cardProfessionals .card-professionals-container .card-professional-text {
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#services-detail #cardProfessionals .card-professionals-container .card-professional-title, #services-detail #cardProfessionals .card-professionals-container .card-professional-text {
  z-index: 2;
}
#services-detail .section1 {
  position: relative;
  padding-top: 169px;
  padding-bottom: 110px;
}
@media (max-width: 1100px) {
  #services-detail .section1 {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 1100px) {
  #services-detail .section1 .row {
    flex-direction: column-reverse;
    gap: 105px;
  }
}
#services-detail .section1 .title-section {
  margin-bottom: 23px;
  margin-top: 79px;
}
@media (max-width: 1100px) {
  #services-detail .section1 .title-section {
    padding-inline: 17.5px;
  }
}
#services-detail .section1 p {
  color: #383838;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 621px;
  margin-bottom: 26px;
}
#services-detail .section1 p strong {
  color: #00AEEF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #services-detail .section1 p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    padding-inline: 17.5px;
  }
  #services-detail .section1 p strong {
    line-height: 18px;
    /* 128.571% */
    font-size: 14px;
  }
}
#services-detail .section1 .video-section {
  position: absolute;
  width: 44%;
  height: 745px;
  top: 67px;
  left: 0;
  padding: 0;
}
@media (max-width: 1600px) {
  #services-detail .section1 .video-section {
    max-width: 48%;
  }
}
@media (max-width: 1100px) {
  #services-detail .section1 .video-section {
    max-width: 100%;
    position: relative;
    width: 100%;
    height: 323px;
    top: 0;
    left: 0;
  }
}
#services-detail .section1 .video-section .video {
  position: relative;
}
@media (max-width: 1100px) {
  #services-detail .section1 .video-section .video {
    width: 90%;
    height: 100%;
  }
}
#services-detail .section1 .video-section .video .img-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#services-detail .section1 .video-section .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#services-detail .section1 .video-section .video .linhas {
  position: absolute;
  left: 0;
  top: -16px;
}
@media (max-width: 1100px) {
  #services-detail .section1 .video-section .video .linhas {
    display: none;
  }
}
#services-detail .section1 .video-section .video .linhas img {
  transform: rotate(180deg);
}
#services-detail .section1 .texto-section {
  font-family: "Revx Neue";
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  text-stroke: 2px #00AEEF;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: 19px;
  left: 17px;
  right: 0;
  margin: 0 auto;
  text-align: center;
  filter: blur(0);
}
@media (max-width: 1100px) {
  #services-detail .section1 .texto-section {
    font-size: 14vw;
    line-height: 95px;
    text-align: left;
    left: 0;
    top: 350px;
  }
}
#services-detail .section2 {
  position: relative;
  padding-top: 287px;
  padding-bottom: 254px;
}
@media (max-width: 1100px) {
  #services-detail .section2 {
    padding-top: 30px;
    padding-bottom: 60px;
  }
}
@media (max-width: 1100px) {
  #services-detail .section2 .row {
    flex-direction: column-reverse;
    gap: 260px;
  }
}
@media (max-width: 991px) {
  #services-detail .section2 .row {
    gap: 195px;
  }
}
#services-detail .section2 .title-section {
  margin-left: 72px;
  margin-bottom: 23px;
}
@media (max-width: 1100px) {
  #services-detail .section2 .title-section {
    margin-left: 0;
    padding-inline: 17.5px;
  }
}
#services-detail .section2 p {
  color: #383838;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 621px;
  margin-bottom: 26px;
  margin-left: 72px;
}
#services-detail .section2 p strong {
  color: #00AEEF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #services-detail .section2 p {
    margin-left: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    padding-inline: 17.5px;
  }
  #services-detail .section2 p strong {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
}
#services-detail .section2 .video-section {
  position: absolute;
  width: 44%;
  height: 745px;
  top: 106px;
  right: 0;
  padding: 0;
}
@media (max-width: 1600px) {
  #services-detail .section2 .video-section {
    max-width: 42%;
  }
}
@media (max-width: 1100px) {
  #services-detail .section2 .video-section {
    max-width: 100%;
    position: relative;
    width: 100%;
    height: 323px;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
  }
}
#services-detail .section2 .video-section .video {
  position: relative;
}
@media (max-width: 1100px) {
  #services-detail .section2 .video-section .video {
    width: 90%;
  }
}
#services-detail .section2 .video-section .video .img-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#services-detail .section2 .video-section .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#services-detail .section2 .video-section .video .linhas {
  position: absolute;
  right: 0;
  bottom: -20px;
}
@media (max-width: 1100px) {
  #services-detail .section2 .video-section .video .linhas {
    display: none;
  }
}
#services-detail .section2 .texto-section {
  font-family: "Revx Neue";
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  text-stroke: 2px #00AEEF;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: 58px;
  left: 0px;
  right: 0;
  margin: 0 auto;
  text-align: start;
  filter: blur(0);
}
@media (max-width: 1100px) {
  #services-detail .section2 .texto-section {
    font-size: 14vw;
    width: 100%;
    word-break: break-all;
    left: 0;
    text-align: end;
    top: 410px;
    line-height: 60px;
    -webkit-text-stroke: 1.5px #00aeef;
  }
}
#services-detail .section3 {
  position: relative;
  padding-top: 156px;
  padding-bottom: 111px;
  background-color: #F5F5F5;
}
@media (max-width: 1400px) {
  #services-detail .section3 {
    padding-bottom: 0px;
  }
}
@media (max-width: 991px) {
  #services-detail .section3 {
    padding-bottom: 40px;
  }
}
#services-detail .section3 .title-section {
  text-align: center;
}
#services-detail .section3 .title-section::after {
  right: 0;
  left: 0;
  margin: 0 auto;
}
#services-detail .section3 p {
  color: #4F4F4F;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 627px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  #services-detail .section3 p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    padding-inline: 17.5px;
  }
}
#services-detail .section3 .blocos {
  margin-top: 83px;
}
@media (max-width: 991px) {
  #services-detail .section3 .blocos {
    margin-top: 50px;
    padding-inline: 17.5px;
  }
}
#services-detail .section3 .blocos .bloco {
  max-width: 406px;
  height: 271px;
  border-radius: 20px 0px;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 40px 20px;
  margin-bottom: 30px;
}
#services-detail .section3 .blocos .bloco h3 {
  color: #0084B5;
  font-family: "Revx Neue";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-top: 12px;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  #services-detail .section3 .blocos .bloco h3 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 37px;
    /* 168.182% */
  }
}
#services-detail .section3 .blocos .bloco p {
  color: #383838;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
@media (max-width: 991px) {
  #services-detail .section3 .blocos .bloco p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}
@media (max-width: 991px) {
  #services-detail .section3 .blocos .bloco img {
    max-width: 40px;
  }
}
#services-detail .section3 .blocos .bloco:hover {
  background-color: #0084B5;
  transition: 0.3s ease;
}
#services-detail .section3 .blocos .bloco:hover img {
  filter: grayscale(100%) brightness(2);
  transition: 0.3s ease;
}
#services-detail .section3 .blocos .bloco:hover h3,
#services-detail .section3 .blocos .bloco:hover p {
  color: #fff;
  transition: 0.3s ease;
}
#services-detail .section3 .texto-section {
  font-family: "Revx Neue";
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  text-stroke: 2px #00AEEF;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: -60px;
  left: 0px;
  right: 0;
  margin: 0 auto;
  text-align: end;
  filter: blur(0);
}
@media (max-width: 991px) {
  #services-detail .section3 .texto-section {
    font-size: 14vw;
    word-break: break-word;
    margin: 0;
    max-width: 75%;
    text-align: left;
    left: 15px;
    line-height: 57px;
    -webkit-text-stroke: 1.5px #00aeef;
    top: -37px;
  }
}
#services-detail .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 34px;
  position: relative;
}
#services-detail .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
}
@media (max-width: 991px) {
  #services-detail .title-section::after {
    left: 17.5px;
  }
}
#services-detail .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 991px) {
  #services-detail .title-section {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
  }
  #services-detail .title-section strong {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}
#services-detail .slides {
  margin-top: 118px;
  margin-bottom: 150px;
}
@media (max-width: 991px) {
  #services-detail .slides {
    margin-bottom: -36px;
    z-index: 9;
    position: relative;
  }
}
#services-detail .slides .title-section {
  text-align: center;
}
#services-detail .slides .title-section::after {
  left: 0;
  right: 0;
  margin: 0 auto;
}
#services-detail .slides p {
  color: #4F4F4F;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 627px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  #services-detail .slides p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}
#services-detail .slides .slider-section {
  position: relative;
  margin-top: 82px;
}
@media (max-width: 991px) {
  #services-detail .slides .slider-section {
    margin-top: 40px;
  }
}
#services-detail .slides .slider-section .slider.center .cl {
  height: 544px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 991px) {
  #services-detail .slides .slider-section .slider.center .cl {
    height: 354px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}
#services-detail .slides .slider-section .slider.center .cl .content {
  width: 100%;
  height: 100%;
  position: relative;
}
#services-detail .slides .slider-section .slider.center .cl .content .texto {
  position: absolute;
  bottom: 69px;
  left: 50px;
  max-width: 339px;
  z-index: 2;
}
#services-detail .slides .slider-section .slider.center .cl .content .texto h4 {
  color: #F5F5F5;
  font-family: "Revx Neue";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin: 20px 0;
}
#services-detail .slides .slider-section .slider.center .cl .content .texto p {
  color: #F5F5F5;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
}
#services-detail .slides .slider-section .slider.center .cl .content .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--tbl-gradients-effects-gradient-linear, linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #849550 100%));
  mix-blend-mode: multiply;
}
#services-detail .slides .slider-section .slider.center .slick-active {
  padding: 30px 0;
}
#services-detail .slides .slider-section .slider.center .slick-center {
  transform: scale(1.1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 99;
  position: relative;
  max-width: 540px;
}
@media (max-width: 991px) {
  #services-detail .slides .slider-section .slider.center .slick-center {
    box-shadow: none;
  }
}
#services-detail .slides .slider-section .slider.center .slick-center::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #0084B5;
  bottom: 34px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
@media (max-width: 991px) {
  #services-detail .slides .slider-section .slider.center .slick-center::after {
    background: rgba(0, 0, 0, 0.2509803922);
    height: calc(100% - 68px);
    bottom: 38px;
  }
}
#services-detail .slides .slider-section .slider.center .slick-slide:not(.slick-active) {
  margin: 30px 0;
}
#services-detail .slides .slider-section .slider.center .child {
  width: 100%;
}
#services-detail .slides .slider-section .slider.center .slide:not(.slick-active) {
  cursor: pointer;
}
#services-detail .slides .slider-section .slider.center .layer-blue {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0) 100%);
}
#services-detail .slides .slider-section .center-prev,
#services-detail .slides .slider-section .center-next {
  position: absolute;
  top: calc(50% - 64px);
  cursor: pointer;
  z-index: 2;
}
@media (max-width: 1100px) {
  #services-detail .slides .slider-section .center-prev,
#services-detail .slides .slider-section .center-next {
    display: none;
  }
}
#services-detail .slides .slider-section .center-prev {
  left: 88px;
}
#services-detail .slides .slider-section .center-next {
  right: 88px;
}
#services-detail .slides .slider-section .layer-green {
  position: absolute;
  width: 476px;
  height: calc(100% - 64px);
  top: 30px;
  background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0) 100%);
  z-index: 1;
}
@media (max-width: 1100px) {
  #services-detail .slides .slider-section .layer-green {
    display: none;
  }
}
#services-detail .slides .slider-section .layer-green-second {
  position: absolute;
  width: 476px;
  height: calc(100% - 64px);
  top: 30px;
  right: 0;
  background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0) 100%);
  z-index: 1;
  transform: rotate(180deg);
}
@media (max-width: 1100px) {
  #services-detail .slides .slider-section .layer-green-second {
    display: none;
  }
}
#services-detail .local {
  position: relative;
  overflow: hidden;
  padding-bottom: 240px;
  background: #f5f5f5;
  padding-top: 165px;
}
@media (max-width: 1100px) {
  #services-detail .local {
    padding-bottom: 40px;
    padding-top: 65px;
  }
}
#services-detail .local p {
  color: var(--tbl-default-black, #383838);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 406px;
  margin-bottom: 26px;
}
#services-detail .local p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #services-detail .local p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    padding-inline: 17.5px;
  }
  #services-detail .local p strong {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
}
@media (max-width: 1100px) {
  #services-detail .local .title-section {
    padding-inline: 17.5px;
  }
}
#services-detail .local .btn-section {
  height: 50px;
  width: 190px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 51px;
  margin-right: 47px;
  text-decoration: none;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 89px;
}
@media (max-width: 1100px) {
  #services-detail .local .btn-section {
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 40px;
    width: 100%;
    width: calc(100% - 35px);
  }
}
#services-detail .local .btn-section::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#services-detail .local .btn-section:hover::after {
  transform: scaleY(1);
}
#services-detail .local .retangulo {
  position: absolute;
  bottom: -137px;
  right: 0;
  width: 50%;
  height: 656px;
  background: #F1F1F1;
}
@media (max-width: 1100px) {
  #services-detail .local .retangulo {
    display: none;
  }
}
#services-detail .local .mapa {
  position: absolute;
  right: 0;
  top: -263px;
}
#services-detail .local .mapa img {
  transform: translate(100px, 120px) scale(0.75);
}
@media (max-width: 1600px) {
  #services-detail .local .mapa {
    top: -197px;
    width: 68%;
  }
}
@media (max-width: 1100px) {
  #services-detail .local .mapa {
    position: relative;
    width: 100%;
    top: 0;
    right: 0;
    height: 400px;
  }
  #services-detail .local .mapa img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: none;
  }
}

#rodoviario {
  overflow: hidden;
}
#rodoviario .rodoviario {
  padding-block-start: 13px;
}
#rodoviario .rodoviario .rotas-container,
#rodoviario .rodoviario .frotas-container,
#rodoviario .rodoviario .cargas-container {
  display: flex;
}
#rodoviario .rodoviario .rotas-container .clip-text,
#rodoviario .rodoviario .frotas-container .clip-text,
#rodoviario .rodoviario .cargas-container .clip-text {
  width: 100%;
  height: 250px;
  z-index: 2;
}
#rodoviario .rodoviario .rotas-container .clip-text h3,
#rodoviario .rodoviario .frotas-container .clip-text h3,
#rodoviario .rodoviario .cargas-container .clip-text h3 {
  font-family: "Revx Neue", sans-serif;
  font-size: 11vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  /* 40% */
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* width and color of the stroke */
  text-stroke: 2px #00AEEF;
  /* Standard syntax, if supported */
  background-clip: text;
  position: relative;
  bottom: -80px;
  left: 0;
  filter: blur(0);
}
@media (max-width: 991px) {
  #rodoviario .rodoviario .rotas-container .clip-text h3,
#rodoviario .rodoviario .frotas-container .clip-text h3,
#rodoviario .rodoviario .cargas-container .clip-text h3 {
    font-size: 77px;
  }
}
@media (max-width: 550px) {
  #rodoviario .rodoviario .rotas-container .clip-text h3,
#rodoviario .rodoviario .frotas-container .clip-text h3,
#rodoviario .rodoviario .cargas-container .clip-text h3 {
    font-size: 16vw;
  }
}
#rodoviario .rodoviario .rotas-container--img,
#rodoviario .rodoviario .frotas-container--img,
#rodoviario .rodoviario .cargas-container--img {
  position: relative;
  height: fit-content;
}
#rodoviario .rodoviario .rotas-container--img img,
#rodoviario .rodoviario .frotas-container--img img,
#rodoviario .rodoviario .cargas-container--img img {
  max-width: 100%;
}
#rodoviario .rodoviario .rotas-container--img .icon-link,
#rodoviario .rodoviario .frotas-container--img .icon-link,
#rodoviario .rodoviario .cargas-container--img .icon-link {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}
#rodoviario .rodoviario .rotas-container--img .listras,
#rodoviario .rodoviario .frotas-container--img .listras,
#rodoviario .rodoviario .cargas-container--img .listras {
  position: absolute;
}
#rodoviario .rodoviario .rotas-container--text .divider,
#rodoviario .rodoviario .frotas-container--text .divider,
#rodoviario .rodoviario .cargas-container--text .divider {
  height: 4px;
  width: 63px;
  background: #00AEEF;
  margin-bottom: 35px;
}
#rodoviario .rodoviario .rotas-container--text h2,
#rodoviario .rodoviario .frotas-container--text h2,
#rodoviario .rodoviario .cargas-container--text h2 {
  color: #383838;
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-block-end: 26px;
}
#rodoviario .rodoviario .rotas-container--text h2 strong,
#rodoviario .rodoviario .frotas-container--text h2 strong,
#rodoviario .rodoviario .cargas-container--text h2 strong {
  font-family: inherit;
  font-weight: 700;
}
#rodoviario .rodoviario .rotas-container--text .text p + p,
#rodoviario .rodoviario .frotas-container--text .text p + p,
#rodoviario .rodoviario .cargas-container--text .text p + p {
  margin-top: 32px;
}
#rodoviario .rodoviario .rotas-container--text .text p,
#rodoviario .rodoviario .frotas-container--text .text p,
#rodoviario .rodoviario .cargas-container--text .text p {
  color: #383838;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#rodoviario .rodoviario .rotas-container--text .text p strong,
#rodoviario .rodoviario .frotas-container--text .text p strong,
#rodoviario .rodoviario .cargas-container--text .text p strong {
  color: #00AEEF;
  font-family: inherit;
  font-weight: 700;
}
#rodoviario .rodoviario .rotas-container {
  gap: 130px;
  padding-inline-end: 77px;
}
@media (max-width: 1100px) {
  #rodoviario .rodoviario .rotas-container {
    padding-inline-end: 0;
    gap: 0;
    flex-direction: column;
  }
}
#rodoviario .rodoviario .rotas-container .clip-text {
  top: -130px;
  right: -208px;
}
@media (max-width: 1100px) {
  #rodoviario .rodoviario .rotas-container .clip-text {
    left: 0;
  }
}
#rodoviario .rodoviario .rotas-container--text {
  max-width: 621px;
  align-self: end;
  padding-bottom: 32px;
}
@media (max-width: 1600px) {
  #rodoviario .rodoviario .rotas-container--text {
    margin-top: 150px;
  }
}
@media (max-width: 1100px) {
  #rodoviario .rodoviario .rotas-container--text {
    margin-top: 100px;
    padding-inline: 24px;
  }
}
#rodoviario .rodoviario .rotas-container .listras {
  left: 0;
  top: -15px;
  transform: rotate(180deg);
}
@media (max-width: 1100px) {
  #rodoviario .rodoviario .rotas-container .listras {
    display: none;
  }
}
#rodoviario .rodoviario .frotas-container {
  gap: 131px;
  padding-inline-start: 77px;
  flex-direction: row-reverse;
}
#rodoviario .rodoviario .frotas-container .clip-text {
  top: -80px;
  left: 359px;
}
@media (max-width: 1100px) {
  #rodoviario .rodoviario .frotas-container .clip-text {
    left: 15px;
    top: -140px;
  }
}
#rodoviario .rodoviario .frotas-container--text {
  max-width: 546px;
  align-self: center;
  padding-top: 32px;
}
@media (max-width: 1100px) {
  #rodoviario .rodoviario .frotas-container--text {
    padding-inline: 24px;
  }
}
#rodoviario .rodoviario .frotas-container .listras {
  right: 0;
  bottom: -19px;
}
@media (max-width: 1100px) {
  #rodoviario .rodoviario .frotas-container .listras {
    display: none;
  }
}
#rodoviario .rodoviario .cargas-container {
  gap: 132px;
  padding-inline-end: 77px;
}
@media (max-width: 1100px) {
  #rodoviario .rodoviario .cargas-container {
    padding-inline-end: 0;
    gap: 0;
    flex-direction: column;
  }
}
#rodoviario .rodoviario .cargas-container .clip-text {
  top: -130px;
  left: 22.5%;
}
@media (max-width: 1100px) {
  #rodoviario .rodoviario .cargas-container .clip-text {
    left: 8.5%;
  }
}
#rodoviario .rodoviario .cargas-container--text {
  max-width: 621px;
  align-self: center;
  padding-top: 32px;
}
@media (max-width: 1600px) {
  #rodoviario .rodoviario .cargas-container--text {
    margin-top: 150px;
    padding-bottom: 32px;
  }
}
@media (max-width: 1100px) {
  #rodoviario .rodoviario .cargas-container--text {
    margin-top: 100px;
    padding-inline: 24px;
    padding-top: 0;
  }
}
#rodoviario .rodoviario .cargas-container .listras {
  left: 0;
  bottom: -25px;
  transform: rotate(180deg);
}
@media (max-width: 1100px) {
  #rodoviario .rodoviario .cargas-container .listras {
    display: none;
  }
}
#rodoviario .rodoviario .rodoviario-item + .rodoviario-item {
  margin-top: 208px;
}
@media (max-width: 1100px) {
  #rodoviario .rodoviario .rodoviario-item + .rodoviario-item {
    margin-top: 100px;
    flex-direction: column;
    gap: 50px;
    padding: 0;
  }
}
#rodoviario .pracas {
  background: var(--tbl-default-off-white, #F5F5F5);
  padding: 100px 0 100px 77px;
  display: flex;
  justify-content: end;
  max-height: 886px;
  overflow: hidden;
}
@media (max-width: 1100px) {
  #rodoviario .pracas {
    padding-inline: 24px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
#rodoviario .pracas--text {
  max-width: 407px;
  align-self: center;
}
#rodoviario .pracas--text .divider {
  height: 4px;
  width: 63px;
  background: #00AEEF;
  margin-bottom: 35px;
}
#rodoviario .pracas--text h2 {
  color: #383838;
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  max-width: 223px;
  margin-block-end: 44px;
}
#rodoviario .pracas--text h2 strong {
  font-family: inherit;
  font-weight: 700;
}
#rodoviario .pracas--text .text p {
  color: #383838;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#rodoviario .pracas--text .text p strong {
  color: #00AEEF;
  font-family: inherit;
  font-weight: 700;
}
#rodoviario .pracas--text a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  margin-top: 50px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#rodoviario .pracas--text a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#rodoviario .pracas--text a:hover::after {
  transform: scaleY(1);
}
#rodoviario .pracas--img img {
  max-width: min(100%, 65vw);
  transform: translate(60px, -75px);
  z-index: -1;
}
@media (max-width: 1100px) {
  #rodoviario .pracas--img img {
    display: none;
  }
}

#esg_gente #cardProfessionals {
  max-width: min(1270px, 100%);
  position: relative;
  z-index: 3;
}
#esg_gente #cardProfessionals .card-professionals-container {
  gap: 0 8px;
  margin-block: -201px 100px;
  isolation: isolate;
}
#esg_gente #cardProfessionals .card-professionals-container .card-professional {
  padding: 34px 47px;
  color: #F5F5F5;
  height: 311px;
  mix-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
#esg_gente #cardProfessionals .card-professionals-container .card-professional .conteudo {
  position: unset;
  z-index: 2;
}
#esg_gente #cardProfessionals .card-professionals-container .card-professional::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%);
  mix-blend-mode: multiply;
  transition: all 0.3s ease-in;
}
#esg_gente #cardProfessionals .card-professionals-container .card-professional:hover {
  transition: all 0.3s ease-in;
  margin-top: -15px;
}
#esg_gente #cardProfessionals .card-professionals-container .card-professional:hover ::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(180deg, rgba(0, 132, 181, 0) 0%, #0084B5 100%);
  transition: all 0.3s ease-in;
}
#esg_gente #cardProfessionals .card-professionals-container .card-professional-img {
  inset: 0;
  object-fit: cover;
  height: 100%;
  z-index: -2;
}
#esg_gente #cardProfessionals .card-professionals-container .card-professional-title {
  font-family: "Revx Neue", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 100% */
  margin-bottom: 11px;
}
#esg_gente #cardProfessionals .card-professionals-container .card-professional-text {
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 156.25% */
}
#esg_gente #cardProfessionals .card-professionals-container .card-professional-title, #esg_gente #cardProfessionals .card-professionals-container .card-professional-text {
  z-index: 2;
}
#esg_gente .title-section {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 34px;
  position: relative;
}
#esg_gente .title-section::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
}
#esg_gente .title-section strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
#esg_gente .section1 {
  position: relative;
  padding-top: 338px;
  padding-bottom: 164px;
}
@media (max-width: 991px) {
  #esg_gente .section1 {
    padding-bottom: 50px;
    padding-top: 0;
  }
  #esg_gente .section1 .row {
    flex-direction: column-reverse;
  }
}
#esg_gente .section1 .title-section {
  margin-bottom: 23px;
}
@media (max-width: 991px) {
  #esg_gente .section1 .title-section {
    margin-top: 180px;
  }
}
#esg_gente .section1 p {
  color: #383838;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 621px;
  margin-bottom: 26px;
}
#esg_gente .section1 p strong {
  color: #00AEEF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
#esg_gente .section1 .video-section {
  position: absolute;
  width: 843px;
  height: 745px;
  top: 59px;
  right: 0;
  padding: 0;
}
@media (max-width: 1600px) {
  #esg_gente .section1 .video-section {
    max-width: 48%;
  }
}
@media (max-width: 991px) {
  #esg_gente .section1 .video-section {
    position: relative;
    max-width: inherit;
    width: 100%;
    height: auto;
    top: 0;
  }
}
#esg_gente .section1 .video-section .video {
  position: relative;
}
#esg_gente .section1 .video-section .video .img-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#esg_gente .section1 .video-section .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#esg_gente .section1 .video-section .video .linhas {
  position: absolute;
  right: 0;
  bottom: -12px;
}
@media (max-width: 991px) {
  #esg_gente .section1 .video-section .video .linhas {
    display: none;
  }
}
#esg_gente .section1 .texto-section {
  font-family: "Revx Neue";
  font-size: 250px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  /* Para navegadores WebKit (Safari, Chrome antigo) */
  text-stroke: 2px #00AEEF;
  /* Padrão */
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: 0;
  left: -351px;
  right: 0;
  margin: 0 auto;
  text-align: center;
  filter: blur(0px);
}
@media (max-width: 991px) {
  #esg_gente .section1 .texto-section {
    font-size: 60px;
    left: -75px;
    top: 330px;
  }
}
#esg_gente .section2 {
  position: relative;
}
#esg_gente .section2 .video {
  padding: 84px;
  position: relative;
  border-radius: 22px;
  background: #F5F5F5;
  z-index: 2;
}
@media (max-width: 991px) {
  #esg_gente .section2 .video {
    padding: 17.5px;
  }
}
#esg_gente .section2 .video .img-video {
  width: 100%;
  height: 456px;
  border-radius: 10px;
  object-fit: cover;
}
#esg_gente .section2 .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#esg_gente .section2 .quadrado {
  width: 948px;
  max-width: 100%;
  height: 546px;
  background: #0084B5;
  position: absolute;
  top: 186px;
  left: 0;
  z-index: 1;
}
@media (max-width: 991px) {
  #esg_gente .section2 .quadrado {
    width: 75%;
    height: 240px;
  }
}
#esg_gente .section3 {
  position: relative;
  padding-top: 461px;
  padding-bottom: 253px;
}
@media (max-width: 991px) {
  #esg_gente .section3 {
    padding-bottom: 50px;
    padding-top: 150px;
  }
  #esg_gente .section3 .row {
    flex-direction: column-reverse;
  }
}
#esg_gente .section3 .title-section {
  margin-bottom: 23px;
  margin-top: 79px;
}
@media (max-width: 991px) {
  #esg_gente .section3 .title-section {
    margin-top: 200px;
  }
}
#esg_gente .section3 p {
  color: #383838;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 621px;
  margin-bottom: 26px;
}
#esg_gente .section3 p strong {
  color: #00AEEF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
#esg_gente .section3 .video-section {
  position: absolute;
  width: 843px;
  height: 745px;
  top: 274px;
  left: 0;
  padding: 0;
}
@media (max-width: 1600px) {
  #esg_gente .section3 .video-section {
    max-width: 48%;
  }
}
@media (max-width: 991px) {
  #esg_gente .section3 .video-section {
    width: 100%;
    top: 0;
    position: relative;
    top: 0;
    height: auto;
    max-width: inherit;
  }
}
#esg_gente .section3 .video-section .video {
  position: relative;
}
#esg_gente .section3 .video-section .video .img-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#esg_gente .section3 .video-section .video .player img {
  position: absolute;
  top: calc(50% - 39px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
#esg_gente .section3 .video-section .video .linhas {
  position: absolute;
  left: 0;
  bottom: -33px;
}
@media (max-width: 991px) {
  #esg_gente .section3 .video-section .video .linhas {
    display: none;
  }
}
#esg_gente .section3 .video-section .video .linhas img {
  transform: rotate(180deg);
}
#esg_gente .section3 .texto-section {
  font-family: "Revx Neue";
  font-size: 250px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  text-stroke: 2px #00AEEF;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: 204px;
  left: 0;
  right: 0;
  text-align: end;
  filter: blur(0);
}
@media (max-width: 991px) {
  #esg_gente .section3 .texto-section {
    font-size: 63px;
    right: inherit;
    -webkit-text-stroke: 1.5px #00AEEF;
    text-stroke: 1.5px #00AEEF;
    top: 500px;
  }
}
#esg_gente .section4 {
  position: relative;
  padding-top: 156px;
  padding-bottom: 111px;
  background-color: #F5F5F5;
}
@media (max-width: 991px) {
  #esg_gente .section4 {
    padding-bottom: 50px;
  }
}
#esg_gente .section4 .title-section {
  text-align: center;
}
#esg_gente .section4 .title-section::after {
  right: 0;
  left: 0;
  margin: 0 auto;
}
#esg_gente .section4 p {
  color: #4F4F4F;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 627px;
  margin: 0 auto;
}
#esg_gente .section4 .blocos {
  margin-top: 83px;
}
#esg_gente .section4 .blocos .bloco {
  max-width: 406px;
  height: 326px;
  border-radius: 20px 0px;
  background: #FFF;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 40px 20px;
  margin-bottom: 30px;
}
#esg_gente .section4 .blocos .bloco h3 {
  color: #0084B5;
  font-family: "Revx Neue";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-top: 12px;
  margin-bottom: 20px;
}
#esg_gente .section4 .blocos .bloco p {
  color: #383838;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 20px;
}
#esg_gente .section4 .blocos .bloco a {
  display: inline-block;
  text-decoration: none;
  color: #00AEEF;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}
#esg_gente .section4 .blocos .bloco:hover {
  background-color: #0084B5;
  transition: 0.3s ease;
}
#esg_gente .section4 .blocos .bloco:hover img {
  filter: grayscale(100%) brightness(2);
  transition: 0.3s ease;
}
#esg_gente .section4 .blocos .bloco:hover h3,
#esg_gente .section4 .blocos .bloco:hover p,
#esg_gente .section4 .blocos .bloco:hover a {
  color: #fff;
  transition: 0.3s ease;
}
#esg_gente .section4 .texto-section {
  font-family: "Revx Neue";
  font-size: 250px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  color: transparent;
  -webkit-text-stroke: 2px #00AEEF;
  text-stroke: 2px #00AEEF;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 99;
  position: absolute;
  top: -60px;
  left: -30px;
  right: 0;
  margin: 0 auto;
  text-align: start;
  filter: blur(0);
}
@media (max-width: 991px) {
  #esg_gente .section4 .texto-section {
    font-size: 60px;
    left: 15px;
    -webkit-text-stroke: 1.5px #00AEEF;
  }
}

#contract .table-container {
  width: 100%;
  margin: 20px 0;
  overflow-x: auto;
}
#contract table {
  width: 100%;
  border-collapse: collapse;
}
#contract thead {
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: white;
}
#contract th, #contract td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}
#contract th {
  height: 50px;
}
#contract td {
  height: 40px;
  font-weight: bold;
}
#contract .shipment-tracking th {
  border-left: 1px solid #fff;
}
#contract .grid-header {
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.33fr;
  font-size: 16px;
  font-weight: bold;
}
#contract .grid-header div {
  border: 1px solid #383838;
  padding: 5px;
}
#contract .grid-footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}
#contract .grid-footer div {
  border: 1px solid #383838;
  padding: 5px;
}
#contract .consulta {
  padding: 96px 77px 152px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
#contract .consulta-container {
  width: min(1280px, 100%);
  display: flex;
  justify-content: space-between;
  gap: 32px;
  flex-wrap: wrap;
}
#contract .consulta-container--text {
  max-width: 416px;
}
#contract .consulta-container--text .divider {
  height: 4px;
  width: 63px;
  background: #00AEEF;
  margin-bottom: 35px;
}
#contract .consulta-container--text h2 {
  color: #383838;
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  max-width: 290px;
  margin-bottom: 32px;
}
#contract .consulta-container--text h2 strong {
  font-family: inherit;
  font-weight: 700;
}
#contract .consulta-container--text .description p {
  color: var(--tbl-default-black, #383838);
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
#contract .consulta-container--text .description p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-weight: 700;
}
#contract .consulta-container--forms {
  width: min(100%, 625px);
  align-self: center;
}
#contract .consulta-container--forms .form-row + .form-row {
  margin-top: 20px;
}
#contract .consulta-container--forms .form-control {
  padding: 24px 32px;
  background: var(--tbl-default-off-white, #F5F5F5);
  min-width: 100%;
  border: none;
  border-radius: 0;
}
#contract .consulta-container--forms .form-control, #contract .consulta-container--forms .form-control::placeholder {
  color: var(--tbl-neutral-neutral-300, #898989);
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
#contract .consulta-container--forms .form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 133, 181, 0.4588235294);
}
#contract .consulta-container--forms button > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  margin-top: 32px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#contract .consulta-container--forms button > a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#contract .consulta-container--forms button > a:hover::after {
  transform: scaleY(1);
}
@media (width < 991px) {
  #contract .consulta {
    padding: 32px 25px 60px;
  }
  #contract .consulta-container {
    flex-direction: column;
    gap: 40px;
  }
  #contract .consulta-container--text {
    max-width: 100%;
  }
  #contract .consulta-container--text h2 {
    font-size: 28px;
    line-height: 1;
    max-width: 180px;
  }
  #contract .consulta-container--text p {
    font-size: 14px;
    line-height: 18px;
  }
  #contract .consulta-container--forms button,
#contract .consulta-container--forms button > a {
    min-width: 100%;
  }
}
#contract .our-news {
  background-color: #F5F5F5;
}
@media (max-width: 1100px) {
  #contract .our-news {
    padding-top: 120px;
  }
}

#invoice .table-container {
  width: 100%;
  margin: 20px 0;
  overflow-x: auto;
}
#invoice table {
  width: 100%;
  border-collapse: collapse;
}
#invoice thead {
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: white;
}
#invoice th, #invoice td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}
#invoice th {
  height: 50px;
}
#invoice td {
  height: 40px;
  font-weight: bold;
}
#invoice .nf {
  padding: 96px 77px 152px;
  display: flex;
  justify-content: center;
}
#invoice .nf-container {
  width: min(1280px, 100%);
  gap: 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#invoice .nf-container--text {
  max-width: 416px;
}
#invoice .nf-container--text .divider {
  height: 4px;
  width: 63px;
  background: #00AEEF;
  margin-bottom: 35px;
}
#invoice .nf-container--text h2 {
  color: #383838;
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  /* 111.111% */
  max-width: 290px;
  margin-bottom: 32px;
}
#invoice .nf-container--text h2 strong {
  font-family: inherit;
  font-weight: 700;
}
#invoice .nf-container--text .description p {
  color: var(--tbl-default-black, #383838);
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
#invoice .nf-container--text .description p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-weight: 700;
}
#invoice .nf-container--forms {
  width: min(100%, 766px);
  padding-top: 35px;
}
#invoice .nf-container--forms .form-row + .form-row {
  margin-top: 20px;
}
#invoice .nf-container--forms .form-row {
  gap: 20px;
}
#invoice .nf-container--forms .form-row:not(:last-child) > div:nth-child(1) {
  flex-grow: 1;
}
#invoice .nf-container--forms .form-row:not(:last-child) > div:nth-child(2) {
  width: min(100%, 250px) !important;
}
#invoice .nf-container--forms .form-row .form-control {
  padding: 24px 32px;
  background: var(--tbl-default-off-white, #F5F5F5);
  min-width: 100%;
  border: none;
  border-radius: 0;
}
#invoice .nf-container--forms .form-row .form-control, #invoice .nf-container--forms .form-row .form-control::placeholder {
  color: var(--tbl-neutral-neutral-300, #898989);
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
#invoice .nf-container--forms .form-row .form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 133, 181, 0.4588235294);
}
#invoice .nf-container--forms .form-row .form-group {
  padding: 24px 32px;
  background: var(--tbl-default-off-white, #F5F5F5);
  min-width: 100%;
  border: none;
  border-radius: 0;
}
#invoice .nf-container--forms .form-row .form-group .form-control-label {
  color: var(--tbl-neutral-neutral-300, #898989);
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}
#invoice .nf-container--forms .form-row .form-group .check-wrapper {
  gap: 20px;
}
#invoice .nf-container--forms .form-row .form-group .check-wrapper .form-check {
  gap: 20px;
}
#invoice .nf-container--forms .form-row .form-group .check-wrapper .form-check-label {
  color: var(--tbl-neutral-neutral-300, #898989);
  order: 2;
  font-family: inherit;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
#invoice .nf-container--forms .form-row .form-group .check-wrapper .form-check-input {
  border-radius: 50%;
  border: 2px solid #00AEEF;
  width: 20px;
  height: 20px;
  order: 1;
}
#invoice .nf-container--forms button > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  margin-top: 32px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#invoice .nf-container--forms button > a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#invoice .nf-container--forms button > a:hover::after {
  transform: scaleY(1);
}
@media (width < 991px) {
  #invoice .nf {
    padding: 32px 25px 60px;
  }
  #invoice .nf-container {
    flex-direction: column;
    gap: 40px;
  }
  #invoice .nf-container--text {
    max-width: 100%;
  }
  #invoice .nf-container--text h2 {
    font-size: 28px;
    line-height: 1;
    max-width: 180px;
  }
  #invoice .nf-container--text p {
    font-size: 14px;
    line-height: 18px;
  }
  #invoice .nf-container--forms .form-row + .form-row {
    margin-top: 32px;
  }
  #invoice .nf-container--forms .form-row {
    flex-direction: column;
  }
  #invoice .nf-container--forms .form-row:not(:last-child) > div:nth-child(2) {
    min-width: 100% !important;
  }
  #invoice .nf-container--forms button,
#invoice .nf-container--forms button > a {
    min-width: 100%;
  }
}

#politica .banner-normal {
  max-height: 100vh;
}
#politica .content {
  padding: 96px 0;
}
@media (max-width: 1100px) {
  #politica .content {
    padding: 71px 0 20px;
    padding-inline: 17.5px;
  }
}
#politica .content h2 {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 34px;
  position: relative;
}
#politica .content h2::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
}
#politica .content h2 strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  #politica .content h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 100% */
  }
  #politica .content h2 strong {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}
#politica .content h3 {
  color: var(--tbl-default-black, #383838);
  font-family: Revx Neue;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-top: 92px;
  margin-bottom: 40px;
  position: relative;
}
#politica .content h3::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 4px;
  background-color: #00AEEF;
  top: -38px;
  left: 0;
}
#politica .content h3 strong {
  color: var(--tbl-default-black, #383838);
  font-family: "Revx Neue";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
@media (max-width: 1100px) {
  #politica .content h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 110% */
  }
  #politica .content h3 strong {
    font-size: 20px;
    line-height: 22px;
    /* 110% */
  }
}
#politica .content p {
  color: var(--tbl-default-black, #383838);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 20px;
  max-width: 1063px;
}
#politica .content p strong {
  color: var(--tbl-primary-primary-500, #00AEEF);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #politica .content p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
  #politica .content p strong {
    line-height: 18px;
    /* 128.571% */
    font-size: 14px;
  }
}
#politica .content ul {
  padding-left: 20px;
  margin-bottom: 56px !important;
}
#politica .content ul li {
  list-style: disc;
  color: var(--tbl-default-black, #383838);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #politica .content ul li {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}
#politica .our-news {
  background: #F5F5F5;
}
@media (max-width: 1100px) {
  #politica .our-news {
    padding-top: 120px;
  }
}
#politica .explore {
  background-color: #F5F5F5;
}

#successForm {
  position: relative;
  min-height: 100vh;
  display: grid;
  place-items: center;
}
#successForm::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 18.24%, rgba(0, 132, 181, 0) 81.3%);
  mix-blend-mode: multiply;
  z-index: -1;
}
#successForm .bg-success {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}
#successForm .success-wrapper {
  padding: 120px;
  margin-top: 80px;
  color: var(--tbl-default-off-white, #F5F5F5);
  text-align: center;
  max-width: 870px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#successForm .success-wrapper--title {
  font-family: "Revx Neue", sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  max-width: 410px;
  margin-block-end: 32px;
}
#successForm .success-wrapper--sub-title {
  font-family: "Revx Neue", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
}
#successForm .success-wrapper--text {
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-width: 494px;
  margin-block: 32px 56px;
}
#successForm .success-wrapper .sub-title-wrapper {
  display: flex;
  gap: 20px;
  text-align: start;
  max-width: 500px;
}
#successForm .success-wrapper .sub-title-wrapper img {
  max-width: 27px;
  aspect-ratio: 1;
}
#successForm .success-wrapper .btn-white {
  display: flex;
  padding: 15px 50px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  background: #F5F5F5;
  color: var(--tbl-secondary-secondary-500, #0084B5);
  font-family: inherit;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
#successForm .success-wrapper .btn-white:hover {
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: #F5F5F5;
}
@media (width < 991px) {
  #successForm .success-wrapper {
    padding: 25px;
  }
  #successForm--title {
    font-size: 42px;
    line-height: 100%;
    margin-bottom: 20px;
  }
  #successForm--sub-title {
    text-align: center;
    font-size: 20px;
    line-height: 22px;
  }
  #successForm--text {
    font-size: 14px;
    line-height: 18px;
    margin-block: 20px 40px;
  }
  #successForm .sub-title-wrapper {
    flex-direction: column;
    gap: 12px;
    text-align: center;
  }
  #successForm .sub-title-wrapper img {
    max-width: 24px;
    display: block;
    margin-inline: auto;
  }
  #successForm .btn-white {
    font-size: 14px;
  }
}

#rastreie {
  height: 100vh;
  background-color: #006083;
  display: grid;
  place-items: center;
}
#rastreie .wrapper {
  max-width: fit-content;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
}
#rastreie .wrapper span {
  display: block;
  margin-inline: auto;
}
#rastreie .wrapper span svg {
  width: 40px;
  height: 40px;
}
#rastreie .wrapper span svg path {
  fill: #f5f5f5;
}
#rastreie .wrapper h1 {
  color: #f5f5f5;
  font-family: "Revx Neue", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}
#rastreie .wrapper button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 185px;
  background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  color: var(--tbl-default-white, #FFF);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}
#rastreie .wrapper button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
  z-index: -1;
  transition: 0.4s;
  transition: transform 0.3s ease;
  transform-origin: 0 100%;
  will-change: transform;
  transform: scaleY(0);
}
#rastreie .wrapper button:hover::after {
  transform: scaleY(1);
}
@media (max-width: 1100px) {
  #rastreie .wrapper button {
    width: 100%;
    margin-top: 40px;
  }
}
#rastreie .wrapper button a {
  text-decoration: none;
}