#politica {
    .banner-normal { max-height: 100vh; }    
    .content {
        padding: 96px 0;
        @media (max-width: 1100px) {
            padding: 71px 0 20px;
            padding-inline: 17.5px;
        }
        h2 {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            margin-bottom: 34px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 63px;
                height: 4px;
                background-color: #00AEEF;
                top: -38px;
                left: 0;
            }

            strong {
                color: var(--tbl-default-black, #383838);
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }
            @media (max-width: 1100px) {
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px; /* 100% */
                strong{
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
        }

        h3 {
            color: var(--tbl-default-black, #383838);
            font-family: Revx Neue;
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
            line-height: 27px;
            margin-top: 92px;
            margin-bottom: 40px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 63px;
                height: 4px;
                background-color: #00AEEF;
                top: -38px;
                left: 0;
            }

            strong {
                color: var(--tbl-default-black, #383838);
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }
            @media (max-width: 1100px) {
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px; /* 110% */
                strong{
                    font-size: 20px;
                    line-height: 22px; /* 110% */
                }
            }
        }

        p {
            color: var(--tbl-default-black, #383838);
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            margin-bottom: 20px;
            max-width: 1063px;

            strong {
                color: var(--tbl-primary-primary-500, #00AEEF);
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
                strong{
                    line-height: 18px; /* 128.571% */
                    font-size: 14px;
                }
            }
        }

        ul {
            padding-left: 20px;
            margin-bottom: 56px !important;

            li {
                list-style: disc;
                color: var(--tbl-default-black, #383838);
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                @media (max-width: 1100px) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px; /* 128.571% */
                }
            }
        }
    }
    .our-news {
        background: #F5F5F5;

        @media (max-width: 1100px) {
            padding-top: 120px;
        }
    }
    .explore { background-color: #F5F5F5; }
}
