#successForm {
    position: relative;
    min-height: 100vh;

    display: grid;
    place-items: center;

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(90deg, rgba(0, 132, 181, 0.95) 18.24%, rgba(0, 132, 181, 0.00) 81.3%);
        mix-blend-mode: multiply;      
        z-index: -1;  
    }
    .bg-success {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -2;
    }
    .success-wrapper {
        padding: 120px;
        margin-top: 80px;
        color: var(--tbl-default-off-white, #F5F5F5);
        text-align: center;
        max-width: 870px;
        z-index: 1;
        
        display: flex;
        flex-direction: column;
        align-items: center;

        &--title {
            font-family: 'Revx Neue', sans-serif;
            font-size: 52px;
            font-style: normal;
            font-weight: 700;
            line-height: 1;

            max-width: 410px;
            margin-block-end: 32px;
        }
        &--sub-title {
            font-family: 'Revx Neue', sans-serif;
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
            line-height: 27px;
        }
        &--text {
            font-family: inherit;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;     
            
            max-width: 494px;
            margin-block: 32px 56px;
        }
        .sub-title-wrapper {
            display: flex;
            gap: 20px;
            text-align: start;
            max-width: 500px;

            img { 
                max-width: 27px;
                aspect-ratio: 1;
            }
        }
        .btn-white {
            display: flex;
            padding: 15px 50px;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            transition: all .5s ease-in-out;

            background: #F5F5F5;
            color: var(--tbl-secondary-secondary-500, #0084B5);
            font-family: inherit;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal; 

            &:hover {
                background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                color: #F5F5F5;
            }
        }
    }

    @media (width < 991px) {
        .success-wrapper { padding: 25px; }

        &--title {
            font-size: 42px;
            line-height: 100%;    
            margin-bottom: 20px;    
        }
        &--sub-title {
            text-align: center;
            font-size: 20px;
            line-height: 22px;            
        }
        &--text {
            font-size: 14px;
            line-height: 18px;     
            margin-block: 20px 40px;       
        }
        .sub-title-wrapper {
            flex-direction: column;
            gap: 12px;
            text-align: center;

            img { 
                max-width: 24px;
                display: block;
                margin-inline: auto;
            }
        }
        .btn-white { font-size: 14px; }
    }
}