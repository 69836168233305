#esg_gente {


    // componente cardProfessionals
    #cardProfessionals {
        max-width: Min(1270px, 100%);
        position: relative;
        z-index: 3;

        .card-professionals-container {
            gap: 0 8px;
            margin-block: -201px 100px;
            isolation: isolate;

            .card-professional {
                padding: 34px 47px;
                color: #F5F5F5;
                height: 311px;
                mix-blend-mode: multiply;

                display: flex;
                flex-direction: column;
                justify-content: end;

                .conteudo {
                    position: unset;
                    z-index: 2;
                }

                &::after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    z-index: -1;
                    background: linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%);
                    mix-blend-mode: multiply;
                    transition: all .3s ease-in;
                }

                &:hover {
                    transition: all .3s ease-in;
                    margin-top: -15px;

                    ::after {
                        content: '';
                        position: absolute;
                        inset: 0;
                        z-index: -1;
                        background: linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%);
                        transition: all .3s ease-in;
                    }
                }

                &-img {
                    inset: 0;
                    object-fit: cover;
                    height: 100%;
                    z-index: -2;
                }

                &-title {
                    font-family: 'Revx Neue', sans-serif;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px;
                    /* 100% */
                    margin-bottom: 11px;
                }

                &-text {
                    font-family: inherit;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    /* 156.25% */
                }

                &-title,
                &-text {
                    z-index: 2;
                }
            }
        }
    }

    .title-section {
        color: var(--tbl-default-black, #383838);
        font-family: 'Revx Neue';
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        margin-bottom: 34px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 63px;
            height: 4px;
            background-color: #00AEEF;
            top: -38px;
            left: 0;
        }

        strong {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px;
        }
    }

    .section1 {
        position: relative;
        padding-top: 338px;
        padding-bottom: 164px;
        @media (max-width: 991px) {
            padding-bottom: 50px;
            padding-top: 0;
            .row{
                flex-direction: column-reverse;
            }
        }
        .title-section {
            margin-bottom: 23px;
            @media (max-width: 991px) {
                margin-top: 180px;
            }
        }

        p {
            color: #383838;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 621px;
            margin-bottom: 26px;
            strong {
                color: #00AEEF;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
        }

        .video-section {
            position: absolute;
            width: 843px;
            height: 745px;
            top: 59px;
            right: 0;
            padding: 0;

            @media(max-width: 1600px) {
                max-width: 48%;
            }
            @media (max-width: 991px) {
                position: relative;
                max-width: inherit;
                width: 100%;
                height: auto;
                top: 0;
            }
            .video {
                position: relative;

                .img-video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }


                .player img {
                    position: absolute;
                    top: calc(50% - 39px);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                .linhas {
                    position: absolute;
                    right: 0;
                    bottom: -12px;
                    @media (max-width: 991px) {
                        display: none;
                    }
                }
            }
        }


        .texto-section {
            font-family: "Revx Neue";
            font-size: 250px;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #00AEEF; /* Para navegadores WebKit (Safari, Chrome antigo) */
            text-stroke: 2px #00AEEF; /* Padrão */
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: 0;
            left: -351px;
            right: 0;
            margin: 0 auto;
            text-align: center;
            filter: blur(0px);
            @media (max-width: 991px) {
                font-size: 60px;
                left: -75px;
                top: 330px;
            }
        }


    }

    .section2 {
        position: relative;

        .video {
            padding: 84px;
            position: relative;
            border-radius: 22px;
            background: #F5F5F5;
            z-index: 2;
            @media (max-width: 991px) {
                padding: 17.5px;
            }
            .img-video {
                width: 100%;
                height: 456px;
                border-radius: 10px;
                object-fit: cover;
            }


            .player img {
                position: absolute;
                top: calc(50% - 39px);
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }

        .quadrado {
            width: 948px;
            max-width: 100%;
            height: 546px;
            background: #0084B5;
            position: absolute;
            top: 186px;
            left: 0;
            z-index: 1;
            @media (max-width: 991px) {
                width: 75%;
                height: 240px;
            }
        }
    }

    .section3 {
        position: relative;
        padding-top: 461px;
        padding-bottom: 253px;
        @media (max-width: 991px) {
            padding-bottom: 50px;
            padding-top: 150px;
            .row{
                flex-direction: column-reverse;
            }
        }
        .title-section {
            margin-bottom: 23px;
            margin-top: 79px;
            @media (max-width: 991px) {
                margin-top: 200px;
            }
        }

        p {
            color: #383838;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 621px;
            margin-bottom: 26px;

            strong {
                color: #00AEEF;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
        }

        .video-section {
            position: absolute;
            width: 843px;
            height: 745px;
            top: 274px;
            left: 0;
            padding: 0;

            @media(max-width: 1600px) {
                max-width: 48%;
            }
            @media (max-width: 991px) {
                width: 100%;
                top: 0;
                position: relative;
                top: 0;
                height: auto;
                max-width: inherit;
            }
            .video {
                position: relative;

                .img-video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }


                .player img {
                    position: absolute;
                    top: calc(50% - 39px);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                .linhas {
                    position: absolute;
                    left: 0;
                    bottom: -33px;
                    @media (max-width: 991px) {
                        display: none;
                    }
                    img {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .texto-section {
            font-family: "Revx Neue";
            font-size: 250px;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #00AEEF;
            text-stroke: 2px #00AEEF;
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: 204px;
            left: 0;
            right: 0;
            text-align: end;
            filter: blur(0);
            @media (max-width: 991px) {
                font-size: 63px;
                right: inherit;
                -webkit-text-stroke: 1.5px #00AEEF;
                text-stroke: 1.5px #00AEEF;
                top: 500px;
            }
        }
    }

    .section4 {
        position: relative;
        padding-top: 156px;
        padding-bottom: 111px;
        background-color: #F5F5F5;
        @media (max-width: 991px) {
            padding-bottom: 50px;
        }
        .title-section {
            text-align: center;

            &::after {
                right: 0;
                left: 0;
                margin: 0 auto;
            }
        }

        p {
            color: #4F4F4F;
            text-align: center;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 627px;
            margin: 0 auto;
        }

        .blocos {
            margin-top: 83px;

            .bloco {
                max-width: 406px;
                height: 326px;
                border-radius: 20px 0px;
                background: #FFF;
                box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
                text-align: center;
                padding: 40px 20px;
                margin-bottom: 30px;

                h3 {
                    color: #0084B5;
                    font-family: 'Revx Neue';
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                    margin-top: 12px;
                    margin-bottom: 20px;
                }

                p {
                    color: #383838;
                    text-align: center;
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    margin-bottom: 20px;
                }

                a {
                    display: inline-block;
                    text-decoration: none;
                    color: #00AEEF;
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 25px;
                }


                &:hover {
                    background-color: #0084B5;
                    transition: 0.3s ease;

                    img {
                        filter: grayscale(100%) brightness(2);
                        transition: 0.3s ease;
                    }

                    h3,
                    p,
                    a {
                        color: #fff;
                        transition: 0.3s ease;
                    }
                }
            }
        }


        .texto-section {
            font-family: "Revx Neue";
            font-size: 250px;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #00AEEF;
            text-stroke: 2px #00AEEF;
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: -60px;
            left: -30px;
            right: 0;
            margin: 0 auto;
            text-align: start;
            filter: blur(0);
            @media (max-width: 991px) {
                font-size: 60px;
                left: 15px;
                -webkit-text-stroke: 1.5px #00AEEF;
            }
        }
    }

}
