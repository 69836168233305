#footer {
    .news {
        padding: 99px 0 75px 0;
        background: #00AEEF;

        @media (max-width: 1100px) {
            padding: 30px 17.5px 33px 17.5px;
        }

        h3 {
            color: #FFF;
            font-family: 'Revx Neue';
            font-size: 32px;
            font-style: normal;
            font-weight: 300;
            line-height: 40px;

            strong {
                color: #FFF;
                font-family: 'Revx Neue';
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: 40px;
            }

            @media (max-width: 1100px) {
                font-size: 25px;
                font-style: normal;
                font-weight: 300;
                line-height: 30px;
                margin-bottom: 24px;
                max-width: 80%;

                br {
                    display: none;
                }

                strong {
                    font-size: 25px;
                    font-style: normal;
                    line-height: 30px;
                }
            }
        }

        form {

            input,
            input::placeholder {
                color: #575756;
                font-family: 'Roboto';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 32px;

                @media (max-width: 1100px) {
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    height: 59px;
                }
            }

            input {
                border: none;
                padding: 9px 27px;
                width: 100%;
            }

            input[type="submit"] {
                padding: 15px 0px;
                background: #0084B5;
                color: var(--tbl-default-white, #FFF);
                font-family: 'Roboto';
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                position: relative;
                z-index: 1;
                overflow: hidden;
            }

            .aceite {
                display: flex;
                align-items: start;
                margin-top: 28px;
                gap: 15px;

                p {
                    color: #00668B;
                    font-family: 'Roboto';
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;

                    @media (max-width: 1100px) {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        /* 128.571% */
                    }
                }
            }

            .btn-section {
                height: 50px;
                width: 190px;
                background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #036f96 0%, #0084B5 134.33%));
                color: var(--tbl-default-white, #FFF);
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 0;
                margin-right: 0;
                text-decoration: none;
                position: relative;
                z-index: 1;
                overflow: hidden;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #036f96 0%, #0084B5 134.33%));
                    z-index: -1;
                    transition: 0.4s;
                    transition: transform 0.3s ease;
                    transform-origin: 0 100%;
                    will-change: transform;
                    transform: scaleY(0);
                }

                &:hover {
                    &::after {
                        transform: scaleY(1);
                    }
                }
            }

        }

        .checkbox.style-c {
            display: inline-block;
            position: relative;
            padding-left: 30px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .checkbox.style-c input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkbox.style-c input:checked~.checkbox__checkmark {
            background-color: #0084B5;
        }

        .checkbox.style-c input:checked~.checkbox__checkmark:after {
            opacity: 1;
        }

        .checkbox.style-c:hover input~.checkbox__checkmark {
            background-color: #eee;
        }

        .checkbox.style-c:hover input:checked~.checkbox__checkmark {
            background-color: #0084B5;
        }

        .checkbox.style-c .checkbox__checkmark {
            position: absolute;
            top: 2px;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #eee;
            transition: background-color 0.25s ease;
            border-radius: 4px;
        }

        .checkbox.style-c .checkbox__checkmark:after {
            content: "";
            position: absolute;
            left: 8px;
            top: 4px;
            width: 5px;
            height: 10px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            opacity: 0;
            transition: opacity 0.25s ease;
        }

        .checkbox.style-c .checkbox__body {
            color: #fff;
            line-height: 1.4;
            font-size: 16px;
        }

    }

    .links {
        padding-top: 111px;
        border-bottom: solid 1px #FFF;
        background: #0084B5;

        @media (max-width: 1100px) {
            padding-inline: 25px;
            padding-top: 68px;
        }

        .logos {
            display: grid;
            gap: 42px;

            @media (max-width: 1100px) {
                grid-template-columns: 50% 50%;
                gap: 15px;
                align-items: center;
                margin-bottom: 45px;
                img{
                    width: 100%;
                }
            }
        }

        h4 {
            color: #FFF;
            font-family: 'Roboto';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 30px;

            @media (max-width: 1100px) {
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

            }
        }

        ul {
            padding-left: 0;

            li {
                margin-bottom: 14px;

                a {
                    display: inline-block;
                    text-decoration: none;
                    color: #FFF;
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    transition: .3s ease-in;
                    position: relative;

                    &:after {
                        content: "";
                        width: 100%;
                        border-bottom: 1px solid #fff;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        opacity: 0;
                        transition: opacity .3s ease-in;
                    }

                    &:hover {
                        text-decoration: underline 2px #f5f5f5;
                        text-underline-offset: 6px;
                        transition: .3s ease-out;
                    }

                    @media (max-width: 1100px) {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }

        .parte2 {
            margin-top: 60px;
            margin-bottom: 29px;

            @media (max-width: 1100px) {
                margin-bottom: 43px;
            }

            p {
                color: #FFF;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;

                @media (max-width: 1100px) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                a {
                    color: inherit;
                    font-size: inherit;
                    text-decoration: none;
                    transition: all .3s ease-out;

                    &:hover {
                        text-decoration: underline 2px #f5f5f5;
                        text-underline-offset: 6px;
                    }
                }
            }
        }
    }

    .copy {
        padding: 21px 0;
        background: #0084B5;

        @media (max-width: 1100px) {
            padding-inline: 25px;
            padding-top: 30px;
            padding-bottom: 17px;
        }

        a {
            display: inline-block;
            text-decoration: none;
        }

        .redes {
            display: flex;
            gap: 20px;

            @media (max-width: 1100px) {
                display: none;
            }
        }

        .politcas {
            display: flex;
            gap: 41px;
            margin-left: 56px;

            @media(max-width: 1600px) {
                margin-left: 0px;
            }

            @media (max-width: 1100px) {
                flex-direction: column;
                gap: 0;
            }

            a {
                color: #FFF;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
            }

            p {
                color: #FFF;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
            }
        }

    }

    .error--message {
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
    }
}
