#contact {
    overflow: hidden;

    .mask-text {
        margin-block: 45px 120px;
        text-align: end;
        height: fit-content;

        h3 {
            font-family: 'Revx Neue', sans-serif;
            font-size: 13vw;
            font-style: normal;
            font-weight: 700;
            line-height: 120px;
            line-clamp: 1;
            color: transparent;
            -webkit-text-stroke: 2px #00AEEF;
            /* width and color of the stroke */
            text-stroke: 2px #00AEEF;
            /* Standard syntax, if supported */
            background-clip: text;
            position: relative;
            bottom: -50px;
            left: auto;
            right: 0;
            filter: blur(0);
        }

        @media (width < 991px) {
            h3 {
                font-size: 90px;
                font-style: normal;
                font-weight: 700;
                line-height: 80px;
            }
        }
    }

    .form-contato {
        &-container {
            display: flex;
            flex-direction: column;
            gap: 80px;

            &--text {
                .divider {
                    height: 4px;
                    width: 63px;
                    background: #00AEEF;
                    margin-bottom: 35px;
                }

                h2 {
                    color: #383838;

                    font-family: 'Revx Neue', sans-serif;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 50px;
                    max-width: 200px;
                    margin-block-end: 26px;

                    strong {
                        font-family: inherit;
                        font-weight: 700;
                    }
                }

                .content-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .text {
                        max-width: 521px;

                        p {
                            color: var(--tbl-default-black, #383838);

                            font-family: inherit;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 25px;
                            /* 156.25% */
                        }
                    }

                    .select-wrapper {
                        width: 406px;

                        .form-select {
                            $bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
                            background-image: $bs-form-select-bg-img;
                            background-color: var(--tbl-primary-primary-500, #00AEEF);
                            background-position: right 24px center;
                            background-size: 24px;
                            padding: 24px 32px;
                            border: none;
                            border-radius: 0 !important;
                            text-align: start;
                            width: 100%;

                            &,
                            &::placeholder {
                                color: var(--bertolini-white, #FFF);
                                font-family: inherit;
                                font-size: 17px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 100%;
                                /* 17px */
                            }
                        }

                        .options {
                            padding: 14px 0;
                            font-size: 17px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 100%;
                            background-color: #006083;

                            &:not(:last-child) {
                                border-bottom: 1px solid #339DC4;
                            }
                        }
                    }
                }
            }

            &--forms {
                .form-container {
                    .form-row+.form-row {
                        margin-top: 20px;
                    }

                    .form-row {
                        gap: 32px;

                        &>div {
                            &:nth-child(1) {
                                flex-grow: 1;
                            }

                            &:nth-child(2) {
                                width: Min(100%, 406px) !important;
                            }
                        }

                        .form-label {
                            color: var(--tbl-default-black, #383838);

                            font-family: inherit;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 18px;
                            /* 128.571% */
                            margin-bottom: 12px;
                        }

                        .form-control {
                            padding: 24px 32px;
                            background: var(--tbl-default-off-white, #F5F5F5);
                            min-width: 100%;
                            border: none;
                            border-radius: 0;

                            &,
                            &::placeholder {
                                color: var(--tbl-neutral-neutral-300, #898989);

                                font-family: inherit;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 100%;
                            }

                            &:focus {
                                box-shadow: 0 0 0 0.25rem #0085b575;
                            }
                        }

                        .form-select {
                            $bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
                            background-image: $bs-form-select-bg-img;
                            background-color: var(--tbl-default-off-white, #F5F5F5);
                            background-position: right 24px center;
                            background-repeat: no-repeat;
                            background-size: 24px;
                        }

                        textarea {
                            resize: none;
                            width: 100%;
                            height: 200px;
                        }
                    }

                    .form-check {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .form-check-input {
                            border-radius: 1px;
                            border: 1px solid var(--tbl-primary-primary-500, #00AEEF);
                            width: 24px;
                            height: 24px;
                            box-shadow: 0px 10px 32px 0px rgba(0, 0, 0, 0.10);

                            &:checked {
                                background-color: #00AEEF;
                            }

                            &:focus {
                                outline: 0;
                            }
                        }

                        .form-check-label {
                            color: var(--tbl-neutral-neutral-300, #898989);

                            font-family: 'Revx Neue', sans-serif;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 16px;
                            /* 100% */
                        }
                    }

                    .btn-submit {
                        margin-top: 80px;

                        a {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 50px;
                            width: 185px;
                            margin-top: 50px;
                            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                            color: var(--tbl-default-white, #FFF);
                            font-size: 17px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            z-index: 1;
                            text-decoration: none;
                            overflow: hidden;
                            position: relative;

                            &::after {
                                content: '';
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                                z-index: -1;
                                transition: 0.4s;
                                transition: transform 0.3s ease;
                                transform-origin: 0 100%;
                                will-change: transform;
                                transform: scaleY(0);
                            }

                            &:hover {
                                &::after {
                                    transform: scaleY(1);
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (width < 991px) {
            gap: 56px;

            .container {
                margin: 0;
                padding: 0;
                max-width: 100%;
            }

            &-container {
                &--text {

                    h2,
                    .divider {
                        margin-inline: 25px;
                    }

                    h2 {
                        font-size: 28px;
                        line-height: 28px;
                        max-width: 120px;

                        strong {
                            font-size: 28px;
                            line-height: 28px;
                        }
                    }

                    .content-container {
                        flex-direction: column;
                        justify-content: start;
                        align-items: start;
                        gap: 40px;

                        .text {
                            max-width: 100%;
                            padding-inline: 25px;

                            p {
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;
                            }
                        }

                        .select-wrapper {
                            width: 100%;
                            margin-inline: auto;

                            .form-select {
                                font-size: 14px;
                                line-height: 100%;
                            }
                        }
                    }
                }

                &--forms {
                    padding-inline: 25px;

                    .form-container {
                        .form-row {
                            flex-direction: column;

                            &>div {
                                &:nth-child(2) {
                                    width: 100% !important;
                                }
                            }
                        }

                        .form-check {
                            gap: 20px;

                            &-label {
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }

                        .btn-submit {
                            margin-top: 40px;

                            a,
                            button {
                                width: 100%
                            }

                            a {
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .unidades {
        .mask-text {
            inset: 170px auto auto 0;

            h3 {
                font-family: 'Revx Neue', sans-serif;
                font-size: 13vw;
                font-style: normal;
                font-weight: 700;
                line-height: 100px;
                /* 40% */
                color: transparent;
                -webkit-text-stroke: 2px #00AEEF;
                /* width and color of the stroke */
                text-stroke: 2px #00AEEF;
                /* Standard syntax, if supported */
                background-clip: text;
                position: relative;
                bottom: 150px;
                left: 0;
                filter: blur(0);

                @media(min-width: 1900px) {
                    gap: -50px;
                }
            }
        }

        &-container {
            display: flex;
            justify-content: end;
            gap: 97.5px;
            padding-left: 207px;
            @media (max-width: 1399px) {
                padding-top: 100px;
            }
            &--text {
                width: 434px;
                align-self: center;

                .divider {
                    height: 4px;
                    width: 63px;
                    background: #00AEEF;
                    margin-bottom: 35px;
                }

                h2 {
                    color: #383838;

                    font-family: 'Revx Neue', sans-serif;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 50px;
                    max-width: 432px;
                    margin-block-end: 44px;

                    strong {
                        font-family: inherit;
                        font-weight: 700;
                    }
                }

                p {
                    color: var(--tbl-default-black, #383838);

                    font-family: inherit;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    margin-bottom: 32px;
                }

                .form-select {
                    $bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
                    background-image: $bs-form-select-bg-img;
                    background-color: var(--tbl-primary-primary-500, #00AEEF);
                    background-position: right 50px center;
                    background-size: 24px;
                    padding: 15px 50px;
                    border: none;
                    border-radius: 0 !important;

                    color: var(--bertolini-white, #FFF);
                    font-family: inherit;
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    /* 17px */
                    width: 100%;
                }
            }

            &--img {

                &,
                img {
                    max-width: 100%;
                    z-index: -1;
                    transform: scale(.7);
                }
            }
        }

        &-list {
            background: var(--tbl-default-off-white, #F5F5F5);
            display: flex;
            justify-content: start;
            padding-inline: 77px 168px;

            z-index: 2;
            transform: translateY(-207px);

            &--container {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 80px;
                padding-block: 84px;
                justify-content: space-between;

                @media(max-width: 1600px) {
                    gap: 15px;
                }

                .unidade-item {
                    width: 400px;
                    border-bottom: solid 2px #00AEEF;
                    padding: 35px 0 20px 0;
                    height: 100%;
                    border-radius: 20px 0px;
                    background: #FFF;
                    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
                    padding: 24px 28px;
                    max-width: 358px;
                    height: 220px;
                    margin-top: 56px;

                    &--title {
                        color: var(--tbl-neutral-neutral-500, #4F4F4F);

                        font-family: 'Revx Neue', sans-serif;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 18px;
                        margin-block: 32px
                    }

                    ul {
                        width: 100%;

                        li+li {
                            margin-top: 12px;
                        }
                    }

                    &--info {
                        a {
                            text-decoration: none;
                            display: flex;
                            align-items: center;
                            gap: 12px;

                            color: var(--tbl-neutral-neutral-500, #4F4F4F);
                            font-family: inherit;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 25px;

                            i,
                            ion-icon {
                                color: var(--tbl-primary-primary-500, #00AEEF);
                                font-size: 20px;
                            }
                        }
                    }

                    .socials {
                        display: flex;
                        gap: 20px;
                        margin-block-start: 40px;

                        a {
                            display: flex;
                            align-items: center;
                            gap: 12px;

                            i {
                                color: var(--tbl-primary-primary-500, #00AEEF);
                                font-size: 20px;
                            }
                        }

                    }
                }
            }
        }

        @media (width < 991px) {
            .mask-text {
                inset: -40px auto auto 0;
                position: relative !important;
                margin-block: 64px 32px;
                width: fit-content;
                display: block;
                margin-left: auto;

                h3 {
                    font-size: 90px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 80px;
                    text-align: end;

                    word-break: break-all;
                    inset: 0 15px 0 auto !important;
                    max-width: 280px;
                }
            }

            &-container {
                flex-direction: column;
                gap: 0;
                padding: 0;
                justify-content: start;

                &--text {
                    width: 100%;
                    padding-inline: 25px;

                    h2 {
                        font-size: 28px;
                        line-height: 28px;
                        max-width: 323px;

                        strong {
                            font-size: 28px;
                            line-height: 28px;
                        }
                    }

                    p {
                        font-size: 14px;
                        line-height: 18px;
                        max-width: 100%;
                    }
                }

                &--img {}
            }

            &-list {
                padding-inline: 25px;

                &--container {
                    padding-block: 0 60px;
                    justify-content: center;
                }
            }
        }
    }

    .duvidas {
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: center;
        gap: 48px;
        padding: 207px 0 80px 77px;
        z-index: 99;
        position: relative;
        .mask-text {
            inset: -351px auto auto 107px;

            h3 {
                font-family: 'Revx Neue', sans-serif;
                font-size: 13vw;
                font-style: normal;
                font-weight: 700;
                line-height: 100px;
                /* 40% */

                color: transparent;
                -webkit-text-stroke: 2px #00AEEF;
                /* width and color of the stroke */
                text-stroke: 2px #00AEEF;
                /* Standard syntax, if supported */
                background-clip: text;
                position: relative;
                bottom: -50px;
                left: 0;
                filter: blur(0);
            }
        }

        &-container {
            display: flex;
            justify-content: end;
            gap: 133px;
            max-width: 1598px;

            &--text {
                max-width: 621px;
                align-self: center;
                position: relative;

                .divider {
                    height: 4px;
                    width: 63px;
                    background: #00AEEF;
                    margin-bottom: 35px;
                }

                h2 {
                    color: #383838;

                    font-family: 'Revx Neue', sans-serif;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 50px;
                    max-width: 235px;
                    margin-block-end: 26px;

                    strong {
                        font-family: inherit;
                        font-weight: 700;
                    }
                }

                .text-container {
                    p+p {
                        margin-top: 26px;
                    }

                    p {
                        color: var(--tbl-default-black, #383838);

                        font-family: inherit;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25px;
                        /* 156.25% */

                        strong {
                            font-weight: 700;
                            color: var(--tbl-primary-primary-500, #00AEEF);
                        }
                    }
                }
            }

            &--img {
                z-index: -1;

                img {
                    max-width: 100%;
                }
            }
        }

        &-accordion-container {
            width: Min(100%, 1597px);
            padding-inline-end: 77px;

            .accordion-item {
                max-width: 1278px;
            }

            .accordion-button {
                color: var(--tbl-neutral-neutral-500, #4F4F4F);

                font-family: 'Revx Neue', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;
                /* 100% */
                padding-top: 32px;
                padding-bottom: 32px;
                width: 100%;

                &::after {
                    background-size: contain;
                    filter: invert(55%) sepia(71%) saturate(3790%) hue-rotate(164deg) brightness(101%) contrast(101%);
                    margin-left: 32px;
                    width: 32px;
                    height: 20px;
                }

                &:focus {
                    box-shadow: none;
                }
            }

            .accordion-button:not(.collapsed) {
                background: #fff;
                box-shadow: none;
                color: #00AEEF;
            }

            .text-accordion {
                color: var(--tbl-neutral-neutral-500, #4F4F4F);

                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                /* 156.25% */
            }

            .accordion-body {
                display: flex;
                gap: 32px;
                flex-direction: column;
                padding-bottom: 32px;
                border-bottom: 1px solid var(--bertolini-secondary-secondary-50, #E6F3F8);
            }
        }

        .btn-container {
            padding-inline-end: 77px;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 185px;
                margin-top: 80px;
                background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                color: var(--tbl-default-white, #FFF);
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                z-index: 1;
                text-decoration: none;
                overflow: hidden;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                    z-index: -1;
                    transition: 0.4s;
                    transition: transform 0.3s ease;
                    transform-origin: 0 100%;
                    will-change: transform;
                    transform: scaleY(0);
                }

                &:hover {
                    &::after {
                        transform: scaleY(1);
                    }
                }
            }
        }

        @media (width < 991px) {
            padding: 0 0 60px 25px;

            .mask-text {
                inset: -40px auto auto 0;
                position: relative !important;
                margin-block: 64px 32px;
                width: fit-content;
                display: block;
                margin-left: auto;

                h3 {
                    font-size: 90px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 80px;
                    text-align: end;
                    inset: 0 15px 0 auto !important;
                }
            }

            &-container {
                flex-direction: column-reverse;
                gap: 0;

                &--text {
                    max-width: 100%;
                    align-self: start;
                    padding-inline-end: 25px;

                    h2 {
                        font-size: 28px;
                        line-height: 28px;
                        max-width: 147px;
                        margin-bottom: 32px;

                        strong {
                            font-size: 28px;
                            line-height: 28px;
                        }
                    }

                    .text-container {
                        p+p {
                            margin-top: 20px;
                        }

                        p,
                        p>strong {
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }
                }
            }

            &-accordion-container {
                padding-inline-end: 25px;

                .accordion-button {
                    padding-inline: 0 25px;
                    justify-content: space-between;
                }

                .accordion-body {
                    padding: 0 25px 20px 0;
                }
            }

            .btn-container {
                padding-inline: 0 25px;
                width: 100%;

                a {
                    margin-top: 0;
                    width: 100%;
                }
            }
        }
    }


    .unidades-local {
        padding: 84px 0;
        background: #F5F5F5;
        position: relative;
        z-index: 99;
        padding-top: 4.375vw;
        @media (max-width: 1100px) {
            padding-bottom: 3rem;

        }

        .box-unidades {}

        .card-unidade {
            border-bottom: solid 2px #00AEEF;
            padding: 35px 0 20px 0;
            height: 100%;
            border-radius: 20px 0px;
            background: #FFF;
            box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
            padding: 24px 28px;
            max-width: 358px;
            height: 200px;
            margin-top: 56px;

            @media (max-width: 1100px) {
                padding-inline: 55px;
                padding-bottom: 3rem;
                height: auto;
            }

            a{
                text-decoration: none;
            }

            h2 {
                color: var(--tbl-neutral-neutral-500, #4F4F4F);
                /* H5 - Description */
                font-family: 'Revx Neue';
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;
                /* 100% */
                margin-bottom: 32px;
            }

            p {
                display: flex;
                gap: 12px;
                color: var(--tbl-neutral-neutral-500, #4F4F4F);
                /* H6 - Text Body */
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                /* 156.25% */
                margin-bottom: 12px;
            }

        }

        .redes {
            display: flex;
            gap: 20px;
            padding-top: 23px;
            border-top: 2px solid #fff;
            margin-top: 8px;

            a {
                text-decoration: none;
            }
        }
    }
}
