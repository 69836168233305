#services-detail {
    overflow-x: hidden;
    .banner-normal {
        .container{
            @media (max-width: 991px) {
                justify-content: end;
                gap: 60px;
            }
        }
        .content-text {
            position: relative;
            z-index: 9;
            display: flex;
            flex-direction: column;
            max-width: 583px;

            h1 {
                color: #FFF;
                font-family: 'Revx Neue', sans-serif;
                font-size: 70px;
                font-style: normal;
                font-weight: 700;
                line-height: 65px;
                /* 92.857% */
                margin-bottom: 37px;
                @media (max-width: 991px) {
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 35px; /* 100% */
                }
            }

            p {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                /* 156.25% */
                margin-bottom: 49px;
                max-width: 538px;
                @media (max-width: 991px) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px; /* 128.571% */
                }
            }

            .links {
                display: flex;
                gap: 46px;
                @media (max-width: 991px) {
                    align-items: center;
                    gap: 29px;
                }
            }
            .btn-banner{
                a{
                    @media (max-width: 991px) {
                        width: fit-content;
                        height: 46px;
                        padding: 12px 40px;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        white-space: pre;
                    }
                }
            }
            a {
                width: 239px;
            }

            .player {
                a {
                    width: auto;
                    height: auto;
                    background: transparent;
                    &::after {
                        display: none;
                    }
                }
                @media (max-width: 991px) {
                    width: 40px;
                    height: 40px;
                    a{
                        width: 40px;
                        height: 40px;
                        background: transparent;
                        padding: 0;
                    }
                }
            }
        }


    }

    #cardProfessionals {
        max-width: Min(1270px, 100%);
        position: relative;
        z-index: 3;

        .card-professionals-container {
            gap: 0 8px;
            margin-block: -198px 157px;
            isolation: isolate;

            @media(max-width: 1600px) {
                margin-block: -102px 157px;
            }

            @media (max-width: 1100px) {
                overflow-y: scroll;
            }

            .card-professional {
                padding: 34px 47px;
                color: #F5F5F5;
                height: 311px;
                mix-blend-mode: multiply;
                display: flex;
                flex-direction: column;
                justify-content: end;

                @media (max-width: 1100px) {
                    aspect-ratio: 1.1;
                }

                .conteudo {
                    position: unset;
                    z-index: 2;
                }

                &::after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    z-index: -1;
                    background: linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%);
                    mix-blend-mode: multiply;
                    transition: all .3s ease-in;
                }

                &:hover {
                    transition: all .3s ease-in;
                    margin-top: -15px;

                    ::after {
                        content: '';
                        position: absolute;
                        inset: 0;
                        z-index: -1;
                        background: linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #0084B5 100%);
                        transition: all .3s ease-in;
                    }
                }

                &-img {
                    inset: 0;
                    object-fit: cover;
                    height: 100%;
                    z-index: -2;
                }

                &-title {
                    font-family: 'Revx Neue', sans-serif;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px;
                    /* 100% */
                    margin-bottom: 11px;
                }

                &-text {
                    font-family: inherit;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    /* 156.25% */
                }

                &-title,
                &-text {
                    z-index: 2;
                }
            }
        }
    }

    .section1 {
        position: relative;
        padding-top: 169px;
        padding-bottom: 110px;
        @media (max-width: 1100px) {
            padding-top: 0;
            padding-bottom: 0;
        }
        .row {
            @media (max-width: 1100px) {
                flex-direction: column-reverse;
                gap: 105px;
            }
        }
        .title-section {
            margin-bottom: 23px;
            margin-top: 79px;
            @media (max-width: 1100px) {
                padding-inline: 17.5px;
            }
        }

        p {
            color: #383838;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 621px;
            margin-bottom: 26px;

            strong {
                color: #00AEEF;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
                padding-inline: 17.5px;
                strong{
                    line-height: 18px; /* 128.571% */
                    font-size: 14px;
                }
            }
        }

        .video-section {
            position: absolute;
            width: 44%;
            height: 745px;
            top: 67px;
            left: 0;
            padding: 0;


            @media(max-width: 1600px) {
                max-width: 48%;
            }
            @media (max-width: 1100px) {
                max-width: 100%;
                position: relative;
                width: 100%;
                height: 323px;
                top: 0;
                left: 0;
            }
            .video {
                position: relative;
                @media (max-width: 1100px) {
                    width: 90%;
                    height: 100%;
                }
                .img-video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }


                .player img {
                    position: absolute;
                    top: calc(50% - 39px);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                .linhas {
                    position: absolute;
                    left: 0;
                    top: -16px;
                    @media (max-width: 1100px) {
                        display: none;
                    }
                    img {
                        transform: rotate(180deg);
                    }
                }
            }
        }


        .texto-section {
            font-family: "Revx Neue";
            font-size: 14vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #00AEEF;
            text-stroke: 2px #00AEEF;
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: 19px;
            left: 17px;
            right: 0;
            margin: 0 auto;
            text-align: center;
            filter: blur(0);
            @media (max-width: 1100px) {
                font-size: 14vw;
                line-height: 95px;
                text-align: left;
                left: 0;
                top: 350px;
            }
        }


    }

    .section2 {
        position: relative;
        padding-top: 287px;
        padding-bottom: 254px;
        @media (max-width: 1100px) {
            padding-top: 30px;
            padding-bottom: 60px;
        }
        .row{
            @media (max-width: 1100px) {
                flex-direction: column-reverse;
                gap: 260px;
            }
            @media (max-width: 991px) {
                gap: 195px;
            }
        }
        .title-section {
            margin-left: 72px;
            margin-bottom: 23px;
            @media (max-width: 1100px) {
                margin-left: 0;
                padding-inline: 17.5px;
            }
        }

        p {
            color: #383838;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 621px;
            margin-bottom: 26px;
            margin-left: 72px;

            strong {
                color: #00AEEF;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
            @media (max-width: 1100px) {
                margin-left: 0;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
                padding-inline: 17.5px;
                strong{
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px;
                }
            }
        }

        .video-section {
            position: absolute;
            width: 44%;
            height: 745px;
            top: 106px;
            right: 0;
            padding: 0;

            @media(max-width: 1600px) {
                max-width: 42%;
            }
            @media (max-width: 1100px) {
                max-width: 100%;
                position: relative;
                width: 100%;
                height: 323px;
                top: 0;
                right: 0;
                display: flex;
                justify-content: flex-end;
            }

            .video {
                position: relative;
                @media (max-width: 1100px) {
                    width: 90%;
                }
                .img-video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }


                .player img {
                    position: absolute;
                    top: calc(50% - 39px);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                .linhas {
                    position: absolute;
                    right: 0;
                    bottom: -20px;
                    @media (max-width: 1100px) {
                        display: none;
                    }
                }
            }
        }


        .texto-section {
            font-family: "Revx Neue";
            font-size: 14vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #00AEEF;
            text-stroke: 2px #00AEEF;
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: 58px;
            left: 0px;
            right: 0;
            margin: 0 auto;
            text-align: start;
            filter: blur(0);
            @media (max-width: 1100px) {
                font-size: 14vw;
                width: 100%;
                word-break: break-all;
                left: 0;
                text-align: end;
                top: 410px;
                line-height: 60px;
                -webkit-text-stroke: 1.5px #00aeef;
            }
        }


    }

    .section3 {
        position: relative;
        padding-top: 156px;
        padding-bottom: 111px;
        background-color: #F5F5F5;

        @media(max-width: 1400px) {
            padding-bottom: 0px;
        }

        @media(max-width: 991px) {
            padding-bottom: 40px;
        }

        .title-section {
            text-align: center;

            &::after {
                right: 0;
                left: 0;
                margin: 0 auto;
            }
        }

        p {
            color: #4F4F4F;
            text-align: center;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 627px;
            margin: 0 auto;
            @media (max-width: 991px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
                padding-inline: 17.5px;
            }
        }

        .blocos {
            margin-top: 83px;
            @media (max-width: 991px) {
                margin-top: 50px;
                padding-inline: 17.5px;
            }
            .bloco {
                max-width: 406px;
                height: 271px;
                border-radius: 20px 0px;
                background: #FFF;
                box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
                text-align: center;
                padding: 40px 20px;
                margin-bottom: 30px;

                h3 {
                    color: #0084B5;
                    font-family: 'Revx Neue';
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                    margin-top: 12px;
                    margin-bottom: 20px;
                    @media (max-width: 991px) {
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 37px; /* 168.182% */
                    }
                }

                p {
                    color: #383838;
                    text-align: center;
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    @media (max-width: 991px) {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px; /* 128.571% */
                    }
                }
                img{
                    @media (max-width: 991px) {
                        max-width: 40px;
                    }
                }
                &:hover {
                    background-color: #0084B5;
                    transition: 0.3s ease;

                    img {
                        filter: grayscale(100%) brightness(2);
                        transition: 0.3s ease;
                    }

                    h3,
                    p {
                        color: #fff;
                        transition: 0.3s ease;
                    }
                }
            }
        }


        .texto-section {
            font-family: "Revx Neue";
            font-size: 14vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100px;
            color: transparent;
            -webkit-text-stroke: 2px #00AEEF;
            text-stroke: 2px #00AEEF;
            -webkit-background-clip: text;
            background-clip: text;
            z-index: 99;
            position: absolute;
            top: -60px;
            left: 0px;
            right: 0;
            margin: 0 auto;
            text-align: end;
            filter: blur(0);

            @media(max-width: 991px) {
                font-size: 14vw;
                word-break: break-word;
                margin: 0;
                max-width: 75%;
                text-align: left;
                left: 15px;
                line-height: 57px;
                -webkit-text-stroke: 1.5px #00aeef;
                top: -37px;
            }
        }
    }

    .title-section {
        color: var(--tbl-default-black, #383838);
        font-family: 'Revx Neue';
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        margin-bottom: 34px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 63px;
            height: 4px;
            background-color: #00AEEF;
            top: -38px;
            left: 0;
            @media (max-width: 991px) {
                left: 17.5px;
            }
        }

        strong {
            color: var(--tbl-default-black, #383838);
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px;
        }
        @media (max-width: 991px) {
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 100% */
            strong{
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
            }
        }
    }

    .slides {
        margin-top: 118px;
        margin-bottom: 150px;
        @media (max-width: 991px) {
            margin-bottom: -36px;
            z-index: 9;
            position: relative;
        }
        .title-section {
            text-align: center;

            &::after {
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }

        p {
            color: #4F4F4F;
            text-align: center;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 627px;
            margin: 0 auto;
            @media (max-width: 991px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
            }
        }

        .slider-section {
            position: relative;
            margin-top: 82px;
            @media (max-width: 991px) {
                margin-top: 40px;
            }
            .slider.center {
                .cl {
                    height: 544px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    @media (max-width: 991px) {
                        height: 354px;
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    }
                    .content {
                        width: 100%;
                        height: 100%;
                        position: relative;

                        .texto {
                            position: absolute;
                            bottom: 69px;
                            left: 50px;
                            max-width: 339px;
                            z-index: 2;

                            h4 {
                                color: #F5F5F5;
                                font-family: 'Revx Neue';
                                font-size: 30px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 30px;
                                margin: 20px 0;
                            }

                            p {
                                color: #F5F5F5;
                                font-family: 'Roboto';
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 25px;
                                text-align: left;
                            }
                        }

                        .layer {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: var(--tbl-gradients-effects-gradient-linear, linear-gradient(180deg, rgba(0, 132, 181, 0.00) 0%, #849550 100%));
                            mix-blend-mode: multiply;
                        }
                    }
                }

                .slick-active {
                    padding: 30px 0;
                }

                .slick-center {
                    transform: scale(1.1);
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    z-index: 99;
                    position: relative;
                    max-width: 540px;
                    @media (max-width: 991px) {
                        box-shadow: none;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 4px;
                        background-color: #0084B5;
                        bottom: 34px;
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                        @media (max-width: 991px) {
                            background: #00000040;
                            height: calc(100% - 68px);
                            bottom: 38px;
                        }
                    }
                }

                .slick-slide:not(.slick-active) {
                    margin: 30px 0;
                }

                .child {
                    width: 100%;
                }

                .slide:not(.slick-active) {
                    cursor: pointer;
                }

                .layer-blue {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0.00) 100%);
                }
            }

            .center-prev,
            .center-next {
                position: absolute;
                top: calc(50% - 64px);
                cursor: pointer;
                z-index: 2;
                @media (max-width: 1100px) {
                    display: none;
                }
            }

            .center-prev {
                left: 88px;
            }

            .center-next {
                right: 88px
            }

            .layer-green {
                position: absolute;
                width: 476px;
                height: calc(100% - 64px);
                top: 30px;
                background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0.00) 100%);
                z-index: 1;
                @media (max-width: 1100px) {
                    display: none;
                }
            }

            .layer-green-second {
                position: absolute;
                width: 476px;
                height: calc(100% - 64px);
                top: 30px;
                right: 0;
                background: linear-gradient(90deg, #0084B5 0%, rgba(0, 132, 181, 0.00) 100%);
                z-index: 1;
                transform: rotate(180deg);
                @media (max-width: 1100px) {
                    display: none;
                }
            }
        }
    }

    .local {
        position: relative;
        overflow: hidden;
        padding-bottom: 240px;
        background: #f5f5f5;
        padding-top: 165px;
        @media (max-width: 1100px) {
            padding-bottom: 40px;
            padding-top: 65px;
        }
        p {
            color: var(--tbl-default-black, #383838);
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            max-width: 406px;
            margin-bottom: 26px;

            strong {
                color: var(--tbl-primary-primary-500, #00AEEF);
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
                padding-inline: 17.5px;
                strong{
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px;
                }
            }
        }
        .title-section{
            @media (max-width: 1100px) {
                padding-inline: 17.5px;
            }
        }
        .btn-section {
            height: 50px;
            width: 190px;
            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            color: var(--tbl-default-white, #FFF);
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 51px;
            margin-right: 47px;
            text-decoration: none;
            position: relative;
            z-index: 1;
            overflow: hidden;
            margin-top: 89px;

            @media (max-width: 1100px) {
                margin: 0 auto;
                margin-bottom: 40px;
                margin-top: 40px;
                width: 100%;
                width: calc(100% - 35px);
            }
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                z-index: -1;
                transition: 0.4s;
                transition: transform 0.3s ease;
                transform-origin: 0 100%;
                will-change: transform;
                transform: scaleY(0);
            }

            &:hover {
                &::after {
                    transform: scaleY(1);
                }
            }
        }

        .retangulo {
            position: absolute;
            bottom: -137px;
            right: 0;
            width: 50%;
            height: 656px;
            background: #F1F1F1;
            @media (max-width: 1100px) {
                display: none;
            }
        }

        .mapa {
            position: absolute;
            right: 0;
            top: -263px;
            img{
                transform: translate(100px, 120px) scale(0.75);
            }
            @media(max-width: 1600px) {
                top: -197px;
                width: 68%;
            }

            @media (max-width: 1100px) {
                position: relative;
                width: 100%;
                top: 0;
                right: 0;
                height: 400px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: none;
                }
            }

        }
    }

}
