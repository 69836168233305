#workWithUs{
    .oportunidade{
        position: relative;
        padding-bottom: 130px;
        padding-top: 220px;
        @media (max-width: 1399px) {

        }
        @media (max-width: 1100px) {
            padding-bottom: 60px;
            padding-top: 25px;
        }
        .wrap-img{
            position: absolute;
            right: 0;
            aspect-ratio: 1.1315;
            max-width: 43.906vw;
            @media (max-width: 1100px) {
                position: relative;
                display: flex;
                justify-content: flex-end;
                max-width: inherit;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                @media (max-width: 1100px) {
                    width: 90%;
                }
            }
            .listras{
                position: absolute;
                width: 256px;
                height: 39px;
                bottom: -15px;
                right: 0;
                object-fit: contain;
                object-position: right;
                @media (max-width: 1100px) {
                    display: none;
                }
            }
        }
        .text-absolute{
            top: 80px;
            right: 0;
            width: 100%;
            overflow: hidden;
            height: 250px;
            position: absolute;
            h3{
                font-family: 'Revx Neue';
                font-size: 14vw;
                font-style: normal;
                font-weight: 700;
                line-height: 100px; /* 40% */
                color: transparent;
                -webkit-text-stroke: 2px #00AEEF; /* width and color of the stroke */
                text-stroke: 2px #00AEEF; /* Standard syntax, if supported */
                background-clip: text;
                position: relative;
                right: 0px;
                text-align: end;
                bottom: -80px;
                filter: blur(0);
            }
            @media (max-width: 1400px) {
                h3{
                    font-size: 14vw;
                }
            }
            @media (max-width: 1100px) {
                height: auto;
                width: 90%;
                top: 35%;
                right: 15px;
                h3{
                    font-size: 70px;
                    line-height: 90px;
                    top: 0;
                    right: 0;
                    word-break: break-word;
                }
            }
        }
        .content-text{
            padding-top: 240px;
            @media (max-width: 1399px) {
                padding-top: 100px;
            }
            @media (max-width: 1100px) {
                padding-inline: 17.5px;
            }
            h2{
                color: #383838;
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 400;
                line-height: 50px; /* 111.111% */
                margin-bottom: 26px;
                position: relative;
                &::after{
                    position: absolute;
                    top: -35px;
                    left: 0;
                    width: 63px;
                    height: 4px;
                    background: #00AEEF;
                    content: '';
                }
                span{
                    color: #383838;
                    /* H2 - Title bold */
                    font-family: 'Revx Neue';
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 50px;
                }
                @media (max-width: 1100px) {
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28px; /* 100% */
                    span{
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 28px;
                    }
                }
            }
            p{
                color: var(--tbl-default-black, #383838);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px; /* 156.25% */
                span{
                    color: var(--TBL-Primary-primary-500, #00AEEF);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 25px;
                }
                @media (max-width: 1100px) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px; /* 128.571% */
                    margin-bottom: 20px;
                    span{
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                    }
                }
            }
            a{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 185px;
                background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                color: var(--tbl-default-white, #FFF);
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                z-index: 1;
                text-decoration: none;
                overflow: hidden;
                position: relative;
                margin-top: 80px;
                &::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                    z-index: -1;
                    transition: 0.4s;
                    transition: transform 0.3s ease;
                    transform-origin: 0 100%;
                    will-change: transform;
                    transform: scaleY(0);
                }
                &:hover{
                    &::after{
                        transform: scaleY(1);
                        }
                    }
                    @media (max-width: 1100px) {
                        width: 100%;
                    }
            }
        }
    }
    .vagas-destaque{
        padding-top: 190px;
        padding-bottom: 14.844vw;
        background: var(--tbl-default-white-off, #F5F5F5);
        @media (max-width: 1100px) {
            padding-top: 100px;
            padding-inline: 17.5px;
            padding-bottom: 60px;
        }
        .destaque-title{
            color: var(--tbl-default-black, #383838);
            text-align: center;
            /* H2 - Title Regular */
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px; /* 111.111% */
            margin-bottom: 32px;
            position: relative;
            span{
                color: var(--tbl-default-black, #383838);
                /* H2 - Title bold */
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }
            &::after{
                position: absolute;
                top: -35px;
                width: 63px;
                height: 4px;
                background: #00AEEF;
                content: '';
                left: 50%;
                transform: translateX(-50%);
            }
            @media (max-width: 1100px) {
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px; /* 100% */
                span{
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
        }
        .destaque-text{
            color: var(--TBL-Neutral-neutral-500, #4F4F4F);
            text-align: center;
            /* H6 - TextBody */
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 128.571% */
            margin-bottom: 50px;
        }
        .card-destaque{
            display: flex;
            padding: 40px 32px;
            flex-direction: column;
            align-items: center;
            flex-shrink: 0;
            background: #fff;
            border-radius: 20px 0px;
            box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
            margin-bottom: 30px;
            @media (max-width: 1100px) {
                margin-bottom: 20px;
            }
            .title{
                color: var(--tbl-neutral-neutral-500, #4F4F4F);
                /* H5 - Description */
                font-family: 'Revx Neue';
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px; /* 100% */
                margin-bottom: 20px;
            }
            p{
                color: var(--tbl-neutral-neutral-500, #4F4F4F);
                text-align: center;
                /* H6 - Text Body */
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px; /* 156.25% */
                margin-bottom: 40px;
                @media (max-width: 1100px) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px; /* 128.571% */
                }
            }
            a{
                color: var(--tbl-primary-primary-500, #00AEEF);
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 25px; /* 156.25% */
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    .text-vagas{
        @media (max-width: 1100px) {
            padding-inline: 17.5px;
        }
        h1{
            color: #383838;
            /* H2 - Title Regular */
            font-family: 'Revx Neue';
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px; /* 111.111% */
            margin-bottom: 26px;
            position: relative;
            &::after{
                position: absolute;
                top: -35px;
                left: 0;
                width: 63px;
                height: 4px;
                background: #00AEEF;
                content: '';
            }
            span{
                color: #383838;
                /* H2 - Title bold */
                font-family: 'Revx Neue';
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px;
            }
            @media (max-width: 1100px) {
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px; /* 100% */
                span{
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                }
            }
        }
        p{
            color: var(--tbl-default-black, #383838);
            /* H6 - Text Body */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 156.25% */
            margin-bottom: 62px;
            @media (max-width: 1100px) {
                margin-bottom: 32px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
            }
        }
    }
    .nossas-vagas{
        position: relative;
        padding-top: 190px;
        padding-bottom: 237px;
        @media (max-width: 1100px) {
            padding-bottom: 75px;
        }
        .btn-load-more{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: calc(100% - 35px);
            margin: 0 auto;
            margin-top: 40px;
            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            color: var(--tbl-default-white, #FFF);
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            z-index: 1;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                z-index: -1;
                transition: 0.4s;
                transition: transform 0.3s ease;
                transform-origin: 0 100%;
                will-change: transform;
                transform: scaleY(0);
            }
            &:hover{
                &::after{
                    transform: scaleY(1);
                    }
                }
                @media (max-width: 1100px) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
        }
        .accordions-box{
            .accordion-button{
                color: var(--tbl-neutral-neutral-500, #4F4F4F);
                /* H5 - Description */
                font-family: 'Revx Neue';
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px; /* 100% */
                padding-top: 32px;
                padding-bottom: 32px;
                width: 100%;
                &::after{
                    background-size: contain;
                    filter: invert(55%) sepia(71%) saturate(3790%) hue-rotate(164deg) brightness(101%) contrast(101%);
                    margin-left: 32px;
                    width: 32px;
                    height: 20px;
                }
                &:focus{
                    box-shadow: none;
                }
                @media (max-width: 1100px) {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16px; /* 100% */
                    padding-bottom: 20px;
                    padding-top: 20px;
                }
            }
            .accordion-button:not(.collapsed){
                background: #fff;
                box-shadow: none;
                color: #00AEEF;
            }
            .text-accordion{
                color: var(--tbl-neutral-neutral-500, #4F4F4F);
                /* H6 - Text Body */
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px; /* 156.25% */
                @media (max-width: 1100px) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px; /* 128.571% */
                }
            }
            .accordion-body{
                display: flex;
                gap: 32px;
                flex-direction: column;
                padding-bottom: 32px;
                @media (max-width: 1100px) {
                    gap: 20px;
                }
            }
            .detail-accordion-box{
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                @media (max-width: 1100px) {
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 32px;
                }
                a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 50px;
                    width: 185px;
                    background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                    color: var(--tbl-default-white, #FFF);
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    z-index: 1;
                    text-decoration: none;
                    overflow: hidden;
                    position: relative;
                    &::after{
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                        z-index: -1;
                        transition: 0.4s;
                        transition: transform 0.3s ease;
                        transform-origin: 0 100%;
                        will-change: transform;
                        transform: scaleY(0);
                    }
                    &:hover{
                        &::after{
                            transform: scaleY(1);
                            }
                        }
                        @media (max-width: 1100px) {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }
                }
            }
            .details{
                display: flex;
                flex-direction: column;
                gap: 12px;
                p{
                    color: var(--bertolini-support-support-400, #758187);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px; /* 150% */
                span{
                    color: var(--bertolini-support-support-400, #758187);
                    /* Desktop/H6 - Text Body */
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                }
                @media (max-width: 1100px) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18px; /* 128.571% */
                    span{
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                    }
                }
                }

            }
        }
        .text-absolute{
            top: -130px;
            right: 0;
            width: 100%;
            overflow: hidden;
            height: 250px;
            position: absolute;
            h3{
                font-family: 'Revx Neue';
                font-size: 14vw;
                font-style: normal;
                font-weight: 700;
                line-height: 100px; /* 40% */
                color: transparent;
                -webkit-text-stroke: 2px #00AEEF; /* width and color of the stroke */
                text-stroke: 2px #00AEEF; /* Standard syntax, if supported */
                background-clip: text;
                position: relative;
                right: 0px;
                text-align: end;
                bottom: -80px;
                filter: blur(0);
            }
            @media (max-width: 1400px) {
                h3{
                    font-size: 14vw;
                }
            }
            @media (max-width: 1100px) {
                height: auto;
                width: 80%;
                top: -40px;
                right: 15px;
                h3{
                    font-size: 85px;
                    line-height: 85px;
                    left: 0;
                    right: 0;
                    word-break: break-all;
                    top: 0;
                }
            }
        }
        .box-filter{
            display: flex;
            justify-content: space-between;
            height: 60px;
            .input-group{
                button{
                    position: absolute;
                    right: 32px;
                    transform: translate(0, -50%);
                    top: 50%;
                    span{
                        display: none;
                        color: var(--bertolini-white, #FFF);
                        /* Desktop/Labels */
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px; /* 150% */
                    }
                }
            }
            .input-filter{
                width: 100%;
                height: 100%;
                padding-left: 32px;
                border: none;
                background: var(--tbl-primary-primary-500, #00AEEF);
                color: #FFF;
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%; /* 17px */
                transition: all 0.3s ease-in-out;
                &::placeholder{
                    color: #FFF;
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%; /* 17px */
                }
                &:focus-visible{
                    background: var(--tbl-primary-primary-700, #007CAA);
                }
            }
        }
        /* Filter Button */
            .filter-btn {
                background: var(--tbl-neutral-neutral-200, #AEAEAE);
                color: #fff;
                border: 2px solid #A9A9A9; /* Border color */
                cursor: pointer;
                font-size: 17px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 17px */
                letter-spacing: 1.7px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                gap: 80px;
                padding-inline: 18px;
                @media (max-width: 1100px) {
                    padding-inline: 25px;
                }
            }

            .filter-icon {
                margin-left: 5px;
            }

            /* Filter Panel - initially hidden */
            .filter-panel {
                display: none; /* Hidden by default */
                width: 100%;
                border: 1px solid #E0E0E0;
                background-color: #F5F5F5;
                position: relative;
                z-index: 9;
            }

        .filter-header {
            background-color: #fff; /* Dark gray background */
            color: var(--tbl-neutral-neutral-500, #4F4F4F);
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 17px */
            letter-spacing: 1.7px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 58px;
            position: relative;
            padding-inline: 20px;
            &::after{
                content: '';
                position: absolute;
                bottom: 0;
                left: 20px;
                width: 83%;
                height: 1px;
                background: #AEAEAE;
            }
        }

        .filter-header span {
            font-weight: bold;
        }

        .close-button {
            background: none;
            border: none;
            color: white;
            font-size: 16px;
            cursor: pointer;
        }

        .filter-options {
            padding-inline: 20px;
            background: #fff;
        }

        .filter-options label {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            cursor: pointer;
            height: 58px;
            color: var(--tbl-neutral-neutral-500, #4F4F4F);
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: 1.5px;
            border-bottom: 1px solid #AEAEAE;
        }

        .filter-options input[type="checkbox"] {
            margin-right: 10px;
        }

        .accordions-box{
            margin-top: 80px;
            @media (max-width: 1100px) {
                margin-top: 40px;
            }
        }
    }
    .candidate{
        padding-top: 200px;
        background: var(--tbl-default-white-off, #F5F5F5);
        position: relative;
        @media (max-width: 1100px) {
            padding-top: 150px;
        }
        .text-absolute{
            top: -135px;
            left: 0;
            width: 100%;
            overflow: hidden;
            height: 250px;
            position: absolute;
            h3{
                font-family: 'Revx Neue';
                font-size: 14vw;
                font-style: normal;
                font-weight: 700;
                line-height: 100px; /* 40% */
                color: transparent;
                -webkit-text-stroke: 2px #00AEEF; /* width and color of the stroke */
                text-stroke: 2px #00AEEF; /* Standard syntax, if supported */
                background-clip: text;
                position: relative;
                text-align: start;
                bottom: -80px;
                left: 0px;
                filter: blur(0);
            }
            @media (max-width: 1400px) {
                h3{
                    font-size: 14vw;
                }
            }
            @media (max-width: 1100px) {
                height: auto;
                width: 100%;
                right: 0;
                left: inherit;
                top: -40px;
                h3{
                    font-size: 76px;
                    line-height: 85px;
                    top: 0;
                    right: 0;
                    word-break: break-word;
                    left: inherit;
                    text-align: end;
                }
            }
        }
    }

    .form-candidate{
        background: var(--tbl-default-white-off, #F5F5F5);
        padding-bottom: 165px;
        @media (max-width: 1100px) {
            padding-bottom: 82px;
        }
        .form-select{
            height: 72px;
            background: var(--tbl-primary-primary-500, #00AEEF);
            color: var(--bertolini-white, #FFF);
            font-family: 'Roboto';
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 17px */
            --bs-form-select-bg-img: url("../img/arrow-down.svg");
            padding-inline: 32px;
            background-image: url("../img/arrow-down.svg") ;
            background-repeat: no-repeat;
            background-position: right 24px center;
            background-size: 24px;
        }
        .box-selected-job{
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-bottom: 56px;
            .selected-job{
                height: 72px;
                background: var(--tbl-primary-primary-700, #007CAA);
                padding-inline: 32px;
                color: var(--bertolini-white, #FFF);
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%; /* 17px */
                display: flex;
                justify-content: space-between;
                align-items: center;
                button{
                    color: var(--bertolini-white, #FFF);
                    /* Desktop/Labels */
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px; /* 150% */
                }
            }
        }
        .form-box{
            @media (max-width: 1100px) {
                padding-inline: 17.5px;
            }
            .btn-submit{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 185px;
                background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                color: var(--tbl-default-white, #FFF);
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                z-index: 1;
                text-decoration: none;
                overflow: hidden;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                    z-index: -1;
                    transition: 0.4s;
                    transition: transform 0.3s ease;
                    transform-origin: 0 100%;
                    will-change: transform;
                    transform: scaleY(0);
                }
                &:hover{
                    &::after{
                        transform: scaleY(1);
                        }
                    }
                    @media (max-width: 1100px) {
                        width: 100%;
                        margin-top: 56px;
                    }
            }
            .input-box{
                display: flex;
                flex-direction: column;
                textarea{
                    height: 200px;
                    background: var(--tbl-default-white, #FFF);
                    border: none;
                    padding-inline: 32px;
                    padding-top: 32px;
                    color: var(--tbl-neutral-neutral-300, #898989);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%; /* 16px */
                    margin-bottom: 24px;
                    &::placeholder{
                        color: var(--tbl-neutral-neutral-300, #898989);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%; /* 16px */
                    }
                }
                p{
                    color: var(--tbl-neutral-neutral-300, #898989);
                    font-family: 'Revx Neue';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px; /* 128.571% */
                    margin-top: 12px;
                }
                label{
                    color: var(--tbl-default-black, #383838);
                    font-family: 'Revx Neue';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px; /* 128.571% */
                    margin-bottom: 12px;
                }
                input{
                    background: var(--tbl-default-white, #FFF);
                    border: none;
                    height: 72px;
                    padding-inline: 32px;
                    color: var(--tbl-neutral-neutral-300, #898989);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%; /* 16px */
                    &::placeholder{
                        color: var(--tbl-neutral-neutral-300, #898989);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%; /* 16px */
                    }
                }
            }
            .form-check-input{
                --blue-primary: #00AEEF;
                border: 1px solid var(--blue-primary);
                border-radius: 1px !important;
                background-color: var(--blue-50, #F1FAFE);
                box-shadow: 0px 20px 44px 0px rgba(0, 0, 0, 0.20);
                width: 24px;
                height: 24px;
                &:checked {
                    background-color: var(--blue-primary);
                    border-color: var(--blue-primary);
                }
                &:focus {
                    border-color: var(--blue-primary);
                    outline: 0;
                    box-shadow: 0 0 0 1px var(--blue-primary);
                }
            }
            .form-check-label {
                color: var(--tbl-neutral-neutral-300, #898989);
                /* Desktop/btn */
                font-family: 'Revx Neue';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px; /* 100% */
                margin-top: 10px;
                margin-left: 20px;

                a {
                    &, &:hover {
                        color: inherit;
                        font-size: inherit;
                        text-decoration: underline;
                    }
                }
            }
        }
    }


    @media (max-width: 1100px) {

    }
}
