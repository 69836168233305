#rastreie {
    height: 100vh;
    background-color: #006083;

    display: grid;
    place-items: center;

    .wrapper {
        max-width: fit-content;
        text-align: center;

        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 32px;

        span {
            display: block;
            margin-inline: auto;

            svg {
                width: 40px;
                height: 40px;

                path {
                    fill: #f5f5f5;
                }
            }
        }
        h1 {
            color: #f5f5f5;
            font-family: 'Revx Neue', sans-serif;
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px;
        }
        button{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            width: 185px;
            background: var(--tbl-gradients-effects-gradient-btn, linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
            color: var(--tbl-default-white, #FFF);
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            z-index: 1;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: var(--Gradiente-Hover-BTN, linear-gradient(180deg, rgba(0, 96, 131, 0.00) 0%, #006083 100%), linear-gradient(180deg, #00AEEF 0%, #0084B5 134.33%));
                z-index: -1;
                transition: 0.4s;
                transition: transform 0.3s ease;
                transform-origin: 0 100%;
                will-change: transform;
                transform: scaleY(0);
            }
            &:hover{
                &::after{
                    transform: scaleY(1);
                    }
            }
            @media (max-width: 1100px) {
                width: 100%;
                margin-top: 40px;
            }
            a { text-decoration: none; }
        }
    }
}